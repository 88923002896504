import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    CreateRestaurantGroupInput,
    RestaurantGroup,
    RestaurantGroupWithUsers,
    Role,
    UpdateRestaurantGroupInput,
    UserProfile,
} from "../generated-interfaces/graphql";
import { Restaurant } from "../types/restaurant";
import { CreateUserAdminInput, UpdateUserAdminInput } from "../types/user";
import {
    CallbackFunction,
    DeleteItemType,
    RestaurantDetails,
} from "../utils/types";

export interface UserManagementState {
    users: {
        [userId: string]: UserProfile;
    };
    restaurants: {
        [restaurantId: string]: Restaurant;
    };
    restaurantGroups: {
        [restaurantGroupId: string]: RestaurantGroup;
    };
    restaurantGroupsWithUsers: {
        [groupName: string]: RestaurantGroupWithUsers;
    };
    roles: {
        [roleId: string]: Role;
    };
}

export const initialState: UserManagementState = {
    users: {},
    restaurants: {},
    restaurantGroups: {},
    restaurantGroupsWithUsers: {},
    roles: {},
};

export const getUserManagement = createAction("GET_USER_MANAGEMENTS");
export const createUser = createAction<CreateUserAdminInput & CallbackFunction>(
    "CREATE_USER"
);
export const updateUser = createAction<UpdateUserAdminInput & CallbackFunction>(
    "UPDATE_USER"
);
export const deleteUser = createAction<DeleteItemType>("DELETE_USER");
export const createRestaurant = createAction<
    RestaurantDetails & CallbackFunction
>("CREATE_RESTAURANT");
export const updateRestaurant = createAction<
    RestaurantDetails & CallbackFunction
>("DELETE_RESTAURANT");
export const createRestaurantGroup = createAction<
    CreateRestaurantGroupInput & CallbackFunction
>("CREATE_RESTAURANT_GROUP");
export const updateRestaurantGroup = createAction<
    UpdateRestaurantGroupInput & CallbackFunction
>("UPDATE_RESTAURANT_GROUP");
export const deleteRestaurantGroup = createAction<DeleteItemType>(
    "DELETE_RESTAURANT_GROUP"
);

export interface Payload {
    users: UserProfile[];
    restaurants: Restaurant[];
    restaurantGroups: RestaurantGroup[];
    restaurantGroupsWithUsers: RestaurantGroupWithUsers[];
    roles: Role[];
}

export const userManagementState = createSlice({
    name: "userManagement",
    initialState: initialState,
    reducers: {
        loadUserManagementSuccess: (state, action: PayloadAction<Payload>) => {
            state.users = action.payload.users.reduce((acc, user) => {
                acc[user.id] = user;
                return acc;
            }, {} as UserManagementState["users"]);
            state.restaurants = action.payload.restaurants.reduce(
                (acc, restaurant) => {
                    acc[restaurant.id] = restaurant;
                    return acc;
                },
                {} as UserManagementState["restaurants"]
            );
            state.restaurantGroups = action.payload.restaurantGroups.reduce(
                (acc, restaurantGroup) => {
                    acc[restaurantGroup.id] = restaurantGroup;
                    return acc;
                },
                {} as UserManagementState["restaurantGroups"]
            );
            state.restaurantGroupsWithUsers = action.payload.restaurantGroupsWithUsers.reduce(
                (acc, restaurantGroupWithUser) => {
                    acc[
                        restaurantGroupWithUser.groupName
                    ] = restaurantGroupWithUser;
                    return acc;
                },
                {} as UserManagementState["restaurantGroupsWithUsers"]
            );
            state.roles = action.payload.roles.reduce((acc, user) => {
                acc[user.id] = user;
                return acc;
            }, {} as UserManagementState["roles"]);
        },
    },
});

export const USER_MANAGEMENT_ACTIONS = userManagementState.actions;
