import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { MouseEvent } from "react";
import { BACK_DROP_CLICK } from "../constants";
import { UserSession } from "../types/menu";
import { getSessionID } from "../utils/local-storage";

interface IProps {
    open: boolean;
    userSessions: UserSession[];
    onClose: () => void;
}
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    },
    close: {
        position: "absolute",
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
    },
    table: {
        minWidth: 650,
    },
    userid: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: theme.spacing(1),

        "& .MuiChip-root": {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
        },
    },
}));

const USER_ID = "User id",
    FIRST_NAME = "First Name",
    LAST_NAME = "Last Name",
    SOURCE_MODULE = "Source Module";
export default function ActiveSessionConfirmDialog({
    open,
    userSessions,
    onClose,
}: IProps) {
    const classes = useStyles();

    const currentUserSession = getSessionID();
    const handleClose = (e: MouseEvent, reason: string) => {
        if (reason !== BACK_DROP_CLICK) {
            onClose();
        }
    };

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            {<DialogTitle> Active Users </DialogTitle>}
            <IconButton className={classes.close} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="active user table"
                    >
                        <TableHead>
                            <TableRow>
                                {[
                                    USER_ID,
                                    FIRST_NAME,
                                    LAST_NAME,
                                    SOURCE_MODULE,
                                ].map((heading) => (
                                    <TableCell align="left" key={heading}>
                                        {heading}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userSessions.map(
                                ({
                                    unique_user_id,
                                    user_session_id,
                                    first_name,
                                    last_name,
                                    source_module,
                                }) => {
                                    const isCurrentSession =
                                        currentUserSession === user_session_id;
                                    return (
                                        <TableRow key={user_session_id}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <div className={classes.userid}>
                                                    <span>
                                                        {unique_user_id}
                                                    </span>
                                                    {isCurrentSession ? (
                                                        <Chip
                                                            variant="outlined"
                                                            size="small"
                                                            label="Current"
                                                        />
                                                    ) : null}
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                {first_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {last_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {source_module}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
