import { Switch, withStyles } from "@material-ui/core";

const ToggleSwitch = withStyles((theme) => ({
    switchBase: {
        color: "#FAE392",
        "&$checked": {
            color: "#FFC107",
        },
        "&$checked + $track": {
            backgroundColor: "#FFC107",
        },
    },
    checked: {},
    track: {},
}))(Switch);

export default ToggleSwitch;
