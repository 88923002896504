import Axios from "axios";
import { RestaurantInfo } from "../types/restaurant";
import {
    createUuid,
    readEnvVariable,
    snakeToCamelCase,
    toRequestedCase,
} from "../utils/helper-functions";
import { getAuthToken } from "../utils/local-storage";
import { StringOrNull } from "../utils/types";

export const restaurantApi = Axios.create({
    baseURL: readEnvVariable("RESTAURANT_API"),
    headers: {
        "Content-Type": "application/json",
    },
});

restaurantApi.interceptors.response.use(
    ({ data }) => toRequestedCase(data, snakeToCamelCase),
    (error) => {
        return Promise.reject(error);
    }
);

function setAuthSource(isAuth0: boolean, config: any) {
    if (isAuth0) {
        config.headers["Auth-source"] = "Auth0";
    }
}

export const fetchRestaurantInfoCall = async (
    restaurantCode: string,
    token: StringOrNull,
    isAuth0 = false,
    params?: { data: string }
) => {
    let config: any = {
        params: {
            request_id: createUuid(),
            ...(params || {}),
        },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSource(isAuth0, config);
    return restaurantApi.get<RestaurantInfo>(
        `/restaurants/${restaurantCode}`,
        config
    );
};

export const fetchRestaurantListCall = async (
    isAuth0: boolean,
    token: StringOrNull,
    params?: { data?: string }
) => {
    let config: any = {
        params: {
            request_id: createUuid(),
            ...(params || {}),
        },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSource(isAuth0, config);
    return restaurantApi.get<RestaurantInfo>(`/restaurants`, config);
};
