import { createSelector } from "@reduxjs/toolkit";
import { POS_ID, POS_NAME } from "../constants/menu";
import {
    Category,
    Maybe,
    MenuItem,
    MenuOverride,
    ModifierGroup,
    PropertyType,
    SortOrder,
    TimePeriod,
} from "../generated-interfaces/graphql";
import { isPropertySetToTrue } from "../pages/menu-editor/utils";
import { MenuState } from "../reducers/menuReducer";
import { RootState } from "../reducers/rootReducer";
import { InputProperties, LegacyMenuSchema, MenuSchemaV1 } from "../types/menu";
import {
    DAYS_SHORT,
    IS_UPSELL,
    IS_UPSELL_PROMPT,
    VOICE_PROPERTIES_DELIMITER,
} from "../utils/constants";
import { camelToSnakeCase, createUuid } from "./helper-functions";
import { GetIngestedDataType } from "./types";
import logger from "./logger";

export const renderTableCollectionText = (
    itemRecords: Record<string, any>,
    maxItems: number = 2,
    fieldName: string = "name"
) => {
    if (!itemRecords) return "";
    const items = itemRecords ? Object.values(itemRecords) : [];
    if (!items) {
        return "";
    }
    const itemNames = items.map((item) => item?.[fieldName] || "");
    if (items.length <= maxItems) {
        return itemNames.join(", ");
    }
    return `${itemNames.slice(0, maxItems).join(", ")}, +${
        itemNames.length - maxItems
    }`;
};

const getObjectKey = () => {
    return createUuid();
};

/*
The following "selectors" will only re-calculate if one of the "inputs" (i.e. menuItemsSelector) changes.
This makes them super efficient and will only re-calculate on data changes
 */
export const isEntitySavingSelector = (state: RootState) =>
    state.menu.isSavingEntity;
export const menuItemsSelector = (state: RootState) => state.menu.menuItems;
export const modifierGroupsSelector = (state: RootState) =>
    state.menu.modifierGroups;
export const categoriesSelector = (state: RootState) => state.menu.categories;
export const timePeriodsSelector = (state: RootState) => state.menu.timePeriods;
export const menuOverridesSelector = (state: RootState) =>
    state.menu.menuOverrides;
export const voicePropsFiltersSelector = (state: RootState) =>
    state.menu.voicePropsFilters;

type TreeProps<S> = {
    key: string;
    id: string;
    name: string;
    description: Maybe<string>;
    children: Record<string, S>;
    ownSortOrder?: number;
    sortOrder?: SortOrder[];
    timePeriods?: TimePeriod[];
    modifierGroups?: any[];
};
export type TreeObject<T, S> = T & TreeProps<S>;
export type TreeCategory = TreeObject<Category, TreeMenuItem>;
export type ItemType = Category | MenuItem | ModifierGroup;
export type TreeNode = ItemType & {
    id: string;
    value: string;
    nodes: TreeNode[];
};

export type TreeMenuItem = TreeObject<MenuItem, ModifierGroup>;
export type TreeModifierGroup = TreeObject<ModifierGroup, MenuItem>;
type MenuStateComponents = { menuItems: MenuState["menuItems"] } & {
    modifierGroups: MenuState["modifierGroups"];
} & { categories: MenuState["categories"] };
export interface MenuItemWithCategories extends MenuItem {
    childModifierGroups: Record<string, ModifierGroup>;
    parentCategories: Record<string, Category>;
    settings: Record<string, string>;
    overrides: Record<string, MenuOverride>;
    displayName: string;
    posId?: string;
    posName?: string;
}
export interface IMenuItemWithCategoriesAndVoiceProps
    extends MenuItemWithCategories {
    voiceProperties: Record<string, string>;
}

export type ModifierGroupWithMenuItems = ModifierGroup & {
    childMenuItems: Record<string, MenuItem>;
    posId?: string;
    posName?: string;
} & { parentMenuItems: Record<string, MenuItem> };
export type FormattedTimePeriod = TimePeriod & { availableDays: string[] };

export const getTimePeriodList = (
    timePeriodIdList: number[],
    timePeriods: Record<string, TimePeriod>
) => {
    return timePeriodIdList.map((id) => {
        return timePeriods[id.toString()];
    });
};

export const getModifierGroupList = (
    modifierIdList: number[],
    modifierGroups: Record<string, ModifierGroup>
) => {
    return modifierIdList.map((id) => {
        return modifierGroups[id.toString()];
    });
};

export const menuTreeSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    categoriesSelector,
    timePeriodsSelector,
    (menuItems, modifierGroups, categories, timePeriods) => {
        const menuStateComponents: MenuStateComponents = {
            menuItems,
            modifierGroups,
            categories,
        };
        return Object.values(categories).reduce((acc, category) => {
            const menuItemObjects = toRecord(
                category.menuItems.map((menuItemId) => {
                    let modifierGroupsData: number[] = [];
                    if (menuStateComponents.menuItems[menuItemId]) {
                        modifierGroupsData =
                            menuStateComponents.menuItems[menuItemId]
                                .modifierGroups;
                    }
                    return {
                        ...menuStateComponents.menuItems[menuItemId],
                        modifierGroups: getModifierGroupList(
                            modifierGroupsData,
                            modifierGroups
                        ) as any,
                        key: getObjectKey(),
                        children: {},
                    };
                })
            );
            acc[category.id] = {
                ...category,
                id: category.id,
                ownSortOrder: category.ownSortOrder as any,
                key: getObjectKey(),
                children: menuItemObjects,
                timePeriods: getTimePeriodList(
                    category.timePeriods,
                    timePeriods
                ) as any,
            };
            return acc;
        }, {} as Record<string, TreeCategory>);
    }
);

export const menuTreeNodeSelector = createSelector(
    menuTreeSelector,
    (menuTree) => {
        return Object.values(menuTree).map(convertTreeObjectToNodes);
    }
);

const getMenuItem = (
    menuItemId: number,
    menuState: MenuStateComponents
): TreeMenuItem => {
    const menuItem = menuState.menuItems[menuItemId];
    const modifierGroupObjects = toRecord(
        menuItem.modifierGroups.map((modGroupId) =>
            getModGroup(modGroupId, menuState)
        )
    );
    return {
        ...menuItem,
        key: getObjectKey(),
        children: modifierGroupObjects,
    };
};

const getModGroup = (
    modGroupId: number,
    menuState: MenuStateComponents
): TreeModifierGroup => {
    const modGroup = menuState.modifierGroups[modGroupId];

    const menuItemObjectsTemp = modGroup.menuItems.map(
        (menuItemId) => menuState.menuItems[menuItemId]
    );
    const hasRecursiveObject = !!menuItemObjectsTemp.find((item) =>
        item.modifierGroups.includes(Number(modGroup.id))
    );

    if (hasRecursiveObject) {
        console.error("Recursive MenuItem/ModGroup Found", modGroup);
        return {
            ...modGroup,
            key: getObjectKey(),
            children: {},
        };
    }

    const menuItemObjects = toRecord(
        modGroup.menuItems.map((menuItemId) =>
            getMenuItem(menuItemId, menuState)
        )
    );
    return {
        ...modGroup,
        key: getObjectKey(),
        children: menuItemObjects,
    };
};

export const modifierGroupTreeSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    (menuItems, modifierGroups) => {
        const menuStateComponents: MenuStateComponents = {
            menuItems,
            modifierGroups,
            categories: {}, // categories aren't needed here
        };
        return toRecord(
            Object.values(modifierGroups).map((modGroup) =>
                getModGroup(Number(modGroup.id), menuStateComponents)
            )
        );
    }
);

export const menuItemsWithCategoriesSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    categoriesSelector,
    menuOverridesSelector,
    (menuItems, modifierGroups, categories, menuOverrides) => {
        return Object.values(menuItems).reduce((acc, menuItem) => {
            const parentCategories = toRecord(
                Object.values(categories).reduce((acc, category) => {
                    if (category.menuItems.includes(Number(menuItem.id))) {
                        acc.push(category);
                    }
                    return acc;
                }, [] as Category[])
            );
            const childModifierGroups = toRecord(
                menuItem.modifierGroups.map((id) => modifierGroups[id])
            );
            const settings = menuItem.settings;
            const overrides = toRecord(
                menuItem.menuOverrides.map((id) => menuOverrides[id])
            );

            let posId: string | undefined = undefined;
            let posName: string | undefined = undefined;

            for (const [key, value] of Object.entries(
                menuItem.posPropertyMap || {}
            )) {
                switch (key) {
                    case POS_ID:
                        posId = value;
                        break;
                    case POS_NAME:
                        posName = value;
                        break;
                    default:
                        break;
                }
            }

            const displayName = menuItem?.voiceProperties?.display_name || "";
            acc[menuItem.id] = {
                ...menuItem,
                childModifierGroups,
                parentCategories,
                settings,
                overrides,
                posId,
                posName,
                displayName,
            };
            return acc;
        }, {} as Record<string, MenuItemWithCategories>);
    }
);

export const menuItemListSelector = createSelector(
    menuItemsWithCategoriesSelector,
    voicePropsFiltersSelector,
    (menuItemsRecord, voicePropertiesFilter) => {
        const result: IMenuItemWithCategoriesAndVoiceProps[] = [];

        for (const itemId in menuItemsRecord) {
            if (
                !Object.prototype.hasOwnProperty.call(menuItemsRecord, itemId)
            ) {
                continue;
            }

            const menuItem = menuItemsRecord[itemId];

            if (
                isUpsellOrPromptItemFilter(
                    menuItem.voiceProperties,
                    voicePropertiesFilter.upsellItems,
                    voicePropertiesFilter.upsellPrompts
                )
            ) {
                /**
                 * Don't add this menu-item if it does not satisfy the search criteria
                 */
                continue;
            }

            result.push(menuItem);
        }

        return result;
    }
);

export const modifierGroupWithMenuItemsSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    (menuItems, modifierGroups) => {
        return Object.values(modifierGroups).reduce((acc, modGroup) => {
            const parentMenuItems = Object.values(menuItems).reduce(
                (acc, menuItem) => {
                    if (menuItem.modifierGroups.includes(Number(modGroup.id))) {
                        acc[menuItem.id] = menuItem;
                    }
                    return acc;
                },
                {} as Record<string, MenuItem>
            );
            const childMenuItems = toRecord(
                modGroup.menuItems.map((itemId) => menuItems[itemId])
            );

            let posId: string | undefined = undefined;
            let posName: string | undefined = undefined;

            for (const [key, value] of Object.entries(
                modGroup.posPropertyMap || {}
            )) {
                switch (key) {
                    case POS_ID:
                        posId = value;
                        break;
                    case POS_NAME:
                        posName = value;
                        break;
                    default:
                        break;
                }
            }

            acc[modGroup.id] = {
                ...modGroup,
                posId,
                posName,
                parentMenuItems,
                childMenuItems,
            };
            return acc;
        }, {} as Record<string, ModifierGroupWithMenuItems>);
    }
);

export const timePeriodTableSelector = createSelector(
    timePeriodsSelector,
    (timePeriods) => {
        return Object.values(timePeriods).reduce((acc, timePeriod) => {
            const availableDays = timePeriod.availability
                ?.filter(
                    (av) =>
                        av.alwaysEnabled || (av.hours && av.hours.length > 0)
                )
                .map((av) => DAYS_SHORT[av.day]);
            acc[timePeriod.id] = {
                ...timePeriod,
                availableDays: availableDays || [],
            };
            return acc;
        }, {} as Record<string, FormattedTimePeriod>);
    }
);

export const modifierGroupWithMenuItemsWithUPsellFilterSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    voicePropsFiltersSelector,
    (menuItems, modifierGroups, voicePropertiesFilter) => {
        return Object.values(modifierGroups).reduce((acc, modGroup) => {
            const parentMenuItems = Object.values(menuItems).reduce(
                (acc, menuItem) => {
                    if (menuItem.modifierGroups.includes(Number(modGroup.id))) {
                        acc[menuItem.id] = menuItem;
                    }
                    return acc;
                },
                {} as Record<string, MenuItem>
            );
            const childMenuItems = toRecord(
                modGroup.menuItems.map((itemId) => menuItems[itemId])
            );

            let posId: string | undefined = undefined;
            let posName: string | undefined = undefined;

            for (const [key, value] of Object.entries(
                modGroup.posPropertyMap
            )) {
                switch (key) {
                    case POS_ID:
                        posId = value;
                        break;
                    case POS_NAME:
                        posName = value;
                        break;
                    default:
                        break;
                }
            }

            if (
                isUpsellOrPromptItemFilter(
                    modGroup.voiceProperties,
                    voicePropertiesFilter.upsellItems,
                    voicePropertiesFilter.upsellPrompts
                )
            ) {
                /**
                 * Don't add this menu-item if it does not satisfies the
                 * search criteria
                 */
                return acc;
            }
            acc[modGroup.id] = {
                ...modGroup,
                posId,
                posName,
                parentMenuItems,
                childMenuItems,
            };
            return acc;
        }, {} as Record<string, ModifierGroupWithMenuItems>);
    }
);

const convertTreeObjectToNodes = (treeObject: TreeProps<any>): TreeNode => {
    let newItemSort = [...(treeObject.sortOrder as any[])];
    newItemSort.sort((a, b) => {
        if (a.sortOrder === null || b.sortOrder === null) return 1;
        return a.sortOrder - b.sortOrder;
    });
    const childList = newItemSort.map((order) => treeObject.children[order.id]);
    let timePeriodListName = "";
    if (treeObject.timePeriods && treeObject.timePeriods.length > 0) {
        timePeriodListName =
            "  ( " +
            renderTableCollectionText(
                treeObject.timePeriods,
                2,
                "description"
            ) +
            ")";
    }
    let modifierListName = "";
    if (treeObject.modifierGroups && treeObject.modifierGroups.length > 0) {
        modifierListName =
            "  ( " +
            renderTableCollectionText(treeObject.modifierGroups, 2, "name") +
            ")";
    }
    return {
        value: treeObject.name + timePeriodListName + modifierListName,
        nodes: childList
            .filter((c) => c !== undefined)
            .map(convertTreeObjectToNodes),
        ...treeObject,
    } as any;
};

export const toRecord = <T extends { id: string }>(
    records: Array<T>
): Record<string, T> => {
    return records.reduce((acc, record) => {
        if (record?.id) {
            acc[record.id] = record;
        }
        return acc;
    }, {} as Record<string, T>);
};

export const generatePropertyList = (record: object) => {
    return Object.entries(record).reduce((acc, [key, val]) => {
        const property_key = camelToSnakeCase(key);
        const property_value =
            val && typeof val === "object" ? JSON.stringify(val) : val;
        acc.push({
            property_key,
            property_value,
        });
        return acc;
    }, [] as {}[]);
};

export function transformVoiceProperties(
    voiceProperties: InputProperties[] | Record<string, string>
): any {
    if (Array.isArray(voiceProperties)) {
        const map: Record<string, string> = {};
        voiceProperties?.forEach(({ key, value }) => {
            if (key) {
                map[key] = value || "";
            }
        });
        return map;
    } else {
        return Object.entries(voiceProperties)
            .map(
                ([key, value]) =>
                    key && {
                        key,
                        value,
                    }
            )
            .filter((item) => item);
    }
}

export function toKeyValueRecord(
    inputProperties: InputProperties[]
): Record<string, string> {
    const keyValueRecord: Record<string, string> = {};

    for (let { key, value } of inputProperties) {
        if (key && value) {
            keyValueRecord[key] = value;
        }
    }

    return keyValueRecord;
}

export function getValidKeyValuePair(inpProps: InputProperties[]) {
    return Object.values(inpProps).filter(({ key }) => key);
}

export const generateVoiceProperties = (vp: GetIngestedDataType[]) =>
    vp.reduce(
        (
            acc,
            { unique_identifier, property_value = "{}", property_type = "" }
        ) => {
            const transformedVP = JSON.parse(property_value || "{}");
            acc[
                `${unique_identifier}${VOICE_PROPERTIES_DELIMITER}${property_type}`
            ] = transformVoiceProperties(transformedVP);
            return acc;
        },
        {} as Record<string, InputProperties[]>
    );

const isUpsellOrPromptItemFilter = (
    voiceProperties: Record<string, string>,
    isUpsellFilter: Boolean,
    isPromptFilter: Boolean
) => {
    if (!(isUpsellFilter || isPromptFilter)) {
        return false;
    }

    const isUpselling = isPropertySetToTrue(voiceProperties[IS_UPSELL]);
    const isUpsellingPrompt = isPropertySetToTrue(
        voiceProperties[IS_UPSELL_PROMPT]
    );
    return !(
        (isUpsellFilter && isUpselling) ||
        (isPromptFilter && isUpsellingPrompt)
    );
};

export function transformToMenuSchemaV1({
    menuItems,
    menuOverrides,
    modifierGroups,
    categories,
    timePeriods,
    voiceProperties,
    posProperties,
    menuItemSettings,
}: LegacyMenuSchema): MenuSchemaV1 {
    const timer = logger.startTimer();

    const menuItemsById = new Map<string, MenuItem>();
    const menuItemsByName = new Map<string, MenuItem>();

    const modifierGroupsById = new Map<string, ModifierGroup>();
    const modifierGroupsByName = new Map<string, ModifierGroup>();

    const categoriesByName = new Map<string, Category>();

    // init menu items
    for (const menuItem of menuItems) {
        menuItem.settings = {};
        menuItem.posPropertyMap = {};
        menuItem.voiceProperties = {};
        menuItemsById.set(menuItem.id, menuItem);
        menuItemsByName.set(menuItem.name, menuItem);
    }

    // init modifier groups
    for (const modifierGroup of modifierGroups) {
        modifierGroup.posPropertyMap = {};
        modifierGroup.voiceProperties = {};
        modifierGroupsById.set(modifierGroup.id, modifierGroup);
        modifierGroupsByName.set(modifierGroup.name, modifierGroup);
    }

    // init categories
    for (const category of categories) {
        category.voiceProperties = {};
        categoriesByName.set(category.name, category);
    }

    // transform menuItemSettings to setting map
    for (const { menuItemId, key, value } of menuItemSettings) {
        const menuItem = menuItemsById.get(menuItemId.toString());
        if (!menuItem) continue;
        menuItem.settings[key] = value;
    }

    // transform posProperties to posPropertyMap
    for (const {
        key,
        value,
        objectPrimaryKey,
        propertyType,
    } of posProperties) {
        const mapsByPropertyType = {
            [PropertyType.MenuItem]: menuItemsById,
            [PropertyType.ModifierGroup]: modifierGroupsById,
            [PropertyType.Category]: null, // unsupported property-type
            [PropertyType.Restaurant]: null, // unsupported property-type
            [PropertyType.TimePeriod]: null, // unsupported property-type
        };

        const map = mapsByPropertyType[propertyType];
        if (!map) continue; // unsupported property-type

        const item = map.get(objectPrimaryKey);
        if (!item) continue;

        item.posPropertyMap[key] = value;
    }

    // transform voice properties
    for (const {
        property_key,
        property_value,
        property_type,
        unique_identifier, // entity name, ex. menu_item's name
    } of voiceProperties) {
        if (!property_value || property_key !== "voice_properties") continue;

        const mapsByPropertyType = {
            [PropertyType.MenuItem.toLowerCase()]: menuItemsByName,
            [PropertyType.ModifierGroup.toLowerCase()]: modifierGroupsByName,
            [PropertyType.Category.toLowerCase()]: categoriesByName,
        };

        const map = mapsByPropertyType[property_type.toLowerCase()];
        if (!map) continue; // unsupported property-type

        const item = map.get(unique_identifier);
        if (!item) continue;

        try {
            const voiceProperties = JSON.parse(property_value);
            item.voiceProperties = {
                ...item.voiceProperties,
                ...voiceProperties,
            };
        } catch (error) {
            logger.error("Error occured while parsing voice properties", error);
        }
    }

    timer.done({ message: "Legacy menu conversion completed" });

    return {
        menuItems,
        menuOverrides,
        modifierGroups,
        categories,
        timePeriods,
    };
}
