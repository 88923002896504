import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import Dropzone, {
    IFileWithMeta,
    ILayoutProps,
    StatusValue,
} from "react-dropzone-uploader";
import { StringOrNull } from "../utils/types";
import ReadOnlyWrapper from "./ReadOnlyWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    uploadContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        width: "400px",
        height: "300px",
        backgroundImage: (props: Props) => `url(${props.imageUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    chooseFile: {
        width: "140px",
    },
}));

const layoutStyles = makeStyles((theme: Theme) => ({
    dropzoneContainer: {
        position: "relative",
        textAlign: "center",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    contentContaier: {
        textAlign: "left",
        marginLeft: 40,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginTop: theme.spacing(4),
        },
    },
    content: {
        fontSize: "14px",
        color: "#535663",
    },
}));

interface Props {
    isImageUploading: boolean;
    title: string;
    header: string;
    imageUrl?: StringOrNull;
    deleteOriginalImage?: () => void;
    uploadImage: (file: any) => void;
    setImageLoadingStatus: (status: string) => void;
}

interface LayoutProps extends ILayoutProps {
    imageUrl?: StringOrNull;
    deleteOriginalImage?: () => void;
    title: string;
}

const Layout = ({
    input,
    previews,
    deleteOriginalImage,
    imageUrl,
    title,
    dropzoneProps,
    files,
    extra: { maxFiles },
}: LayoutProps) => {
    const classes = layoutStyles();
    return (
        <div className={classes.dropzoneContainer}>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            <div className={classes.contentContaier}>
                <Typography style={{ fontWeight: "bold", marginBottom: 20 }}>
                    {title}
                </Typography>
                <Typography className={classes.content}>
                    Images must be JPG, GIF or PNG.
                </Typography>
                <Typography className={classes.content}>
                    Maximum size 5MB.
                </Typography>
                <Typography
                    style={{ marginBottom: 20 }}
                    className={classes.content}
                >
                    Width and height must be at least 600 pixels.
                </Typography>
                <div style={{ display: "flex" }}>
                    {/* <div>{files.length > 0 && submitButton}</div> */}
                    <div>
                        {(files.length > 0 || imageUrl) && (
                            <Button
                                disableElevation
                                color="secondary"
                                variant="contained"
                                style={{
                                    marginLeft: "20px",
                                    color: "#02263D",
                                }}
                                onClick={() => {
                                    if (files.length > 0)
                                        files.forEach((f) => f.remove());
                                    else if (imageUrl && deleteOriginalImage)
                                        deleteOriginalImage();
                                }}
                            >
                                Delete Image
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

function ImageUpload(props: Props) {
    const classes = useStyles(props);
    const handleSubmit = (
        files: IFileWithMeta[],
        allFiles: IFileWithMeta[]
    ) => {
        if (files.length > 0 && files[0].file && props.uploadImage) {
            props.uploadImage(files[0].file);
        }
    };

    const handleChangeStatus = (
        { meta }: IFileWithMeta,
        status: StatusValue,
        allFiles: IFileWithMeta[]
    ) => {
        props.setImageLoadingStatus(status);
        if (status === "done" && allFiles.length > 0 && allFiles[0].file)
            props.uploadImage(allFiles[0].file);
    };

    const generateLayoutWithProps = useCallback(
        (layoutProps: ILayoutProps) => (
            <Layout
                {...layoutProps}
                deleteOriginalImage={props.deleteOriginalImage}
                imageUrl={props.imageUrl}
                title={props.title}
            />
        ),
        [props.deleteOriginalImage, props.imageUrl, props.title]
    );

    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography
                style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    paddingBottom: "10px",
                }}
                gutterBottom
            >
                {props.header}
            </Typography>
            <ReadOnlyWrapper
                element={Dropzone}
                accept="image/*"
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent={
                    <div
                        key="dropzone-preview"
                        className={classes.uploadContainer}
                    >
                        {!props.imageUrl && (
                            <React.Fragment>
                                <p>Drag and drop image here or</p>
                                <div
                                    className={`${classes.chooseFile} chooseFile MuiButton-root MuiButton-containedSecondary`}
                                >
                                    Upload image
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                }
                LayoutComponent={generateLayoutWithProps}
                onSubmit={handleSubmit}
                canRemove={true}
                onChangeStatus={handleChangeStatus}
            />
        </div>
    );
}

export default memo(ImageUpload);
