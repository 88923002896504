import { Button, ListItem, makeStyles, Theme } from "@material-ui/core";
import * as QueryString from "query-string";
import * as React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { WHITE_COLOR } from "../../../utils/branding";
import { MouseHoverElementProps } from "../Wrappers/AppWrapper";

const useStyles = makeStyles<Theme, MouseHoverElementProps>((theme) => ({
    item: {
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(0, 2),
        },
        [theme.breakpoints.down("md")]: {
            padding: ({ isMouseHovered }) =>
                isMouseHovered ? theme.spacing(0, 2) : theme.spacing(1, 0),
        },
    },
    button: {
        color: WHITE_COLOR,
        justifyContent: "flex-start",
        letterSpacing: 0,
        padding: theme.spacing(1, 0),
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.main,
        },
    },
    icon: {
        marginLeft: theme.spacing(1.5),
    },
    title: {
        marginRight: "auto",
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            display: ({ isMouseHovered }) =>
                isMouseHovered ? "inline" : "none",
        },
    },
    active: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.light,
        "& $icon": {
            color: theme.palette.secondary.main,
        },
    },
}));

interface NavItemProps {
    title: string;
    href?: string;
    onClick?: () => void;
    icon: any; // Fix TS errors for now
    isMouseHovered?: boolean;
}

export function NavItem({
    title,
    href,
    icon: Icon,
    onClick,
    isMouseHovered,
}: NavItemProps) {
    const classes = useStyles({ isMouseHovered });
    const history = useHistory();
    const queryParams = QueryString.parse(history.location.search);
    const isStaffTablet = queryParams["isStaffTablet"] === "true";

    const linkHref = href
        ? href + (isStaffTablet ? "?isStaffTablet=true" : "")
        : "#";

    const buttonProps: {
        [key: string]: any;
    } = {};

    if (href) {
        buttonProps.to = href + (isStaffTablet ? "?isStaffTablet=true" : "");
    } else if (onClick) {
        buttonProps.onClick = onClick;
    }

    return (
        <ListItem disableGutters className={classes.item}>
            <Button
                disableElevation
                activeClassName={href ? classes.active : ""}
                className={classes.button}
                component={NavLink}
                to={linkHref}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    if (onClick) {
                        onClick();
                    }
                }}
                onMouseOver={(e: React.MouseEvent) => {
                    e.stopPropagation();
                }}
                color="secondary"
                fullWidth
            >
                {Icon && <Icon className={classes.icon} size="20" />}
                <span className={classes.title}>{title}</span>
            </Button>
        </ListItem>
    );
}
