import { useHistory } from "react-router-dom";

export default function useCustomHistory() {
    const history = useHistory();

    return {
        pushToHistory: (pathname: string, search?: string) => {
            history.push({
                pathname,
                search: search || history.location.search,
            });
        },
    };
}
