import { v4 as uuidv4 } from "uuid";
import { LoggerOptions, createLogger, format, transports } from "winston";
import WinstonNewrelicLogsTransport from "winston-newrelic-logs-transport";

import pkg from "../../package.json";
import { config } from "../config";
import { NodeEnv } from "../constants/enums";

// We have to wait for the store to be instantiated before we can use it
// So we call this function once the main App.tsx has mounted
export const updateLogger = () => {
    setTimeout(() => {
        logger.configure(createCustomLogger());
    });
};

const { printf } = format;

const reduceDepth = (obj: any, depth = 2, currentDepth = 0) => {
    if (depth === currentDepth && typeof obj === "object" && obj !== null) {
        return "{...}";
    } else if (typeof obj === "object" && obj !== null) {
        const objCopy: any = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                objCopy[key] = reduceDepth(obj[key], depth, currentDepth + 1);
            }
        }
        return objCopy;
    } else {
        return obj;
    }
};

const customFormat = printf(
    ({
        level,
        message,
        timestamp,
        browser,
        version,
        module,
        environment,
        browserSessionId,
        restaurantCode,
        ...rest
    }) => {
        if (Object.keys(rest).length > 0) {
            const meta = JSON.stringify(
                reduceDepth(rest, 3), // trade-off to not block the UI thread
                null,
                2
            );
            return `${level}: ${message} ${meta}`;
        }
        return `${level}: ${message}`;
    }
);

const createCustomLogger = () => {
    const transportOptions: LoggerOptions["transports"] = [
        new transports.Console({
            format: customFormat,
        }),
    ];

    if (config.NODE_ENV !== NodeEnv.test) {
        transportOptions.push(
            new WinstonNewrelicLogsTransport({
                licenseKey: config.NEWRELIC_LICENCE_KEY,
                apiUrl: config.NEWRELIC_API,
            })
        );
    }

    return {
        level: config.LOG_LEVEL,
        transports: transportOptions,
        defaultMeta: {
            module: "PRP-UI",
            version: pkg.version,
            environment: config.NODE_ENV.toLowerCase(),
            browser: window.navigator.userAgent,
            browserSessionId: uuidv4(), // will be unique and constant until the application is refreshed or closed
        },
    };
};

const logger = createLogger(createCustomLogger());

export default logger;
