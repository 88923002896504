import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { IItem } from "../types/menu";
import { menuItemsSelector, modifierGroupsSelector } from "../utils/menu";

const selectMenu = (state: RootState) => state.menu;

export const selectDidInitialMenuLoad = createSelector(
    selectMenu,
    (menu) => menu.didInitialLoad
);
export const selectMenuVersionRequestStatus = createSelector(
    selectMenu,
    (menu) => menu.menuVersionRequestStatus
);

export const selectRestorationInProgress = createSelector(
    selectMenu,
    (menu) => menu.restorationInProgress
);
export const selectStagesOverview = createSelector(
    selectMenu,
    (menu) => menu.stagesOverview
);
export const selectVersionList = createSelector(
    selectMenu,
    (menu) => menu.versionList
);
export const selectMenuVersionActivity = createSelector(
    selectMenu,
    (menu) => menu.menuVersionActivity
);
export const selectLatestVersionInfo = createSelector(
    selectMenu,
    (menu) => menu.latestVersionInfo
);
export const selectLatestVersionCommitIdInfo = createSelector(
    selectMenu,
    (menu) => menu?.latestVersionInfo?.commitId || ""
);

export const selectAuditLogs = createSelector(
    selectMenu,
    (menu) => menu.auditLogs
);

export const selectAuditLogsTotalCount = createSelector(
    selectMenu,
    (menu) => menu.auditLogsTotalCount
);

export const selectItemsMap = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    (menuItems, modifierGroups) => {
        const itemsMap: IItem[] = Object.values(menuItems)
            .map(({ name, id }) => ({ name, id, type: "MENU_ITEM" }))
            .concat(
                Object.values(modifierGroups).map(({ name, id }) => ({
                    name,
                    id,
                    type: "MODIFIER_GROUP",
                }))
            );

        return itemsMap;
    }
);

export const selectUnavailableItems = createSelector(
    selectMenu,
    (menu) => menu.unavailableItems
);

export const selectItemsToCopy = createSelector(
    selectMenu,
    (menu) => menu.itemsToCopy
);
export const selectCopyInProgress = createSelector(
    selectMenu,
    (menu) => menu.copyInProgress
);
