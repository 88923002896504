import {
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Theme,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import TimePeriodComponent from "../../components/menu/TimePeriod";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import {
    Availability,
    AvailabilityInput,
    HoursOfOperationInput,
} from "../../generated-interfaces/graphql";
import {
    createHoursOfOperation,
    updateHoursOfOperation,
} from "../../reducers/restaurantReducer";
import { restaurantInfoSelector } from "../../selectors/restaurant";
import {
    AVAILABILITY_STATE,
    initialAvailabilities,
    OFFSET_TIMEZONE,
    TIME_OUT,
} from "../../utils/constants";
import { CallbackFunction } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) => ({
    timePeriodContainer: {
        padding: theme.spacing(3, 4),
    },
    saveBtn: {
        float: "right",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
    timezoneContainer: {
        marginTop: "24px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "48px",
        },
        "& .MuiInputBase-input": {
            paddingLeft: theme.spacing(1),
        },
    },
    menuPaper: {
        maxHeight: "400px",
    },
}));

function OperationOfHours(props: Props) {
    const history = useHistory();
    const classes = useStyles();
    const alert = useAlert();

    const restaurantInfo = useSelector(restaurantInfoSelector);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [timeAvailabilities, setTimeAvailabilities] = useState<
        Availability[]
    >([]);
    const [timeZone, setTimezone] = useState("");

    const initialValue = restaurantInfo?.hoursOfOperation;

    useEffect(() => {
        if (initialValue && initialValue.availability) {
            const availability = initialValue.availability as Availability[];
            const newTimePeriods: AvailabilityInput[] = [];
            for (let i = 0; i < 7; i++) {
                const aIndex = availability.findIndex(
                    (timePeriod) => timePeriod.day === i
                );
                if (aIndex > -1) {
                    const timeAvailability = availability[aIndex];
                    newTimePeriods.push({
                        day: timeAvailability.day,
                        alwaysEnabled: timeAvailability.alwaysEnabled,
                        hours: timeAvailability.hours || [],
                    });
                } else {
                    newTimePeriods.push({
                        day: i,
                        hours: [],
                        alwaysEnabled: false,
                    });
                }
            }
            setTimeAvailabilities([...newTimePeriods]);
            setTimezone(initialValue.timezone);
        } else {
            setTimeAvailabilities([...initialAvailabilities]);
            setTimezone("US/Pacific");
        }
    }, [initialValue]);

    const handleUpdateTimePeriod = (
        day: number,
        hour: [number, number],
        index: number
    ) => {
        let selectedTimePeriod = { ...timeAvailabilities[day] };
        let hours = selectedTimePeriod.hours
            ? [...selectedTimePeriod.hours]
            : [];
        if (hours.length > 0) hours.splice(index, 1, hour);
        selectedTimePeriod = { ...selectedTimePeriod, hours };

        timeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...timeAvailabilities]);
        setIsFormDirty(true);
    };

    const handleDeleteTimePeriod = (day: number, index: number) => {
        const selectedTimePeriod = timeAvailabilities[day];
        if (selectedTimePeriod.hours) selectedTimePeriod.hours.splice(index, 1);
        timeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...timeAvailabilities]);
        setIsFormDirty(true);
    };

    const handleSubmit = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        e.stopPropagation();
        if (
            !initialValue ||
            !initialValue.availability ||
            initialValue.availability.length === 0
        ) {
            props.createHoursOfOperation({
                timezone: timeZone,
                availability: timeAvailabilities,
                successCallback: () => {
                    alert.success("Operation Hours Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: () => {
                    alert.error("Error Saving Operation Hours", {
                        timeout: TIME_OUT,
                    });
                },
            });
        } else {
            props.updateHoursOfOperation({
                timezone: timeZone,
                availability: timeAvailabilities,
                successCallback: () => {
                    alert.success("Operation Hours Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: () => {
                    alert.error("Error Saving Operation Hours", {
                        timeout: TIME_OUT,
                    });
                },
            });
        }
        setIsFormDirty(false);
    };

    const isValidHours = () => {
        const unAvailableTimeperiodIndex = timeAvailabilities.findIndex(
            (available) => {
                if (available && available.hours) {
                    const unAvailableIndex = available.hours.findIndex(
                        (hour) => {
                            return !(
                                hour &&
                                hour.length === 2 &&
                                hour[0] !== undefined &&
                                hour[1] !== undefined &&
                                hour[1] > hour[0]
                            );
                        }
                    );
                    return unAvailableIndex > -1;
                }
                return false;
            }
        );
        return unAvailableTimeperiodIndex === -1;
    };

    const addTimePeriod = (day: number) => {
        const newTimeAvailabilities = [...timeAvailabilities];
        let selectedTimePeriod = { ...newTimeAvailabilities[day] };
        let hours = selectedTimePeriod.hours
            ? [...selectedTimePeriod.hours, [900, 1700]]
            : [];
        selectedTimePeriod = { ...selectedTimePeriod, hours };
        newTimeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...newTimeAvailabilities]);
        setIsFormDirty(true);
    };

    const handleGoBack = () => {
        if (isFormDirty) {
            setShowBackConfirmModal(true);
        } else {
            history.push(
                `/${restaurantInfo?.restaurantCode}/restaurant-settings`
            );
        }
    };

    const updateTimePeriodAvailability = (day: number, value: string) => {
        let selectedTimePeriod = { ...timeAvailabilities[day] };
        if (value === AVAILABILITY_STATE.available)
            selectedTimePeriod.alwaysEnabled = true;
        if (value === AVAILABILITY_STATE.unavailable) {
            selectedTimePeriod.alwaysEnabled = false;
            selectedTimePeriod.hours = [];
        }
        if (value === AVAILABILITY_STATE.exact) {
            selectedTimePeriod.alwaysEnabled = false;
            selectedTimePeriod.hours = [[900, 1700]];
        }
        timeAvailabilities.splice(day, 1, selectedTimePeriod);
        setIsFormDirty(true);
        setTimeAvailabilities([...timeAvailabilities]);
    };

    return (
        <div className={classes.timePeriodContainer}>
            <form onSubmit={handleSubmit}>
                {showBackConfirmModal && (
                    <ConfirmDialog
                        title="Leave without saving?"
                        content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                        open={showBackConfirmModal}
                        onSuccess={() => {
                            history.push(
                                `/${restaurantInfo?.restaurantCode}/restaurant-settings`
                            );
                        }}
                        onCancel={() => setShowBackConfirmModal(false)}
                    />
                )}
                <IconButton onClick={handleGoBack}>
                    <ArrowBackIcon />
                </IconButton>
                <ReadOnlyWrapper
                    element={EntitySaveButton}
                    disabled={!isValidHours()}
                    className={classes.saveBtn}
                />
                {/* <ReadOnlyWrapper
                    element={Button} className={classes.saveBtn} variant="contained" color="secondary" onClick={handleGoBack}>
                    Cancel
                </ReadOnlyWrapper> */}
                <Typography variant="h6" color="primary" gutterBottom>
                    Hours of Operation
                </Typography>
                <Typography style={{ marginBottom: "38px" }}>
                    Set the hours of operations for your restaurant.
                </Typography>
                <TimePeriodComponent
                    timeAvailabilities={timeAvailabilities}
                    changeAvailability={updateTimePeriodAvailability}
                    updateTimePeriod={handleUpdateTimePeriod}
                    deleteTimePeriod={handleDeleteTimePeriod}
                    addTimePeriod={addTimePeriod}
                />
                <div className={classes.timezoneContainer}>
                    <Typography variant="h6" color="primary">
                        Time Zone
                    </Typography>
                    <Typography>Set the time zone of your location</Typography>
                    <Select
                        id="timeZone"
                        name="timeZone"
                        value={timeZone}
                        onChange={(evt) => {
                            setTimezone(evt.target.value as string);
                            setIsFormDirty(true);
                        }}
                        className="custom-input-gray"
                        style={{ marginTop: "24px" }}
                        disableUnderline
                    >
                        {OFFSET_TIMEZONE.map((zone) => (
                            <MenuItem value={zone.value} key={zone.value}>
                                {zone.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </form>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateHoursOfOperation: (
            data: HoursOfOperationInput & CallbackFunction
        ) => dispatch(updateHoursOfOperation(data)),
        createHoursOfOperation: (
            data: HoursOfOperationInput & CallbackFunction
        ) => dispatch(createHoursOfOperation(data)),
    };
};

type Props = ConnectedProps<typeof connected>;

const connected = connect(null, mapDispatchToProps);

export default connected(OperationOfHours);
