import { createMuiTheme, ThemeOptions } from "@material-ui/core";

export const PRIMARY_COLOR = "#02263D";
export const SECONDARY_COLOR = "#FFC107";

export const LIGHT_BLUE_COLOR = "rgba(33, 150, 243, 0.08)";
export const DARK_BLUE_COLOR = "#2f9bf3";

export const BACKGROUND_COLOR = "#f6f7f8";
export const WHITE_COLOR = "#e0e0e0";
export const ERROR_COLOR = "#f50057";

export const UI_THEME: ThemeOptions = createMuiTheme({
    shape: {
        borderRadius: 8,
    },
    palette: {
        primary: {
            main: PRIMARY_COLOR,
            light: LIGHT_BLUE_COLOR,
            dark: DARK_BLUE_COLOR,
        },
        secondary: {
            main: SECONDARY_COLOR,
        },
        error: {
            main: ERROR_COLOR,
        },
        background: {
            default: BACKGROUND_COLOR,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
            },
            textSecondary: {
                color: PRIMARY_COLOR,
            },
            containedSecondary: {
                color: PRIMARY_COLOR,
            },
        },
        MuiTab: {
            root: {
                textTransform: "none",
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: BACKGROUND_COLOR,
                "&:hover": {
                    backgroundColor: BACKGROUND_COLOR,
                },
                "&.Mui-focused": {
                    backgroundColor: BACKGROUND_COLOR,
                },
            },
        },
    },
    typography: {
        fontFamily: ["Prompt", "Helvetica", "Arial", "sans-serif"].join(","),
        fontWeightBold: "normal",
        fontWeightLight: "normal",
        fontWeightMedium: "normal",
        fontWeightRegular: "normal",
    },
});
