import {
    Button,
    Grid,
    InputLabel,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import React, { FC, memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { RestaurantAutoComplete } from "../../components/RestaurantAutoComplete";
import { Restaurant } from "../../types/restaurant";
import VoiceConfigEnabler from "./VoiceConfigEnabler";

const useStyles = makeStyles((theme: Theme) => ({
    sectionClass: {
        paddingTop: theme.spacing(1),
    },
    subheadingClass: {
        color: "black",
    },
}));

interface IPrestoVoiceConfigurationProps {
    restaurantInfo: Restaurant | null;
    restaurantsMap: Record<string, Restaurant>;
    primaryRestaurantCode: string;
    setPrimaryRestaurantCode: Function;
    isTREnabled: number;
    toggleEnableTR: Function;
    tempTTSScheduler: boolean;
    changeTTSScheduler: Function;
    tempEnableAI: boolean;
    changeEnableAI: Function;
    tempIsUnsupervisedAI: boolean;
    changeIsUnsupervisedAI: Function;
}

const PrestoVoiceConfiguration: FC<IPrestoVoiceConfigurationProps> = ({
    restaurantInfo,
    restaurantsMap,
    primaryRestaurantCode,
    setPrimaryRestaurantCode,
    isTREnabled,
    toggleEnableTR,
    tempTTSScheduler,
    changeTTSScheduler,
    tempEnableAI,
    changeEnableAI,
    tempIsUnsupervisedAI,
    changeIsUnsupervisedAI,
}) => {
    const { sectionClass, subheadingClass } = useStyles();
    const history = useHistory();

    const showTTSHoursOfOperation = useCallback(() => {
        history.push(
            `/${restaurantInfo?.restaurantCode}/restaurant-settings/tts-operation-hours`
        );
    }, [history]);

    const handleTREnablementChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            toggleEnableTR(event.target.checked ? 1 : 0);
        },
        []
    );

    const handleTTSSchedulerChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            changeTTSScheduler(event.target.checked);
        },
        []
    );

    const handleEnableAIChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            changeEnableAI(event.target.checked);
        },
        []
    );

    const handleIsUnsupervisedAIChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            changeIsUnsupervisedAI(event.target.checked);
        },
        []
    );

    const handleRestaurantSelect = useCallback((val) => {
        setPrimaryRestaurantCode(val as string);
    }, []);

    return (
        <div data-testid="prp-settings-voiceconfig">
            <Typography variant="h6" color="primary">
                Presto Voice Configuration
            </Typography>
            <VoiceConfigEnabler
                label="Enable Task Router"
                checkedValue={!!isTREnabled}
                testId="trEnabled"
                onChange={handleTREnablementChange}
            />
            <VoiceConfigEnabler
                label="Enable TTS Scheduler"
                checkedValue={!!tempTTSScheduler}
                testId="ttsScheduler"
                onChange={handleTTSSchedulerChange}
            />
            <VoiceConfigEnabler
                label="Enable AI"
                checkedValue={!!tempEnableAI}
                testId="enableAI"
                onChange={handleEnableAIChange}
            />
            <VoiceConfigEnabler
                label="Enable unsupervised AI"
                checkedValue={!!tempIsUnsupervisedAI}
                testId="isUnsupervisedAI"
                onChange={handleIsUnsupervisedAIChange}
            />
            <Grid
                container
                item
                xs={12}
                alignItems="center"
                className={sectionClass}
            >
                <Grid item xs={2}>
                    <Typography className={subheadingClass}>
                        TTS Hours of Operation
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={showTTSHoursOfOperation}
                        disableElevation
                        type="button"
                    >
                        Edit Hours of Operation
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                item
                xs={12}
                alignItems="center"
                className={sectionClass}
            >
                <Grid item xs={2}>
                    <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        className={subheadingClass}
                    >
                        Set Mirror Restaurant
                    </InputLabel>
                </Grid>
                <Grid item xs={10}>
                    <RestaurantAutoComplete
                        restaurantsEntries={Object.entries(restaurantsMap)}
                        handleSelect={handleRestaurantSelect}
                        inputValue={primaryRestaurantCode}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default memo(PrestoVoiceConfiguration);
