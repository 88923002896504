import { Checkbox, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialTableProps } from "material-table";
import * as React from "react";
import { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Image } from "../../../components/layout/Image";
import { Tag } from "../../../components/Tag/Tag";
import { MenuType } from "../../../constants/menu";
import { duplicateMenuItem, MENU_ACTIONS } from "../../../reducers/menuReducer";
import { selectUnavailableItems } from "../../../selectors/menu";
import {
    currencySelector,
    selectedStageSelector,
} from "../../../selectors/restaurant";
import { ComponentType } from "../../../types/menu";
import { MAX_SELECTIONS } from "../../../utils/constants";
import { formatCurrencyAndAmount } from "../../../utils/currency";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../../utils/data-tables";
import {
    menuItemListSelector,
    MenuItemWithCategories,
    renderTableCollectionText,
} from "../../../utils/menu";
import RowActions from "../RowActions";
import { getItemAvailabilityText } from "../utils";
import AddNewItem from "./AddNewItem";
import ItemToolbar from "./ItemToolbar";

const useStyles = makeStyles((theme: Theme) => {
    const { spacing } = theme;
    return {
        ...DATA_TABLE_DEFAULT_STYLING(theme),
        availability: {
            color: "white",
            backgroundColor: "#9400ff",
            "&.available": {
                backgroundColor: "#02cb63",
            },
            "&.sold_out": {
                backgroundColor: "#ff0041",
            },
        },
        selectedItemsTitle: {
            padding: spacing(2.5),
            margin: spacing(1.25, 0),
            width: "100%",
            color: "#FFC107",
            backgroundColor: "#fff7e1",
            textAlign: "right",
        },
    };
});

function Items() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const menuItemsFromSelector = useSelector(menuItemListSelector);
    const currency = useSelector(currencySelector);
    const unavailableItems = useSelector(selectUnavailableItems);
    const stage = useSelector(selectedStageSelector);
    const [selectedItems, setSelectedItems] = useState<
        Record<string, MenuItemWithCategories>
    >({});
    const [enableEdit, setEnableEdit] = useState<boolean>(false);

    const selectedItemsCount = Object.values(selectedItems).length;

    const menuItems = useMemo(
        () => menuItemsFromSelector.map((o) => ({ ...o })),
        [menuItemsFromSelector]
    );

    const handleSelection = useCallback(
        (data: MenuItemWithCategories) => (
            _: ChangeEvent<HTMLInputElement>,
            checked: boolean
        ) => {
            const updatedSelectedItems = { ...selectedItems };

            if (checked) {
                updatedSelectedItems[data.id] = data;
            } else {
                delete updatedSelectedItems[data.id];
            }
            setSelectedItems(updatedSelectedItems);
        },
        [selectedItems]
    );

    const menuItemsTableConfig: MaterialTableProps<MenuItemWithCategories> = useMemo(() => {
        const config: MaterialTableProps<MenuItemWithCategories> = {
            components: {
                Toolbar: (props) => <ItemToolbar toolbarProps={props} />,
            },
            columns: [
                {
                    title: "Photo",
                    field: "imageUrl",
                    render: (data: MenuItemWithCategories) => {
                        return (
                            <Image
                                url={data.imageUrl || undefined}
                                isMenuItemImage={true}
                                height={50}
                                width={50}
                            />
                        );
                    },
                },
                { title: "Item Name", field: "name" },

                { title: "Display Name", field: "displayName" },
                {
                    title: "POS Id",
                    field: "posId",
                },
                {
                    title: "POS Name",
                    field: "posName",
                },
                {
                    title: "Status",
                    customSort: (
                        a: MenuItemWithCategories,
                        b: MenuItemWithCategories
                    ) => {
                        const aText = getItemAvailabilityText({
                            item: a,
                            stage,
                            unavailableItems,
                        });
                        const bText = getItemAvailabilityText({
                            item: b,
                            stage,
                            unavailableItems,
                        });
                        if (aText < bText) {
                            return -1;
                        }
                        if (aText > bText) {
                            return 1;
                        }
                        return 0;
                    },
                    render: (data: MenuItemWithCategories) => {
                        const status = getItemAvailabilityText({
                            item: data,
                            stage,
                            unavailableItems,
                        });
                        return (
                            <Tag
                                className={`${
                                    classes.availability
                                } ${status.toLowerCase().replace(" ", "_")}`}
                            >
                                {status}
                            </Tag>
                        );
                    },
                },
                {
                    title: "Price",
                    field: "price",
                    render: (data: MenuItemWithCategories) =>
                        formatCurrencyAndAmount(currency, data.price),
                },
                {
                    title: "Categories",
                    field: "categories",
                    render: (data: MenuItemWithCategories) =>
                        renderTableCollectionText(data.parentCategories),
                },
                {
                    title: "Modifier Groups",
                    field: "modifierGroups",
                    render: (data: MenuItemWithCategories) =>
                        renderTableCollectionText(data.childModifierGroups),
                },
                {
                    title: "Actions",
                    render: (data: MenuItemWithCategories) => {
                        return (
                            <RowActions
                                data={data}
                                duplicateAction={duplicateMenuItem}
                                componentType={ComponentType.menuItem}
                                type={MenuType.menuItem}
                            />
                        );
                    },
                    align: "right",
                },
            ],
            data: menuItems,
        };
        if (enableEdit) {
            config.columns.splice(0, 0, {
                field: "checkbox",
                render: (data: MenuItemWithCategories) => {
                    return (
                        <Checkbox
                            onChange={handleSelection(data)}
                            disabled={
                                selectedItemsCount === MAX_SELECTIONS &&
                                !selectedItems[data.id]
                            }
                            checked={!!selectedItems[data.id]}
                            data-testid="select-item-checkbox"
                        />
                    );
                },
            });
        }
        return config;
    }, [
        menuItems,
        enableEdit,
        stage,
        unavailableItems,
        classes.availability,
        currency,
        handleSelection,
        selectedItemsCount,
        selectedItems,
    ]);

    const handleBulkEdit = useCallback((isBulkEditSelected) => {
        setEnableEdit(isBulkEditSelected);
        if (!isBulkEditSelected) {
            setSelectedItems({});
        }
    }, []);

    const handleExportItems = useCallback(() => {
        dispatch(MENU_ACTIONS.updateItemsToCopy(selectedItems));

        history.push(history.location.pathname + "/export");
    }, [dispatch, history, selectedItems]);

    return (
        <div id="menu-items">
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        Items
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <AddNewItem
                        type={MenuType.menuItem}
                        showBulkEdit
                        hasSelectedItems={selectedItemsCount > 0}
                        onBulkEdit={handleBulkEdit}
                        onExportItems={handleExportItems}
                    />
                </Grid>
            </Grid>

            {!!selectedItemsCount && (
                <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    className={classes.selectedItemsTitle}
                >
                    {`${selectedItemsCount} row(s) selected(Max ${MAX_SELECTIONS})`}
                </Typography>
            )}
            <CustomizedMaterialTable {...menuItemsTableConfig} />
        </div>
    );
}

export default memo(Items);
