import { Auth0Client } from "@auth0/auth0-spa-js";
import { readEnvVariable } from "../../../utils/helper-functions";

const auth0 = new Auth0Client({
    authorizationParams: {
        redirect_uri: `${window.location.origin}/profile`,
        audience: readEnvVariable("AUTH0_AUDIENCE"),
        scope: "openid profile email offline_access",
    },
    domain: readEnvVariable("AUTH0_DOMAIN"),
    clientId: readEnvVariable("AUTH0_CLIENT_ID"),
    useRefreshTokens: true,
    cacheLocation: "localstorage",
});

export default auth0;
