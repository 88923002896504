import {
    Button,
    Divider,
    Grid,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Tab,
    Tabs,
    Theme,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import QueryString from "query-string";
import * as React from "react";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import MirrorRestaurantLabel from "../../components/common/MirrorRestaurantLabel";
import Banner from "../../components/layout/Banner";
import {
    useCustomHistory,
    useLoadMenu,
    useRecursiveTimeout,
} from "../../hooks";
import useDisplayMenuVersion from "../../hooks/useDisplayMenuVersion.hook";
import {
    fetchLatestVersionOnDB,
    fetchMenuVersionRequestStatus,
    MENU_ACTIONS,
} from "../../reducers/menuReducer";
import { RootState } from "../../reducers/rootReducer";
import { AppRoute } from "../../routes";
import { selectDidInitialMenuLoad } from "../../selectors/menu";
import { restaurantInfoSelector } from "../../selectors/restaurant";
import { tabActiveSelector } from "../../selectors/user";
import { BACKGROUND_COLOR } from "../../utils/branding";
import { TIME_OUT } from "../../utils/constants";
import { MENU_EDITOR_ROUTES } from "./menu-editor-routes";

const useStyles = makeStyles((theme: Theme) => ({
    childComponent: {
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.background.paper,
    },
    tabsList: {
        backgroundColor: theme.palette.background.default,
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
    },
    tab: {
        minWidth: "140px",
    },
    tabMenu: {
        backgroundColor: "#FAFAFA",
        fontWeight: "bold",
        padding: theme.spacing(1),
        borderRadius: 0,
    },
    saveMenuButton: {
        backgroundColor: BACKGROUND_COLOR,
        padding: theme.spacing(3 / 8, 1.5),
    },
    refreshButton: {
        padding: theme.spacing(9 / 8),
    },
}));

const renderRoute = (routeParams: AppRoute) => {
    return (
        <Route key={routeParams.path} path={routeParams.path}>
            {routeParams.component}
        </Route>
    );
};

const getRoutes = (restaurantCode: string) => {
    const menuEditorRoutes = MENU_EDITOR_ROUTES(restaurantCode || "");
    return Object.values(menuEditorRoutes).map((route) => {
        const routes = [renderRoute(route)];
        if (route.subRoutes) {
            routes.push(
                ...Object.values(route.subRoutes).map((subRoute) =>
                    renderRoute(subRoute)
                )
            );
        }
        return routes;
    });
};

export function MenuEditorWrapper() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const { restaurantCode } = useSelector(restaurantInfoSelector) || {};
    const { pathname: path, search: locationSearch } = useSelector(
        (state: RootState) => state.router.location
    );
    const didInitialLoad = useSelector(selectDidInitialMenuLoad);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedMenu, setSelectedMenu] = useState<string>("Overview");
    const queryParams = QueryString.parse(history.location.search);
    const isStaffTablet = queryParams["isStaffTablet"] === "true";
    const tabActive = useSelector(tabActiveSelector);
    const menuEditorRoutes = useMemo(
        () => MENU_EDITOR_ROUTES(restaurantCode || ""),
        [restaurantCode]
    );
    const { loadMenu } = useLoadMenu();
    const { pushToHistory } = useCustomHistory();
    const menuVersion = queryParams["version"];
    const {
        displayMenuVersion,
        defaultMenuVersionSetting,
    } = useDisplayMenuVersion();

    const fetchRequestStatus = useCallback((): Promise<{}> => {
        if (!!restaurantCode && !defaultMenuVersionSetting) {
            return Promise.resolve(
                dispatch(fetchMenuVersionRequestStatus({ fromRoot: true }))
            );
        }
        return Promise.resolve(true);
    }, [defaultMenuVersionSetting, dispatch, restaurantCode]);

    useEffect(() => {
        if (restaurantCode) {
            if (!didInitialLoad) {
                loadMenu({ reload: true });
                if (!defaultMenuVersionSetting) {
                    fetchRequestStatus();
                    dispatch(fetchLatestVersionOnDB({}));
                }
            }
            const selectedRoute = Object.values(menuEditorRoutes).find(
                (route) => path.indexOf(route.path) > -1
            );
            setSelectedMenu(selectedRoute?.title || "Overview");
        }
    }, [
        path,
        didInitialLoad,
        restaurantCode,
        defaultMenuVersionSetting,
        loadMenu,
        menuEditorRoutes,
        fetchRequestStatus,
        dispatch,
    ]);

    useEffect(() => {
        dispatch(MENU_ACTIONS.updateDidInitialLoad(false));
        return () => {
            dispatch(MENU_ACTIONS.updateDidInitialLoad(false));
        };
    }, [dispatch, menuVersion]);

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const fetchMenuItems = (): Promise<any> => {
        const matchingRoute = Object.values(menuEditorRoutes).find(
            (route) => !route.path.includes(":") && route.path === path
        );

        if (!matchingRoute || (!isStaffTablet && !tabActive))
            return Promise.resolve(true);

        return Promise.resolve(loadMenu({}));
    };

    const onMenuItemClick = useCallback(
        (route) => () => {
            setSelectedMenu(route.title);
            pushToHistory(route.path);
            handleClose();
        },
        [handleClose, pushToHistory]
    );

    useRecursiveTimeout(fetchMenuItems, 60 * TIME_OUT, "fetchMenuItems");
    useRecursiveTimeout(fetchRequestStatus, 6 * TIME_OUT, "fetchRequestStatus");

    if (!restaurantCode) {
        return null;
    }

    if (path.endsWith("/menu-editor")) {
        return <Redirect to={path + "/overview"} />;
    }

    let MENU_EDITOR_TABS = { ...menuEditorRoutes };
    if (path.indexOf("/items/") > -1) {
        MENU_EDITOR_TABS.items = {
            ...MENU_EDITOR_TABS.items,
            visibleTab: false,
        };
        MENU_EDITOR_TABS.newMenuItem = {
            ...MENU_EDITOR_TABS.newMenuItem,
            path,
            visibleTab: true,
        };
    }

    if (path.indexOf("/modifier-groups/") > -1) {
        MENU_EDITOR_TABS.modifierGroups = {
            ...MENU_EDITOR_TABS.modifierGroups,
            visibleTab: false,
        };
        MENU_EDITOR_TABS.newModifierGroup = {
            ...MENU_EDITOR_TABS.newModifierGroup,
            path,
            visibleTab: true,
        };
    }

    if (path.indexOf("/categories/") > -1) {
        MENU_EDITOR_TABS.categories = {
            ...MENU_EDITOR_TABS.categories,
            visibleTab: false,
        };
        MENU_EDITOR_TABS.newCategory = {
            ...MENU_EDITOR_TABS.newCategory,
            path,
            visibleTab: true,
        };
    }

    if (path.indexOf("/meal-periods/") > -1) {
        MENU_EDITOR_TABS.mealPeriods = {
            ...MENU_EDITOR_TABS.mealPeriods,
            visibleTab: false,
        };
        MENU_EDITOR_TABS.newMealPeriod = {
            ...MENU_EDITOR_TABS.newMealPeriod,
            path,
            visibleTab: true,
        };
    }

    return (
        <>
            {!isStaffTablet ? (
                <Paper elevation={0}>
                    {displayMenuVersion && <Banner />}
                    <Divider />
                    {restaurantCode && (
                        <Tabs
                            scrollButtons="auto"
                            value={path}
                            aria-label="Menu Editor Navigation Tabs"
                            className={classes.tabsList}
                            indicatorColor="secondary"
                        >
                            {Object.values(MENU_EDITOR_TABS).map((route) => {
                                return (
                                    route.visibleTab && (
                                        <Tab
                                            key={route.path}
                                            value={route.path}
                                            component={Link}
                                            label={route.title}
                                            to={{
                                                pathname:
                                                    route.originalPath ||
                                                    route.path,
                                                search: locationSearch,
                                            }}
                                            className={classes.tab}
                                        />
                                    )
                                );
                            })}
                        </Tabs>
                    )}
                </Paper>
            ) : (
                <Paper
                    style={{ position: "fixed", width: "100%", zIndex: 1100 }}
                >
                    <Paper elevation={0} className={classes.tabMenu}>
                        <Grid
                            container
                            style={{ width: "100%" }}
                            justify="flex-end"
                        >
                            <Grid item>
                                <Button
                                    disableElevation
                                    style={{ float: "right" }}
                                    onClick={handleClick}
                                >
                                    {selectedMenu}
                                    {anchorEl ? (
                                        <ArrowDropUp />
                                    ) : (
                                        <ArrowDropDown />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <MenuList>
                            {Object.values(menuEditorRoutes)
                                .filter((route) => route.rootTab)
                                .map((route) => (
                                    <MenuItem
                                        key={route.path}
                                        onClick={onMenuItemClick(route)}
                                    >
                                        {route.title}
                                    </MenuItem>
                                ))}
                        </MenuList>
                    </Popover>
                </Paper>
            )}
            <MirrorRestaurantLabel />
            <div className={classes.childComponent}>
                <Switch>{getRoutes(restaurantCode || "")}</Switch>
            </div>
        </>
    );
}
