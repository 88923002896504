import { createStyles, Drawer, makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { useRecursiveTimeout, useCurrentRouteEditor } from "../../../hooks";
import { RootState } from "../../../reducers/rootReducer";
import { checkAuthStatusAction } from "../../../reducers/userReducer";
import { selectAuthTimeOutInSec } from "../../../redux/features/config/config.selector";
import { selectIsRestaurantStatusReceived } from "../../../selectors/restaurant";
import { CircularLoading } from "../../CircularLoading";
import { Sidebar } from "../Sidebar/Sidebar";

const SIDEBAR_WIDTH = 256;
const SMALL_SIDEBAR_WIDTH = 48;
interface AppWrapperProps {
    window?: () => Window;
    children: ReactNode;
}

export interface MouseHoverElementProps {
    isMouseHovered?: boolean;
}

const useStyles = makeStyles<Theme, MouseHoverElementProps>((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        drawer: {
            transition: "0.2s",
            [theme.breakpoints.up("lg")]: {
                width: SIDEBAR_WIDTH,
                flexShrink: 0,
            },
            [theme.breakpoints.down("md")]: {
                width: ({ isMouseHovered }) =>
                    isMouseHovered ? SIDEBAR_WIDTH : SMALL_SIDEBAR_WIDTH,
                flexShrink: 0,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            transition: "0.2s",
            width: SIDEBAR_WIDTH,
            position: "fixed",
            borderRight: 0,
            zIndex: 1111, // 1 more than AppBar
            [theme.breakpoints.down("md")]: {
                width: ({ isMouseHovered }) =>
                    isMouseHovered ? SIDEBAR_WIDTH : SMALL_SIDEBAR_WIDTH,
                flexShrink: 0,
            },
        },
        content: {
            backgroundColor: theme.palette.background.paper,
            flexGrow: 1,
        },
    })
);

export function AppWrapper(props: AppWrapperProps) {
    // To align max-with with mui theme md =1279.95
    const isTablet = useMediaQuery({ query: "(max-width: 1279.95px)" });
    const [isMouseHovered, setMouseHover] = React.useState(false);
    const classes = useStyles({ isMouseHovered });
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);

    const { restaurantId } = useParams<{ restaurantId: string }>();
    const authTime = useSelector(selectAuthTimeOutInSec);
    const isRestaurantStatusReceived = useSelector(
        selectIsRestaurantStatusReceived
    );
    const isCurrentRouteEditor = useCurrentRouteEditor("menu-editor");

    React.useEffect(() => {
        if (user.isLoggedIn && restaurantId) {
            dispatch(
                checkAuthStatusAction({
                    restaurantCode: restaurantId,
                })
            );
        }
    }, [dispatch, user.isLoggedIn, restaurantId]);

    const checkAuthStatus = (): Promise<any> => {
        if (user.isLoggedIn && !user.isAuth0) {
            return Promise.resolve(
                dispatch(
                    checkAuthStatusAction({
                        restaurantCode: restaurantId,
                    })
                )
            );
        }
        return Promise.resolve(true);
    };

    useRecursiveTimeout(
        checkAuthStatus,
        parseInt(authTime) * 1000,
        "checkAuthStatus"
    );

    return (
        <React.Fragment>
            <nav
                className={classes.drawer}
                aria-label="mailbox folders"
                onMouseOver={(e) => {
                    if (isTablet) setMouseHover(true);
                }}
                onMouseLeave={(e) => {
                    if (isTablet) setMouseHover(false);
                }}
            >
                <div className={classes.toolbar} />
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <Sidebar isMouseHovered={isMouseHovered} />
                </Drawer>
            </nav>
            {isRestaurantStatusReceived || isCurrentRouteEditor ? (
                <main className={classes.content}>{props.children}</main>
            ) : (
                <CircularLoading />
            )}
        </React.Fragment>
    );
}
