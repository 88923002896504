import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../reducers/rootReducer";

export const selectConfig = (state: RootState) => state.config;
export const selectNodeEnv = createSelector(
    selectConfig,
    (state) => state.NODE_ENV
);
export const selectFeatureFlagMenuVersion = createSelector(
    selectConfig,
    (state) => state.FEATURE_FLAG_MENU_VERSION
);
export const selectPrpPersistentApi = createSelector(
    selectConfig,
    (state) => state.PRP_PERSISTENT_API
);

export const selectPrpApi = createSelector(
    selectConfig,
    (state) => state.PRP_API
);

export const selectMenuApi = createSelector(
    selectConfig,
    (state) => state.MENU_API
);

export const selectForceLogoutApi = createSelector(
    selectConfig,
    (state) => state.FORCE_LOGOUT_API
);

export const selectTTSOnOffApi = createSelector(
    selectConfig,
    (state) => state.TTS_ON_OFF_API
);

export const selectTRapi = createSelector(
    selectConfig,
    (state) => state.TR_API
);

export const selectMenuAuthApi = createSelector(
    selectConfig,
    (state) => state.MENU_AUTH_API
);

export const selectAIapi = createSelector(
    selectConfig,
    (state) => state.AI_API
);

export const selectAuthTimeOutInSec = createSelector(
    selectConfig,
    (state) => state.AUTH_TIME_OUT_IN_SEC
);

export const selectEventLoopTimeOut = createSelector(
    selectConfig,
    (state) => state.EVENT_LOOP_TIME_OUT
);

export const selectRestaurantApi = createSelector(
    selectConfig,
    (state) => state.RESTAURANT_API
);
