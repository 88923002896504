import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    Category,
    MenuItem,
    MenuItemSetting,
    MenuOverride,
    ModifierGroup,
    PosProperties,
    TimePeriod,
} from "../generated-interfaces/graphql";
import {
    MENU_ACTIONS,
    copyMenuVersionToRestaurant,
    createMenuVersion,
    createUnavialableItems,
    deleteUnavialableItems,
    fetchActiveMenuCommitStage,
    fetchAuditLogs,
    fetchCommittedMenuVersion,
    fetchLatestVersionOnDB,
    fetchMenuCommitStage,
    fetchMenuVersionActivity,
    fetchMenuVersionRequestStatus,
    fetchUnavailableItems,
    getMenu,
    promoteMenuVersion,
    resetMenuVersionData,
    restoreDB,
    restoreDBStatus,
    updateUnavialableItems,
} from "../reducers/menuReducer";
import {
    selectMenuApi,
    selectPrpPersistentApi,
} from "../redux/features/config/config.selector";
import {
    selectRestorationInProgress,
    selectUnavailableItems,
} from "../selectors/menu";
import {
    selectedPrimaryRestaurantCodeSelector,
    selectedRestaurantCodeSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { currentUserSelector, getTokenSelector } from "../selectors/user";
import {
    BasicAPIResponseType,
    RequestStatus,
    RequestType,
} from "../types/menu";
import {
    CreateMenuVersionResponseType,
    IFetchLatestVersionOnDBType,
    IFetchMenuVersionActionType,
    IFetchMenuVersionRequestStatusResponse,
    IFetchRestoreDBStatusType,
    IFetchUnavailableItem,
    IMenuHistory,
    IMenuHistoryResponse,
    IMenuVersionActivity,
    IMenuVersionActivityResponse,
    IMenuVersionRequestStatus,
    IPromoteMenuVersionActionType,
    IRestoreDBCallType,
    IUnavailableItem,
    MenuStages,
} from "../types/menuVersion";
import { StagesRank } from "../utils/constants";
import {
    camelToSnakeCase,
    snakeToCamelCase,
    snakeToCamelCaseRecord,
    sortByDate,
    toRequestedCase,
} from "../utils/helper-functions";
import logger from "../utils/logger";
import { transformToMenuSchemaV1 } from "../utils/menu";
import { PerfTimer } from "../utils/timer";
import {
    CreateMenuVersion,
    GetIngestedDataType,
    IAuditLogs,
    IAuditLogsInput,
    ICopyMenuVersionToRestaurant,
    StringOrNull,
} from "../utils/types";
import {
    CreateMenuVersionCall,
    copyMenuVersionToRestaurantCall,
    createUnavailableItemsCall,
    deleteUnavailableItemsCall,
    fetchActiveMenuCommitStageCall,
    fetchAuditLogsCall,
    fetchCommittedMenuVersionCall,
    fetchLatestVersionOnDBCall,
    fetchMenuCommitStageCall,
    fetchMenuVersionActivityCall,
    fetchMenuVersionPreSignedUrlCall,
    fetchMenuVersionRequestStatusCall,
    fetchRestoreDBStatusCall,
    fetchUnavailableItemsCall,
    promoteMenuVersionCall,
    restoreDBCall,
    updateUnavailableItemsCall,
} from "../utils/webserviceAPI";
import { getAuthInfo } from "./utilsSaga";

const {
    setIsLoadingEntity,
    updateStateProperty,
    getMenuSuccess,
} = MENU_ACTIONS;

function getUserInfo(currentUser: any) {
    const user = {
        username: "",
        first_name: "",
        last_name: "",
    };
    user.username = currentUser.email || currentUser.username;
    user.first_name = currentUser.given_name || currentUser.firstName;
    user.last_name = currentUser.family_name || currentUser.lastName;
    return user;
}

function* createMenuVersionSaga(action: PayloadAction<CreateMenuVersion>) {
    const timer = new PerfTimer();
    const { comment, successCallback, errorCallback } = action.payload;
    logger.debug("Attempting to create menu version");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const user = getUserInfo(yield select(currentUserSelector));
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: CreateMenuVersionResponseType = yield call(
            CreateMenuVersionCall,
            {
                comment,
                restaurantCode,
                user,
                stage: stage.toLowerCase(),
                url,
                token,
                isAuth0,
            }
        );
        yield put(setIsLoadingEntity(false));
        successCallback?.(response);
        logger.info("Successfully triggered create menu version", {
            duration: timer.stop(),
        });
    } catch (error) {
        yield put(setIsLoadingEntity(false));
        errorCallback?.(error);
        logger.error("Creating menu version Failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* fetchMenuCommitStageSaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch stage specific committed menu");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        yield put(
            updateStateProperty({
                versionList: [] as IMenuHistory[],
            })
        );
        const response: {
            data: { data: IMenuHistoryResponse[]; success: string };
        } = yield call(fetchMenuCommitStageCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
            url,
            token,
            isAuth0,
        });
        const versionListMapping = (response?.data?.data || [])
            .map(
                ({
                    commit_id: commitId,
                    created_at: createdAt,
                    creator_first_name,
                    creator_last_name,
                    publisher_username: publisherUsername,
                    publisher_first_name,
                    publisher_last_name,
                    creator_username: creatorUsername,
                    id,
                    stage,
                    is_active: isActive,
                    updated_at: updatedAt,
                    comment,
                }) => ({
                    commitId,
                    createdAt,
                    creatorUsername,
                    creatorName: `${creator_first_name} ${creator_last_name}`,
                    publisherUsername,
                    publisherName: `${publisher_first_name} ${publisher_last_name}`,
                    id,
                    stage,
                    isActive,
                    updatedAt,
                    comment,
                })
            )
            .sort(
                ({ stage: itemStage1 }, { stage: itemStage2 }) =>
                    StagesRank[itemStage1] - StagesRank[itemStage2]
            )
            .reduce((list, item) => {
                list[item.commitId] = item;
                return list;
            }, {} as Record<string, IMenuHistory>);
        const versionList = Object.values(versionListMapping).sort(
            (a, b) => Number(b.commitId) - Number(a.commitId)
        );
        yield put(
            updateStateProperty({
                versionList,
            })
        );
        logger.info("Successfully got committed menu", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching committed menu Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* fetchActiveMenuCommitStageSaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch stage specific active committed menu");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        yield put(
            updateStateProperty({
                stagesOverview: [] as IMenuHistory[],
            })
        );
        const response: {
            data: { data: IMenuHistoryResponse[]; success: string };
        } = yield call(fetchActiveMenuCommitStageCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
            url,
            token,
            isAuth0,
        });
        const stagesOverview = (response?.data?.data || [])
            .map(
                ({
                    commit_id: commitId,
                    created_at: createdAt,
                    creator_first_name,
                    creator_last_name,
                    publisher_username: publisherUsername,
                    publisher_first_name,
                    publisher_last_name,
                    creator_username: creatorUsername,
                    id,
                    stage,
                    is_active: isActive,
                    updated_at: updatedAt,
                    comment,
                }) => ({
                    commitId,
                    createdAt,
                    creatorUsername,
                    creatorName: `${creator_first_name} ${creator_last_name}`,
                    publisherUsername,
                    publisherName: `${publisher_first_name} ${publisher_last_name}`,
                    id,
                    stage,
                    isActive,
                    updatedAt,
                    comment,
                })
            )
            .filter(({ stage }) => stage.toUpperCase() !== MenuStages.PRELIVE)
            .sort(
                (a, b) =>
                    StagesRank[a.stage.toUpperCase()] -
                    StagesRank[b.stage.toUpperCase()]
            );
        yield put(updateStateProperty({ stagesOverview }));
        logger.info("Successfully got active committed menu", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching active committed menu Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* fetchMenuVersionRequestStatusSaga(
    action: PayloadAction<{ fromRoot: boolean }>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch menu version request status");
    if (!action?.payload?.fromRoot) yield put(setIsLoadingEntity(true));
    try {
        const isCurrentlyRestorationInProgress: boolean = yield select(
            selectRestorationInProgress
        );
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        yield put(
            updateStateProperty({
                menuVersionRequestStatus: [] as IMenuVersionRequestStatus[],
            })
        );
        const response: {
            data: {
                data: IFetchMenuVersionRequestStatusResponse[];
                success: string;
            };
        } = yield call(fetchMenuVersionRequestStatusCall, {
            restaurantCode,
            stage: MenuStages.PLAYGROUND.toLowerCase(),
            url,
            token,
            isAuth0,
        });
        let restorationInProgress = false;
        let copyInProgress = false;
        const menuVersionRequestStatus: IMenuVersionRequestStatus[] = (toRequestedCase(
            response?.data?.data,
            snakeToCamelCase
        ) as IMenuVersionRequestStatus[]).sort(
            (o1, o2) => Number(o2?.id) - Number(o1?.id)
        );

        menuVersionRequestStatus.forEach(({ status, requestType }) => {
            const requestTypeLowerCase = requestType.toLowerCase();
            if (
                [
                    RequestStatus.inProgress.toLowerCase(),
                    RequestStatus.pending.toLowerCase(),
                ].includes(status.toLowerCase())
            ) {
                restorationInProgress =
                    requestTypeLowerCase ===
                        RequestType.restore.toLowerCase() ||
                    restorationInProgress;
                copyInProgress =
                    [
                        RequestType.copy.toLowerCase(),
                        RequestType.clone.toLowerCase(),
                    ].includes(requestTypeLowerCase) || copyInProgress;
            }
        });
        /***
         *    we need to load menu on the trasition of the status from progreess to complete.
         */
        if (isCurrentlyRestorationInProgress && !restorationInProgress) {
            yield put(getMenu());
        }
        yield put(
            updateStateProperty({
                menuVersionRequestStatus,
                restorationInProgress,
                copyInProgress,
            })
        );
        logger.info("Successfully got menu version request status", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching menu version request status failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* promoteMenuVersionSaga(
    action: PayloadAction<IPromoteMenuVersionActionType>
) {
    const timer = new PerfTimer();
    const { successCallback, errorCallback, commitId, stage } = action.payload;
    logger.debug("Attempting to promote menu version");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const user = getUserInfo(yield select(currentUserSelector));

        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: { data: BasicAPIResponseType } = yield call(
            promoteMenuVersionCall,
            {
                restaurantCode,
                stage,
                commitId,
                user,
                url,
                token,
                isAuth0,
            }
        );
        yield put(setIsLoadingEntity(false));
        if (response?.data?.status.toLowerCase() === "success") {
            yield resetMenuVersionDataSaga();
            successCallback?.();
        } else errorCallback?.();
        logger.info("Successfully promoted menu version", {
            duration: timer.stop(),
        });
    } catch (error) {
        yield put(setIsLoadingEntity(false));
        errorCallback?.({ message: error?.response?.data?.error_message });
        logger.error("Promoting menu version failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* menuItemsSaga(action: PayloadAction<IFetchMenuVersionActionType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to get menu items");
    const { commitId, errorCallback, successCallback, stage } = action.payload;
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: string = yield select(
            selectedPrimaryRestaurantCodeSelector
        );
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const output: {
            data: { data: { menu_url: string; unavailable_items: any[] } };
        } = yield call(fetchMenuVersionPreSignedUrlCall, {
            restaurantCode,
            commitId,
            stage,
            url,
            token,
            isAuth0,
        });
        const {
            data: { menu_url: menuUrl, unavailable_items },
        } = output?.data;
        const response: {
            data: {
                menuItems: MenuItem[];
                modifierGroups: ModifierGroup[];
                categories: Category[];
                menuOverrides: MenuOverride[];
                menuItemSettings: MenuItemSetting[];
                timePeriods: TimePeriod[];
                posProperties: PosProperties[];
                voiceProperties: GetIngestedDataType[];
                commit_id: string;
            };
        } = yield call(fetchCommittedMenuVersionCall, {
            menuUrl,
        });

        const {
            menuItems,
            modifierGroups,
            categories,
            menuOverrides,
            timePeriods,
        } = transformToMenuSchemaV1(
            response?.data || {
                categories: [],
                menuItems: [],
                menuItemSettings: [],
                menuOverrides: [],
                modifierGroups: [],
                posProperties: [],
                timePeriods: [],
                voiceProperties: [],
            }
        );

        yield put(
            getMenuSuccess({
                menuItems,
                modifierGroups,
                categories,
                timePeriods,
                menuOverrides,
            })
        );
        yield put(updateStateProperty({ didInitialLoad: true }));
        const unavailableItems: Record<string, IUnavailableItem> = (
            unavailable_items || []
        ).reduce((itemList, item) => {
            itemList[item.item_unique_identifier] = snakeToCamelCaseRecord(
                item
            );

            return itemList;
        }, {} as Record<string, IUnavailableItem>);

        yield put(updateStateProperty({ unavailableItems }));

        const latestVersionInfo = {
            commitId: response?.data?.commit_id ?? "",
        } as IMenuHistory;

        yield put(
            updateStateProperty({
                latestVersionInfo,
            })
        );
        successCallback?.();
        logger.info("Successfully got menu items", { duration: timer.stop() });
    } catch (error) {
        logger.error("Get Menu Items Failed", {
            error,
            duration: timer.stop(),
        });
        yield put(
            getMenuSuccess({
                menuItems: [],
                modifierGroups: [],
                categories: [],
                timePeriods: [],
                menuOverrides: [],
            })
        );
        errorCallback?.(error);
    }
    yield put(setIsLoadingEntity(false));
}

function* fetchMenuVersionActivitySaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch menu version request status");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        yield put(
            updateStateProperty({
                menuVersionActivity: [] as IMenuVersionActivity[],
            })
        );
        const menuVersionActivityResponse: {
            data: {
                data: IMenuVersionActivityResponse[];
                success: string;
            };
        } = yield call(fetchMenuVersionActivityCall, {
            restaurantCode,
            stage: stage.toLowerCase(),
            url,
            token,
            isAuth0,
        });

        const menuHistoryResponse: {
            data: { data: IMenuHistoryResponse[]; success: string };
        } = yield call(fetchMenuCommitStageCall, {
            restaurantCode,
            stage: MenuStages.PLAYGROUND.toLowerCase(),
            url,
            token,
            isAuth0,
        });
        const versionList = (menuHistoryResponse?.data?.data || []).reduce(
            (acc, item) => {
                const { commit_id } = item;
                if (acc[commit_id]) return acc;
                acc[commit_id] = item;
                return acc;
            },
            {} as Record<string, IMenuHistoryResponse>
        );
        const menuVersionActivity: IMenuVersionActivity[] = menuVersionActivityResponse?.data?.data
            .map(
                ({
                    id,
                    restaurant_code: restaurantCode,
                    stage,
                    commit_id: commitId,
                    creator_first_name: publisherFirstName,
                    creator_last_name: publisherLastName,
                    creator_username: publisherUsername,
                    updated_at: updatedAt,
                    action_name: actionName,
                    is_active: isActive,
                }) => {
                    const {
                        creator_username: creatorUsername,
                        creator_first_name,
                        creator_last_name,
                        comment,
                        created_at: createdAt,
                    } = versionList[commitId];
                    return {
                        id,
                        restaurantCode,
                        stage,
                        creatorUsername,
                        creatorName: `${creator_first_name} ${creator_last_name}`,
                        publisherUsername,
                        publisherName: `${publisherFirstName} ${publisherLastName}`,
                        updatedAt,
                        createdAt,
                        commitId,
                        actionName,
                        isActive,
                        comment,
                    };
                }
            )
            .sort(sortByDate("updatedAt", "desc"));
        yield put(
            updateStateProperty({
                menuVersionActivity,
            })
        );
        logger.info("Successfully got menu version request status", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching menu version request status failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* restoreDBSaga(action: PayloadAction<IRestoreDBCallType>) {
    const timer = new PerfTimer();
    const { successCallback, errorCallback, commitId } = action.payload;
    logger.debug("Attempting to restore menu version");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectMenuApi);
        const stage: MenuStages = yield select(selectedStageSelector);
        const user = getUserInfo(yield select(currentUserSelector));
        const { token, isAuth0 } = yield call(getAuthInfo);

        const response: { data: BasicAPIResponseType } = yield call(
            restoreDBCall,
            {
                restaurantCode,
                stage: stage.toLowerCase(),
                commitId,
                user,
                url,
                token,
                isAuth0,
            }
        );
        if (response?.data?.status.toLowerCase() === "success") {
            yield put(updateStateProperty({ restorationInProgress: true }));
            yield resetMenuVersionDataSaga();
            successCallback?.();
        } else errorCallback?.();
        logger.info("Successfully initiated menu version restoration", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.({
            message: error?.response?.data?.error_message || error?.message,
        });
        logger.error("Restoring menu version failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* fetchRestoreDBStatusSaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch restore DB status");
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const restorationInProgress: boolean = yield select(
            selectRestorationInProgress
        );
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: {
            data: IFetchRestoreDBStatusType;
            success: string;
        } = yield call(fetchRestoreDBStatusCall, {
            restaurantCode,
            stage: MenuStages.PLAYGROUND.toLowerCase(),
            url,
            token,
            isAuth0,
        });

        const { incomplete_jobs = [] } = response?.data;
        if (restorationInProgress && incomplete_jobs.length === 0) {
            yield put(getMenu());
        }
        yield put(
            updateStateProperty({
                restorationInProgress: incomplete_jobs.length > 0,
            })
        );
        logger.info("Successfully got restore db status", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching restore db status Failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* fetchLatestVersionOnDBSaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch latest version on DB");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const stage: string = yield select(selectedStageSelector);
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: {
            data: IFetchLatestVersionOnDBType;
            success: string;
        } = yield call(fetchLatestVersionOnDBCall, {
            restaurantCode,
            stage,
            url,
            token,
            isAuth0,
        });

        const { last_commit_on_db } = response?.data;
        const latestVersionInfo: IMenuHistory = snakeToCamelCaseRecord(
            last_commit_on_db
        );
        yield put(updateStateProperty({ latestVersionInfo }));
        logger.info("Successfully got committed menu", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching committed menu Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* resetMenuVersionDataSaga(): Generator<any, any, any> {
    try {
        yield put(fetchMenuCommitStage());
        yield put(fetchActiveMenuCommitStage());
        yield put(fetchLatestVersionOnDB({}));
        yield put(fetchMenuVersionRequestStatus({ fromRoot: false }));
        yield put(fetchMenuVersionActivity());
    } catch (error) {
        logger.error("Error while resetting the menu version data", error);
    }
}

function* copyMenuVersionToRestaurantSaga(
    action: PayloadAction<ICopyMenuVersionToRestaurant>
) {
    const timer = new PerfTimer();
    const {
        commitId,
        applicableRestaurants,
        itemIds,
        allowDuplicates,
        successCallback,
        errorCallback,
    } = action.payload;
    logger.debug("Attempting to copy menu version to restaurant");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const user = getUserInfo(yield select(currentUserSelector));
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: CreateMenuVersionResponseType = yield call(
            copyMenuVersionToRestaurantCall,
            {
                commitId,
                applicableRestaurants,
                restaurantCode,
                allowDuplicates,
                user,
                url,
                itemIds,
                token,
                isAuth0,
            }
        );
        yield put(setIsLoadingEntity(false));
        successCallback?.(response);
        logger.info("Successfully triggered copy menu version to restaurant", {
            duration: timer.stop(),
        });
    } catch (error) {
        yield put(setIsLoadingEntity(false));
        errorCallback?.(error);
        logger.error("Copying menu version to restaurant Failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* fetchAuditLogsSaga(action: PayloadAction<IAuditLogsInput>) {
    const timer = new PerfTimer();
    const { successCallback, errorCallback, ...inputParams } = action.payload;
    logger.debug("Attempting to fetch audit logs");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectPrpPersistentApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const params = Object.entries(inputParams).reduce(
            (obj, [key, value]) => {
                obj[camelToSnakeCase(key)] = value;
                return obj;
            },
            {} as Record<string, string>
        );

        const {
            data: {
                audit_logs: auditLogsInput,
                total_logs_count: auditLogsTotalCount,
            },
        }: {
            data: { audit_logs: IAuditLogs[]; total_logs_count: number };
        } = yield call(fetchAuditLogsCall, {
            ...params,
            restaurantCode,
            url,
            token,
            isAuth0,
        });
        const auditLogs = auditLogsInput.reduce((list, item) => {
            list.push(snakeToCamelCaseRecord(item));
            return list;
        }, [] as IAuditLogs[]);
        yield put(setIsLoadingEntity(false));
        yield put(
            updateStateProperty({
                auditLogs,
                auditLogsTotalCount,
            })
        );
        successCallback?.();
        logger.info("Successfully found audit logs", {
            duration: timer.stop(),
        });
    } catch (error) {
        yield put(setIsLoadingEntity(false));
        if (error?.response?.status !== 404) {
            errorCallback?.(
                "Error while fetching audit logs. Please try again later."
            );
        }
        yield put(
            updateStateProperty({ auditLogs: [], auditLogsTotalCount: 0 })
        );

        logger.error("fetching audit logs failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* fetchUnavailableItemsSaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch unavailable items");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectMenuApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: {
            data: { data: IFetchUnavailableItem[] };
        } = yield call(fetchUnavailableItemsCall, {
            restaurantCode,
            url,
            token,
            isAuth0,
        });
        const unavailableItems: Record<string, IUnavailableItem> = (
            response?.data?.data || []
        ).reduce((itemList, item) => {
            itemList[item.item_unique_identifier] = snakeToCamelCaseRecord(
                item
            );

            return itemList;
        }, {} as Record<string, IUnavailableItem>);

        yield put(updateStateProperty({ unavailableItems }));
        logger.info("Successfully got unavailable items", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Fetching unavailable items Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* createUnavailableItemsSaga(action: PayloadAction<any>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create unavailable items");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const prevUnavailableItems: Record<
            string,
            IUnavailableItem
        > = yield select(selectUnavailableItems);
        const {
            itemUniqueIdentifier: item_unique_identifier,
            unavailableUntil: unavailable_until,
        } = action.payload;
        const url: string = yield select(selectMenuApi);

        const { token, isAuth0 } = yield call(getAuthInfo);
        const { data }: { data: IFetchUnavailableItem } = yield call(
            createUnavailableItemsCall,
            {
                restaurantCode,
                item_unique_identifier,
                unavailable_until,
                url,
                token,
                isAuth0,
            }
        );

        const unavailableItems = {
            ...prevUnavailableItems,
            [data.item_unique_identifier]: snakeToCamelCaseRecord(data),
        };

        yield put(updateStateProperty({ unavailableItems }));

        logger.info("Successfully created unavailable items", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Creating unavailable items Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* updateUnavailableItemsSaga(action: PayloadAction<any>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update unavailable items");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const prevUnavailableItems: Record<
            string,
            IUnavailableItem
        > = yield select(selectUnavailableItems);
        const {
            itemUniqueIdentifier: item_unique_identifier,
            unavailableUntil: unavailable_until,
            unavailableItemsId: unavailable_items_id,
        } = action.payload;
        const url: string = yield select(selectMenuApi);

        const { token, isAuth0 } = yield call(getAuthInfo);
        const { data }: { data: IFetchUnavailableItem } = yield call(
            updateUnavailableItemsCall,
            {
                restaurantCode,
                item_unique_identifier,
                unavailable_items_id,
                unavailable_until,
                url,
                token,
                isAuth0,
            }
        );
        const unavailableItems = {
            ...prevUnavailableItems,
            [data.item_unique_identifier]: snakeToCamelCaseRecord(data),
        };

        yield put(updateStateProperty({ unavailableItems }));

        yield put(updateStateProperty(unavailableItems));
        logger.info("Successfully updated unavailable items", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Updating unavailable items Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

function* deleteUnavailableItemsSaga(
    action: PayloadAction<{
        unavailableItemsId: string;
        itemUniqueIdentifier: string;
    }>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to delete unavailable items");
    yield put(setIsLoadingEntity(true));
    try {
        const restaurantCode: StringOrNull = yield select(
            selectedRestaurantCodeSelector
        );
        const prevUnavailableItems: Record<
            string,
            IUnavailableItem
        > = yield select(selectUnavailableItems);
        const {
            unavailableItemsId: unavailable_items_id,
            itemUniqueIdentifier,
        } = action.payload;
        const url: string = yield select(selectMenuApi);

        const { token, isAuth0 } = yield call(getAuthInfo);
        yield call(deleteUnavailableItemsCall, {
            restaurantCode,
            unavailable_items_id,
            url,
            token,
            isAuth0,
        });
        const unavailableItems = { ...prevUnavailableItems };
        delete unavailableItems[itemUniqueIdentifier];

        yield put(updateStateProperty({ unavailableItems }));
        logger.info("Successfully deleted unavailable items", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Deleted successfully unavailable items", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(setIsLoadingEntity(false));
}

export default function* menuVersionSaga() {
    yield takeEvery(createMenuVersion.toString(), createMenuVersionSaga);
    yield takeEvery(fetchMenuCommitStage.toString(), fetchMenuCommitStageSaga);
    yield takeEvery(
        fetchActiveMenuCommitStage.toString(),
        fetchActiveMenuCommitStageSaga
    );
    yield takeEvery(
        fetchMenuVersionRequestStatus.toString(),
        fetchMenuVersionRequestStatusSaga
    );

    yield takeEvery(promoteMenuVersion.toString(), promoteMenuVersionSaga);
    yield takeEvery(fetchCommittedMenuVersion.toString(), menuItemsSaga);
    yield takeEvery(
        fetchMenuVersionActivity.toString(),
        fetchMenuVersionActivitySaga
    );
    yield takeEvery(restoreDB.toString(), restoreDBSaga);
    yield takeEvery(restoreDBStatus.toString(), fetchRestoreDBStatusSaga);
    yield takeEvery(
        fetchLatestVersionOnDB.toString(),
        fetchLatestVersionOnDBSaga
    );
    yield takeEvery(resetMenuVersionData.toString(), resetMenuVersionDataSaga);
    yield takeEvery(
        copyMenuVersionToRestaurant.toString(),
        copyMenuVersionToRestaurantSaga
    );
    yield takeEvery(fetchAuditLogs.toString(), fetchAuditLogsSaga);
    yield takeEvery(
        fetchUnavailableItems.toString(),
        fetchUnavailableItemsSaga
    );

    yield takeEvery(
        createUnavialableItems.toString(),
        createUnavailableItemsSaga
    );
    yield takeEvery(
        updateUnavialableItems.toString(),
        updateUnavailableItemsSaga
    );
    yield takeEvery(
        deleteUnavialableItems.toString(),
        deleteUnavailableItemsSaga
    );
}
