import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemNavigation from "../../components/ItemNavigation";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { componentTypeConfig, MenuType } from "../../constants/menu";
import { Category, ModifierGroup } from "../../generated-interfaces/graphql";
import { useDuplicatePayload, useReadOnly } from "../../hooks";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { ComponentType } from "../../types/menu";
import { MenuItemWithCategories } from "../../utils/menu";

interface IRowActions {
    data: Category | ModifierGroup | MenuItemWithCategories;
    duplicateAction: Function;
    componentType: ComponentType;
    type: keyof typeof MenuType;
}

export default function RowActions({
    data,
    duplicateAction,
    componentType,
    type,
}: IRowActions) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const dispatch = useDispatch();

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { generateDuplicatePayload } = useDuplicatePayload();
    const { title, url } = componentTypeConfig[type];
    const { isReadOnly } = useReadOnly();

    return (
        <div id="row-actions">
            <ItemNavigation
                url={`/${restaurantCode}/menu-editor/${url}/${data.id}`}
                title={title}
            />
            {!isReadOnly && (
                <>
                    <IconButton
                        onClick={(e) => handleMenuClick(e)}
                        aria-controls={`more-options-${data.id}`}
                    >
                        <Tooltip title="More options" aria-label="More options">
                            <MoreVertIcon />
                        </Tooltip>
                    </IconButton>
                    <Menu
                        id={`more-options-${data.id}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <ReadOnlyWrapper
                            element={MenuItem}
                            onClick={() => {
                                handleMenuClose();
                                dispatch(
                                    duplicateAction(
                                        generateDuplicatePayload(
                                            data.id,
                                            componentType
                                        )
                                    )
                                );
                            }}
                        >
                            Duplicate
                        </ReadOnlyWrapper>
                    </Menu>
                </>
            )}
        </div>
    );
}
