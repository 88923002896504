import { useCallback } from "react";
import { parse } from "query-string";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchCommittedMenuVersion, getMenu } from "../reducers/menuReducer";
import {
    restaurantInfoSelector,
    selectedRestaurantAccessSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { IFetchMenuVersionActionType, MenuStages } from "../types/menuVersion";
import { RolePermissions } from "../types/restaurant";
import { TIME_OUT } from "../utils/constants";
import { isNumber, readEnvVariable } from "../utils/helper-functions";
import { hasRoleAccess } from "../utils/restaurants";
import { CallbackFunction } from "../utils/types";

export default function useLoadMenu() {
    const dispatch = useDispatch();
    const selectedStage = useSelector(selectedStageSelector);
    const { primaryRestaurantCode } = useSelector(restaurantInfoSelector) || {};
    const location = useLocation();
    const queryParams = parse(location.search);
    const menuVersion = queryParams["version"];
    const alert = useAlert();
    const selectedRestaurantAccessLevel = useSelector(
        selectedRestaurantAccessSelector
    );
    const isEditorScreen = () => {
        let isEditor = false;
        if (location?.pathname?.indexOf("menu-editor") !== -1) {
            const lastPart = location?.pathname?.substring(
                location?.pathname?.lastIndexOf("/") + 1
            );
            if (isNumber(lastPart)) {
                isEditor = true;
            }
        }
        return isEditor;
    };
    return {
        loadMenu: useCallback(
            ({
                stage,
                successCallback,
                reload = false,
            }: CallbackFunction & {
                stage?: MenuStages;
                reload?: boolean;
            }) => {
                const latestStage = stage || selectedStage;
                const isMenuVersionFeatureFlagEnabled =
                    readEnvVariable(
                        "FEATURE_FLAG_MENU_VERSION"
                    ).toUpperCase() === "TRUE";
                const shouldGetMenuVersion =
                    !hasRoleAccess(
                        RolePermissions.RestaurantManager,
                        selectedRestaurantAccessLevel
                    ) || !!primaryRestaurantCode;
                if (
                    isMenuVersionFeatureFlagEnabled &&
                    (shouldGetMenuVersion ||
                        latestStage !== MenuStages.PLAYGROUND ||
                        !!menuVersion)
                ) {
                    const payload: IFetchMenuVersionActionType = {
                        successCallback,
                        stage: shouldGetMenuVersion
                            ? MenuStages.LIVE
                            : latestStage,
                    };
                    if (menuVersion) {
                        payload["commitId"] = menuVersion as string;
                        payload[
                            "errorCallback"
                        ] = /* istanbul ignore next */ () => {
                            alert.error(
                                `Error fetching menu for version ${menuVersion}`,
                                {
                                    timeout: TIME_OUT,
                                }
                            );
                        };
                    }
                    dispatch(fetchCommittedMenuVersion(payload));
                } else if (!isEditorScreen() || reload) {
                    dispatch(getMenu({ successCallback }));
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                selectedStage,
                selectedRestaurantAccessLevel,
                primaryRestaurantCode,
                menuVersion,
                dispatch,
                alert,
            ]
        ),
    };
}
