import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import { cloneMenu } from "../../reducers/menuReducer";
import { ApiStack, PosType } from "./constants";
import MenuIngestionFormBody from "./MenuIngestionFormBody";
import { IMenuIngestionFormData } from "./types";
import { TIME_OUT } from "../../utils/constants";

const MenuIngestion = () => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const { restaurantId: targetRestaurantCode } = useParams<{
        restaurantId: string;
    }>();

    const initialValues: IMenuIngestionFormData = {
        sourceRestaurant: null,
        targetPosType: PosType.aloha,
        targetRawFileUrl: "",
        apiStack: ApiStack.api15,
        locationToken: "",
        runValidation: false,
    };

    async function onSubmit({
        sourceRestaurant,
        targetPosType,
        targetRawFileUrl,
        locationToken,
        apiStack,
        runValidation,
    }: IMenuIngestionFormData) {
        return new Promise((resolve, reject) => {
            dispatch(
                cloneMenu({
                    sourceRestaurantCode: sourceRestaurant?.restaurantCode!,
                    targetPosType,
                    targetRawFileUrl,
                    targetRestaurantCode,
                    locationToken,
                    apiStack,
                    runValidation,
                    successCallback: (response: any) => {
                        resolve();
                        alert.success(`${response.data.message}`, {
                            timeout: TIME_OUT,
                        });
                    },
                    errorCallback: (err: any) => {
                        reject();
                        alert.error(
                            `${err.data.error_message} (${err.data.error_code})`,
                            {
                                timeout: TIME_OUT,
                            }
                        );
                    },
                })
            );
        });
    }

    async function validate({
        sourceRestaurant,
        targetRawFileUrl,
        targetPosType,
        locationToken,
    }: IMenuIngestionFormData) {
        const errors: Record<string, boolean> = {};
        const isBrink = targetPosType === PosType.brink;
        if (!sourceRestaurant) {
            errors.sourceRestaurant = true;
        }
        if (!isBrink && !targetRawFileUrl) {
            errors.targetRawFileUrl = true;
        }
        if (isBrink && !locationToken) {
            errors.locationToken = true;
        }

        return errors;
    }

    return (
        <Form
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={(props) => <MenuIngestionFormBody {...props} />}
        />
    );
};

export default MenuIngestion;
