export enum PosType {
    aloha = "aloha",
    xpient = "xpient",
    qu = "qu",
    brink = "brink",
}

export enum ApiStack {
    api15 = "api15",
    apiApiInt = "api-apiint",
}
