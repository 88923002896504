import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Theme,
    Typography,
} from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import ArrayMove from "array-move";
import MuiTreeView from "material-ui-treeview";
import React, {
    ChangeEvent,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useAlert } from "react-alert";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { Dispatch } from "redux";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { UpdateCategoriesSortOrderInput } from "../../generated-interfaces/graphql";
import { useCustomHistory } from "../../hooks";
import { updateCategoriesSortOrder } from "../../reducers/menuReducer";
import { RootState } from "../../reducers/rootReducer";
import { selectDidInitialMenuLoad } from "../../selectors/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { DAYS_MAPPING, TIME_OUT } from "../../utils/constants";
import { numberToDate } from "../../utils/helper-functions";
import {
    menuItemsSelector,
    menuTreeNodeSelector,
    TreeNode,
} from "../../utils/menu";
import { CallbackFunction } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) => ({
    dragContainer: {
        backgroundColor: "rgba(0,0,0,0.08)",
        padding: "20px 40px",
        marginLeft: "-36px",
        marginRight: "-30px",
    },
    line: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        margin: "10px 0",
        borderBottom: "1px solid #eeeeee",
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
        backgroundColor: "white",
        overflow: "hidden",
        "& .MuiExpansionPanelSummary-content p": {
            maxWidth: "calc(100vw - 410px)",
        },
        [theme.breakpoints.down("sm")]: {
            "& .MuiExpansionPanelSummary-content p": {
                maxWidth: "calc(100vw - 90px)",
            },
        },
    },
    header: {
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            marginBottom: theme.spacing(2),
        },
    },
    newBtn: {
        width: "100%",
    },
    limitedQuantityWarning: {
        backgroundColor: "#ffc107",
        padding: "20px 40px",
        marginLeft: "-36px",
        marginRight: "-36px",
        marginBottom: "16px",
        "& > div": {
            marginBottom: "8px",
            fontSize: "18px",
        },
    },
    menuPreviewContainer: {
        display: "flex",
        maxWidth: "400px",
        "& .MuiInputBase-root": {
            width: "126px",
            "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1rem",
                paddingLeft: theme.spacing(1),
            },
            "& .MuiInputAdornment-positionEnd": {
                marginLeft: "2px",
                "& .MuiTypography-body1": {
                    marginRight: "8px",
                },
            },
        },
    },
}));

const dummyNodes: any[] = [
    {
        id: "dummy1",
        value: "dummy1",
        nodes: [],
    },
    {
        id: "dummy2",
        value: "dummy2",
        nodes: [],
    },
    {
        id: "dummy3",
        value: "dummy3",
        nodes: [],
    },
];
const DragHandle = SortableHandle(() => (
    <span style={{ margin: "0 10px" }}>
        <DehazeIcon />
    </span>
));
const SortableItem = SortableElement(
    (data: { value: TreeNode; loading?: boolean }) => {
        const classes = useStyles();
        const restaurantCode = useSelector(selectedRestaurantCodeSelector);
        const { pushToHistory } = useCustomHistory();
        const onLeafClick = useCallback(
            (props) => {
                pushToHistory(
                    `/${restaurantCode}/menu-editor/items/${props.id}`
                );
            },
            [pushToHistory, restaurantCode]
        );
        return (
            <div className={classes.line}>
                {data.loading ? (
                    <Skeleton variant="rect" width="100%" height="100%">
                        <DragHandle />
                        <MuiTreeView
                            tree={[data.value]}
                            expansionPanelSummaryProps={{
                                expandIcon: <ExpandMoreIcon />,
                            }}
                            expansionPanelDetailsProps={{
                                style: {
                                    fontSize: "14px",
                                },
                            }}
                            listItemProps={{
                                style: {
                                    fontSize: "14px",
                                },
                            }}
                            onLeafClick={onLeafClick}
                        />
                    </Skeleton>
                ) : (
                    <>
                        <DragHandle />
                        <MuiTreeView
                            tree={[data.value]}
                            expansionPanelSummaryProps={{
                                expandIcon: <ExpandMoreIcon />,
                                style: {
                                    paddingRight: 0,
                                    marginLeft: 0,
                                },
                            }}
                        />
                    </>
                )}
            </div>
        );
    }
);
const Container = SortableContainer((data: { children: ReactNode }) => {
    const classes = useStyles();
    return <div className={classes.dragContainer}>{data.children}</div>;
});

function Overview(props: Props) {
    const { updateCategoriesSortOrder } = props;
    const alert = useAlert();
    const nodeList = useSelector(menuTreeNodeSelector);
    const initialLoad = useSelector(selectDidInitialMenuLoad);
    const [menuTreeNodes, setMenuTreeNodes] = useState<TreeNode[]>([]);
    const classes = useStyles();
    const { search: locationSearch } = useLocation();
    const menuItemsMap = useSelector(menuItemsSelector);
    const [menuPreviewType, setMenuPreviewTypeValue] = useState("currentTime");
    const handleMenuPreviewTypeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setMenuPreviewTypeValue(event.target.value);
        },
        []
    );
    const [selectedDay, setDay] = useState(1);
    const handleDayChange = useCallback(
        (event: ChangeEvent<{ value: unknown }>) => {
            setDay(Number(event.target.value as string));
        },
        []
    );
    const [selectedTime, setTime] = useState(1230);
    const handleTimeChange = useCallback((selectedDate: Date | null) => {
        if (selectedDate) {
            const newTimeValue =
                selectedDate.getHours() * 100 + selectedDate.getMinutes();
            setTime(newTimeValue);
        }
    }, []);

    useEffect(() => {
        let newNodeList = [...nodeList];
        newNodeList = newNodeList.sort((a: any, b: any) => {
            if (a.ownSortOrder !== null && b.ownSortOrder !== null)
                return a.ownSortOrder - b.ownSortOrder;
            else return 1;
        });
        setMenuTreeNodes([...newNodeList]);
    }, [nodeList]);

    const renderMenuPreview = () => {
        return (
            <>
                <Typography
                    variant="h6"
                    color="primary"
                    style={{ marginBottom: "16px" }}
                >
                    Preview Menu
                </Typography>
                <Typography style={{ marginBottom: "16px", fontSize: "18px" }}>
                    Browse the menu like your guests would.
                </Typography>
                <FormControl
                    component="fieldset"
                    className={classes.menuPreviewContainer}
                >
                    <RadioGroup
                        aria-label="menu-preview-type"
                        name="menu-preview-type"
                        value={menuPreviewType}
                        onChange={handleMenuPreviewTypeChange}
                    >
                        <FormControlLabel
                            value="currentTime"
                            control={<ReadOnlyWrapper element={Radio} />}
                            label="Current Day & Time"
                        />
                        <Grid container alignItems="center" spacing={1}>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{ maxWidth: "110px" }}
                            >
                                <FormControlLabel
                                    value="customizedTime"
                                    control={
                                        <ReadOnlyWrapper element={Radio} />
                                    }
                                    label="Custom"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Select
                                    className="custom-input-gray"
                                    labelId="customize-day-select-label"
                                    id="customize-day-select"
                                    value={selectedDay}
                                    onChange={handleDayChange}
                                    disabled={
                                        menuPreviewType !== "customizedTime"
                                    }
                                    disableUnderline
                                >
                                    {DAYS_MAPPING.map((day) => (
                                        <ReadOnlyWrapper
                                            element={MenuItem}
                                            value={day.value}
                                            key={day.value}
                                        >
                                            {day.label}
                                        </ReadOnlyWrapper>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        className="custom-input-gray"
                                        id="customize-time-select"
                                        value={numberToDate(selectedTime)}
                                        onChange={handleTimeChange}
                                        disabled={
                                            menuPreviewType !== "customizedTime"
                                        }
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </>
        );
    };
    const onSortEnd = useCallback(
        (data: { oldIndex: number; newIndex: number }) => {
            setMenuTreeNodes(
                ArrayMove(menuTreeNodes, data.oldIndex, data.newIndex)
            );
        },
        [menuTreeNodes]
    );

    const handleSaveSortOrder = useCallback(() => {
        const categoriesSortOrder: any[] = [];

        menuTreeNodes.forEach((item, index) => {
            categoriesSortOrder.push({
                id: parseFloat(item.id),
                sortOrder: index,
            });
        });

        updateCategoriesSortOrder({
            categoriesSortOrder,
            restaurantCode: "",
            rebuildCache: true,
            successCallback: () => {
                alert.success("Sort Order Saved", { timeout: TIME_OUT });
            },
            errorCallback: () => {
                alert.error("Error Saving Sort Order", {
                    timeout: TIME_OUT,
                });
            },
        });
    }, [alert, menuTreeNodes, updateCategoriesSortOrder]);

    const renderTreeView = () => {
        if (!initialLoad) {
            return dummyNodes.map((menu, index) => (
                <ReadOnlyWrapper
                    element={SortableItem}
                    key={`overview-${menu.id}`}
                    index={index}
                    value={menu}
                    loading={true}
                />
            ));
        }
        if (menuTreeNodes.length > 0) {
            return (
                <>
                    {menuTreeNodes.map((menu, index) => (
                        <ReadOnlyWrapper
                            element={SortableItem}
                            key={`overview-${menu.id}`}
                            index={index}
                            value={menu}
                        />
                    ))}
                </>
            );
        }
        return (
            <Typography variant="h6" color="primary">
                Add categories and items to get started
            </Typography>
        );
    };

    const renderLimitedItemsWarning = () => {
        const firstItemWithZeroQuantity = Object.values(menuItemsMap).find(
            (item) => item.availableLimitedQuantity === 0
        );

        if (!!firstItemWithZeroQuantity) {
            return (
                <div className={classes.limitedQuantityWarning}>
                    <Typography variant="h6" color="primary">
                        Limited Items Warning
                    </Typography>
                    <div>You ran out of limited items</div>
                    <Link
                        to={{
                            pathname: `/${props.restaurantCode}/item-availability`,
                            search: locationSearch,
                        }}
                    >
                        <ReadOnlyWrapper
                            element={Button}
                            color="primary"
                            variant="contained"
                            disableElevation
                        >
                            SEE 86D ITEMS
                        </ReadOnlyWrapper>
                    </Link>
                </div>
            );
        }
    };

    return (
        <div>
            <Grid container justify="space-between" className={classes.header}>
                <Grid item>
                    <Typography
                        variant="h6"
                        component="h4"
                        color="primary"
                        style={{ marginBottom: "20px" }}
                    >
                        Overview
                    </Typography>
                </Grid>
                <Grid item>
                    <ReadOnlyWrapper
                        element={Button}
                        onClick={handleSaveSortOrder}
                        color="secondary"
                        variant="contained"
                        className={classes.newBtn}
                        disableElevation
                    >
                        Save
                    </ReadOnlyWrapper>
                </Grid>
            </Grid>
            {renderLimitedItemsWarning()}
            {renderMenuPreview()}
            <Typography variant="h6" color="primary">
                Menu Overview
            </Typography>
            <Typography
                style={{
                    marginBottom: "16px",
                    marginTop: "8px",
                    fontSize: "18px",
                }}
            >
                You can rearrange the order of the categories by dragging them
                up and down.
            </Typography>
            <Container onSortEnd={onSortEnd} useDragHandle>
                {renderTreeView()}
            </Container>
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        restaurantCode: state.restaurant.selectedRestaurantCode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateCategoriesSortOrder: (
            data: UpdateCategoriesSortOrderInput & CallbackFunction
        ) => dispatch(updateCategoriesSortOrder(data)),
    };
};

type Props = ConnectedProps<typeof connected>;

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Overview);
