import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config";
import { NodeEnv } from "../../../constants/enums";

export interface ConfigState {
    NODE_ENV: NodeEnv;
    FEATURE_FLAG_MENU_VERSION: boolean;
    PRP_PERSISTENT_API: string;
    PRP_API: string;
    MENU_API: string;
    WEBSERVICE_API: string;
    FORCE_LOGOUT_API: string;
    GOOGLE_CLIENT_ID: string;
    TTS_ON_OFF_API: string;
    TR_API: string;
    MENU_AUTH_API: string;
    AI_API: string;
    AUTH_TIME_OUT_IN_SEC: string;
    EVENT_LOOP_TIME_OUT: string;
    RESTAURANT_API: string;
}

export const initialState: ConfigState = config;

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Partial<ConfigState>>) => {
            //This reducer is really not needed as environment variables which are passed are not changed during runtime
            Object.assign(state, action.payload);
        },
    },
});

export const { update } = configSlice.actions;
export default configSlice.reducer;
