import {
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from "@material-ui/core";
import { Cancel as CancelIcon } from "@material-ui/icons";
import { MTableToolbar } from "material-table";
import React, { ChangeEventHandler, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MENU_ACTIONS } from "../../../reducers/menuReducer";
import { voicePropsFiltersSelector } from "../../../utils/menu";

enum FilterOption {
    upsellPrompt = "Upsell-prompt",
    upsellItem = "Upsell-item",
}

interface IItemToolbarProps {
    toolbarProps: any;
}

const ItemToolbar = ({ toolbarProps }: IItemToolbarProps) => {
    const dispatch = useDispatch();
    const voicePropsFilters = useSelector(voicePropsFiltersSelector);

    const selectedOptions = useMemo(() => {
        const selectedOptions: FilterOption[] = [];

        if (voicePropsFilters.upsellItems) {
            selectedOptions.push(FilterOption.upsellItem);
        }

        if (voicePropsFilters.upsellPrompts) {
            selectedOptions.push(FilterOption.upsellPrompt);
        }

        return selectedOptions;
    }, [voicePropsFilters.upsellItems, voicePropsFilters.upsellPrompts]);

    const updateSelectedOptions = useCallback(
        (value: FilterOption[]) => {
            dispatch(
                MENU_ACTIONS.setVoicePropsFilters({
                    upsellItems: value.includes(FilterOption.upsellItem),
                    upsellPrompts: value.includes(FilterOption.upsellPrompt),
                })
            );
        },
        [dispatch]
    );

    const handleChange: ChangeEventHandler<{
        name?: string | undefined;
        value: unknown;
    }> = useCallback(
        (e) => {
            const value = e.target.value as FilterOption[];
            updateSelectedOptions(value);
        },
        [updateSelectedOptions]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <MTableToolbar {...toolbarProps} />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="property-filter">Filter</InputLabel>
                    <Select
                        multiple
                        data-testid="itemtoolbar-select"
                        labelId="property-filter"
                        value={selectedOptions}
                        onChange={handleChange}
                        renderValue={(selectedFilter) =>
                            (selectedFilter as FilterOption[]).map(
                                (selectedItem) => (
                                    <Chip
                                        key={selectedItem}
                                        label={selectedItem}
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) =>
                                                    event.stopPropagation()
                                                }
                                            />
                                        }
                                        onDelete={() => {
                                            updateSelectedOptions(
                                                selectedOptions.filter(
                                                    (i) => i !== selectedItem
                                                )
                                            );
                                        }}
                                    />
                                )
                            )
                        }
                    >
                        <MenuItem value={FilterOption.upsellItem}>
                            <Checkbox checked={voicePropsFilters.upsellItems} />
                            <ListItemText primary={FilterOption.upsellItem} />
                        </MenuItem>
                        <MenuItem value={FilterOption.upsellPrompt}>
                            <Checkbox
                                checked={voicePropsFilters.upsellPrompts}
                            />
                            <ListItemText primary={FilterOption.upsellPrompt} />
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ItemToolbar;
