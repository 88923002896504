import React, { useEffect } from "react";
import { CircularLoading } from "../../../components/CircularLoading";
import { useDispatch, useSelector } from "react-redux";
import { auth0LoginRequestAction } from "../../../reducers/userReducer";

export const Auth0Login = () => {
    const dispatch = useDispatch();
    const user: any = useSelector(function (state) {
        return state;
    });

    useEffect(() => {
        if (!user.user.isLoggedIn) {
            dispatch(auth0LoginRequestAction());
        }
    }, [dispatch]);

    return <CircularLoading />;
};
