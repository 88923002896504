import { Dispatch, MiddlewareAPI, PayloadAction } from "@reduxjs/toolkit";
import ReactGA from "react-ga";
import { config } from "../config";

export const getGoogleAnalyticsId = () => {
    switch (config.NODE_ENV) {
        case "PRODUCTION":
            return "G-ZVPPDH2X5V";
        case "STAGING":
        case "DEVELOPMENT":
        case "BJS":
        default:
            return "G-KYMX436GKD";
    }
};

export const googleAnalyticsMiddleware = (api: MiddlewareAPI) => (
    next: Dispatch
) => (action: PayloadAction<any>) => {
    if (action.type === "@@router/LOCATION_CHANGE") {
        const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
        trackPageView(nextPage);
    }
    return next(action);
};

export function trackPageView(path: string) {
    ReactGA.pageview(path);
}

export function capturePerformanceMetric(metric: any) {
    ReactGA.timing({
        category: "web-vital-performance",
        variable: metric.name,
        value: metric.value,
        label: metric.label,
    });
}

export function captureEvent(
    category: string,
    action: string,
    label: string,
    nonInteraction: boolean,
    value: number
) {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        nonInteraction: nonInteraction,
        value: value,
    });
}
