import { NodeEnv } from "./constants/enums";
import { readEnvVariable } from "./utils/helper-functions";

export const config = {
    NODE_ENV: (process.env.REACT_APP_DEPLOY_ENV?.toUpperCase() ||
        process.env.NODE_ENV.toUpperCase()) as NodeEnv,
    FEATURE_FLAG_MENU_VERSION:
        readEnvVariable("FEATURE_FLAG_MENU_VERSION").toUpperCase() === "TRUE",
    PRP_PERSISTENT_API: readEnvVariable("PRP_PERSISTENT_API"),
    PRP_API: readEnvVariable("PRP_API"),
    MENU_API: readEnvVariable("MENU_API"),
    WEBSERVICE_API: readEnvVariable("WEBSERVICE_API"),
    FORCE_LOGOUT_API: readEnvVariable("FORCE_LOGOUT_API"),
    GOOGLE_CLIENT_ID: readEnvVariable("GOOGLE_CLIENT_ID"),
    TTS_ON_OFF_API: readEnvVariable("TTS_ON_OFF_API"),
    TR_API: readEnvVariable("TR_API"),
    MENU_AUTH_API: readEnvVariable("MENU_AUTH_API"),
    AI_API: readEnvVariable("AI_API"),
    AUTH_TIME_OUT_IN_SEC: readEnvVariable("AUTH_TIME_OUT_IN_SEC") || "120",
    EVENT_LOOP_TIME_OUT: readEnvVariable("EVENT_LOOP_TIME_OUT") || "86400000",
    RESTAURANT_API: readEnvVariable("RESTAURANT_API"),
    NEWRELIC_API: readEnvVariable("NEWRELIC_API"),
    NEWRELIC_LICENCE_KEY: readEnvVariable("NEWRELIC_LICENCE_KEY"),
    LOG_LEVEL: readEnvVariable("LOG_LEVEL") || "info",
};
