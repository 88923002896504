import DateFnsUtils from "@date-io/date-fns";
import {
    Divider,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import React from "react";
import { Availability } from "../../generated-interfaces/graphql";
import { AVAILABILITY_STATE, DAYS } from "../../utils/constants";
import { numberToDate } from "../../utils/helper-functions";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    hoverBtn: {
        backgroundColor: "rgba(0,0,0,0.3)",
    },
    container: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2, 4),
        marginLeft: "-36px",
        marginRight: "-30px",
        [theme.breakpoints.down("sm")]: {
            padding: "20px 24px",
            marginLeft: "-24px",
            marginRight: "-24px",
        },
    },
    timeField: {
        "& input": {
            padding: theme.spacing(1),
            border: `1px solid #E0E1E1`,
            borderRadius: theme.shape.borderRadius,
        },
    },
    row: {
        width: "150px",
        display: "flex",
        flexDirection: "column",
        margin: "5px 0",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    closeIcon: {
        width: 16,
        height: 16,
        color: "rgba(0,0,0,0.8)",
        cursor: "pointer",
    },
    fullRow: {
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            "& .MuiGrid-item": {
                margin: "10px 0",
            },
        },
    },
}));

export interface TimePeriodProps {
    timeAvailabilities: Availability[];
    updateTimePeriod: (
        day: number,
        hour: [number, number],
        index: number
    ) => void;
    deleteTimePeriod: (day: number, number: number) => void;
    addTimePeriod: (day: number) => void;
    changeAvailability: (day: number, value: string) => void;
}

export default function TimePeriodComponent(props: TimePeriodProps) {
    const classes = useStyles();
    const { timeAvailabilities } = props;

    const getDropdownValue = (timePeriod: Availability) => {
        if (timePeriod.alwaysEnabled) return AVAILABILITY_STATE.available;
        if (!timePeriod.hours || timePeriod.hours.length === 0)
            return AVAILABILITY_STATE.unavailable;
        return AVAILABILITY_STATE.exact;
    };

    const getAvailabilityColor = (timePeriod: Availability) => {
        if (timePeriod.alwaysEnabled) return "#7ed321";
        if (!timePeriod.hours || timePeriod.hours.length === 0)
            return "#d0021b";
        return "#2196f3";
    };

    return (
        <div className={classes.container}>
            <Grid container justify="space-between" className={classes.fullRow}>
                {timeAvailabilities.map((availability, index) => (
                    <Grid item key={`${availability.day}-row`}>
                        <div>{DAYS[availability.day]}</div>
                        <Select
                            inputProps={{
                                "data-testid": "day-availability-selector",
                            }}
                            // data-testid="day-availability-selector"
                            disableUnderline
                            value={getDropdownValue(availability)}
                            onChange={(e) => {
                                props.changeAvailability(
                                    index,
                                    e.target.value as string
                                );
                            }}
                            style={{
                                color: getAvailabilityColor(availability),
                                width: "100%",
                            }}
                        >
                            <ReadOnlyWrapper
                                element={MenuItem}
                                value={AVAILABILITY_STATE.available}
                            >
                                {AVAILABILITY_STATE.available}
                            </ReadOnlyWrapper>
                            <ReadOnlyWrapper
                                element={MenuItem}
                                value={AVAILABILITY_STATE.unavailable}
                            >
                                {AVAILABILITY_STATE.unavailable}
                            </ReadOnlyWrapper>
                            <ReadOnlyWrapper
                                element={MenuItem}
                                value={AVAILABILITY_STATE.exact}
                            >
                                {AVAILABILITY_STATE.exact}
                            </ReadOnlyWrapper>
                        </Select>

                        {getDropdownValue(availability) ===
                            AVAILABILITY_STATE.exact && (
                            <div>
                                <Divider style={{ marginBottom: "15px" }} />
                                {availability.hours &&
                                    availability.hours.map((hour, index) => (
                                        <div
                                            key={`time-${index}`}
                                            className={classes.row}
                                        >
                                            <Grid
                                                container
                                                justify="space-between"
                                                alignItems="center"
                                                style={{ fontSize: 14 }}
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >{`Time Period ${
                                                    index + 1
                                                }`}</div>
                                                <ReadOnlyWrapper
                                                    element={IconButton}
                                                    data-testid="delete-time-period"
                                                    className={
                                                        classes.closeIcon
                                                    }
                                                    onClick={() =>
                                                        props.deleteTimePeriod(
                                                            availability.day,
                                                            index
                                                        )
                                                    }
                                                >
                                                    <CloseIcon />
                                                </ReadOnlyWrapper>
                                            </Grid>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <TimePicker
                                                    className={
                                                        classes.timeField
                                                    }
                                                    label="Start Time"
                                                    value={numberToDate(
                                                        hour[0]
                                                    )}
                                                    inputProps={{
                                                        "data-testid":
                                                            "change-start-time-period",
                                                    }}
                                                    style={{ margin: "15px 0" }}
                                                    onChange={(newDate) => {
                                                        if (newDate) {
                                                            const selectedDate = new Date(
                                                                newDate
                                                            );
                                                            props.updateTimePeriod(
                                                                availability.day,
                                                                [
                                                                    selectedDate.getHours() *
                                                                        100 +
                                                                        selectedDate.getMinutes(),
                                                                    hour[1] ||
                                                                        0,
                                                                ],
                                                                index
                                                            );
                                                        }
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                />
                                                <TimePicker
                                                    className={
                                                        classes.timeField
                                                    }
                                                    label="Stop Time"
                                                    value={numberToDate(
                                                        hour[1]
                                                    )}
                                                    inputProps={{
                                                        "data-testid":
                                                            "change-stop-time-period",
                                                    }}
                                                    style={{ margin: "15px 0" }}
                                                    onChange={(newDate) => {
                                                        if (newDate) {
                                                            const selectedDate = new Date(
                                                                newDate
                                                            );
                                                            const newTimeValue =
                                                                selectedDate.getHours() *
                                                                    100 +
                                                                selectedDate.getMinutes();
                                                            const firstTimeValue =
                                                                hour[0] || 0;
                                                            if (
                                                                newTimeValue >=
                                                                firstTimeValue
                                                            ) {
                                                                props.updateTimePeriod(
                                                                    availability.day,
                                                                    [
                                                                        firstTimeValue,
                                                                        newTimeValue,
                                                                    ],
                                                                    index
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                />
                                                <Divider
                                                    style={{ margin: "15px 0" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ))}
                                <div style={{ textAlign: "center" }}>
                                    <ReadOnlyWrapper
                                        element={IconButton}
                                        data-testid="add-time-period"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            props.addTimePeriod(
                                                availability.day
                                            )
                                        }
                                    >
                                        <AddCircleOutlineIcon />
                                    </ReadOnlyWrapper>
                                </div>
                            </div>
                        )}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
