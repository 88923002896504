import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchRestaurantInfoCall } from "../api/restaurant";
import {
    AvailabilityInput,
    HoursOfOperation,
    RestaurantBrandingInput,
    RestaurantContactInfoInput,
    RestaurantSettingsInput,
} from "../generated-interfaces/graphql";
import { MENU_ACTIONS } from "../reducers/menuReducer";
import {
    createHoursOfOperation,
    createTREnablement,
    createTTSHoursOfOperation,
    createTTSScheduler,
    getAIEnablement,
    getTREnablement,
    getTTSHoursOfOperation,
    getTTSScheduler,
    initialRestaurantState,
    RESTAURANT_ACTIONS,
    updateAIEnablement,
    updateHoursOfOperation,
    updatePrimaryRestaurant,
    updateRestaurantBranding,
    updateRestaurantContactInfo,
    updateRestaurantSettings,
    updateTREnablement,
    updateTTSHoursOfOperation,
    updateTTSScheduler,
} from "../reducers/restaurantReducer";
import { RootState } from "../reducers/rootReducer";
import {
    retrieveUserSessionAction,
    updateUserSessionAction,
    USER_ACTIONS,
} from "../reducers/userReducer";
import {
    selectAIapi,
    selectTRapi,
    selectTTSOnOffApi,
} from "../redux/features/config/config.selector";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import {
    IAIEnablementUpdatePayload,
    ITREnablementUpdatePayload,
    ITTSchedulerUpdatePayload,
    ITTSOperationHour,
    ITTSSchedulerCreatePayload,
    TTSHoursOfOperationInput,
    UserRestaurantsRole,
} from "../types/restaurant";
import {
    camelToSnakeCase,
    snakeToCamelCase,
    toRequestedCase,
} from "../utils/helper-functions";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";
import { getHighestAccessRestaurant } from "../utils/restaurants";
import { CallbackFunction, RestaurantUpdateDetails } from "../utils/types";
import {
    createTaskRouterEnablementCall,
    createTTSHoursOfOperationCall,
    createTTSSchedulerCall,
    disableAIEnablementCall,
    enableAIEnablementCall,
    fetchAIEnablementCall,
    fetchTaskRouterEnablementCall,
    fetchTTSHoursOfOperationCall,
    fetchTTSSchedulerCall,
    updateTaskRouterEnablementCall,
    updateTTSHoursOfOperationCall,
    updateTTSSchedulerCall,
} from "../utils/webserviceAPI";
import { PerfTimer } from "../utils/timer";
import { getAuthInfo } from "./utilsSaga";

function* getRestaurantInfo(action: PayloadAction<string>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to getRestaurantInfo");
    try {
        const isLoggedIn = yield select(
            (state: RootState) => state.user.isLoggedIn
        );
        if (!isLoggedIn) {
            logger.info("Not Logged In For getRestaurantInfo");
            return;
        }
        const restaurantCode = action.payload;
        const { token, isAuth0 } = yield call(getAuthInfo);
        // @ts-ignore
        const restaurantInfo = yield call(
            fetchRestaurantInfoCall,
            restaurantCode,
            token,
            isAuth0,
            {
                data: "info",
            }
        );
        yield put(updateUserSessionAction(restaurantCode));
        yield put({
            type: retrieveUserSessionAction.toString(),
            payload: { restaurantCode },
        });
        const { restaurantBranding, restaurantSettings: rs, ...props } =
            restaurantInfo || {};

        const { isUnsupervisedAi: isUnsupervisedAI, ...restaurantSettings } =
            rs || {};

        yield put(
            RESTAURANT_ACTIONS.restaurantInfoLoadSuccess({
                ...props,
                restaurantBranding: {
                    ...restaurantBranding,
                    logoUrl: restaurantBranding?.restaurantLogoUrl || "",
                },
                restaurantSettings: {
                    ...restaurantSettings,
                    isUnsupervisedAI: isUnsupervisedAI || false,
                    toGoDefaultSMSText:
                        restaurantSettings?.toGoDefaultSmsText || "",
                },
            })
        );
        yield put(MENU_ACTIONS.updateDidInitialLoad(false));
        logger.info("Successfully got restaurant info", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Failed To Get Restaurant Info", {
            error,
            duration: timer.stop(),
        });
    }
}

function* autoSelectRestaurant(action: PayloadAction<UserRestaurantsRole[]>) {
    logger.debug("Automatically selecting restaurant based on user access");
    const preSelectedRestaurant = getHighestAccessRestaurant(action.payload);
    if (preSelectedRestaurant) {
        yield put(
            RESTAURANT_ACTIONS.selectPrimaryRestaurant({
                restaurantCode: preSelectedRestaurant.restaurantCode,
                primaryRestaurantCode:
                    preSelectedRestaurant.primaryRestaurantCode || undefined,
            })
        );
    } else {
        logger.error("Failed To Select Restaurant Automatically");
    }
}

function* updateRestaurantSettingsSaga(
    action: PayloadAction<RestaurantSettingsInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to save restaurant setting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateRestaurantSettings({
            dineInModalityTaxRate: payload.dineInModalityTaxRate,
            deliveryModalityTaxRate: payload.deliveryModalityTaxRate,
            toGoModalityTaxRate: payload.toGoModalityTaxRate,
            restaurantCode,
            toGoOrderConfirmationMessage: payload.toGoOrderConfirmationMessage,
            isAlertingEnabled: payload.isAlertingEnabled,
            toGoPickupMinutes: payload.toGoPickupMinutes,
            toGoDefaultSMSText: payload.toGoDefaultSMSText,
            isUnsupervisedAI: payload.isUnsupervisedAI,
            // skipAdvancedPayments: false,
            // currency: null,
            // isAvailableForOrdering: null,
            // isTabEnabled: null,
            // tabSize: null,
            // isPayOnlyEnabled: null,
            // isTaxEnabled: null,
            // isTipEnabled: null,
            // defaultTipPercentages: null,
        } as any);

        if (
            payload.isUnsupervisedAI !== undefined &&
            payload.isUnsupervisedAI != null
        ) {
            yield put(
                RESTAURANT_ACTIONS.updateIsUnsupervisedAI({
                    isEnabled: payload.isUnsupervisedAI,
                })
            );
        }

        if (successCallback) successCallback();
        logger.info("Successfully updated restaurant settings", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Save Restaurant settings failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateRestaurantContactInfoSaga(
    action: PayloadAction<RestaurantContactInfoInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to save restaurant setting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateRestaurantContactInfo({
            email: payload.email as string,
            address: payload.address,
            city: payload.city,
            zipCode: payload.zipCode,
            state: payload.state,
            country: payload.country,
            phoneNumber: payload.phoneNumber as string,
            website: payload.website,
            restaurantCode,
        });
        if (successCallback) successCallback();
        logger.info("Successfully updated restaurant contact info", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Save Restaurant settings failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateRestaurantBrandingSaga(
    action: PayloadAction<RestaurantBrandingInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to upload restaurant randing");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateRestaurantBranding({
            ...payload,
            restaurantCode,
        });
        if (successCallback) successCallback();
        logger.info("Successfully updated restaurant branding", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Update Restaurant Branding Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateHoursOfOperationSaga(
    action: PayloadAction<HoursOfOperation & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );

        yield sdk.updateHoursOfOperation({
            timezone: payload.timezone,
            availability: payload.availability as AvailabilityInput[],
            restaurantCode,
        });
        yield put(RESTAURANT_ACTIONS.selectRestaurant(restaurantCode));
        if (successCallback) successCallback();
        logger.info("Successfully updated hours of operations", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Update Hours of Operation Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createHoursOfOperationSaga(
    action: PayloadAction<HoursOfOperation & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.createHoursOfOperation({
            timezone: payload.timezone,
            availability: payload.availability as AvailabilityInput[],
            restaurantCode,
        });
        yield put(RESTAURANT_ACTIONS.selectRestaurant(restaurantCode));
        if (successCallback) successCallback();
        logger.info("Successfully created hours of operations", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Create Hours of Operation Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updatePrimaryRestaurantSaga(
    action: PayloadAction<RestaurantUpdateDetails & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to upload restaurant randing");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updatePrimaryRestaurant({
            restaurantCode,
            primaryRestaurantCode: payload.primaryRestaurantCode,
        });

        yield put(
            RESTAURANT_ACTIONS.selectPrimaryRestaurant({
                restaurantCode,
                primaryRestaurantCode: payload.primaryRestaurantCode,
            })
        );
        yield put(MENU_ACTIONS.updateDidInitialLoad(false));
        if (successCallback) successCallback();
        logger.info("Successfully updated primary restaurant", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Update Restaurant Branding Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* fetchTTSHoursOfOperationSaga(
    action: PayloadAction<HoursOfOperation & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to fetch TTS hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTTSOnOffApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const {
            data,
        }: {
            data: ITTSOperationHour;
        } = yield call(fetchTTSHoursOfOperationCall, {
            restaurantCode,
            url,
            token,
            isAuth0,
        });
        const ttsOperationHours: ITTSOperationHour = toRequestedCase(
            data,
            snakeToCamelCase
        );
        yield put(
            RESTAURANT_ACTIONS.ttsHoursOfOperationSuccess(ttsOperationHours)
        );
        if (successCallback) successCallback();
        logger.info("Successfully fetched TTS hours of operations", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (error?.response?.status === 404) {
            yield put(
                RESTAURANT_ACTIONS.ttsHoursOfOperationSuccess(
                    initialRestaurantState.ttsOperationHours
                )
            );
        }
        if (errorCallback) errorCallback();
        logger.error("Fetching TTS hours of operations failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createTTSHoursOfOperationSaga(
    action: PayloadAction<TTSHoursOfOperationInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create TTS hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTTSOnOffApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const updatedPayload = toRequestedCase(
            {
                ...payload,
                restaurantCode,
            },
            camelToSnakeCase
        );
        const {
            data,
        }: {
            data: ITTSOperationHour;
        } = yield call(createTTSHoursOfOperationCall, {
            payload: updatedPayload,
            restaurantCode,
            url,
            token,
            isAuth0,
        });

        const ttsOperationHours: ITTSOperationHour = toRequestedCase(
            data,
            snakeToCamelCase
        );
        yield put(
            RESTAURANT_ACTIONS.ttsHoursOfOperationSuccess(ttsOperationHours)
        );
        if (successCallback) successCallback();
        logger.info("Successfully created TTS hours of operations", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Create TTS Hours of Operation Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateTTSHoursOfOperationSaga(
    action: PayloadAction<HoursOfOperation & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update TTS hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTTSOnOffApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        const updatedPayload = toRequestedCase(payload, camelToSnakeCase);
        const {
            data,
        }: {
            data: ITTSOperationHour;
        } = yield call(updateTTSHoursOfOperationCall, {
            payload: updatedPayload,
            restaurantCode,
            url,
            token,
            isAuth0,
        });
        const ttsOperationHours: ITTSOperationHour = Object.values(data).length
            ? toRequestedCase(data, snakeToCamelCase)
            : initialRestaurantState.ttsOperationHours;
        yield put(
            RESTAURANT_ACTIONS.ttsHoursOfOperationSuccess(ttsOperationHours)
        );
        successCallback?.();
        logger.info("Successfully updated TTS hours of operations", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Update TTS Hours of Operation Failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* getTREnablementSaga(action: PayloadAction<string>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to get entry of task-router enablement");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const url: string = yield select(selectTRapi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        if (!url) throw new Error("TR API not found!");
        const {
            data,
        }: {
            data: { active: number };
        } = yield call(fetchTaskRouterEnablementCall, {
            restaurantCode: action.payload,
            url,
            token,
            isAuth0,
        });
        const activeValue = data?.active;
        yield put(RESTAURANT_ACTIONS.updateTREnablement(activeValue));
        logger.info("Successfully fetched TR enablement config", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (error?.response?.status === 404) {
            yield put(RESTAURANT_ACTIONS.updateTREnablement(null));
        }
        logger.error("Getting entry for task router enablement failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createTREnablementSaga(
    action: PayloadAction<ITREnablementUpdatePayload & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create new entry for task-router enablement");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTRapi);
        const { token, isAuth0 } = yield getAuthInfo();
        const {
            data,
        }: {
            data: { active: number };
        } = yield call(createTaskRouterEnablementCall, {
            payload: {
                ...payload,
                restaurant_code: restaurantCode,
            },
            url,
            token,
            isAuth0,
        });
        const activeValue = data?.active;
        yield put(RESTAURANT_ACTIONS.updateTREnablement(activeValue));
        successCallback?.();
        logger.info("Successfully created TR enablement config", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Create new entry for task router enablement failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateTREnablementSaga(
    action: PayloadAction<ITREnablementUpdatePayload & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update new entry for task-router enablement");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTRapi);
        const { token, isAuth0 } = yield getAuthInfo();
        const {
            data,
        }: {
            data: { active: number };
        } = yield call(updateTaskRouterEnablementCall, {
            payload,
            restaurantCode,
            url,
            token,
            isAuth0,
        });
        yield put(RESTAURANT_ACTIONS.updateTREnablement(data.active));
        successCallback?.();
        logger.info("Successfully updated TR enablement config", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Updating entry for task router enablement failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* getTTSSchedulerSaga(action: PayloadAction<string>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to get entry of tts scheduler");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const url: string = yield select(selectTTSOnOffApi);
        const { token, isAuth0 } = yield call(getAuthInfo);
        if (!url) throw new Error("TTS on/off API not found!");
        const {
            data,
        }: {
            data: { scheduler_enabled: boolean };
        } = yield call(fetchTTSSchedulerCall, {
            restaurantCode: action.payload,
            url,
            token,
            isAuth0,
        });
        yield put(
            RESTAURANT_ACTIONS.updateTTSScheduler(data?.scheduler_enabled)
        );
        logger.info("Successfully fetched TTS scheduler entry", {
            duration: timer.stop(),
        });
    } catch (error) {
        if (error?.response?.status === 404) {
            yield put(RESTAURANT_ACTIONS.updateTTSScheduler(null));
        }
        logger.error("Getting entry for tts scheduler failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createTTSSchedulerSaga(
    action: PayloadAction<ITTSSchedulerCreatePayload & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create new entry for tts scheduler");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTTSOnOffApi);
        const { token, isAuth0 } = yield getAuthInfo();
        const {
            data,
        }: {
            data: { scheduler_enabled: boolean };
        } = yield call(createTTSSchedulerCall, {
            payload: {
                ...payload,
                tts_status: "tts_off",
                restaurant_code: restaurantCode,
            },
            url,
            token,
            isAuth0,
        });
        yield put(
            RESTAURANT_ACTIONS.updateTTSScheduler(data?.scheduler_enabled)
        );
        successCallback?.();
        logger.info("Successfully created TTS scheduler entry", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Create new entry for tts scheduler failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateTTSSchedulerSaga(
    action: PayloadAction<ITTSchedulerUpdatePayload & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update entry of tts scheduler");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectTTSOnOffApi);
        const { token, isAuth0 } = yield getAuthInfo();
        const {
            data,
        }: {
            data: { scheduler_enabled: boolean };
        } = yield call(updateTTSSchedulerCall, {
            payload,
            restaurantCode,
            url,
            token,
            isAuth0,
        });
        yield put(
            RESTAURANT_ACTIONS.updateTTSScheduler(data?.scheduler_enabled)
        );
        successCallback?.();
        logger.info("Successfully updated TTS scheduler entry", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Updating entry for tts scheduler failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* getAIEnablementSaga(action: PayloadAction<string>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to get AI enabled or not for a restaurant");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    try {
        const restaurantCode = action.payload;
        const url: string = yield select(selectAIapi);
        const { token, isAuth0 } = yield getAuthInfo();
        const { data } = yield call(fetchAIEnablementCall, {
            restaurantCode,
            url,
            token,
            isAuth0,
        });

        const { ai_enabled: aiEnabled } = data;
        yield put(RESTAURANT_ACTIONS.updateAIEnablement(aiEnabled));
        logger.info("Successfully fetched AI enablement config", {
            duration: timer.stop(),
        });
    } catch (error) {
        yield put(RESTAURANT_ACTIONS.updateAIEnablement(false));
        logger.error("Getting entry for AI enablement failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateAIEnablementSaga(
    action: PayloadAction<IAIEnablementUpdatePayload & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update AI enablement for a restaurant");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const url: string = yield select(selectAIapi);
        const { token, isAuth0 } = yield getAuthInfo();
        const { data } = yield call(
            payload.aiActive ? enableAIEnablementCall : disableAIEnablementCall,
            {
                restaurantCode,
                url,
                token,
                isAuth0,
            }
        );

        if (data) {
            yield put(RESTAURANT_ACTIONS.updateAIEnablement(payload.aiActive));
            successCallback?.();
        }

        logger.info("Successfully updated AI enablement config", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Updating entry for AI enablement failed", {
            error,
            duration: timer.stop(),
        });
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

export default function* restaurantSaga() {
    yield takeEvery(
        RESTAURANT_ACTIONS.selectRestaurant.toString(),
        getRestaurantInfo
    );
    yield takeEvery(
        RESTAURANT_ACTIONS.userRolesLoadSuccess.toString(),
        autoSelectRestaurant
    );
    yield takeEvery(
        updateRestaurantSettings.toString(),
        updateRestaurantSettingsSaga
    );
    yield takeEvery(
        updateRestaurantContactInfo.toString(),
        updateRestaurantContactInfoSaga
    );
    yield takeEvery(
        updateRestaurantBranding.toString(),
        updateRestaurantBrandingSaga
    );
    yield takeEvery(
        updateHoursOfOperation.toString(),
        updateHoursOfOperationSaga
    );
    yield takeEvery(
        createHoursOfOperation.toString(),
        createHoursOfOperationSaga
    );
    yield takeEvery(
        updatePrimaryRestaurant.toString(),
        updatePrimaryRestaurantSaga
    );
    yield takeEvery(
        getTTSHoursOfOperation.toString(),
        fetchTTSHoursOfOperationSaga
    );
    yield takeEvery(
        updateTTSHoursOfOperation.toString(),
        updateTTSHoursOfOperationSaga
    );
    yield takeEvery(
        createTTSHoursOfOperation.toString(),
        createTTSHoursOfOperationSaga
    );

    yield takeEvery(getTREnablement.toString(), getTREnablementSaga);
    yield takeEvery(createTREnablement.toString(), createTREnablementSaga);
    yield takeEvery(updateTREnablement.toString(), updateTREnablementSaga);

    yield takeEvery(getTTSScheduler.toString(), getTTSSchedulerSaga);
    yield takeEvery(createTTSScheduler.toString(), createTTSSchedulerSaga);
    yield takeEvery(updateTTSScheduler.toString(), updateTTSSchedulerSaga);

    yield takeEvery(getAIEnablement.toString(), getAIEnablementSaga);
    yield takeEvery(updateAIEnablement.toString(), updateAIEnablementSaga);
}
