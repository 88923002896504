import { print } from "graphql";
import { GraphQLClient } from "graphql-request";
import { GraphQLError } from "graphql-request/dist/types";
import { Headers } from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Custom scalar type for key-value pair JSON object */
    KeyValue: Record<string, string>;
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any;
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type Query = {
    __typename?: "Query";
    restaurants: Array<Restaurant>;
    restaurant: Restaurant;
    primaryRestaurant: Restaurant;
    primaryRestaurants: Array<Restaurant>;
    menu: Menu;
    categories: Array<Category>;
    menuItems: Array<MenuItem>;
    modifierGroups: Array<ModifierGroup>;
    /** @deprecated No longer needed as MenuItemSettings are nested inside MenuItem */
    menuItemSettings: Array<MenuItemSetting>;
    timePeriods: Array<TimePeriod>;
    menuOverrides: Array<MenuOverride>;
    timePeriodForCategories: Array<TimePeriodForCategory>;
    queryCKEMenu: Scalars["String"];
    posPropertyMaps: Array<Scalars["JSONObject"]>;
    posPropertyConflicts: Scalars["JSONObject"];
    /** @deprecated No longer needed as PosProperties are nested inside MenuItem and ModifierGroup */
    posProperties: Array<PosProperties>;
    posPropertiesByKeyValues: Array<PosProperties>;
    users: Array<UserProfile>;
    restaurantGroupsWithUsers: Array<RestaurantGroupWithUsers>;
    roles: Array<Role>;
    restaurantGroups: Array<RestaurantGroup>;
    restaurantAgents: Array<RestaurantAgentView>;
    userByEmail: UserProfile;
    restaurantsByUserRole: Array<UserRestaurantsRole>;
    authStatus: UserProfile;
    login: UserProfile;
    mfLogin: UserProfile;
    verifyGAuthToken: MfUserProfile;
    logOut: Scalars["String"];
};

export type QueryRestaurantArgs = {
    restaurantCode: Scalars["String"];
};

export type QueryPrimaryRestaurantArgs = {
    restaurantCode: Scalars["String"];
};

export type QueryMenuArgs = {
    enableCache?: Maybe<Scalars["Boolean"]>;
    restaurantCode: Scalars["String"];
};

export type QueryCategoriesArgs = {
    categoryIds: Maybe<Array<Scalars["Float"]>>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryMenuItemsArgs = {
    itemIds: Maybe<Array<Scalars["Float"]>>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryModifierGroupsArgs = {
    modifierGroupIds: Maybe<Array<Scalars["Float"]>>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryMenuItemSettingsArgs = {
    itemIds: Maybe<Array<Scalars["Float"]>>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryTimePeriodsArgs = {
    timePeriodIds: Maybe<Array<Scalars["Float"]>>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryMenuOverridesArgs = {
    overrideIds: Maybe<Array<Scalars["Float"]>>;
    overrideTypeKeys: Maybe<Array<Scalars["Float"]>>;
    overrideType: Maybe<Scalars["String"]>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryTimePeriodForCategoriesArgs = {
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryQueryCkeMenuArgs = {
    restaurantCode: Scalars["String"];
};

export type QueryPosPropertyMapsArgs = {
    restaurantCode: Scalars["String"];
};

export type QueryPosPropertyConflictsArgs = {
    restaurantCode: Scalars["String"];
};

export type QueryPosPropertiesArgs = {
    propertyTypeKeys: Maybe<Array<Scalars["Float"]>>;
    objectPrimaryKey: Maybe<Scalars["String"]>;
    propertyType: Maybe<Scalars["String"]>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryPosPropertiesByKeyValuesArgs = {
    valueKeys: Maybe<Array<Scalars["String"]>>;
    key: Maybe<Scalars["String"]>;
    version: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
};

export type QueryRestaurantAgentsArgs = {
    restaurantCode: Maybe<Scalars["String"]>;
};

export type QueryUserByEmailArgs = {
    data: EmailInput;
};

export type QueryLoginArgs = {
    data: LoginInput;
};

export type QueryMfLoginArgs = {
    data: MfLoginInput;
};

export type QueryVerifyGAuthTokenArgs = {
    source: Scalars["String"];
    token: Scalars["String"];
};

export type Restaurant = {
    __typename?: "Restaurant";
    id: Scalars["ID"];
    restaurantName: Scalars["String"];
    restaurantCode: Scalars["String"];
    accountType: RestaurantAccountType;
    restaurantSettings: Maybe<RestaurantSettings>;
    restaurantBranding: Maybe<RestaurantBranding>;
    restaurantContactInfo: Maybe<RestaurantContactInfo>;
    hoursOfOperation: Maybe<HoursOfOperation>;
    primaryRestaurantCode: Maybe<Scalars["String"]>;
};

export enum RestaurantAccountType {
    Live = "LIVE",
    Demo = "DEMO",
}

export type RestaurantSettings = {
    __typename?: "RestaurantSettings";
    id: Scalars["ID"];
    disablePortalMenu: Scalars["Boolean"];
    skipAdvancedPayments: Scalars["Boolean"];
    currency: Currency;
    isAvailableForOrdering: Scalars["Boolean"];
    isTabEnabled: Scalars["Boolean"];
    tabSize: Scalars["Float"];
    isPayOnlyEnabled: Scalars["Boolean"];
    isTaxEnabled: Scalars["Boolean"];
    dineInModalityTaxRate: Scalars["Float"];
    deliveryModalityTaxRate: Scalars["Float"];
    toGoModalityTaxRate: Scalars["Float"];
    toGoOrderConfirmationMessage: Maybe<Scalars["String"]>;
    isTipEnabled: Scalars["Boolean"];
    defaultTipPercentages: Array<Scalars["Float"]>;
    isAlertingEnabled: Scalars["Boolean"];
    toGoPickupMinutes: Maybe<Scalars["Float"]>;
    toGoDefaultSMSText: Maybe<Scalars["String"]>;
    autoGratuityInPercentage: Maybe<Scalars["Float"]>;
    autoGratuityDisclaimerText: Maybe<Scalars["String"]>;
    smsVerificationText: Maybe<Scalars["String"]>;
    isUnsupervisedAI: Scalars["Boolean"];
};

export enum Currency {
    Usd = "USD",
    Afa = "AFA",
    All = "ALL",
    Dzd = "DZD",
    Aor = "AOR",
    Ars = "ARS",
    Amd = "AMD",
    Awg = "AWG",
    Aud = "AUD",
    Azn = "AZN",
    Bsd = "BSD",
    Bhd = "BHD",
    Bdt = "BDT",
    Bbd = "BBD",
    Byn = "BYN",
    Bzd = "BZD",
    Bmd = "BMD",
    Btn = "BTN",
    Bob = "BOB",
    Bwp = "BWP",
    Brl = "BRL",
    Gbp = "GBP",
    Bnd = "BND",
    Bgn = "BGN",
    Bif = "BIF",
    Khr = "KHR",
    Cad = "CAD",
    Cve = "CVE",
    Kyd = "KYD",
    Xof = "XOF",
    Xaf = "XAF",
    Xpf = "XPF",
    Clp = "CLP",
    Cny = "CNY",
    Cop = "COP",
    Kmf = "KMF",
    Cdf = "CDF",
    Crc = "CRC",
    Hrk = "HRK",
    Cup = "CUP",
    Czk = "CZK",
    Dkk = "DKK",
    Djf = "DJF",
    Dop = "DOP",
    Xcd = "XCD",
    Egp = "EGP",
    Svc = "SVC",
    Ern = "ERN",
    Eek = "EEK",
    Etb = "ETB",
    Eur = "EUR",
    Fkp = "FKP",
    Fjd = "FJD",
    Gmd = "GMD",
    Gel = "GEL",
    Ghs = "GHS",
    Gip = "GIP",
    Xau = "XAU",
    Xfo = "XFO",
    Gtq = "GTQ",
    Gnf = "GNF",
    Gyd = "GYD",
    Htg = "HTG",
    Hnl = "HNL",
    Hkd = "HKD",
    Huf = "HUF",
    Isk = "ISK",
    Xdr = "XDR",
    Inr = "INR",
    Idr = "IDR",
    Irr = "IRR",
    Iqd = "IQD",
    Ils = "ILS",
    Jmd = "JMD",
    Jpy = "JPY",
    Jod = "JOD",
    Kzt = "KZT",
    Kes = "KES",
    Kwd = "KWD",
    Kgs = "KGS",
    Lak = "LAK",
    Lvl = "LVL",
    Lbp = "LBP",
    Lsl = "LSL",
    Lrd = "LRD",
    Lyd = "LYD",
    Ltl = "LTL",
    Mop = "MOP",
    Mkd = "MKD",
    Mga = "MGA",
    Mwk = "MWK",
    Myr = "MYR",
    Mvr = "MVR",
    Mro = "MRO",
    Mur = "MUR",
    Mxn = "MXN",
    Mdl = "MDL",
    Mnt = "MNT",
    Mad = "MAD",
    Mzn = "MZN",
    Mmk = "MMK",
    Nad = "NAD",
    Npr = "NPR",
    Ang = "ANG",
    Nzd = "NZD",
    Nio = "NIO",
    Ngn = "NGN",
    Kpw = "KPW",
    Nok = "NOK",
    Omr = "OMR",
    Pkr = "PKR",
    Xpd = "XPD",
    Pab = "PAB",
    Pgk = "PGK",
    Pyg = "PYG",
    Pen = "PEN",
    Php = "PHP",
    Xpt = "XPT",
    Pln = "PLN",
    Qar = "QAR",
    Ron = "RON",
    Rub = "RUB",
    Rwf = "RWF",
    Shp = "SHP",
    Wst = "WST",
    Std = "STD",
    Sar = "SAR",
    Rsd = "RSD",
    Scr = "SCR",
    Sll = "SLL",
    Xag = "XAG",
    Sgd = "SGD",
    Sbd = "SBD",
    Sos = "SOS",
    Zar = "ZAR",
    Krw = "KRW",
    Lkr = "LKR",
    Sdg = "SDG",
    Srd = "SRD",
    Szl = "SZL",
    Sek = "SEK",
    Chf = "CHF",
    Syp = "SYP",
    Twd = "TWD",
    Tjs = "TJS",
    Tzs = "TZS",
    Thb = "THB",
    Top = "TOP",
    Ttd = "TTD",
    Tnd = "TND",
    Try = "TRY",
    Tmt = "TMT",
    Aed = "AED",
    Ugx = "UGX",
    Xfu = "XFU",
    Uah = "UAH",
    Uyu = "UYU",
    Uzs = "UZS",
    Vuv = "VUV",
    Vef = "VEF",
    Vnd = "VND",
    Yer = "YER",
    Zmk = "ZMK",
    Zwl = "ZWL",
}

export type RestaurantBranding = {
    __typename?: "RestaurantBranding";
    id: Scalars["ID"];
    primaryColor: Scalars["String"];
    secondaryColor: Scalars["String"];
    logoUrl: Maybe<Scalars["String"]>;
    backgroundImageUrl: Maybe<Scalars["String"]>;
    timezone: Scalars["String"];
};

export type RestaurantContactInfo = {
    __typename?: "RestaurantContactInfo";
    id: Scalars["ID"];
    address: Maybe<Scalars["String"]>;
    city: Maybe<Scalars["String"]>;
    zipCode: Maybe<Scalars["String"]>;
    state: Maybe<Scalars["String"]>;
    country: Maybe<Scalars["String"]>;
    phoneNumber: Maybe<Scalars["String"]>;
    email: Maybe<Scalars["String"]>;
    website: Maybe<Scalars["String"]>;
};

export type HoursOfOperation = {
    __typename?: "HoursOfOperation";
    id: Scalars["ID"];
    timezone: Scalars["String"];
    availability: Maybe<Array<Availability>>;
    currentUTCTime: Scalars["String"];
};

export type Availability = {
    __typename?: "Availability";
    day: Scalars["Float"];
    hours: Maybe<Array<Array<Scalars["Float"]>>>;
    alwaysEnabled: Scalars["Boolean"];
};

export type Menu = {
    __typename?: "Menu";
    restaurantCode: Scalars["ID"];
    categories: Array<Category>;
    menuItems: Array<MenuItem>;
    menuOverrides: Array<MenuOverride>;
    modifierGroups: Array<ModifierGroup>;
    timePeriods: Array<TimePeriod>;
};

export type Category = {
    __typename?: "Category";
    id: Scalars["ID"];
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    ownSortOrder: Maybe<Scalars["Float"]>;
    voiceProperties: Scalars["KeyValue"];
    timePeriods: Array<Scalars["Float"]>;
    menuItems: Array<Scalars["Float"]>;
    sortOrder: Array<SortOrder>;
};

export type SortOrder = {
    __typename?: "SortOrder";
    id: Scalars["Float"];
    sortOrder: Maybe<Scalars["Float"]>;
};

export type MenuItem = {
    __typename?: "MenuItem";
    id: Scalars["ID"];
    name: Scalars["String"];
    price: Scalars["Float"];
    tax: Maybe<Scalars["Float"]>;
    description: Maybe<Scalars["String"]>;
    imageUrl: Maybe<Scalars["String"]>;
    available: Scalars["Boolean"];
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    customRequestAvailable: Scalars["Boolean"];
    isModifierOnly: Scalars["Boolean"];
    settings: Scalars["KeyValue"];
    posPropertyMap: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
    /** @deprecated Use {@link MenuItem.settings settings} instead */
    menuItemSettings: Array<Scalars["Float"]>;
    /** @deprecated Use {@link MenuItem.posPropertyMap posPropertyMap} instead */
    posProperties: Array<Scalars["Float"]>;
    menuOverrides: Array<Scalars["Float"]>;
    modifierGroups: Array<Scalars["Float"]>;
    sortOrder: Array<SortOrder>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
    parentCategoryIds: Array<Scalars["Float"]>;
};

export type MenuOverride = {
    __typename?: "MenuOverride";
    id: Scalars["ID"];
    overrideType: OverrideType;
    objectPrimaryKey: Scalars["String"];
    secondaryType: Maybe<SecondaryType>;
    secondaryId: Maybe<Scalars["String"]>;
    overrideKey: OverrideKey;
    modalityType: Maybe<ModalityType>;
    overrideValue: Scalars["String"];
    availableOverride: Scalars["Boolean"];
};

export enum OverrideType {
    MenuItem = "MENU_ITEM",
    Modifier = "MODIFIER",
}

export enum SecondaryType {
    ModifierGroup = "MODIFIER_GROUP",
    Category = "CATEGORY",
}

export enum OverrideKey {
    Price = "PRICE",
    Tax = "TAX",
    PosId = "POS_ID",
    ScreenNumber = "SCREEN_NUMBER",
    CellNumber = "CELL_NUMBER",
}

export enum ModalityType {
    Dinein = "DINEIN",
    Togo = "TOGO",
    Delivery = "DELIVERY",
}

export type ModifierGroup = {
    __typename?: "ModifierGroup";
    id: Scalars["ID"];
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    minimumSelections: Scalars["Float"];
    maximumSelections: Scalars["Float"];
    defaultSelectedItemIds: Maybe<Array<Scalars["Float"]>>;
    menuItems: Array<Scalars["Float"]>;
    sortOrder: Array<SortOrder>;
    posPropertyMap: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
    /** @deprecated Use {@link ModifierGroup.posPropertyMap posPropertyMap} instead */
    posProperties: Maybe<Array<Scalars["Float"]>>;
};

export type TimePeriod = {
    __typename?: "TimePeriod";
    id: Scalars["ID"];
    description: Maybe<Scalars["String"]>;
    startDate: Maybe<Scalars["DateTime"]>;
    endDate: Maybe<Scalars["DateTime"]>;
    availability: Array<Availability>;
    timePeriodCategoryMappings: Array<Scalars["Float"]>;
};

export type MenuItemSetting = {
    __typename?: "MenuItemSetting";
    id: Scalars["ID"];
    key: Scalars["String"];
    value: Scalars["String"];
    menuItemId: Scalars["Float"];
};

export type TimePeriodForCategory = {
    __typename?: "TimePeriodForCategory";
    id: Scalars["ID"];
    timePeriodRowId: Scalars["Float"];
    categoryRowId: Scalars["Float"];
    sortOrder: Scalars["Float"];
};

export type PosProperties = {
    __typename?: "PosProperties";
    id: Scalars["ID"];
    propertyType: PropertyType;
    objectPrimaryKey: Scalars["String"];
    key: Scalars["String"];
    value: Scalars["String"];
};

export enum PropertyType {
    MenuItem = "MENU_ITEM",
    ModifierGroup = "MODIFIER_GROUP",
    Restaurant = "RESTAURANT",
    Category = "CATEGORY",
    TimePeriod = "TIME_PERIOD",
}

export type UserProfile = {
    __typename?: "UserProfile";
    authorizationToken: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    username: Scalars["String"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    password: Scalars["String"];
    resetCode: Maybe<Scalars["String"]>;
    disabled: Scalars["Boolean"];
    loginAttempts: Scalars["Float"];
    lastLogin: Maybe<Scalars["DateTime"]>;
    restaurantGroupRoles: Array<RestaurantGroupRole>;
    mfaEnabled: Scalars["Boolean"];
    isPreferred: Scalars["Boolean"];
};

export type RestaurantGroupRole = {
    __typename?: "RestaurantGroupRole";
    id: Scalars["ID"];
    role: Role;
    restaurantGroup: RestaurantGroup;
};

export type Role = {
    __typename?: "Role";
    id: Scalars["ID"];
    description: Scalars["String"];
    permission: RolePermissions;
};

export enum RolePermissions {
    PrestoAdmin = "PrestoAdmin",
    PrestoCustomerSupport = "PrestoCustomerSupport",
    RestaurantManager = "RestaurantManager",
    RestaurantStaff = "RestaurantStaff",
    Limited = "LIMITED",
    Hitl = "HITL",
}

export type RestaurantGroup = {
    __typename?: "RestaurantGroup";
    id: Scalars["ID"];
    groupName: Scalars["String"];
    restaurants: Array<Restaurant>;
};

export type RestaurantGroupWithUsers = {
    __typename?: "RestaurantGroupWithUsers";
    groupName: Scalars["String"];
    restaurants: Array<Restaurant>;
    attachedUsers: Array<UserRoleAttachment>;
};

export type UserRoleAttachment = {
    __typename?: "UserRoleAttachment";
    permission: RolePermissions;
    user: UserProfile;
};

export type RestaurantAgentView = {
    __typename?: "RestaurantAgentView";
    agentId: Scalars["String"];
    agentUserName: Scalars["String"];
    restaurantName: Scalars["String"];
    permission: Scalars["String"];
    roleId: Scalars["Float"];
    groupName: Scalars["String"];
    restaurantCode: Scalars["String"];
    isUnsupervisedAI: Scalars["Boolean"];
    isPreferred: Scalars["Boolean"];
    timezone: Scalars["String"];
    logoUrl: Maybe<Scalars["String"]>;
    backgroundImageUrl: Maybe<Scalars["String"]>;
};

export type EmailInput = {
    email: Scalars["String"];
};

export type UserRestaurantsRole = {
    __typename?: "UserRestaurantsRole";
    role: RolePermissions;
    restaurants: Array<Restaurant>;
};

export type LoginInput = {
    email: Scalars["String"];
    password: Scalars["String"];
};

export type MfLoginInput = {
    token: Scalars["String"];
    otp: Scalars["String"];
    source: Scalars["String"];
};

export type MfUserProfile = {
    __typename?: "MFUserProfile";
    authorizationToken: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    username: Scalars["String"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    password: Scalars["String"];
    resetCode: Maybe<Scalars["String"]>;
    disabled: Scalars["Boolean"];
    loginAttempts: Scalars["Float"];
    lastLogin: Maybe<Scalars["DateTime"]>;
    restaurantGroupRoles: Array<RestaurantGroupRole>;
    mfaEnabled: Scalars["Boolean"];
    isPreferred: Scalars["Boolean"];
    qrCodeURL: Maybe<Scalars["String"]>;
};

export type Mutation = {
    __typename?: "Mutation";
    createRestaurant: Restaurant;
    updateRestaurant: Restaurant;
    updateRestaurantSettings: Restaurant;
    updateRestaurantBranding: Restaurant;
    updateRestaurantContactInfo: Restaurant;
    updateHoursOfOperation: Restaurant;
    createHoursOfOperation: Restaurant;
    updatePrimaryRestaurant: Restaurant;
    createCategory: Category;
    duplicateCategory: Category;
    duplicateModifierGroup: ModifierGroup;
    duplicateMenuItem: MenuItem;
    createMenuItem: MenuItem;
    createModifierGroup: ModifierGroup;
    createTimePeriod: TimePeriod;
    createMenuOverride: MenuOverride;
    attachMenuItemToModGroup: ModifierGroup;
    attachModGroupToMenuItem: MenuItem;
    attachMenuItemCategory: Category;
    attachTimePeriodCategory: Category;
    removeModGroupFromMenuItem: Scalars["Boolean"];
    removeTimePeriodCategory: Scalars["Boolean"];
    deleteMenuItem: Scalars["Boolean"];
    deleteCategory: Scalars["Boolean"];
    deleteModifierGroup: Scalars["Boolean"];
    deleteTimePeriod: Scalars["Boolean"];
    deleteMenuOverride: Scalars["Boolean"];
    updateMenuItem: MenuItem;
    updateCategory: Category;
    updateModifierGroup: ModifierGroup;
    updateTimePeriod: TimePeriod;
    updateMenuOverride: MenuOverride;
    uploadMenuItemImage: MenuItem;
    uploadImage: Scalars["String"];
    updateCategoriesSortOrder: Array<Category>;
    updateItemAvailability: MenuItem;
    resetRestaurantMenu: Scalars["Boolean"];
    updateMenuItemAvailableQuantity: MenuItem;
    updateMenuItemsAvailableQuantities: Array<MenuItem>;
    clearCache: Scalars["String"];
    ckeMenuUpload: Scalars["String"];
    pagerDutyUpload: Scalars["String"];
    rawMenuFileUpload: Scalars["String"];
    createRestaurantGroup: RestaurantGroup;
    updateRestaurantGroup: RestaurantGroup;
    deleteRestaurantGroup: Scalars["Boolean"];
    attachRestaurantToRestaurantGroup: Restaurant;
    createRestaurantGroupRole: RestaurantGroupRole;
    attachRestaurantGroupRoleToUser: UserProfile;
    updateUserAdmin: UserProfile;
    createUserAdmin: UserProfile;
    createNewUser: UserProfile;
    updateUser: UserProfile;
    startPasswordReset: Scalars["Boolean"];
    changePassword: Scalars["Boolean"];
    completePasswordReset: UserProfile;
};

export type MutationCreateRestaurantArgs = {
    data: CreateRestaurantInput;
};

export type MutationUpdateRestaurantArgs = {
    restaurantName: Scalars["String"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateRestaurantSettingsArgs = {
    data: RestaurantSettingsInput;
    restaurantCode: Scalars["String"];
};

export type MutationUpdateRestaurantBrandingArgs = {
    data: RestaurantBrandingInput;
    restaurantCode: Scalars["String"];
};

export type MutationUpdateRestaurantContactInfoArgs = {
    data: RestaurantContactInfoInput;
    restaurantCode: Scalars["String"];
};

export type MutationUpdateHoursOfOperationArgs = {
    data: HoursOfOperationInput;
    restaurantCode: Scalars["String"];
};

export type MutationCreateHoursOfOperationArgs = {
    data: HoursOfOperationInput;
    restaurantCode: Scalars["String"];
};

export type MutationUpdatePrimaryRestaurantArgs = {
    primaryRestaurantCode: Scalars["String"];
    restaurantCode: Scalars["String"];
};

export type MutationCreateCategoryArgs = {
    data: NewCategoryInput;
};

export type MutationDuplicateCategoryArgs = {
    rebuildCache: Maybe<Scalars["Boolean"]>;
    version: Maybe<Scalars["Float"]>;
    sourceCategoryId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDuplicateModifierGroupArgs = {
    rebuildCache: Maybe<Scalars["Boolean"]>;
    version: Maybe<Scalars["Float"]>;
    sourceModifierGroupId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDuplicateMenuItemArgs = {
    rebuildCache: Maybe<Scalars["Boolean"]>;
    version: Maybe<Scalars["Float"]>;
    sourceMenuItemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationCreateMenuItemArgs = {
    data: NewMenuItemInput;
};

export type MutationCreateModifierGroupArgs = {
    data: NewModifierGroupInput;
};

export type MutationCreateTimePeriodArgs = {
    data: NewTimePeriodInput;
};

export type MutationCreateMenuOverrideArgs = {
    data: NewMenuOverrideInput;
};

export type MutationAttachMenuItemToModGroupArgs = {
    sortOrder: Maybe<Scalars["Float"]>;
    menuItemId: Scalars["Float"];
    modifierGroupId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationAttachModGroupToMenuItemArgs = {
    sortOrder: Maybe<Scalars["Float"]>;
    modifierGroupId: Scalars["Float"];
    menuItemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationAttachMenuItemCategoryArgs = {
    sortOrder: Maybe<Scalars["Float"]>;
    menuItemId: Scalars["Float"];
    categoryId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationAttachTimePeriodCategoryArgs = {
    timePeriodId: Scalars["Float"];
    categoryId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationRemoveModGroupFromMenuItemArgs = {
    modifierGroupId: Scalars["Float"];
    menuItemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationRemoveTimePeriodCategoryArgs = {
    timePeriodId: Scalars["Float"];
    categoryId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDeleteMenuItemArgs = {
    rebuildCache: Maybe<Scalars["Boolean"]>;
    itemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDeleteCategoryArgs = {
    rebuildCache: Maybe<Scalars["Boolean"]>;
    categoryId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDeleteModifierGroupArgs = {
    rebuildCache: Maybe<Scalars["Boolean"]>;
    modifierGroupId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDeleteTimePeriodArgs = {
    rebuildCache: Scalars["Boolean"];
    timePeriodId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationDeleteMenuOverrideArgs = {
    menuOverrideId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateMenuItemArgs = {
    version: Maybe<Scalars["Float"]>;
    data: UpdateMenuItemInput;
    menuItemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateCategoryArgs = {
    data: UpdateCategoryInput;
    categoryId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateModifierGroupArgs = {
    data: UpdateModifierGroupInput;
    modifierGroupId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateTimePeriodArgs = {
    data: UpdateTimePeriodInput;
    timePeriodId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateMenuOverrideArgs = {
    data: UpdateMenuOverrideInput;
    restaurantCode: Scalars["String"];
};

export type MutationUploadMenuItemImageArgs = {
    file: Scalars["Upload"];
    menuItemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUploadImageArgs = {
    file: Scalars["Upload"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateCategoriesSortOrderArgs = {
    data: UpdateCategoriesSortOrderInput;
    restaurantCode: Scalars["String"];
};

export type MutationUpdateItemAvailabilityArgs = {
    data: UpdateMenuItemAvailabilityInput;
    restaurantCode: Scalars["String"];
};

export type MutationResetRestaurantMenuArgs = {
    restaurantCode: Scalars["String"];
};

export type MutationUpdateMenuItemAvailableQuantityArgs = {
    newAvailableQuantity: Scalars["Float"];
    menuItemId: Scalars["Float"];
    restaurantCode: Scalars["String"];
};

export type MutationUpdateMenuItemsAvailableQuantitiesArgs = {
    data: Array<UpdateMenuItemQuantityInput>;
    restaurantCode: Scalars["String"];
};

export type MutationClearCacheArgs = {
    restaurantCode: Scalars["String"];
};

export type MutationCkeMenuUploadArgs = {
    file: Scalars["Upload"];
    restaurantCode: Scalars["String"];
};

export type MutationPagerDutyUploadArgs = {
    fileName?: Maybe<Scalars["String"]>;
    file: Scalars["Upload"];
};

export type MutationRawMenuFileUploadArgs = {
    restaurantCode: Scalars["String"];
    file: Scalars["Upload"];
};

export type MutationCreateRestaurantGroupArgs = {
    data: CreateRestaurantGroupInput;
};

export type MutationUpdateRestaurantGroupArgs = {
    data: UpdateRestaurantGroupInput;
};

export type MutationDeleteRestaurantGroupArgs = {
    id: Scalars["Float"];
};

export type MutationAttachRestaurantToRestaurantGroupArgs = {
    data: AttachRestaurantToRestaurantGroup;
};

export type MutationCreateRestaurantGroupRoleArgs = {
    data: CreateRestaurantGroupRoleInput;
};

export type MutationAttachRestaurantGroupRoleToUserArgs = {
    restaurantGroupRoleId: Scalars["Float"];
    userId: Scalars["Float"];
};

export type MutationUpdateUserAdminArgs = {
    data: UpdateUserAdminInput;
};

export type MutationCreateUserAdminArgs = {
    data: CreateUserAdminInput;
};

export type MutationCreateNewUserArgs = {
    data: CreateUserInput;
};

export type MutationUpdateUserArgs = {
    data: UpdateUserInput;
};

export type MutationStartPasswordResetArgs = {
    data: EmailInput;
};

export type MutationChangePasswordArgs = {
    data: ChangePaswordInput;
};

export type MutationCompletePasswordResetArgs = {
    data: PasswordResetInput;
};

export type CreateRestaurantInput = {
    restaurantName: Scalars["String"];
    restaurantCode: Scalars["String"];
    accountType: RestaurantAccountType;
    primaryRestaurantCode: Maybe<Scalars["String"]>;
};

export type RestaurantSettingsInput = {
    skipAdvancedPayments: Maybe<Scalars["Boolean"]>;
    currency: Maybe<Currency>;
    isAvailableForOrdering: Maybe<Scalars["Boolean"]>;
    isTabEnabled: Maybe<Scalars["Boolean"]>;
    tabSize: Maybe<Scalars["Float"]>;
    isPayOnlyEnabled: Maybe<Scalars["Boolean"]>;
    isTaxEnabled: Maybe<Scalars["Boolean"]>;
    isTipEnabled: Maybe<Scalars["Boolean"]>;
    defaultTipPercentages: Maybe<Array<Scalars["Float"]>>;
    dineInModalityTaxRate: Maybe<Scalars["Float"]>;
    deliveryModalityTaxRate: Maybe<Scalars["Float"]>;
    toGoModalityTaxRate: Maybe<Scalars["Float"]>;
    toGoOrderConfirmationMessage: Maybe<Scalars["String"]>;
    isAlertingEnabled: Maybe<Scalars["Boolean"]>;
    toGoPickupMinutes: Maybe<Scalars["Float"]>;
    toGoDefaultSMSText: Maybe<Scalars["String"]>;
    autoGratuityInPercentage: Maybe<Scalars["Float"]>;
    autoGratuityDisclaimerText: Maybe<Scalars["String"]>;
    isUnsupervisedAI: Maybe<Scalars["Boolean"]>;
};

export type RestaurantBrandingInput = {
    primaryColor: Maybe<Scalars["String"]>;
    secondaryColor: Maybe<Scalars["String"]>;
    logoUrl: Maybe<Scalars["String"]>;
    backgroundImageUrl: Maybe<Scalars["String"]>;
    timezone: Maybe<Scalars["String"]>;
};

export type RestaurantContactInfoInput = {
    email: Maybe<Scalars["String"]>;
    address: Maybe<Scalars["String"]>;
    city: Maybe<Scalars["String"]>;
    zipCode: Maybe<Scalars["String"]>;
    state: Maybe<Scalars["String"]>;
    country: Maybe<Scalars["String"]>;
    phoneNumber: Maybe<Scalars["String"]>;
    website: Maybe<Scalars["String"]>;
};

export type HoursOfOperationInput = {
    timezone: Maybe<Scalars["String"]>;
    availability: Maybe<Array<AvailabilityInput>>;
};

export type AvailabilityInput = {
    day: Scalars["Float"];
    hours: Maybe<Array<Array<Scalars["Float"]>>>;
    alwaysEnabled: Scalars["Boolean"];
};

export type NewCategoryInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    childMenuItemIds: Array<Scalars["Float"]>;
    menuItemsSortOrder: Array<SortOrderInput>;
    timePeriodIds: Array<Scalars["Float"]>;
    menuItemOverrides: Array<MenuOverrideInput>;
    voiceProperties: Scalars["KeyValue"];
};

export type SortOrderInput = {
    id: Scalars["Float"];
    sortOrder: Scalars["Float"];
};

export type MenuOverrideInput = {
    secondaryType: Maybe<SecondaryType>;
    secondaryId: Maybe<Scalars["String"]>;
    objectPrimaryKey: Scalars["String"];
    overrideKey: OverrideKey;
    overrideValue: Maybe<Scalars["String"]>;
    modalityType: Maybe<ModalityType>;
};

export type NewMenuItemInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    price: Scalars["Float"];
    isModifierOnly: Scalars["Boolean"];
    available: Maybe<Scalars["Boolean"]>;
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    imageUrl: Maybe<Scalars["String"]>;
    tax: Maybe<Scalars["Float"]>;
    description: Maybe<Scalars["String"]>;
    modifierGroupIds: Array<Scalars["Float"]>;
    parentCategoryIds: Array<Scalars["Float"]>;
    modifierGroupsSortOrder: Array<SortOrderInput>;
    settings: Scalars["KeyValue"];
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
    menuOverrides: Maybe<Array<MenuOverrideInput>>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
};

export type NewModifierGroupInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    minimumSelections: Scalars["Float"];
    maximumSelections: Scalars["Float"];
    childMenuItemIds: Array<Scalars["Float"]>;
    menuItemsSortOrder: Array<SortOrderInput>;
    menuItemOverrides: Array<MenuOverrideInput>;
    defaultSelectedItemIds: Maybe<Array<Scalars["Float"]>>;
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
};

export type NewTimePeriodInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    description: Maybe<Scalars["String"]>;
    availability: Array<AvailabilityInput>;
    /** Start date of time period. If null then time period is active from beginning of time until the end date. */
    startDate: Maybe<Scalars["DateTime"]>;
    /** End date of time period. If null then time period is active from start date until the end of time. */
    endDate: Maybe<Scalars["DateTime"]>;
};

export type NewMenuOverrideInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    overrideType: OverrideType;
    objectPrimaryKey: Scalars["String"];
    secondaryType: Maybe<SecondaryType>;
    secondaryId: Maybe<Scalars["String"]>;
    overrideKey: OverrideKey;
    modalityType: Maybe<ModalityType>;
    overrideValue: Scalars["String"];
    availableOverride: Maybe<Scalars["Boolean"]>;
};

export type UpdateMenuItemInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    price: Scalars["Float"];
    isModifierOnly: Scalars["Boolean"];
    available: Maybe<Scalars["Boolean"]>;
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    imageUrl: Maybe<Scalars["String"]>;
    tax: Maybe<Scalars["Float"]>;
    description: Maybe<Scalars["String"]>;
    modifierGroupIds: Array<Scalars["Float"]>;
    parentCategoryIds: Array<Scalars["Float"]>;
    modifierGroupsSortOrder: Array<SortOrderInput>;
    settings: Scalars["KeyValue"];
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
    menuOverrides: Maybe<Array<MenuOverrideInput>>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
    id: Scalars["Float"];
};

export type UpdateCategoryInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    childMenuItemIds: Array<Scalars["Float"]>;
    menuItemsSortOrder: Array<SortOrderInput>;
    timePeriodIds: Array<Scalars["Float"]>;
    menuItemOverrides: Array<MenuOverrideInput>;
    voiceProperties: Scalars["KeyValue"];
};

export type UpdateModifierGroupInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    minimumSelections: Maybe<Scalars["Float"]>;
    maximumSelections: Maybe<Scalars["Float"]>;
    childMenuItemIds: Array<Scalars["Float"]>;
    menuItemsSortOrder: Array<SortOrderInput>;
    menuItemOverrides: Array<MenuOverrideInput>;
    defaultSelectedItemIds: Maybe<Array<Scalars["Float"]>>;
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
};

export type UpdateTimePeriodInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    description: Maybe<Scalars["String"]>;
    availability: Array<AvailabilityInput>;
    /** Start date of time period. If null then time period is active from beginning of time until the end date. */
    startDate: Maybe<Scalars["DateTime"]>;
    /** End date of time period. If null then time period is active from start date until the end of time. */
    endDate: Maybe<Scalars["DateTime"]>;
};

export type UpdateMenuOverrideInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    overrideType: OverrideType;
    objectPrimaryKey: Scalars["String"];
    secondaryType: Maybe<SecondaryType>;
    secondaryId: Maybe<Scalars["String"]>;
    overrideKey: OverrideKey;
    modalityType: Maybe<ModalityType>;
    overrideValue: Maybe<Scalars["String"]>;
    availableOverride: Maybe<Scalars["Boolean"]>;
};

export type UpdateCategoriesSortOrderInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    categoriesSortOrder: Array<SortOrderInput>;
};

export type UpdateMenuItemAvailabilityInput = {
    restaurantCode: Scalars["String"];
    rebuildCache: Maybe<Scalars["Boolean"]>;
    id: Scalars["Float"];
    available: Scalars["Boolean"];
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
};

export type UpdateMenuItemQuantityInput = {
    menuItemId: Scalars["Float"];
    quantity: Scalars["Float"];
    type: Scalars["String"];
};

export type CreateRestaurantGroupInput = {
    groupName: Scalars["String"];
    restaurantCodes: Array<Scalars["String"]>;
};

export type UpdateRestaurantGroupInput = {
    groupName: Scalars["String"];
    restaurantCodes: Array<Scalars["String"]>;
    id: Scalars["Float"];
};

export type AttachRestaurantToRestaurantGroup = {
    restaurantCode: Scalars["String"];
    restaurantGroupIds: Array<Scalars["Float"]>;
};

export type CreateRestaurantGroupRoleInput = {
    permission: RolePermissions;
    restaurantGroupId: Scalars["Float"];
};

export type UpdateUserAdminInput = {
    id: Scalars["Float"];
    email: Scalars["String"];
    username: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    isPreferred: Maybe<Scalars["Boolean"]>;
    restaurantGroupRoles: Maybe<Array<UserRestaurantGroupRoleInput>>;
};

export type UserRestaurantGroupRoleInput = {
    groupId: Scalars["Float"];
    permission: RolePermissions;
};

export type CreateUserAdminInput = {
    username: Scalars["String"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    password: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    isPreferred: Maybe<Scalars["Boolean"]>;
    restaurantGroupRoles: Maybe<Array<UserRestaurantGroupRoleInput>>;
};

export type CreateUserInput = {
    username: Scalars["String"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    password: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    isPreferred: Maybe<Scalars["Boolean"]>;
};

export type UpdateUserInput = {
    id: Scalars["Float"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    isPreferred: Maybe<Scalars["Boolean"]>;
};

export type ChangePaswordInput = {
    email: Scalars["String"];
    password: Scalars["String"];
    newPassword: Scalars["String"];
};

export type PasswordResetInput = {
    email: Scalars["String"];
    password: Scalars["String"];
    confirmPassword: Scalars["String"];
    resetCode: Scalars["String"];
};

export type LoginQueryQueryVariables = Exact<{
    email: Scalars["String"];
    password: Scalars["String"];
}>;

export type LoginQueryQuery = { __typename?: "Query" } & {
    login: { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "username"
        | "email"
        | "firstName"
        | "lastName"
        | "authorizationToken"
    >;
};

export type RestaurantInfoQueryVariables = Exact<{
    restaurantCode: Scalars["String"];
}>;

export type RestaurantInfoQuery = { __typename?: "Query" } & {
    restaurant: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        | "restaurantName"
        | "restaurantCode"
        | "accountType"
        | "primaryRestaurantCode"
    > & {
            restaurantSettings: Maybe<
                { __typename?: "RestaurantSettings" } & Pick<
                    RestaurantSettings,
                    | "dineInModalityTaxRate"
                    | "deliveryModalityTaxRate"
                    | "toGoModalityTaxRate"
                    | "toGoOrderConfirmationMessage"
                    | "currency"
                    | "isAlertingEnabled"
                    | "toGoPickupMinutes"
                    | "toGoDefaultSMSText"
                >
            >;
            restaurantContactInfo: Maybe<
                { __typename?: "RestaurantContactInfo" } & Pick<
                    RestaurantContactInfo,
                    | "address"
                    | "city"
                    | "state"
                    | "zipCode"
                    | "country"
                    | "phoneNumber"
                    | "website"
                    | "email"
                >
            >;
            restaurantBranding: Maybe<
                { __typename?: "RestaurantBranding" } & Pick<
                    RestaurantBranding,
                    "logoUrl" | "backgroundImageUrl"
                >
            >;
            hoursOfOperation: Maybe<
                { __typename?: "HoursOfOperation" } & Pick<
                    HoursOfOperation,
                    "id" | "timezone"
                > & {
                        availability: Maybe<
                            Array<
                                { __typename?: "Availability" } & Pick<
                                    Availability,
                                    "day" | "hours" | "alwaysEnabled"
                                >
                            >
                        >;
                    }
            >;
        };
};

export type AuthStatusQueryVariables = Exact<{ [key: string]: never }>;

export type AuthStatusQuery = { __typename?: "Query" } & {
    authStatus: { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "username"
        | "email"
        | "firstName"
        | "lastName"
        | "authorizationToken"
    >;
};

export type StartPasswordResetMutationVariables = Exact<{
    email: Scalars["String"];
}>;

export type StartPasswordResetMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "startPasswordReset"
>;

export type ChangePasswordMutationVariables = Exact<{
    email: Scalars["String"];
    password: Scalars["String"];
    newPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "changePassword"
>;

export type CompletePasswordResetMutationVariables = Exact<{
    email: Scalars["String"];
    password: Scalars["String"];
    confirmPassword: Scalars["String"];
    resetCode: Scalars["String"];
}>;

export type CompletePasswordResetMutation = { __typename?: "Mutation" } & {
    completePasswordReset: { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "username"
        | "email"
        | "resetCode"
        | "authorizationToken"
        | "loginAttempts"
        | "disabled"
    >;
};

export type VerifyGoogleAuthTokenQueryVariables = Exact<{
    source: Scalars["String"];
    token: Scalars["String"];
}>;

export type VerifyGoogleAuthTokenQuery = { __typename?: "Query" } & {
    verifyGAuthToken: { __typename?: "MFUserProfile" } & Pick<
        MfUserProfile,
        | "id"
        | "username"
        | "email"
        | "resetCode"
        | "authorizationToken"
        | "loginAttempts"
        | "disabled"
        | "mfaEnabled"
        | "qrCodeURL"
    >;
};

export type MfLoginQueryQueryVariables = Exact<{
    token: Scalars["String"];
    otp: Scalars["String"];
    source: Scalars["String"];
}>;

export type MfLoginQueryQuery = { __typename?: "Query" } & {
    mfLogin: { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "username"
        | "email"
        | "firstName"
        | "lastName"
        | "authorizationToken"
    >;
};

export type DuplicateCategoryMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    sourceCategoryId: Scalars["Float"];
    version: Maybe<Scalars["Float"]>;
}>;

export type DuplicateCategoryMutation = { __typename?: "Mutation" } & {
    duplicateCategory: { __typename?: "Category" } & Pick<
        Category,
        "id" | "name" | "description" | "ownSortOrder"
    >;
};

export type UploadImageMutationVariables = Exact<{
    file: Scalars["Upload"];
    restaurantCode: Scalars["String"];
}>;

export type UploadImageMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "uploadImage"
>;

export type GetMenuQueryVariables = Exact<{
    restaurantCode: Scalars["String"];
}>;

export type GetMenuQuery = { __typename?: "Query" } & {
    menu: { __typename?: "Menu" } & {
        categories: Array<
            { __typename?: "Category" } & Pick<
                Category,
                | "id"
                | "name"
                | "description"
                | "ownSortOrder"
                | "voiceProperties"
                | "timePeriods"
                | "menuItems"
            > & {
                    sortOrder: Array<
                        { __typename?: "SortOrder" } & Pick<
                            SortOrder,
                            "id" | "sortOrder"
                        >
                    >;
                }
        >;
        menuItems: Array<
            { __typename?: "MenuItem" } & Pick<
                MenuItem,
                | "id"
                | "name"
                | "price"
                | "tax"
                | "description"
                | "imageUrl"
                | "available"
                | "unavailableUntil"
                | "customRequestAvailable"
                | "isModifierOnly"
                | "settings"
                | "posPropertyMap"
                | "voiceProperties"
                | "menuOverrides"
                | "modifierGroups"
                | "availableLimitedQuantity"
                | "parentCategoryIds"
            > & {
                    sortOrder: Array<
                        { __typename?: "SortOrder" } & Pick<
                            SortOrder,
                            "id" | "sortOrder"
                        >
                    >;
                }
        >;
        menuOverrides: Array<
            { __typename?: "MenuOverride" } & Pick<
                MenuOverride,
                | "id"
                | "overrideType"
                | "objectPrimaryKey"
                | "overrideKey"
                | "overrideValue"
                | "secondaryType"
                | "secondaryId"
                | "modalityType"
                | "availableOverride"
            >
        >;
        modifierGroups: Array<
            { __typename?: "ModifierGroup" } & Pick<
                ModifierGroup,
                | "id"
                | "name"
                | "description"
                | "minimumSelections"
                | "maximumSelections"
                | "defaultSelectedItemIds"
                | "menuItems"
                | "posPropertyMap"
                | "voiceProperties"
            > & {
                    sortOrder: Array<
                        { __typename?: "SortOrder" } & Pick<
                            SortOrder,
                            "id" | "sortOrder"
                        >
                    >;
                }
        >;
        timePeriods: Array<
            { __typename?: "TimePeriod" } & Pick<
                TimePeriod,
                | "id"
                | "description"
                | "startDate"
                | "endDate"
                | "timePeriodCategoryMappings"
            > & {
                    availability: Array<
                        { __typename?: "Availability" } & Pick<
                            Availability,
                            "day" | "hours" | "alwaysEnabled"
                        >
                    >;
                }
        >;
    };
};

export type UserManagementQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UserManagementQueryQuery = { __typename?: "Query" } & {
    users: Array<
        { __typename?: "UserProfile" } & Pick<
            UserProfile,
            | "id"
            | "email"
            | "username"
            | "firstName"
            | "lastName"
            | "phoneNumber"
            | "disabled"
            | "isPreferred"
        > & {
                restaurantGroupRoles: Array<
                    { __typename?: "RestaurantGroupRole" } & Pick<
                        RestaurantGroupRole,
                        "id"
                    > & {
                            role: { __typename?: "Role" } & Pick<
                                Role,
                                "id" | "description" | "permission"
                            >;
                        }
                >;
            }
    >;
    restaurants: Array<
        { __typename?: "Restaurant" } & Pick<
            Restaurant,
            "id" | "restaurantName" | "restaurantCode"
        > & {
                restaurantContactInfo: Maybe<
                    { __typename?: "RestaurantContactInfo" } & Pick<
                        RestaurantContactInfo,
                        | "id"
                        | "address"
                        | "city"
                        | "state"
                        | "zipCode"
                        | "country"
                        | "email"
                    >
                >;
            }
    >;
    restaurantGroups: Array<
        { __typename?: "RestaurantGroup" } & Pick<
            RestaurantGroup,
            "id" | "groupName"
        > & {
                restaurants: Array<
                    { __typename?: "Restaurant" } & Pick<
                        Restaurant,
                        "id" | "restaurantName" | "restaurantCode"
                    >
                >;
            }
    >;
    restaurantGroupsWithUsers: Array<
        { __typename?: "RestaurantGroupWithUsers" } & Pick<
            RestaurantGroupWithUsers,
            "groupName"
        > & {
                attachedUsers: Array<
                    { __typename?: "UserRoleAttachment" } & Pick<
                        UserRoleAttachment,
                        "permission"
                    > & {
                            user: { __typename?: "UserProfile" } & Pick<
                                UserProfile,
                                | "id"
                                | "username"
                                | "email"
                                | "firstName"
                                | "lastName"
                            >;
                        }
                >;
            }
    >;
    roles: Array<
        { __typename?: "Role" } & Pick<
            Role,
            "id" | "description" | "permission"
        >
    >;
};

export type AddMenuItemMutationVariables = Exact<{
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    price: Scalars["Float"];
    tax: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
    isModifierOnly: Scalars["Boolean"];
    available: Scalars["Boolean"];
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    modifierGroupIds: Array<Scalars["Float"]> | Scalars["Float"];
    parentCategoryIds: Array<Scalars["Float"]> | Scalars["Float"];
    sortOrder: Array<SortOrderInput> | SortOrderInput;
    imageUrl: Maybe<Scalars["String"]>;
    settings: Scalars["KeyValue"];
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
    menuOverrides: Maybe<Array<MenuOverrideInput> | MenuOverrideInput>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
}>;

export type AddMenuItemMutation = { __typename?: "Mutation" } & {
    createMenuItem: { __typename?: "MenuItem" } & Pick<
        MenuItem,
        | "id"
        | "name"
        | "price"
        | "tax"
        | "description"
        | "isModifierOnly"
        | "modifierGroups"
        | "imageUrl"
        | "availableLimitedQuantity"
    >;
};

export type UpdateMenuItemMutationVariables = Exact<{
    id: Scalars["Float"];
    restaurantCode: Scalars["String"];
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    price: Scalars["Float"];
    tax: Maybe<Scalars["Float"]>;
    available: Scalars["Boolean"];
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    imageUrl: Maybe<Scalars["String"]>;
    isModifierOnly: Scalars["Boolean"];
    modifierGroupIds: Array<Scalars["Float"]> | Scalars["Float"];
    parentCategoryIds: Array<Scalars["Float"]> | Scalars["Float"];
    sortOrder: Array<SortOrderInput> | SortOrderInput;
    settings: Scalars["KeyValue"];
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
    menuOverrides: Maybe<Array<MenuOverrideInput> | MenuOverrideInput>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
}>;

export type UpdateMenuItemMutation = { __typename?: "Mutation" } & {
    updateMenuItem: { __typename?: "MenuItem" } & Pick<
        MenuItem,
        | "id"
        | "name"
        | "price"
        | "tax"
        | "description"
        | "isModifierOnly"
        | "availableLimitedQuantity"
    >;
};

export type CreateModifierGroupMutationVariables = Exact<{
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    minimumSelections: Scalars["Float"];
    maximumSelections: Scalars["Float"];
    restaurantCode: Scalars["String"];
    childMenuItemIds: Array<Scalars["Float"]> | Scalars["Float"];
    sortOrder: Array<SortOrderInput> | SortOrderInput;
    menuItemOverrides: Array<MenuOverrideInput> | MenuOverrideInput;
    defaultSelectedItemIds: Array<Scalars["Float"]> | Scalars["Float"];
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
}>;

export type CreateModifierGroupMutation = { __typename?: "Mutation" } & {
    createModifierGroup: { __typename?: "ModifierGroup" } & Pick<
        ModifierGroup,
        | "id"
        | "name"
        | "minimumSelections"
        | "maximumSelections"
        | "description"
        | "defaultSelectedItemIds"
    >;
};

export type UpdateModifierGroupMutationVariables = Exact<{
    id: Scalars["Float"];
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    minimumSelections: Maybe<Scalars["Float"]>;
    maximumSelections: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
    childMenuItemIds: Array<Scalars["Float"]> | Scalars["Float"];
    sortOrder: Array<SortOrderInput> | SortOrderInput;
    menuItemOverrides: Array<MenuOverrideInput> | MenuOverrideInput;
    defaultSelectedItemIds: Array<Scalars["Float"]> | Scalars["Float"];
    posProperties: Scalars["KeyValue"];
    voiceProperties: Scalars["KeyValue"];
}>;

export type UpdateModifierGroupMutation = { __typename?: "Mutation" } & {
    updateModifierGroup: { __typename?: "ModifierGroup" } & Pick<
        ModifierGroup,
        | "id"
        | "name"
        | "minimumSelections"
        | "maximumSelections"
        | "description"
        | "defaultSelectedItemIds"
    >;
};

export type CreateCategoryMutationVariables = Exact<{
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    restaurantCode: Scalars["String"];
    childMenuItemIds: Array<Scalars["Float"]> | Scalars["Float"];
    sortOrder: Array<SortOrderInput> | SortOrderInput;
    timePeriodIds: Array<Scalars["Float"]> | Scalars["Float"];
    menuItemOverrides: Array<MenuOverrideInput> | MenuOverrideInput;
    voiceProperties: Scalars["KeyValue"];
}>;

export type CreateCategoryMutation = { __typename?: "Mutation" } & {
    createCategory: { __typename?: "Category" } & Pick<
        Category,
        "id" | "name" | "description"
    >;
};

export type UpdateCategoryMutationVariables = Exact<{
    id: Scalars["Float"];
    name: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    restaurantCode: Scalars["String"];
    childMenuItemIds: Array<Scalars["Float"]> | Scalars["Float"];
    sortOrder: Array<SortOrderInput> | SortOrderInput;
    timePeriodIds: Array<Scalars["Float"]> | Scalars["Float"];
    menuItemOverrides: Array<MenuOverrideInput> | MenuOverrideInput;
    voiceProperties: Scalars["KeyValue"];
}>;

export type UpdateCategoryMutation = { __typename?: "Mutation" } & {
    updateCategory: { __typename?: "Category" } & Pick<
        Category,
        "id" | "name" | "description"
    >;
};

export type DeleteMenuItemMutationVariables = Exact<{
    id: Scalars["Float"];
    restaurantCode: Scalars["String"];
}>;

export type DeleteMenuItemMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteMenuItem"
>;

export type DeleteCategoryMutationVariables = Exact<{
    id: Scalars["Float"];
    restaurantCode: Scalars["String"];
}>;

export type DeleteCategoryMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteCategory"
>;

export type DeleteModifierGroupMutationVariables = Exact<{
    id: Scalars["Float"];
    restaurantCode: Scalars["String"];
}>;

export type DeleteModifierGroupMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteModifierGroup"
>;

export type UpdateRestaurantSettingsMutationVariables = Exact<{
    skipAdvancedPayments: Maybe<Scalars["Boolean"]>;
    currency: Maybe<Currency>;
    isAvailableForOrdering: Maybe<Scalars["Boolean"]>;
    isTabEnabled: Maybe<Scalars["Boolean"]>;
    tabSize: Maybe<Scalars["Float"]>;
    isPayOnlyEnabled: Maybe<Scalars["Boolean"]>;
    isTaxEnabled: Maybe<Scalars["Boolean"]>;
    isTipEnabled: Maybe<Scalars["Boolean"]>;
    defaultTipPercentages: Maybe<Array<Scalars["Float"]> | Scalars["Float"]>;
    dineInModalityTaxRate: Maybe<Scalars["Float"]>;
    deliveryModalityTaxRate: Maybe<Scalars["Float"]>;
    toGoModalityTaxRate: Maybe<Scalars["Float"]>;
    restaurantCode: Scalars["String"];
    toGoOrderConfirmationMessage: Maybe<Scalars["String"]>;
    isAlertingEnabled: Maybe<Scalars["Boolean"]>;
    toGoPickupMinutes: Maybe<Scalars["Float"]>;
    toGoDefaultSMSText: Maybe<Scalars["String"]>;
    isUnsupervisedAI: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateRestaurantSettingsMutation = { __typename?: "Mutation" } & {
    updateRestaurantSettings: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type UpdateRestaurantContactInfoMutationVariables = Exact<{
    email: Maybe<Scalars["String"]>;
    address: Maybe<Scalars["String"]>;
    city: Maybe<Scalars["String"]>;
    zipCode: Maybe<Scalars["String"]>;
    state: Maybe<Scalars["String"]>;
    country: Maybe<Scalars["String"]>;
    phoneNumber: Maybe<Scalars["String"]>;
    website: Maybe<Scalars["String"]>;
    restaurantCode: Scalars["String"];
}>;

export type UpdateRestaurantContactInfoMutation = {
    __typename?: "Mutation";
} & {
    updateRestaurantContactInfo: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type UpdateHoursOfOperationMutationVariables = Exact<{
    timezone: Scalars["String"];
    availability: Array<AvailabilityInput> | AvailabilityInput;
    restaurantCode: Scalars["String"];
}>;

export type UpdateHoursOfOperationMutation = { __typename?: "Mutation" } & {
    updateHoursOfOperation: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type CreateHoursOfOperationMutationVariables = Exact<{
    timezone: Scalars["String"];
    availability: Array<AvailabilityInput> | AvailabilityInput;
    restaurantCode: Scalars["String"];
}>;

export type CreateHoursOfOperationMutation = { __typename?: "Mutation" } & {
    createHoursOfOperation: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type CreateTimePeriodMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    startDate: Maybe<Scalars["DateTime"]>;
    endDate: Maybe<Scalars["DateTime"]>;
    availability: Array<AvailabilityInput> | AvailabilityInput;
}>;

export type CreateTimePeriodMutation = { __typename?: "Mutation" } & {
    createTimePeriod: { __typename?: "TimePeriod" } & Pick<
        TimePeriod,
        | "id"
        | "description"
        | "startDate"
        | "endDate"
        | "timePeriodCategoryMappings"
    > & {
            availability: Array<
                { __typename?: "Availability" } & Pick<
                    Availability,
                    "day" | "hours" | "alwaysEnabled"
                >
            >;
        };
};

export type DeleteTimePeriodMutationVariables = Exact<{
    id: Scalars["Float"];
    restaurantCode: Scalars["String"];
}>;

export type DeleteTimePeriodMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteTimePeriod"
>;

export type UpdateTimePeriodMutationVariables = Exact<{
    id: Scalars["Float"];
    restaurantCode: Scalars["String"];
    description: Maybe<Scalars["String"]>;
    startDate: Maybe<Scalars["DateTime"]>;
    endDate: Maybe<Scalars["DateTime"]>;
    availability: Array<AvailabilityInput> | AvailabilityInput;
}>;

export type UpdateTimePeriodMutation = { __typename?: "Mutation" } & {
    updateTimePeriod: { __typename?: "TimePeriod" } & Pick<
        TimePeriod,
        | "id"
        | "description"
        | "startDate"
        | "endDate"
        | "timePeriodCategoryMappings"
    > & {
            availability: Array<
                { __typename?: "Availability" } & Pick<
                    Availability,
                    "day" | "hours" | "alwaysEnabled"
                >
            >;
        };
};

export type UpdateRestaurantBrandingMutationVariables = Exact<{
    primaryColor: Maybe<Scalars["String"]>;
    secondaryColor: Maybe<Scalars["String"]>;
    logoUrl: Maybe<Scalars["String"]>;
    backgroundImageUrl: Maybe<Scalars["String"]>;
    timezone: Maybe<Scalars["String"]>;
    restaurantCode: Scalars["String"];
}>;

export type UpdateRestaurantBrandingMutation = { __typename?: "Mutation" } & {
    updateRestaurantBranding: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type UpdateCategoriesSortOrderMutationVariables = Exact<{
    categoriesSortOrder: Array<SortOrderInput> | SortOrderInput;
    restaurantCode: Scalars["String"];
}>;

export type UpdateCategoriesSortOrderMutation = { __typename?: "Mutation" } & {
    updateCategoriesSortOrder: Array<
        { __typename?: "Category" } & Pick<
            Category,
            "id" | "name" | "description" | "ownSortOrder"
        >
    >;
};

export type UpdateMenuItemAvailabilityMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    id: Scalars["Float"];
    available: Scalars["Boolean"];
    unavailableUntil: Maybe<Scalars["DateTime"]>;
    availableLimitedQuantity: Maybe<Scalars["Float"]>;
}>;

export type UpdateMenuItemAvailabilityMutation = { __typename?: "Mutation" } & {
    updateItemAvailability: { __typename?: "MenuItem" } & Pick<
        MenuItem,
        | "id"
        | "name"
        | "available"
        | "unavailableUntil"
        | "availableLimitedQuantity"
    >;
};

export type UpdateUserAdminMutationVariables = Exact<{
    id: Scalars["Float"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    email: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    isPreferred: Maybe<Scalars["Boolean"]>;
    restaurantGroupRoles: Maybe<
        Array<UserRestaurantGroupRoleInput> | UserRestaurantGroupRoleInput
    >;
}>;

export type UpdateUserAdminMutation = { __typename?: "Mutation" } & {
    updateUserAdmin: { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "username"
        | "email"
        | "firstName"
        | "lastName"
        | "phoneNumber"
        | "isPreferred"
    > & {
            restaurantGroupRoles: Array<
                { __typename?: "RestaurantGroupRole" } & Pick<
                    RestaurantGroupRole,
                    "id"
                > & {
                        role: { __typename?: "Role" } & Pick<
                            Role,
                            "id" | "description" | "permission"
                        >;
                    }
            >;
        };
};

export type CreateUserAdminMutationVariables = Exact<{
    username: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    email: Scalars["String"];
    phoneNumber: Maybe<Scalars["String"]>;
    password: Scalars["String"];
    isPreferred: Maybe<Scalars["Boolean"]>;
    restaurantGroupRoles: Maybe<
        Array<UserRestaurantGroupRoleInput> | UserRestaurantGroupRoleInput
    >;
}>;

export type CreateUserAdminMutation = { __typename?: "Mutation" } & {
    createUserAdmin: { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "username"
        | "email"
        | "firstName"
        | "lastName"
        | "phoneNumber"
        | "isPreferred"
    > & {
            restaurantGroupRoles: Array<
                { __typename?: "RestaurantGroupRole" } & Pick<
                    RestaurantGroupRole,
                    "id"
                > & {
                        role: { __typename?: "Role" } & Pick<
                            Role,
                            "id" | "description" | "permission"
                        >;
                    }
            >;
        };
};

export type DuplicateMenuItemMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    sourceMenuItemId: Scalars["Float"];
    version: Maybe<Scalars["Float"]>;
}>;

export type DuplicateMenuItemMutation = { __typename?: "Mutation" } & {
    duplicateMenuItem: { __typename?: "MenuItem" } & Pick<MenuItem, "id">;
};

export type CreateRestaurantMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    restaurantName: Scalars["String"];
    accountType: RestaurantAccountType;
}>;

export type CreateRestaurantMutation = { __typename?: "Mutation" } & {
    createRestaurant: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type UpdateRestaurantMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    restaurantName: Scalars["String"];
}>;

export type UpdateRestaurantMutation = { __typename?: "Mutation" } & {
    updateRestaurant: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantCode" | "restaurantName"
    >;
};

export type AttachRestaurantToRestaurantGroupMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    restaurantGroupIds: Array<Scalars["Float"]> | Scalars["Float"];
}>;

export type AttachRestaurantToRestaurantGroupMutation = {
    __typename?: "Mutation";
} & {
    attachRestaurantToRestaurantGroup: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantName" | "restaurantCode"
    >;
};

export type CreateRestaurantGroupMutationVariables = Exact<{
    groupName: Scalars["String"];
    restaurantCodes: Array<Scalars["String"]> | Scalars["String"];
}>;

export type CreateRestaurantGroupMutation = { __typename?: "Mutation" } & {
    createRestaurantGroup: { __typename?: "RestaurantGroup" } & Pick<
        RestaurantGroup,
        "id" | "groupName"
    >;
};

export type UpdateRestaurantGroupMutationVariables = Exact<{
    id: Scalars["Float"];
    groupName: Scalars["String"];
    restaurantCodes: Array<Scalars["String"]> | Scalars["String"];
}>;

export type UpdateRestaurantGroupMutation = { __typename?: "Mutation" } & {
    updateRestaurantGroup: { __typename?: "RestaurantGroup" } & Pick<
        RestaurantGroup,
        "id" | "groupName"
    >;
};

export type DeleteRestaurantGroupMutationVariables = Exact<{
    id: Scalars["Float"];
}>;

export type DeleteRestaurantGroupMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteRestaurantGroup"
>;

export type QueryPrimaryRestaurantsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type QueryPrimaryRestaurantsQuery = { __typename?: "Query" } & {
    primaryRestaurants: Array<
        { __typename?: "Restaurant" } & Pick<Restaurant, "restaurantCode">
    >;
};

export type UpdatePrimaryRestaurantMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    primaryRestaurantCode: Scalars["String"];
}>;

export type UpdatePrimaryRestaurantMutation = { __typename?: "Mutation" } & {
    updatePrimaryRestaurant: { __typename?: "Restaurant" } & Pick<
        Restaurant,
        "id" | "restaurantCode" | "restaurantName"
    >;
};

export type ReloadCacheMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
}>;

export type ReloadCacheMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "clearCache"
>;

export type UploadRawMenuFileMutationVariables = Exact<{
    file: Scalars["Upload"];
    restaurantCode: Scalars["String"];
}>;

export type UploadRawMenuFileMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "rawMenuFileUpload"
>;

export type DuplicateModifierGroupMutationVariables = Exact<{
    restaurantCode: Scalars["String"];
    sourceModifierGroupId: Scalars["Float"];
    version: Maybe<Scalars["Float"]>;
}>;

export type DuplicateModifierGroupMutation = { __typename?: "Mutation" } & {
    duplicateModifierGroup: { __typename?: "ModifierGroup" } & Pick<
        ModifierGroup,
        "id"
    >;
};

export const LoginQueryDocument = gql`
    query loginQuery($email: String!, $password: String!) {
        login(data: { email: $email, password: $password }) {
            id
            username
            email
            firstName
            lastName
            authorizationToken
        }
    }
`;
export const RestaurantInfoDocument = gql`
    query restaurantInfo($restaurantCode: String!) {
        restaurant(restaurantCode: $restaurantCode) {
            restaurantName
            restaurantCode
            accountType
            primaryRestaurantCode
            restaurantSettings {
                dineInModalityTaxRate
                deliveryModalityTaxRate
                toGoModalityTaxRate
                toGoOrderConfirmationMessage
                currency
                isAlertingEnabled
                toGoPickupMinutes
                toGoDefaultSMSText
            }
            restaurantContactInfo {
                address
                city
                state
                zipCode
                country
                phoneNumber
                website
                email
            }
            restaurantBranding {
                logoUrl
                backgroundImageUrl
            }
            hoursOfOperation {
                id
                timezone
                availability {
                    day
                    hours
                    alwaysEnabled
                }
            }
        }
    }
`;
export const AuthStatusDocument = gql`
    query authStatus {
        authStatus {
            id
            username
            email
            firstName
            lastName
            authorizationToken
        }
    }
`;
export const StartPasswordResetDocument = gql`
    mutation startPasswordReset($email: String!) {
        startPasswordReset(data: { email: $email })
    }
`;
export const ChangePasswordDocument = gql`
    mutation changePassword(
        $email: String!
        $password: String!
        $newPassword: String!
    ) {
        changePassword(
            data: {
                email: $email
                password: $password
                newPassword: $newPassword
            }
        )
    }
`;
export const CompletePasswordResetDocument = gql`
    mutation completePasswordReset(
        $email: String!
        $password: String!
        $confirmPassword: String!
        $resetCode: String!
    ) {
        completePasswordReset(
            data: {
                email: $email
                password: $password
                confirmPassword: $confirmPassword
                resetCode: $resetCode
            }
        ) {
            id
            username
            email
            resetCode
            authorizationToken
            loginAttempts
            disabled
        }
    }
`;
export const VerifyGoogleAuthTokenDocument = gql`
    query verifyGoogleAuthToken($source: String!, $token: String!) {
        verifyGAuthToken(source: $source, token: $token) {
            id
            username
            email
            resetCode
            authorizationToken
            loginAttempts
            disabled
            mfaEnabled
            qrCodeURL
        }
    }
`;
export const MfLoginQueryDocument = gql`
    query mfLoginQuery($token: String!, $otp: String!, $source: String!) {
        mfLogin(data: { otp: $otp, source: $source, token: $token }) {
            id
            username
            email
            firstName
            lastName
            authorizationToken
        }
    }
`;
export const DuplicateCategoryDocument = gql`
    mutation duplicateCategory(
        $restaurantCode: String!
        $sourceCategoryId: Float!
        $version: Float
    ) {
        duplicateCategory(
            restaurantCode: $restaurantCode
            sourceCategoryId: $sourceCategoryId
            version: $version
            rebuildCache: true
        ) {
            id
            name
            description
            ownSortOrder
        }
    }
`;
export const UploadImageDocument = gql`
    mutation uploadImage($file: Upload!, $restaurantCode: String!) {
        uploadImage(file: $file, restaurantCode: $restaurantCode)
    }
`;
export const GetMenuDocument = gql`
    query getMenu($restaurantCode: String!) {
        menu(restaurantCode: $restaurantCode, enableCache: true) {
            categories {
                id
                name
                description
                ownSortOrder
                voiceProperties
                timePeriods
                menuItems
                sortOrder {
                    id
                    sortOrder
                }
            }
            menuItems {
                id
                name
                price
                tax
                description
                imageUrl
                available
                unavailableUntil
                customRequestAvailable
                isModifierOnly
                settings
                posPropertyMap
                voiceProperties
                menuOverrides
                modifierGroups
                sortOrder {
                    id
                    sortOrder
                }
                availableLimitedQuantity
                parentCategoryIds
            }
            menuOverrides {
                id
                overrideType
                objectPrimaryKey
                overrideKey
                overrideValue
                secondaryType
                secondaryId
                overrideKey
                modalityType
                overrideValue
                availableOverride
            }
            modifierGroups {
                id
                name
                description
                minimumSelections
                maximumSelections
                defaultSelectedItemIds
                menuItems
                sortOrder {
                    id
                    sortOrder
                }
                posPropertyMap
                voiceProperties
            }
            timePeriods {
                id
                description
                startDate
                endDate
                availability {
                    day
                    hours
                    alwaysEnabled
                }
                timePeriodCategoryMappings
            }
        }
    }
`;
export const UserManagementQueryDocument = gql`
    query userManagementQuery {
        users {
            id
            email
            username
            firstName
            lastName
            phoneNumber
            disabled
            isPreferred
            restaurantGroupRoles {
                id
                role {
                    id
                    description
                    permission
                }
            }
        }
        restaurants {
            id
            restaurantName
            restaurantCode
            restaurantContactInfo {
                id
                address
                city
                state
                zipCode
                country
                email
            }
        }
        restaurantGroups {
            id
            groupName
            restaurants {
                id
                restaurantName
                restaurantCode
            }
        }
        restaurantGroupsWithUsers {
            groupName
            attachedUsers {
                permission
                user {
                    id
                    username
                    email
                    firstName
                    lastName
                }
            }
        }
        roles {
            id
            description
            permission
        }
    }
`;
export const AddMenuItemDocument = gql`
    mutation addMenuItem(
        $name: String!
        $description: String
        $price: Float!
        $tax: Float
        $restaurantCode: String!
        $isModifierOnly: Boolean!
        $available: Boolean!
        $unavailableUntil: DateTime
        $modifierGroupIds: [Float!]!
        $parentCategoryIds: [Float!]!
        $sortOrder: [SortOrderInput!]!
        $imageUrl: String
        $settings: KeyValue!
        $posProperties: KeyValue!
        $voiceProperties: KeyValue!
        $menuOverrides: [MenuOverrideInput!]
        $availableLimitedQuantity: Float
    ) {
        createMenuItem(
            data: {
                restaurantCode: $restaurantCode
                name: $name
                description: $description
                price: $price
                tax: $tax
                isModifierOnly: $isModifierOnly
                available: $available
                unavailableUntil: $unavailableUntil
                modifierGroupIds: $modifierGroupIds
                parentCategoryIds: $parentCategoryIds
                modifierGroupsSortOrder: $sortOrder
                imageUrl: $imageUrl
                settings: $settings
                posProperties: $posProperties
                voiceProperties: $voiceProperties
                menuOverrides: $menuOverrides
                availableLimitedQuantity: $availableLimitedQuantity
                rebuildCache: true
            }
        ) {
            id
            name
            price
            tax
            description
            isModifierOnly
            modifierGroups
            imageUrl
            availableLimitedQuantity
        }
    }
`;
export const UpdateMenuItemDocument = gql`
    mutation updateMenuItem(
        $id: Float!
        $restaurantCode: String!
        $name: String!
        $description: String
        $price: Float!
        $tax: Float
        $available: Boolean!
        $unavailableUntil: DateTime
        $imageUrl: String
        $isModifierOnly: Boolean!
        $modifierGroupIds: [Float!]!
        $parentCategoryIds: [Float!]!
        $sortOrder: [SortOrderInput!]!
        $settings: KeyValue!
        $posProperties: KeyValue!
        $voiceProperties: KeyValue!
        $menuOverrides: [MenuOverrideInput!]
        $availableLimitedQuantity: Float
    ) {
        updateMenuItem(
            menuItemId: $id
            restaurantCode: $restaurantCode
            data: {
                id: $id
                restaurantCode: $restaurantCode
                name: $name
                description: $description
                price: $price
                tax: $tax
                available: $available
                unavailableUntil: $unavailableUntil
                imageUrl: $imageUrl
                isModifierOnly: $isModifierOnly
                modifierGroupIds: $modifierGroupIds
                parentCategoryIds: $parentCategoryIds
                modifierGroupsSortOrder: $sortOrder
                settings: $settings
                posProperties: $posProperties
                voiceProperties: $voiceProperties
                menuOverrides: $menuOverrides
                availableLimitedQuantity: $availableLimitedQuantity
                rebuildCache: true
            }
        ) {
            id
            name
            price
            tax
            description
            isModifierOnly
            availableLimitedQuantity
        }
    }
`;
export const CreateModifierGroupDocument = gql`
    mutation createModifierGroup(
        $name: String!
        $description: String
        $minimumSelections: Float!
        $maximumSelections: Float!
        $restaurantCode: String!
        $childMenuItemIds: [Float!]!
        $sortOrder: [SortOrderInput!]!
        $menuItemOverrides: [MenuOverrideInput!]!
        $defaultSelectedItemIds: [Float!]!
        $posProperties: KeyValue!
        $voiceProperties: KeyValue!
    ) {
        createModifierGroup(
            data: {
                name: $name
                description: $description
                minimumSelections: $minimumSelections
                maximumSelections: $maximumSelections
                restaurantCode: $restaurantCode
                childMenuItemIds: $childMenuItemIds
                menuItemsSortOrder: $sortOrder
                menuItemOverrides: $menuItemOverrides
                rebuildCache: true
                defaultSelectedItemIds: $defaultSelectedItemIds
                posProperties: $posProperties
                voiceProperties: $voiceProperties
            }
        ) {
            id
            name
            minimumSelections
            maximumSelections
            description
            defaultSelectedItemIds
        }
    }
`;
export const UpdateModifierGroupDocument = gql`
    mutation updateModifierGroup(
        $id: Float!
        $name: String!
        $description: String
        $minimumSelections: Float
        $maximumSelections: Float
        $restaurantCode: String!
        $childMenuItemIds: [Float!]!
        $sortOrder: [SortOrderInput!]!
        $menuItemOverrides: [MenuOverrideInput!]!
        $defaultSelectedItemIds: [Float!]!
        $posProperties: KeyValue!
        $voiceProperties: KeyValue!
    ) {
        updateModifierGroup(
            modifierGroupId: $id
            restaurantCode: $restaurantCode
            data: {
                name: $name
                description: $description
                minimumSelections: $minimumSelections
                maximumSelections: $maximumSelections
                restaurantCode: $restaurantCode
                childMenuItemIds: $childMenuItemIds
                menuItemsSortOrder: $sortOrder
                menuItemOverrides: $menuItemOverrides
                rebuildCache: true
                defaultSelectedItemIds: $defaultSelectedItemIds
                posProperties: $posProperties
                voiceProperties: $voiceProperties
            }
        ) {
            id
            name
            minimumSelections
            maximumSelections
            description
            defaultSelectedItemIds
        }
    }
`;
export const CreateCategoryDocument = gql`
    mutation createCategory(
        $name: String!
        $description: String
        $restaurantCode: String!
        $childMenuItemIds: [Float!]!
        $sortOrder: [SortOrderInput!]!
        $timePeriodIds: [Float!]!
        $menuItemOverrides: [MenuOverrideInput!]!
        $voiceProperties: KeyValue!
    ) {
        createCategory(
            data: {
                name: $name
                description: $description
                restaurantCode: $restaurantCode
                childMenuItemIds: $childMenuItemIds
                menuItemsSortOrder: $sortOrder
                timePeriodIds: $timePeriodIds
                menuItemOverrides: $menuItemOverrides
                voiceProperties: $voiceProperties
                rebuildCache: true
            }
        ) {
            id
            name
            description
        }
    }
`;
export const UpdateCategoryDocument = gql`
    mutation updateCategory(
        $id: Float!
        $name: String!
        $description: String
        $restaurantCode: String!
        $childMenuItemIds: [Float!]!
        $sortOrder: [SortOrderInput!]!
        $timePeriodIds: [Float!]!
        $menuItemOverrides: [MenuOverrideInput!]!
        $voiceProperties: KeyValue!
    ) {
        updateCategory(
            categoryId: $id
            restaurantCode: $restaurantCode
            data: {
                name: $name
                description: $description
                restaurantCode: $restaurantCode
                childMenuItemIds: $childMenuItemIds
                menuItemsSortOrder: $sortOrder
                timePeriodIds: $timePeriodIds
                menuItemOverrides: $menuItemOverrides
                voiceProperties: $voiceProperties
                rebuildCache: true
            }
        ) {
            id
            name
            description
        }
    }
`;
export const DeleteMenuItemDocument = gql`
    mutation deleteMenuItem($id: Float!, $restaurantCode: String!) {
        deleteMenuItem(
            itemId: $id
            restaurantCode: $restaurantCode
            rebuildCache: true
        )
    }
`;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: Float!, $restaurantCode: String!) {
        deleteCategory(
            categoryId: $id
            restaurantCode: $restaurantCode
            rebuildCache: true
        )
    }
`;
export const DeleteModifierGroupDocument = gql`
    mutation deleteModifierGroup($id: Float!, $restaurantCode: String!) {
        deleteModifierGroup(
            modifierGroupId: $id
            restaurantCode: $restaurantCode
            rebuildCache: true
        )
    }
`;
export const UpdateRestaurantSettingsDocument = gql`
    mutation updateRestaurantSettings(
        $skipAdvancedPayments: Boolean
        $currency: Currency
        $isAvailableForOrdering: Boolean
        $isTabEnabled: Boolean
        $tabSize: Float
        $isPayOnlyEnabled: Boolean
        $isTaxEnabled: Boolean
        $isTipEnabled: Boolean
        $defaultTipPercentages: [Float!]
        $dineInModalityTaxRate: Float
        $deliveryModalityTaxRate: Float
        $toGoModalityTaxRate: Float
        $restaurantCode: String!
        $toGoOrderConfirmationMessage: String
        $isAlertingEnabled: Boolean
        $toGoPickupMinutes: Float
        $toGoDefaultSMSText: String
        $isUnsupervisedAI: Boolean
    ) {
        updateRestaurantSettings(
            restaurantCode: $restaurantCode
            data: {
                skipAdvancedPayments: $skipAdvancedPayments
                currency: $currency
                isAvailableForOrdering: $isAvailableForOrdering
                isTabEnabled: $isTabEnabled
                tabSize: $tabSize
                isPayOnlyEnabled: $isPayOnlyEnabled
                isTaxEnabled: $isTaxEnabled
                isTipEnabled: $isTipEnabled
                defaultTipPercentages: $defaultTipPercentages
                dineInModalityTaxRate: $dineInModalityTaxRate
                deliveryModalityTaxRate: $deliveryModalityTaxRate
                toGoModalityTaxRate: $toGoModalityTaxRate
                toGoOrderConfirmationMessage: $toGoOrderConfirmationMessage
                isAlertingEnabled: $isAlertingEnabled
                toGoPickupMinutes: $toGoPickupMinutes
                toGoDefaultSMSText: $toGoDefaultSMSText
                isUnsupervisedAI: $isUnsupervisedAI
            }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const UpdateRestaurantContactInfoDocument = gql`
    mutation updateRestaurantContactInfo(
        $email: String
        $address: String
        $city: String
        $zipCode: String
        $state: String
        $country: String
        $phoneNumber: String
        $website: String
        $restaurantCode: String!
    ) {
        updateRestaurantContactInfo(
            restaurantCode: $restaurantCode
            data: {
                email: $email
                address: $address
                city: $city
                zipCode: $zipCode
                state: $state
                country: $country
                phoneNumber: $phoneNumber
                website: $website
            }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const UpdateHoursOfOperationDocument = gql`
    mutation updateHoursOfOperation(
        $timezone: String!
        $availability: [AvailabilityInput!]!
        $restaurantCode: String!
    ) {
        updateHoursOfOperation(
            restaurantCode: $restaurantCode
            data: { timezone: $timezone, availability: $availability }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const CreateHoursOfOperationDocument = gql`
    mutation createHoursOfOperation(
        $timezone: String!
        $availability: [AvailabilityInput!]!
        $restaurantCode: String!
    ) {
        createHoursOfOperation(
            restaurantCode: $restaurantCode
            data: { timezone: $timezone, availability: $availability }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const CreateTimePeriodDocument = gql`
    mutation createTimePeriod(
        $restaurantCode: String!
        $description: String
        $startDate: DateTime
        $endDate: DateTime
        $availability: [AvailabilityInput!]!
    ) {
        createTimePeriod(
            data: {
                restaurantCode: $restaurantCode
                description: $description
                startDate: $startDate
                endDate: $endDate
                availability: $availability
                rebuildCache: true
            }
        ) {
            id
            description
            startDate
            endDate
            availability {
                day
                hours
                alwaysEnabled
            }
            timePeriodCategoryMappings
        }
    }
`;
export const DeleteTimePeriodDocument = gql`
    mutation deleteTimePeriod($id: Float!, $restaurantCode: String!) {
        deleteTimePeriod(
            timePeriodId: $id
            restaurantCode: $restaurantCode
            rebuildCache: true
        )
    }
`;
export const UpdateTimePeriodDocument = gql`
    mutation updateTimePeriod(
        $id: Float!
        $restaurantCode: String!
        $description: String
        $startDate: DateTime
        $endDate: DateTime
        $availability: [AvailabilityInput!]!
    ) {
        updateTimePeriod(
            timePeriodId: $id
            restaurantCode: $restaurantCode
            data: {
                restaurantCode: $restaurantCode
                description: $description
                startDate: $startDate
                endDate: $endDate
                availability: $availability
                rebuildCache: true
            }
        ) {
            id
            description
            startDate
            endDate
            availability {
                day
                hours
                alwaysEnabled
            }
            timePeriodCategoryMappings
        }
    }
`;
export const UpdateRestaurantBrandingDocument = gql`
    mutation updateRestaurantBranding(
        $primaryColor: String
        $secondaryColor: String
        $logoUrl: String
        $backgroundImageUrl: String
        $timezone: String
        $restaurantCode: String!
    ) {
        updateRestaurantBranding(
            restaurantCode: $restaurantCode
            data: {
                primaryColor: $primaryColor
                secondaryColor: $secondaryColor
                logoUrl: $logoUrl
                backgroundImageUrl: $backgroundImageUrl
                timezone: $timezone
            }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const UpdateCategoriesSortOrderDocument = gql`
    mutation updateCategoriesSortOrder(
        $categoriesSortOrder: [SortOrderInput!]!
        $restaurantCode: String!
    ) {
        updateCategoriesSortOrder(
            restaurantCode: $restaurantCode
            data: {
                restaurantCode: $restaurantCode
                categoriesSortOrder: $categoriesSortOrder
                rebuildCache: true
            }
        ) {
            id
            name
            description
            ownSortOrder
        }
    }
`;
export const UpdateMenuItemAvailabilityDocument = gql`
    mutation updateMenuItemAvailability(
        $restaurantCode: String!
        $id: Float!
        $available: Boolean!
        $unavailableUntil: DateTime
        $availableLimitedQuantity: Float
    ) {
        updateItemAvailability(
            restaurantCode: $restaurantCode
            data: {
                restaurantCode: $restaurantCode
                id: $id
                available: $available
                unavailableUntil: $unavailableUntil
                availableLimitedQuantity: $availableLimitedQuantity
                rebuildCache: true
            }
        ) {
            id
            name
            available
            unavailableUntil
            availableLimitedQuantity
        }
    }
`;
export const UpdateUserAdminDocument = gql`
    mutation updateUserAdmin(
        $id: Float!
        $firstName: String!
        $lastName: String!
        $email: String!
        $username: String!
        $phoneNumber: String
        $isPreferred: Boolean
        $restaurantGroupRoles: [UserRestaurantGroupRoleInput!]
    ) {
        updateUserAdmin(
            data: {
                id: $id
                firstName: $firstName
                lastName: $lastName
                email: $email
                username: $username
                phoneNumber: $phoneNumber
                isPreferred: $isPreferred
                restaurantGroupRoles: $restaurantGroupRoles
            }
        ) {
            id
            username
            email
            firstName
            lastName
            phoneNumber
            isPreferred
            restaurantGroupRoles {
                id
                role {
                    id
                    description
                    permission
                }
            }
        }
    }
`;
export const CreateUserAdminDocument = gql`
    mutation createUserAdmin(
        $username: String!
        $firstName: String!
        $lastName: String!
        $email: String!
        $phoneNumber: String
        $password: String!
        $isPreferred: Boolean
        $restaurantGroupRoles: [UserRestaurantGroupRoleInput!]
    ) {
        createUserAdmin(
            data: {
                username: $username
                firstName: $firstName
                lastName: $lastName
                email: $email
                phoneNumber: $phoneNumber
                isPreferred: $isPreferred
                password: $password
                restaurantGroupRoles: $restaurantGroupRoles
            }
        ) {
            id
            username
            email
            firstName
            lastName
            phoneNumber
            isPreferred
            restaurantGroupRoles {
                id
                role {
                    id
                    description
                    permission
                }
            }
        }
    }
`;
export const DuplicateMenuItemDocument = gql`
    mutation duplicateMenuItem(
        $restaurantCode: String!
        $sourceMenuItemId: Float!
        $version: Float
    ) {
        duplicateMenuItem(
            restaurantCode: $restaurantCode
            sourceMenuItemId: $sourceMenuItemId
            version: $version
            rebuildCache: true
        ) {
            id
        }
    }
`;
export const CreateRestaurantDocument = gql`
    mutation createRestaurant(
        $restaurantCode: String!
        $restaurantName: String!
        $accountType: RestaurantAccountType!
    ) {
        createRestaurant(
            data: {
                restaurantCode: $restaurantCode
                restaurantName: $restaurantName
                accountType: $accountType
            }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const UpdateRestaurantDocument = gql`
    mutation updateRestaurant(
        $restaurantCode: String!
        $restaurantName: String!
    ) {
        updateRestaurant(
            restaurantCode: $restaurantCode
            restaurantName: $restaurantName
        ) {
            id
            restaurantCode
            restaurantName
        }
    }
`;
export const AttachRestaurantToRestaurantGroupDocument = gql`
    mutation attachRestaurantToRestaurantGroup(
        $restaurantCode: String!
        $restaurantGroupIds: [Float!]!
    ) {
        attachRestaurantToRestaurantGroup(
            data: {
                restaurantCode: $restaurantCode
                restaurantGroupIds: $restaurantGroupIds
            }
        ) {
            id
            restaurantName
            restaurantCode
        }
    }
`;
export const CreateRestaurantGroupDocument = gql`
    mutation createRestaurantGroup(
        $groupName: String!
        $restaurantCodes: [String!]!
    ) {
        createRestaurantGroup(
            data: { groupName: $groupName, restaurantCodes: $restaurantCodes }
        ) {
            id
            groupName
        }
    }
`;
export const UpdateRestaurantGroupDocument = gql`
    mutation updateRestaurantGroup(
        $id: Float!
        $groupName: String!
        $restaurantCodes: [String!]!
    ) {
        updateRestaurantGroup(
            data: {
                id: $id
                groupName: $groupName
                restaurantCodes: $restaurantCodes
            }
        ) {
            id
            groupName
        }
    }
`;
export const DeleteRestaurantGroupDocument = gql`
    mutation deleteRestaurantGroup($id: Float!) {
        deleteRestaurantGroup(id: $id)
    }
`;
export const QueryPrimaryRestaurantsDocument = gql`
    query queryPrimaryRestaurants {
        primaryRestaurants {
            restaurantCode
        }
    }
`;
export const UpdatePrimaryRestaurantDocument = gql`
    mutation updatePrimaryRestaurant(
        $restaurantCode: String!
        $primaryRestaurantCode: String!
    ) {
        updatePrimaryRestaurant(
            restaurantCode: $restaurantCode
            primaryRestaurantCode: $primaryRestaurantCode
        ) {
            id
            restaurantCode
            restaurantName
        }
    }
`;
export const ReloadCacheDocument = gql`
    mutation reloadCache($restaurantCode: String!) {
        clearCache(restaurantCode: $restaurantCode)
    }
`;
export const UploadRawMenuFileDocument = gql`
    mutation uploadRawMenuFile($file: Upload!, $restaurantCode: String!) {
        rawMenuFileUpload(file: $file, restaurantCode: $restaurantCode)
    }
`;
export const DuplicateModifierGroupDocument = gql`
    mutation duplicateModifierGroup(
        $restaurantCode: String!
        $sourceModifierGroupId: Float!
        $version: Float
    ) {
        duplicateModifierGroup(
            restaurantCode: $restaurantCode
            sourceModifierGroupId: $sourceModifierGroupId
            version: $version
            rebuildCache: true
        ) {
            id
        }
    }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();
export function getSdk(
    client: GraphQLClient,
    withWrapper: SdkFunctionWrapper = defaultWrapper
) {
    return {
        loginQuery(
            variables: LoginQueryQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: LoginQueryQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<LoginQueryQuery>(
                    print(LoginQueryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        restaurantInfo(
            variables: RestaurantInfoQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: RestaurantInfoQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<RestaurantInfoQuery>(
                    print(RestaurantInfoDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        authStatus(
            variables?: AuthStatusQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: AuthStatusQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<AuthStatusQuery>(
                    print(AuthStatusDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        startPasswordReset(
            variables: StartPasswordResetMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: StartPasswordResetMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<StartPasswordResetMutation>(
                    print(StartPasswordResetDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        changePassword(
            variables: ChangePasswordMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: ChangePasswordMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<ChangePasswordMutation>(
                    print(ChangePasswordDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        completePasswordReset(
            variables: CompletePasswordResetMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CompletePasswordResetMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CompletePasswordResetMutation>(
                    print(CompletePasswordResetDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        verifyGoogleAuthToken(
            variables: VerifyGoogleAuthTokenQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: VerifyGoogleAuthTokenQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<VerifyGoogleAuthTokenQuery>(
                    print(VerifyGoogleAuthTokenDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        mfLoginQuery(
            variables: MfLoginQueryQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: MfLoginQueryQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<MfLoginQueryQuery>(
                    print(MfLoginQueryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        duplicateCategory(
            variables: DuplicateCategoryMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DuplicateCategoryMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DuplicateCategoryMutation>(
                    print(DuplicateCategoryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        uploadImage(
            variables: UploadImageMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UploadImageMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UploadImageMutation>(
                    print(UploadImageDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        getMenu(
            variables: GetMenuQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: GetMenuQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<GetMenuQuery>(
                    print(GetMenuDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        userManagementQuery(
            variables?: UserManagementQueryQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UserManagementQueryQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UserManagementQueryQuery>(
                    print(UserManagementQueryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        addMenuItem(
            variables: AddMenuItemMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: AddMenuItemMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<AddMenuItemMutation>(
                    print(AddMenuItemDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateMenuItem(
            variables: UpdateMenuItemMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateMenuItemMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateMenuItemMutation>(
                    print(UpdateMenuItemDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createModifierGroup(
            variables: CreateModifierGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateModifierGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateModifierGroupMutation>(
                    print(CreateModifierGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateModifierGroup(
            variables: UpdateModifierGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateModifierGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateModifierGroupMutation>(
                    print(UpdateModifierGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createCategory(
            variables: CreateCategoryMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateCategoryMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateCategoryMutation>(
                    print(CreateCategoryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateCategory(
            variables: UpdateCategoryMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateCategoryMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateCategoryMutation>(
                    print(UpdateCategoryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        deleteMenuItem(
            variables: DeleteMenuItemMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DeleteMenuItemMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DeleteMenuItemMutation>(
                    print(DeleteMenuItemDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        deleteCategory(
            variables: DeleteCategoryMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DeleteCategoryMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DeleteCategoryMutation>(
                    print(DeleteCategoryDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        deleteModifierGroup(
            variables: DeleteModifierGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DeleteModifierGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DeleteModifierGroupMutation>(
                    print(DeleteModifierGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateRestaurantSettings(
            variables: UpdateRestaurantSettingsMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateRestaurantSettingsMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateRestaurantSettingsMutation>(
                    print(UpdateRestaurantSettingsDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateRestaurantContactInfo(
            variables: UpdateRestaurantContactInfoMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateRestaurantContactInfoMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateRestaurantContactInfoMutation>(
                    print(UpdateRestaurantContactInfoDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateHoursOfOperation(
            variables: UpdateHoursOfOperationMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateHoursOfOperationMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateHoursOfOperationMutation>(
                    print(UpdateHoursOfOperationDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createHoursOfOperation(
            variables: CreateHoursOfOperationMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateHoursOfOperationMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateHoursOfOperationMutation>(
                    print(CreateHoursOfOperationDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createTimePeriod(
            variables: CreateTimePeriodMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateTimePeriodMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateTimePeriodMutation>(
                    print(CreateTimePeriodDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        deleteTimePeriod(
            variables: DeleteTimePeriodMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DeleteTimePeriodMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DeleteTimePeriodMutation>(
                    print(DeleteTimePeriodDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateTimePeriod(
            variables: UpdateTimePeriodMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateTimePeriodMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateTimePeriodMutation>(
                    print(UpdateTimePeriodDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateRestaurantBranding(
            variables: UpdateRestaurantBrandingMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateRestaurantBrandingMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateRestaurantBrandingMutation>(
                    print(UpdateRestaurantBrandingDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateCategoriesSortOrder(
            variables: UpdateCategoriesSortOrderMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateCategoriesSortOrderMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateCategoriesSortOrderMutation>(
                    print(UpdateCategoriesSortOrderDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateMenuItemAvailability(
            variables: UpdateMenuItemAvailabilityMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateMenuItemAvailabilityMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateMenuItemAvailabilityMutation>(
                    print(UpdateMenuItemAvailabilityDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateUserAdmin(
            variables: UpdateUserAdminMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateUserAdminMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateUserAdminMutation>(
                    print(UpdateUserAdminDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createUserAdmin(
            variables: CreateUserAdminMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateUserAdminMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateUserAdminMutation>(
                    print(CreateUserAdminDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        duplicateMenuItem(
            variables: DuplicateMenuItemMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DuplicateMenuItemMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DuplicateMenuItemMutation>(
                    print(DuplicateMenuItemDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createRestaurant(
            variables: CreateRestaurantMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateRestaurantMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateRestaurantMutation>(
                    print(CreateRestaurantDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateRestaurant(
            variables: UpdateRestaurantMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateRestaurantMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateRestaurantMutation>(
                    print(UpdateRestaurantDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        attachRestaurantToRestaurantGroup(
            variables: AttachRestaurantToRestaurantGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: AttachRestaurantToRestaurantGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<AttachRestaurantToRestaurantGroupMutation>(
                    print(AttachRestaurantToRestaurantGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        createRestaurantGroup(
            variables: CreateRestaurantGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: CreateRestaurantGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<CreateRestaurantGroupMutation>(
                    print(CreateRestaurantGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updateRestaurantGroup(
            variables: UpdateRestaurantGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdateRestaurantGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdateRestaurantGroupMutation>(
                    print(UpdateRestaurantGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        deleteRestaurantGroup(
            variables: DeleteRestaurantGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DeleteRestaurantGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DeleteRestaurantGroupMutation>(
                    print(DeleteRestaurantGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        queryPrimaryRestaurants(
            variables?: QueryPrimaryRestaurantsQueryVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: QueryPrimaryRestaurantsQuery | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<QueryPrimaryRestaurantsQuery>(
                    print(QueryPrimaryRestaurantsDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        updatePrimaryRestaurant(
            variables: UpdatePrimaryRestaurantMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UpdatePrimaryRestaurantMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UpdatePrimaryRestaurantMutation>(
                    print(UpdatePrimaryRestaurantDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        reloadCache(
            variables: ReloadCacheMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: ReloadCacheMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<ReloadCacheMutation>(
                    print(ReloadCacheDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        uploadRawMenuFile(
            variables: UploadRawMenuFileMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: UploadRawMenuFileMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<UploadRawMenuFileMutation>(
                    print(UploadRawMenuFileDocument),
                    variables,
                    requestHeaders
                )
            );
        },
        duplicateModifierGroup(
            variables: DuplicateModifierGroupMutationVariables,
            requestHeaders?: Headers
        ): Promise<{
            data?: DuplicateModifierGroupMutation | undefined;
            extensions?: any;
            headers: Headers;
            status: number;
            errors?: GraphQLError[] | undefined;
        }> {
            return withWrapper(() =>
                client.rawRequest<DuplicateModifierGroupMutation>(
                    print(DuplicateModifierGroupDocument),
                    variables,
                    requestHeaders
                )
            );
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
