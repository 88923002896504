import { call, put, select } from "redux-saga/effects";
import { RootState } from "../reducers/rootReducer";
import auth0 from "../pages/Auth/Auth0/auth0";
import { getTokenSelector } from "../selectors/user";
import { ERROR_ACTIONS } from "../reducers/errorReducer";

export const getAuthInfo = function* getAuthInfo() {
    const authInfo: { isAuth0: boolean; token: any } = {
        isAuth0: false,
        token: "",
    };
    authInfo.isAuth0 = yield select((state: RootState) => state.user.isAuth0);
    if (authInfo.isAuth0) {
        try {
            yield call([auth0, auth0.getTokenSilently]);
            authInfo.token = (yield call([
                auth0,
                auth0.getIdTokenClaims,
            ])).__raw;
        } catch (error) {
            error.message += " Please try login again";
            yield put(ERROR_ACTIONS.loginFailure([error]));
        }
    } else {
        authInfo.token = yield select(getTokenSelector);
    }
    return authInfo;
};
