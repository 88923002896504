import { useLocation } from "react-router-dom";
import { isNumber } from "../utils/helper-functions";

export default function useCurrentRouteEditor(componentName: string = "") {
    const { pathname } = useLocation();
    if (pathname.indexOf(componentName) !== -1) {
        const lastPart = pathname?.substring(pathname?.lastIndexOf("/") + 1);
        return isNumber(lastPart) || lastPart === "new";
    }
    return false;
}
