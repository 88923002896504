import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { getRestaurantAccessToken } from "../utils/local-storage";

export const isResetCodeSentSelector = (state: RootState) =>
    state.user.isResetCodeSent;
export const resetPasswordCompleteCodeSelector = (state: RootState) =>
    state.user.resetPasswordSuccessCode;

export const userSelector = (state: RootState) => state.user;
export const restaurantSelector = (state: RootState) => state.restaurant;
export const currentUserSelector = (state: RootState) => state.user.userProfile;
export const tabActiveSelector = (state: RootState) => state.user.tabActive;

export const activeUserSessionSelector = createSelector(
    userSelector,
    (user) => user?.userSessions
);
export const currentUserNameSelector = createSelector(
    userSelector,
    (user) => user?.userProfile?.username
);
export const qrCodeURLSelector = createSelector(
    userSelector,
    (user) => user?.qrCodeURL
);
export const isProfileLoadedSelector = createSelector(
    userSelector,
    (user) => !!user?.userProfile
);
export const getTokenSelector = createSelector(
    userSelector,
    restaurantSelector,
    (user, restaurant) => {
        if (!user.token) {
            const { selectedRestaurantCode } = restaurant;
            const restaurantToken = getRestaurantAccessToken(
                selectedRestaurantCode || ""
            );
            return restaurantToken || null;
        }
        return user.token;
    }
);
