import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { DELAY } from "../constants";
import {
    deleteUserSessionAction,
    USER_ACTIONS,
    auth0LogOutRequestAction,
} from "../reducers/userReducer";
import { getSessionID } from "../utils/local-storage";
import { RootState } from "../reducers/rootReducer";

export default function useLogout() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const isAuth0 = useSelector((state: RootState) => {
        return state.user.isAuth0;
    });
    const clearSession = () => {
        if (isAuth0) {
            dispatch({
                type: auth0LogOutRequestAction.toString(),
            });
        } else {
            const sessionId = getSessionID();
            dispatch({
                type: deleteUserSessionAction.toString(),
                payload: sessionId,
            });
            dispatch({ type: USER_ACTIONS.logout.toString() });
        }
    };

    const logout = (message?: string) => {
        if (message) {
            alert.info(message, { timeout: DELAY, onClose: clearSession });
        } else {
            clearSession();
        }
    };

    return {
        logout,
    };
}
