import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { MaterialTableProps } from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { RestaurantGroup } from "../../generated-interfaces/graphql";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    restaurantGroupTableSelector,
    TreeRestaurantGroup,
} from "../../selectors/userManagement";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../utils/data-tables";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export default function Restaurants() {
    const classes = useStyles();
    const history = useHistory();
    const restaurantGroupTable = useSelector(restaurantGroupTableSelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const restaurantTableConfig: MaterialTableProps<TreeRestaurantGroup> = {
        columns: [
            { title: "Restaurant Group Name", field: "groupName" },
            {
                title: "# of Users",
                render: (data) => {
                    return data.attachedUsers.length;
                },
            },
            {
                title: "# of Restaurants",
                render: (data) => {
                    return data.restaurants.length;
                },
            },
        ],
        data: Object.values(restaurantGroupTable).map((o) => ({ ...o })),
        actions: [
            {
                icon: EditIcon,
                tooltip: "Edit Restaurant Group",
                onClick: (event, rowData) => {
                    history.push(
                        `/${restaurantCode}/user-management/restaurant-groups/${
                            (rowData as RestaurantGroup).id
                        }`
                    );
                },
            },
        ],
        options: {
            actionsColumnIndex: -1,
        },
    };

    return (
        <div>
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        Restaurant Groups
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        component={Link}
                        className={classes.newBtn}
                        to={"restaurant-groups/new"}
                        disableElevation
                    >
                        New group
                    </Button>
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...restaurantTableConfig} />
        </div>
    );
}
