import { Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialTableProps } from "material-table";
import * as React from "react";
import { useSelector } from "react-redux";
import { MenuType } from "../../../constants/menu";
import { duplicateModifierGroup } from "../../../reducers/menuReducer";
import { ComponentType } from "../../../types/menu";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../../utils/data-tables";
import {
    ModifierGroupWithMenuItems,
    modifierGroupWithMenuItemsWithUPsellFilterSelector,
    renderTableCollectionText,
} from "../../../utils/menu";
import AddNewItem from "../items/AddNewItem";
import RowActions from "../RowActions";
import ItemToolbar from "../items/ItemToolbar";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export function ModifierGroups() {
    const classes = useStyles();
    const modifierGroups = useSelector(
        modifierGroupWithMenuItemsWithUPsellFilterSelector
    );

    const modifierGroupsTableConfig: MaterialTableProps<ModifierGroupWithMenuItems> = {
        components: {
            Toolbar: (props) => <ItemToolbar toolbarProps={props} />,
        },
        columns: [
            { title: "Display Name", field: "name" },
            {
                title: "POS ID",
                field: "posId",
            },
            {
                title: "POS Name",
                field: "posName",
            },
            {
                title: "Contains",
                field: "childMenuItems",
                render: (data) =>
                    renderTableCollectionText(data.childMenuItems),
            },
            {
                title: "Items Using",
                field: "parentMenuItems",
                render: (data) =>
                    renderTableCollectionText(data.parentMenuItems),
            },
            {
                title: "Description",
                field: "description",
            },
            {
                title: "Actions",
                render: (data) => {
                    return (
                        <RowActions
                            data={data}
                            duplicateAction={duplicateModifierGroup}
                            componentType={ComponentType.modifier}
                            type={MenuType.modifier}
                        />
                    );
                },
                align: "right",
            },
        ],
        data: Object.values(modifierGroups).map((o) => ({ ...o })), // Shallow copy objects b/c they are immutable
    };

    return (
        <div id="modifier-groups">
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        Modifier Groups
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <AddNewItem type={MenuType.modifier} />
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...modifierGroupsTableConfig} />
        </div>
    );
}
