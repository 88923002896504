import QueryString from "query-string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    selectCopyInProgress,
    selectRestorationInProgress,
} from "../selectors/menu";
import {
    restaurantInfoSelector,
    selectedRestaurantAccessSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { MenuStages } from "../types/menuVersion";
import { RolePermissions } from "../types/restaurant";
import { readEnvVariable } from "../utils/helper-functions";
import { hasRoleAccess } from "../utils/restaurants";

export default function useReadOnly() {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isMirrorRestro, setIsMirrorRestro] = useState(false);
    const selectedRestaurant = useSelector(restaurantInfoSelector);
    const restorationInProgress = useSelector(selectRestorationInProgress);
    const copyInProgress = useSelector(selectCopyInProgress);
    const selectedStage = useSelector(selectedStageSelector);
    const { search } = useLocation();
    const queryParams = QueryString.parse(search);
    const menuVersion = queryParams["version"];
    const selectedRestaurantAccessLevel = useSelector(
        selectedRestaurantAccessSelector
    );

    useEffect(() => {
        const hasPrimaryRestro = !!selectedRestaurant?.primaryRestaurantCode;
        const isMenuVersionFeatureFlagEnabled =
            readEnvVariable("FEATURE_FLAG_MENU_VERSION") === "TRUE";
        setIsMirrorRestro(hasPrimaryRestro);
        setIsReadOnly(
            isMenuVersionFeatureFlagEnabled &&
                (!hasRoleAccess(
                    RolePermissions.RestaurantManager,
                    selectedRestaurantAccessLevel
                ) ||
                    selectedStage !== MenuStages.PLAYGROUND ||
                    !!menuVersion ||
                    restorationInProgress ||
                    copyInProgress ||
                    hasPrimaryRestro)
        );
    }, [
        selectedRestaurant,
        selectedStage,
        menuVersion,
        restorationInProgress,
        copyInProgress,
        selectedRestaurantAccessLevel,
    ]);

    return { isReadOnly, isMirrorRestro, setIsReadOnly };
}
