import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import React, { ReactNode } from "react";

interface Props {
    title?: string;
    content: string | ReactNode;
    open: boolean;
    onSuccess: () => void;
    onCancel?: () => void;
    confirmText?: string;
    cancelText?: string;
    showCancelButton?: boolean;
}

const DEFAULT_CONFIRM_TEXT = "Yes, discard";
const DEFAULT_CANCEL_TEXT = "No, go back";

export default function ConfirmDialog(props: Props) {
    return (
        <div>
            <Dialog open={props.open}>
                {props.title && <DialogTitle>{props.title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText>{props.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="text"
                        disableElevation
                        onClick={props.onSuccess}
                    >
                        {props.confirmText || DEFAULT_CONFIRM_TEXT}
                    </Button>
                    {props.showCancelButton && (
                        <Button
                            color="primary"
                            variant="text"
                            disableElevation
                            onClick={props.onCancel}
                        >
                            {props.cancelText || DEFAULT_CANCEL_TEXT}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmDialog.defaultProps = {
    showCancelButton: true,
};
