enum KeyboardKeys {
    ARROW_DOWN = "ArrowDown",
    ARROW_UP = "ArrowUp",
    ARROW_LEFT = "ArrowLeft",
    ARROW_RIGHT = "ArrowRight",
    HOME = "Home",
    END = "End",
    TAB = "Tab",
    ENTER = "Enter",
}
const FORCE_RELOAD_CHECK_TIME = 60_000;
const IDLE_TIME = 1000 * 60 * 60 * 24; // timeout to 24 hours

export { KeyboardKeys, FORCE_RELOAD_CHECK_TIME, IDLE_TIME };

export enum NodeEnv {
    production = "PRODUCTION",
    staging = "STAGING",
    development = "DEVELOPMENT",
    sandbox = "SANDBOX",
    test = "TEST",
    bjs = "BJS",
}
