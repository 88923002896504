import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { ReactNode, useCallback } from "react";
import { ILayoutProps } from "react-dropzone-uploader";
import { StringOrNull } from "../../utils/types";

const useFileUploadLayoutStyles = makeStyles(
    ({ breakpoints, spacing }: Theme) => ({
        dropzoneContainer: {
            position: "relative",
            textAlign: "center",
            display: "flex",
            [breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
        contentContaier: {
            flex: 1,
            textAlign: "left",
            marginLeft: spacing(5),
            [breakpoints.down("xs")]: {
                marginLeft: 0,
                marginTop: spacing(4),
            },
        },
        title: {
            fontWeight: "bold",
            marginBottom: spacing(2.5),
        },
        deleteFile: {
            display: "flex",
            marginTop: spacing(3),
        },
    })
);

interface IFileUploadLayoutProps extends ILayoutProps {
    isUploading: boolean;
    fileUrl?: StringOrNull;
    deleteOriginalFile?: () => void;
    instructions?: ReactNode;
    title: string;
    onDeleteComplete?: Function;
}

const FileUploadLayout = ({
    input,
    previews,
    deleteOriginalFile,
    fileUrl,
    title,
    dropzoneProps,
    files,
    extra: { maxFiles },
    instructions,
    isUploading,
    onDeleteComplete,
}: IFileUploadLayoutProps) => {
    const classes = useFileUploadLayoutStyles();

    const handleDeleteFile = useCallback(() => {
        if (files.length) {
            files.forEach((f) => f.remove());
        } else if (fileUrl) {
            deleteOriginalFile?.();
        }
        onDeleteComplete?.();
    }, [deleteOriginalFile, fileUrl, files]);
    return (
        <div className={classes.dropzoneContainer}>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            <div className={classes.contentContaier}>
                <Typography className={classes.title}>{title}</Typography>
                {instructions}
                <div className={classes.deleteFile}>
                    {(files.length || fileUrl) && !isUploading && (
                        <Button
                            disableElevation
                            color="secondary"
                            variant="contained"
                            onClick={handleDeleteFile}
                        >
                            Delete File
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileUploadLayout;
