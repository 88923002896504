import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import * as React from "react";
import { useSelector } from "react-redux";
import { MenuType } from "../../../constants/menu";
import { duplicateCategory } from "../../../reducers/menuReducer";
import { ComponentType } from "../../../types/menu";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../../utils/data-tables";
import {
    menuTreeSelector,
    renderTableCollectionText,
    TreeCategory,
} from "../../../utils/menu";
import AddNewItem from "../items/AddNewItem";
import RowActions from "../RowActions";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export function Categories() {
    const classes = useStyles();
    const menuTree = useSelector(menuTreeSelector);

    const categoriesTableConfig: MaterialTableProps<TreeCategory> = {
        columns: [
            { title: "Display Name", field: "name" },
            {
                title: "Meal Periods",
                render: (data) => {
                    return renderTableCollectionText(
                        data.timePeriods,
                        3,
                        "description"
                    );
                },
            },
            {
                title: "Menu Items",
                field: "menuItemObjects",
                render: (data) => renderTableCollectionText(data.children),
            },
            {
                title: "Actions",
                render: (data) => {
                    return (
                        <RowActions
                            data={data}
                            duplicateAction={duplicateCategory}
                            componentType={ComponentType.category}
                            type={MenuType.category}
                        />
                    );
                },
                align: "right",
            },
        ],
        data: Object.values(menuTree).map((o) => ({ ...o })), // Shallow copy objects b/c they are immutable
    };
    return (
        <div id="categories">
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        Categories
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <AddNewItem type={MenuType.category} />
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...categoriesTableConfig} />
        </div>
    );
}
