import { useAlert } from "react-alert";
import { ComponentType, IDuplicatePayload } from "../types/menu";
import { TIME_OUT } from "../utils/constants";

export default function useDuplicatePayload() {
    const alert = useAlert();

    const generateDuplicatePayload = (
        id: string,
        type: ComponentType
    ): IDuplicatePayload => ({
        id: Number(id),
        successCallback: () => {
            alert.success(`${type} is duplicated`, {
                timeout: TIME_OUT,
            });
        },
        errorCallback: () => {
            alert.error(`Error duplicating ${type}`, {
                timeout: TIME_OUT,
            });
        },
    });

    return { generateDuplicatePayload };
}
