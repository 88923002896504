import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    AddMenuItemMutation,
    CreateCategoryMutation,
    CreateModifierGroupMutation,
    CreateTimePeriodMutation,
    DeleteCategoryMutation,
    DeleteMenuItemMutation,
    DeleteModifierGroupMutation,
    DeleteTimePeriodMutation,
    GetMenuQuery,
    MenuItem,
    MenuOverrideInput,
    SortOrderInput,
    UpdateCategoriesSortOrderInput,
    UpdateMenuItemAvailabilityInput,
} from "../generated-interfaces/graphql";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import { PosType } from "../pages/menu-ingestion/constants";
import {
    cloneMenu,
    createCategory,
    createMenuItem,
    createModifierGroup,
    createTimePeriod,
    deleteCategory,
    deleteMenuItem,
    deleteModifierGroup,
    deleteTimePeriod,
    duplicateMenuItem,
    getMenu,
    MENU_ACTIONS,
    reloadCache,
    updateCategoriesSortOrder,
    updateCategory,
    updateMenuItem,
    updateMenuItemAvailability,
    updateModifierGroup,
    updateTimePeriod,
} from "../reducers/menuReducer";
import { selectMenuApi } from "../redux/features/config/config.selector";
import {
    selectedPrimaryRestaurantCodeSelector,
    selectedRestaurantCodeSelector,
} from "../selectors/restaurant";
import {
    CategoryType,
    IDuplicatePayload,
    MenuItemType,
    ModifierGroupType,
    TimePeriodInputType,
} from "../types/menu";
import {
    ICloneMenuActionPayload,
    ICloneMenuRequestPayload,
    ICloneMenuResponse,
} from "../types/menu-ingestion";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";
import { CallbackFunction, DeleteItemType } from "../utils/types";
import { cloneMenuCall } from "../utils/webserviceAPI";
import { PerfTimer } from "../utils/timer";
import { getAuthInfo } from "./utilsSaga";
function* updateItemAvailability(
    action: PayloadAction<UpdateMenuItemAvailabilityInput>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update item availability");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateMenuItemAvailability({
            ...action.payload,
            restaurantCode,
        });
        logger.info("Successfully updated item availability", {
            duration: timer.stop(),
        });
        yield put(getMenu());
    } catch (error) {
        logger.error("Failed updating item availability", {
            error,
            duration: timer.stop(),
        });
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

/**
 * @param {Object} action - action object that holds the payload
 * @param {Object} action.payload - the payload object that holds the values used for executing the saga
 */
function* getMenuSaga(action: PayloadAction<CallbackFunction>) {
    const timer = new PerfTimer();
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback } = action?.payload || {};
    logger.debug("Attempting to get menu");
    try {
        const restaurantCode: string = yield select(
            selectedPrimaryRestaurantCodeSelector
        );
        const { token, isAuth0 } = yield call(getAuthInfo);
        const sdk = getSdk(token, isAuth0);
        const { menu }: GetMenuQuery = yield sdk.getMenu({ restaurantCode });
        const {
            categories,
            menuItems,
            menuOverrides,
            modifierGroups,
            timePeriods,
        } = menu;
        yield put(
            MENU_ACTIONS.getMenuSuccess({
                categories,
                // TODO: remove these deprecated keys after menudb remodelling phase-2 rollout
                menuItems: menuItems.map((menuItem) => ({
                    ...menuItem,
                    posProperties: [],
                    menuItemSettings: [],
                })),
                menuOverrides,
                // TODO: remove these deprecated keys after menudb remodelling phase-2 rollout
                modifierGroups: modifierGroups.map((modifierGroup) => ({
                    ...modifierGroup,
                    posProperties: [],
                })),
                timePeriods,
            })
        );
        successCallback?.();
        logger.info("Successfully got menu", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Get Menu Failed", {
            error,
            duration: timer.stop(),
        });
        errorCallback?.();
    }

    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createMenuItemSaga(
    action: PayloadAction<Omit<MenuItemType, "restaurantCode" | "id">>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create menu item");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const available = payload.available === null ? true : payload.available;
        const data: AddMenuItemMutation = yield sdk.addMenuItem({
            ...payload,
            restaurantCode,
            available,
            sortOrder: payload.modifierGroupsSortOrder as SortOrderInput[],
        });
        logger.info("Successfully created menu item", {
            duration: timer.stop(),
        });
        const menuItemId = data.createMenuItem.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)["newMenuItem"].path.replace(
                    ":id",
                    menuItemId
                )
            )
        );
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Add Menu Item Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback(error);
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* updateMenuItemSaga(
    action: PayloadAction<Omit<MenuItemType, "restaurantCode">>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update menu item");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const available = payload.available === null ? true : payload.available;
        yield sdk.updateMenuItem({
            ...payload,
            restaurantCode,
            available,
            sortOrder: payload.modifierGroupsSortOrder as SortOrderInput[],
        });
        logger.info("Successfully updated menu item", {
            duration: timer.stop(),
        });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Menu Item Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback(error);
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* addModifierGroupSaga(action: PayloadAction<ModifierGroupType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to add modifier group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const data: CreateModifierGroupMutation = yield sdk.createModifierGroup(
            {
                ...payload,
                restaurantCode,
                childMenuItemIds: payload.menuItems,
                sortOrder: payload.sortOrder as SortOrderInput[],
                menuItemOverrides: (payload as any)
                    .menuOverrides as MenuOverrideInput[],
                defaultSelectedItemIds: payload.defaultSelectedItemIds as number[],
            }
        );
        logger.info("Successfully create modifier group", {
            duration: timer.stop(),
        });
        const modGroupId = data.createModifierGroup.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "newModifierGroup"
                ].path.replace(":id", modGroupId)
            )
        );
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Modifier Group Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* updateModifierGroupSaga(action: PayloadAction<ModifierGroupType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update modifier group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateModifierGroup({
            ...payload,
            restaurantCode,
            id: parseFloat(payload.id),
            childMenuItemIds: payload.menuItems,
            sortOrder: payload.sortOrder as SortOrderInput[],
            menuItemOverrides: (payload as any)
                .menuOverrides as MenuOverrideInput[],
            defaultSelectedItemIds: payload.defaultSelectedItemIds as number[],
        });
        logger.info("Successfully updated modifier group", {
            duration: timer.stop(),
        });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Modifier Group Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* addCategorySaga(action: PayloadAction<CategoryType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to add new category");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const data: CreateCategoryMutation = yield sdk.createCategory({
            ...payload,
            restaurantCode,
            childMenuItemIds: action.payload.menuItems,
            sortOrder: action.payload.sortOrder as SortOrderInput[],
            timePeriodIds: action.payload.timePeriods,
            menuItemOverrides: (action.payload as any)
                .menuOverrides as MenuOverrideInput[],
        });
        logger.info("Successfully created a new category", {
            duration: timer.stop(),
        });
        const categoryId = data.createCategory.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)["newCategory"].path.replace(
                    ":id",
                    categoryId
                )
            )
        );
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create New Category Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* updateCategorySaga(action: PayloadAction<CategoryType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update category");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateCategory({
            ...payload,
            restaurantCode,
            id: parseFloat(action.payload.id),
            childMenuItemIds: action.payload.menuItems,
            sortOrder: action.payload.sortOrder as SortOrderInput[],
            timePeriodIds: action.payload.timePeriods,
            menuItemOverrides: (action.payload as any)
                .menuOverrides as MenuOverrideInput[],
        });
        logger.info("Successfully updated category", {
            duration: timer.stop(),
        });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Category Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* deleteMenuItemSaga(action: PayloadAction<DeleteItemType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to delete menu item");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const deleteSuccess: DeleteMenuItemMutation = yield sdk.deleteMenuItem({
            id: action.payload.id,
            restaurantCode,
        });
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.info("Successfully delete menu item", {
            duration: timer.stop(),
        });
        yield put(getMenu());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete menu item Failed", {
            error,
            duration: timer.stop(),
        });
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* deleteCategorySaga(action: PayloadAction<DeleteItemType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to delete category");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const deleteSuccess: DeleteCategoryMutation = yield sdk.deleteCategory({
            id: action.payload.id,
            restaurantCode,
        });
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.info("Successfully delete category", {
            duration: timer.stop(),
        });
        yield put(getMenu());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete category Failed", {
            error,
            duration: timer.stop(),
        });
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* deleteModifierGroupSaga(action: PayloadAction<DeleteItemType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to delete modifier group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const deleteSuccess: DeleteModifierGroupMutation = yield sdk.deleteModifierGroup(
            {
                id: action.payload.id,
                restaurantCode,
            }
        );
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.info("Successfully delete modifier group", {
            duration: timer.stop(),
        });
        yield put(getMenu());
    } catch (error) {
        logger.error("Delete modifier group Failed", {
            error,
            duration: timer.stop(),
        });
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* createTimePeriodSaga(
    action: PayloadAction<Omit<TimePeriodInputType, "id">>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create time period");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const data: CreateTimePeriodMutation = yield sdk.createTimePeriod({
            ...payload,
            restaurantCode,
            availability: payload.availability || [],
        });
        const timePeriodId = data.createTimePeriod.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "newMealPeriod"
                ].path.replace(":id", timePeriodId)
            )
        );
        logger.info("Successfully created the timer period", {
            duration: timer.stop(),
        });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Time Period Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* updateTimePeriodSaga(
    action: PayloadAction<TimePeriodInputType & { id: number }>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create time period");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateTimePeriod({
            ...payload,
            restaurantCode,
            availability: payload.availability || [],
        });
        logger.info("Successfully updated the time period", {
            duration: timer.stop(),
        });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Time Period Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* deleteTimePeriodSaga(action: PayloadAction<DeleteItemType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to delete time period");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const deleteSuccess: DeleteTimePeriodMutation = yield sdk.deleteTimePeriod(
            {
                id: action.payload.id,
                restaurantCode,
            }
        );
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.info("Successfully deleted time period", {
            duration: timer.stop(),
        });
        yield put(getMenu());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete TIme Period Failed", {
            error,
            duration: timer.stop(),
        });
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* updateCategoriesSortOrderSaga(
    action: PayloadAction<UpdateCategoriesSortOrderInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update categories sort order");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateCategoriesSortOrder({
            ...payload,
            restaurantCode,
        });
        logger.info("Successfully updated the categories sort order", {
            duration: timer.stop(),
        });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Categories Sort Order Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
        yield put(MENU_ACTIONS.setIsLoadingEntity(false));
    }
}

function* duplicateMenuItemSaga(action: PayloadAction<IDuplicatePayload>) {
    const timer = new PerfTimer();
    const {
        id: sourceMenuItemId,
        successCallback,
        errorCallback,
    } = action.payload;
    logger.debug("Duplicating MenuItem");
    const { token, isAuth0 } = yield call(getAuthInfo);
    const sdk = getSdk(token, isAuth0);
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );

        const dupMenuItemResponse: {
            duplicateMenuItem: MenuItem;
        } = yield sdk.duplicateMenuItem({
            restaurantCode,
            sourceMenuItemId,
            version: 0,
        });

        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "newMenuItemModify"
                ].path.replace(":id", dupMenuItemResponse.duplicateMenuItem.id)
            )
        );

        yield put(getMenu());
        successCallback?.();
        logger.info("Duplicate MenuItem successful", {
            duration: timer.stop(),
        });
    } catch (error) {
        errorCallback?.();
        logger.error("Duplicate MenuItem failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* reloadCacheSaga(action: PayloadAction<CallbackFunction>) {
    const { successCallback, errorCallback } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const { token, isAuth0 } = yield call(getAuthInfo);
        const sdk = getSdk(token, isAuth0);
        yield sdk.reloadCache({ restaurantCode });
        yield put(getMenu());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Error while reloading cache", error);
        if (errorCallback) errorCallback();
    }
}

function* cloneMenuSaga(action: PayloadAction<ICloneMenuActionPayload>) {
    const timer = new PerfTimer();
    const {
        sourceRestaurantCode,
        targetPosType,
        targetRawFileUrl,
        locationToken,
        apiStack,
        targetRestaurantCode,
        runValidation,
        successCallback,
        errorCallback,
    } = action.payload;
    try {
        logger.debug(
            `Cloning menu from ${sourceRestaurantCode} to ${targetRestaurantCode}`
        );

        const url: string = yield select(selectMenuApi);

        const requestPayload = {
            source_restaurant_code: sourceRestaurantCode,
            target_restaurant_code: targetRestaurantCode,
            target_pos_type: targetPosType,
            run_validation: runValidation,
        } as ICloneMenuRequestPayload;

        if (targetPosType !== PosType.brink) {
            requestPayload["target_raw_pos_files_url"] = targetRawFileUrl;
        } else {
            requestPayload["pos_details"] = {
                api_stack: apiStack,
                location_token: locationToken,
            };
        }
        const { token, isAuth0 } = yield call(getAuthInfo);
        const response: ICloneMenuResponse = yield call(cloneMenuCall, {
            requestPayload,
            url,
            token,
            isAuth0,
        });

        logger.info("Menu cloning successful", {
            duration: timer.stop(),
        });
        successCallback?.(response);
    } catch (error) {
        logger.error("Menu clone failed", {
            error: error.response,
            duration: timer.stop(),
        });
        errorCallback?.(error.response);
    }
}

export default function* menuSaga() {
    yield takeEvery(getMenu.toString(), getMenuSaga);
    yield takeEvery(createMenuItem.toString(), createMenuItemSaga);
    yield takeEvery(updateMenuItem.toString(), updateMenuItemSaga);
    yield takeEvery(deleteMenuItem.toString(), deleteMenuItemSaga);
    yield takeEvery(createModifierGroup.toString(), addModifierGroupSaga);
    yield takeEvery(createCategory.toString(), addCategorySaga);
    yield takeEvery(updateModifierGroup.toString(), updateModifierGroupSaga);
    yield takeEvery(updateCategory.toString(), updateCategorySaga);
    yield takeEvery(deleteCategory.toString(), deleteCategorySaga);
    yield takeEvery(deleteModifierGroup.toString(), deleteModifierGroupSaga);
    yield takeEvery(createTimePeriod.toString(), createTimePeriodSaga);
    yield takeEvery(updateTimePeriod.toString(), updateTimePeriodSaga);
    yield takeEvery(deleteTimePeriod.toString(), deleteTimePeriodSaga);
    yield takeEvery(
        updateCategoriesSortOrder.toString(),
        updateCategoriesSortOrderSaga
    );
    yield takeEvery(
        updateMenuItemAvailability.toString(),
        updateItemAvailability
    );
    yield takeEvery(duplicateMenuItem.toString(), duplicateMenuItemSaga);
    yield takeEvery(reloadCache.toString(), reloadCacheSaga);
    yield takeEvery(cloneMenu, cloneMenuSaga);
}
