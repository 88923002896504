import { Paper, Theme } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MaterialTableProps } from "material-table";
import React, { forwardRef } from "react";

export const TABLE_ICONS = {
    Filter: forwardRef<SVGSVGElement>((props, ref) => (
        <FilterList {...props} ref={ref} />
    )),
    FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
        <FirstPage {...props} ref={ref} />
    )),
    LastPage: forwardRef<SVGSVGElement>((props, ref) => (
        <LastPage {...props} ref={ref} />
    )),
    NextPage: forwardRef<SVGSVGElement>((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef<SVGSVGElement>((props, ref) => (
        <Clear {...props} ref={ref} />
    )),
    Search: forwardRef<SVGSVGElement>((props, ref) => (
        <Search {...props} ref={ref} />
    )),
    SortArrow: forwardRef<SVGSVGElement>((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ViewColumn: forwardRef<SVGSVGElement>((props, ref) => (
        <ViewColumn {...props} ref={ref} />
    )),
};

export const DATA_TABLE_DEFAULT_STYLING = ({ spacing, breakpoints }: Theme) => {
    return {
        header: {
            [breakpoints.down("xs")]: {
                flexDirection: "column",
                marginBottom: spacing(2),
            },
        },
        newBtn: {
            [breakpoints.down("xs")]: {
                marginTop: spacing(2),
            },
        },
    };
};

const Container = (props: any) => <Paper elevation={0} {...props} />;

export const CustomizedMaterialTable = <RowData extends Object>(
    props: MaterialTableProps<RowData>
) => {
    const defaultOptions: MaterialTableProps<RowData> = {
        ...props,
        icons: TABLE_ICONS,
        options: {
            showTitle: false,
            searchFieldAlignment: "left",
            pageSize: Math.min(
                20,
                Math.max(10, Math.max(5, props.data.length))
            ),
            ...props.options,
            searchFieldStyle: { marginLeft: "-10px", padding: 0 },
            tableLayout: "auto",
        },
        components: {
            Container,
            ...props.components,
        },
    };
    return (
        <div style={{ maxWidth: "calc(100vw - 16px)", overflow: "auto" }}>
            <MaterialTable {...defaultOptions} />
        </div>
    );
};
