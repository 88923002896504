import { MenuItem } from "../generated-interfaces/graphql";
import {
    CallbackFunction,
    ICommonRequest,
    IUserRequest,
    StringOrNull,
} from "../utils/types";
import { RequestStatus } from "./menu";

export type createMenuVersionType = {
    comment: string;
    restaurantCode: StringOrNull;
    user: Record<string, string>;
    stage: string;
    url: string;
    token: StringOrNull;
    isAuth0: boolean;
} & CallbackFunction;

export type CreateMenuVersionResponseType = {
    data: { status: string; request_id: string; message: string };
};

export enum MenuStages {
    PLAYGROUND = "PLAYGROUND",
    PRELIVE = "PRELIVE",
    LIVE = "LIVE",
    ISACTIVE = "ISACTIVE",
}

export interface IMenuHistory {
    id: string;
    creatorUsername: string;
    creatorName: string;
    publisherUsername: string;
    publisherName: string;
    commitId: string;
    updatedAt: string;
    createdAt: string;
    comment?: string;
    menuCommitUrl?: string;
    stage?: string;
    isActive: boolean;
}

export interface IMenuHistoryResponse {
    id: string;
    restaurant_code: string;
    creator_username: string;
    creator_first_name: string;
    creator_last_name: string;
    commit_id: string;
    updated_at: string;
    created_at: string;
    stage: MenuStages;
    is_active: boolean;
    publisher_username: string;
    publisher_first_name: string;
    publisher_last_name: string;
    commit_created_at: string;
    comment: string;
}

export interface IMenuHistoryActiveResponse extends IMenuHistoryResponse {
    stage: MenuStages;
    is_active: boolean;
}

export interface IMenuHistoryCommitResponse extends IMenuHistoryResponse {
    menu_commit_url: string;
    comment: string;
}

export interface IPromoteMenuVersionActionType extends CallbackFunction {
    stage?: string;
    commitId: string;
}

export interface IFetchMenuVersionActionType extends CallbackFunction {
    commitId?: string;
    stage: string;
}

export interface IMenuVersionActivityResponse extends IMenuHistoryResponse {
    stage: MenuStages;
    is_active: boolean;
    action_name: string;
}

export interface IPromoteMenuVersionCallType
    extends IPromoteMenuVersionActionType,
        IUserRequest {
    restaurantCode: StringOrNull;
}

export interface IActivateMenuVersionCallType
    extends IPromoteMenuVersionCallType {
    commitId: string;
}

export interface IRestoreDBActionType extends IPromoteMenuVersionActionType {
    commitId: string;
}

export interface IMenuVersionRequestStatus {
    id: string;
    restaurantCode?: string;
    requestId: string;
    requestType: string;
    status: RequestStatus;
    updatedAt: string;
    createdAt: string;
    commitId: string;
    menuCommitUrl?: StringOrNull;
    resultsUrl?: StringOrNull;
    humanReadableResultsUrl?: StringOrNull;
}

export interface IFetchMenuVersionRequestStatusResponse {
    id: string;
    restaurant_code: string;
    request_id: string;
    request_type: string;
    status: RequestStatus;
    updated_at: string;
    created_at: string;
    commit_id: string;
    menu_commit_url: StringOrNull;
}

export interface IMenuVersionActivity {
    restaurantCode?: string;
    updatedAt: string;
    createdAt: string;
    commitId: string;
    actionName: string;
    creatorUsername: string;
    creatorName: string;
    publisherUsername: string;
    publisherName: string;
    comment: string;
    stage?: string;
    isActive: boolean;
}

export interface IRestoreDBCallType extends IPromoteMenuVersionCallType {}

export interface IFetchRestoreDBStatusType {
    incomplete_jobs: IMenuVersionRequestStatus[];
}

export interface IFetchLatestVersionOnDBType {
    last_commit_on_db: IMenuHistoryResponse;
}

export const menuStagesMapping: Record<string, string> = {
    [MenuStages.PLAYGROUND]: "Draft",
    [MenuStages.LIVE]: "Prod",
    [MenuStages.PRELIVE]: "",
};

export const menuStagesStatusMapping: Record<string, string> = {
    [MenuStages.PLAYGROUND]: "Unverified",
    [MenuStages.PRELIVE]: "Verified",
    [MenuStages.LIVE]: "Verified",
    [MenuStages.ISACTIVE]: "Live In Prod",
};

export type persistentRequestType = {
    payload: any;
    restaurantCode: string;
    url: string;
    token: StringOrNull;
    isAuth0: boolean;
};

export type menuVersionRequestType = {
    restaurantCode: StringOrNull;
    commitId?: string;
    stage: string;
    url: string;
    token: StringOrNull;
    isAuth0: boolean;
};

export interface IFetchUnavailableItem {
    unavailable_items_id: number;
    restaurant_code: string;
    item_unique_identifier: string;
    unavailable_until: string;
    created_by_username: string;
    created_by_first_name: string;
    created_by_last_name: string;
    created_by_email: string | null;
    updated_at: string;
    created_at: string;
}

export interface IUnavailableItem {
    unavailableItemsId: number;
    restaurantCode: string;
    itemUniqueIdentifier: string;
    unavailableUntil: string;
    createdByUsername: string;
    createdByFirstName: string;
    createdByLastName: string;
    createdByEmail?: string;
    updatedAt: string;
    createdAt: string;
}

export interface ICreateUnavailableItemsRequest extends ICommonRequest {
    unavailable_until?: string;
    item_unique_identifier: string;
}

export interface IUpdateUnavailableItemsRequest
    extends ICreateUnavailableItemsRequest {
    unavailable_items_id: string;
}

export interface IDeleteUnavailableItemsRequest extends ICommonRequest {
    unavailable_items_id: string;
}

export interface IItemAvailabilityText {
    item: MenuItem;
    stage: string;
    unavailableItems: Record<string, IUnavailableItem>;
}
