import Axios from "axios";
import { UserSession } from "../types/menu";
import { ICloneMenuRequestPayload } from "../types/menu-ingestion";
import {
    ICreateUnavailableItemsRequest,
    IDeleteUnavailableItemsRequest,
    IPromoteMenuVersionCallType,
    IRestoreDBCallType,
    IUpdateUnavailableItemsRequest,
    MenuStages,
    createMenuVersionType,
    menuVersionRequestType,
    persistentRequestType,
} from "../types/menuVersion";
import {
    ITREnablementCreatePayload,
    ITREnablementUpdatePayload,
    ITTSOperationHourCommon,
    ITTSSchedulerCreatePayload,
    ITTSchedulerUpdatePayload,
} from "../types/restaurant";
import { SOURCE_MODULE } from "./constants";
import { createUuid, readEnvVariable } from "./helper-functions";
import {
    getAuthToken,
    getSessionID,
    getRestaurantAccessToken,
} from "./local-storage";
import {
    IAuditLogsInput,
    ICommonRequest,
    ICopyMenuVersionToRestaurant,
    StringOrNull,
} from "./types";

export const webserviceAxiosInstance = Axios.create({
    baseURL: readEnvVariable("WEBSERVICE_API"),
    headers: {
        "Content-Type": "application/json",
    },
});

function setAuthSourceHeader(config: any, isAuth0 = false) {
    if (isAuth0) {
        config.headers["Auth-source"] = "Auth0";
    }
}
//PRP internal db calls

export const getPRPIntCall = async ({
    restaurantCode,
    url,
    payload,
    token,
    isAuth0 = false,
}: persistentRequestType) => {
    const config: any = {
        params: { ...payload },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };

    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/persistent-menu-property/${restaurantCode}`,
        config
    );
};

export const createPRPIntCall = ({
    payload,
    restaurantCode,
    url,
    token,
    isAuth0 = false,
}: persistentRequestType) => {
    const config: any = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/persistent-menu-property/${restaurantCode}`,
        payload,
        config
    );
};

export const updatePRPIntCall = ({
    payload,
    restaurantCode,
    url,
    token,
    isAuth0 = false,
}: persistentRequestType) => {
    const config = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.put(
        `${url}/persistent-menu-property/${restaurantCode}`,
        payload,
        config
    );
};

export const deletePRPIntCall = ({
    payload,
    restaurantCode,
    url,
    token,
    isAuth0 = false,
}: persistentRequestType) => {
    const config = {
        data: payload,
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);

    return webserviceAxiosInstance.delete(
        `${url}/persistent-menu-property/${restaurantCode}`,
        config
    );
};

export const CreateMenuVersionCall = async ({
    comment,
    restaurantCode: restaurant_code,
    user,
    stage,
    url,
    token,
    isAuth0 = false,
}: createMenuVersionType) => {
    const config = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/${stage}/menu/commit`,
        {
            restaurant_code,
            request_id: createUuid(),
            user,
            comment,
        },
        config
    );
};

export const promoteMenuVersionCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    stage,
    user,
    token,
    isAuth0 = false,
}: {
    restaurantCode: StringOrNull;
    url: string;
    token: StringOrNull;
    isAuth0: boolean;
} & IPromoteMenuVersionCallType) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${readEnvVariable("MENU_API")}/${stage?.toLowerCase()}/menu/promote`,
        {
            restaurant_code,
            commit_id,
            request_id: createUuid(),
            user,
        },
        config
    );
};

export const createUserSession = async (url: string, request: UserSession) => {
    return webserviceAxiosInstance.post(url, request, {
        headers: {
            Authorization: getAuthToken(),
        },
    });
};

export const updateUserSession = async (
    url: string,
    sessionid: string,
    request: UserSession,
    token: StringOrNull
) => {
    return webserviceAxiosInstance.put(`${url}/${sessionid}`, request, {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
    });
};

export const getUserSessions = async (
    url: string,
    restaurantCodes: string,
    token: StringOrNull
) => {
    return webserviceAxiosInstance.get(
        `${url}?source_module=${SOURCE_MODULE}&restaurant_code=${restaurantCodes}&force_reload=0&active=1`,
        {
            headers: {
                Authorization:
                    token || getRestaurantAccessToken(restaurantCodes),
            },
        }
    );
};

export const getCurrentUserSessions = async (
    url: string,
    restaurantCodes: string,
    token: StringOrNull
) => {
    const sessionId = getSessionID();
    return webserviceAxiosInstance.get(
        `${url}?source_module=${SOURCE_MODULE}&restaurant_code=${restaurantCodes}&active=1&user_session_id=${sessionId}`,
        {
            headers: {
                Authorization:
                    token || getRestaurantAccessToken(restaurantCodes),
            },
        }
    );
};

export const deleteUserSession = async (
    url: string,
    sessionid: string,
    token: StringOrNull
) => {
    return webserviceAxiosInstance.delete(`${url}/${sessionid}`, {
        headers: {
            Authorization: token || getAuthToken(),
        },
    });
};

export const fetchMenuVersionRequestStatusCall = async ({
    restaurantCode: restaurant_code,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code },
        headers: {
            Authorization:
                token || getRestaurantAccessToken(restaurant_code || ""),
        },
    };

    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/${stage}/request-status`,
        config
    );
};

export const fetchMenuVersionPreSignedUrlCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code, commit_id },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);

    return webserviceAxiosInstance.get(
        `${url}/${stage.toLowerCase()}/menu`,
        config
    );
};
export const fetchCommittedMenuVersionCall = async ({
    menuUrl,
}: {
    menuUrl: string;
}) => webserviceAxiosInstance.get(menuUrl);

export const fetchMenuVersionActivityCall = async ({
    restaurantCode: restaurant_code,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/${stage}/menu-commit-stage-activity`,
        config
    );
};

export const restoreDBCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    stage,
    user,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType & IRestoreDBCallType) => {
    const config: any = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/${stage?.toLowerCase()}/reset-db`,
        {
            restaurant_code,
            commit_id,
            request_id: createUuid(),
            user,
        },
        config
    );
};

export const fetchRestoreDBStatusCall = async ({
    restaurantCode: restaurant_code,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };

    setAuthSourceHeader(config, isAuth0);

    return webserviceAxiosInstance.get(
        `${url}/${stage}/reset-db/incomplete`,
        config
    );
};

export const fetchMenuCommitStageCall = async ({
    restaurantCode: restaurant_code,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);

    return webserviceAxiosInstance.get(
        `${url}/${stage}/menu-commit-stage`,
        config
    );
};

export const fetchActiveMenuCommitStageCall = async ({
    restaurantCode: restaurant_code,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/${stage}/menu/commit/active`,
        config
    );
};

export const fetchLatestVersionOnDBCall = async ({
    restaurantCode: restaurant_code,
    stage,
    url,
    token,
    isAuth0 = false,
}: menuVersionRequestType) => {
    const config: any = {
        params: { restaurant_code },
        headers: {
            Authorization:
                token || getRestaurantAccessToken(restaurant_code || ""),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/${stage.toLowerCase()}/menu/commit/latest-on-db`,
        config
    );
};

export const copyMenuVersionToRestaurantCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    applicableRestaurants: applicable_restaurants,
    itemIds: item_ids,
    allowDuplicates: allow_duplicates,
    user,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest & ICopyMenuVersionToRestaurant) => {
    const config: any = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
        params: {
            allow_duplicates,
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/${MenuStages.PLAYGROUND.toLowerCase()}/menu/copy`,
        {
            restaurant_code,
            ...(commit_id ? { commit_id } : {}),
            applicable_restaurants,
            ...(item_ids?.length ? { item_ids } : {}),
            request_id: createUuid(),
            user,
        },
        config
    );
};

export const fetchAuditLogsCall = async ({
    restaurantCode,
    url,
    token,
    isAuth0 = false,
    ...params
}: ICommonRequest & IAuditLogsInput) => {
    const config: any = {
        params,
        headers: {
            Authorization: token || getAuthToken(),
        },
    };

    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/menu-audit-log/${restaurantCode}`,
        config
    );
};

export const fetchUnavailableItemsCall = async ({
    restaurantCode,
    url,
    token,
    isAuth0 = false,
    ...params
}: ICommonRequest) => {
    const config: any = {
        params,
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(
        `${url}/menu/restaurants/${restaurantCode}/unavailable-items`,
        config
    );
};

export const createUnavailableItemsCall = async ({
    restaurantCode,
    item_unique_identifier,
    unavailable_until,
    url,
    token,
    isAuth0 = false,
}: ICreateUnavailableItemsRequest) => {
    const config: any = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/menu/restaurants/${restaurantCode}/unavailable-items`,
        {
            item_unique_identifier,
            unavailable_until,
            request_id: createUuid(),
        },
        config
    );
};

export const updateUnavailableItemsCall = async ({
    restaurantCode,
    item_unique_identifier,
    unavailable_until,
    unavailable_items_id,
    url,
    token,
    isAuth0 = false,
}: IUpdateUnavailableItemsRequest) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.put(
        `${url}/menu/restaurants/${restaurantCode}/unavailable-items/${unavailable_items_id}`,
        {
            item_unique_identifier,
            unavailable_until,
            request_id: createUuid(),
        },
        config
    );
};

export const deleteUnavailableItemsCall = async ({
    restaurantCode,
    unavailable_items_id,
    url,
    token,
    isAuth0 = false,
}: IDeleteUnavailableItemsRequest) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.delete(
        `${url}/menu/restaurants/${restaurantCode}/unavailable-items/${unavailable_items_id}`,
        config
    );
};

export const fetchTTSHoursOfOperationCall = async ({
    restaurantCode: restaurant_code,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest) => {
    const config: any = {
        params: {
            restaurant_code,
        },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(`${url}/tts/hours_of_operation`, config);
};
export const createTTSHoursOfOperationCall = ({
    payload,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest & { payload: ITTSOperationHourCommon }) => {
    const config: any = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/tts/hours_of_operation`,
        payload,
        config
    );
};

export const updateTTSHoursOfOperationCall = ({
    payload,
    restaurantCode,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest & { payload: ITTSOperationHourCommon }) => {
    const config: any = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.put(
        `${url}/tts/hours_of_operation/${restaurantCode}`,
        payload,
        config
    );
};

export const fetchTaskRouterEnablementCall = async ({
    restaurantCode: restaurant_code,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest) => {
    const config: any = {
        params: {
            restaurant_code,
        },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(`${url}/task-router`, config);
};

export const createTaskRouterEnablementCall = ({
    payload,
    url,
    token,
    isAuth0 = false,
}: {
    payload: ITREnablementCreatePayload;
    url: string;
    token: StringOrNull;
    isAuth0: boolean;
}) => {
    const config = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(`${url}/task-router`, payload, config);
};

export const updateTaskRouterEnablementCall = ({
    payload,
    restaurantCode,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest & { payload: ITREnablementUpdatePayload }) => {
    const config: any = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.put(
        `${url}/task-router/${restaurantCode}`,
        payload,
        config
    );
};

/**
 * API call to find out whether a restaurant is AI enabled or not
 */
export const fetchAIEnablementCall = async ({
    restaurantCode,
    url,
}: ICommonRequest) => {
    const config: any = {
        headers: {
            Authorization: `Bearer ${readEnvVariable("AI_AUTH")}`,
        },
    };
    return webserviceAxiosInstance.get(
        `${url}/v1/controlpanel/${restaurantCode}/voice`,
        config
    );
};

/**
 * API call to enable the AI for a restaurant
 */
export const enableAIEnablementCall = ({
    restaurantCode,
    url,
}: ICommonRequest) => {
    const config: any = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${readEnvVariable("AI_AUTH")}`,
        },
    };
    return webserviceAxiosInstance.put(
        `${url}/v1/controlpanel/${restaurantCode}/voice`,
        {
            ai_enabled: true,
        },
        config
    );
};

/**
 * API call to disable the AI for a restaurant
 */
export const disableAIEnablementCall = ({
    restaurantCode,
    url,
}: ICommonRequest) => {
    const config: any = {
        headers: {
            Authorization: `Bearer ${readEnvVariable("AI_AUTH")}`,
        },
    };
    return webserviceAxiosInstance.put(
        `${url}/v1/controlpanel/${restaurantCode}/voice`,
        {
            ai_enabled: false,
        },
        config
    );
};

export const fetchTTSSchedulerCall = async ({
    restaurantCode: restaurant_code,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest) => {
    const config: any = {
        params: {
            restaurant_code,
        },
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.get(`${url}/tts`, config);
};

export const createTTSSchedulerCall = ({
    payload,
    url,
    token,
    isAuth0 = false,
}: {
    payload: ITTSSchedulerCreatePayload;
    url: string;
    token: StringOrNull;
    isAuth0: boolean;
}) => {
    const config = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(`${url}/tts`, payload, config);
};

export const updateTTSSchedulerCall = ({
    payload,
    restaurantCode,
    url,
    token,
    isAuth0 = false,
}: ICommonRequest & { payload: ITTSchedulerUpdatePayload }) => {
    const config: any = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.put(
        `${url}/tts/${restaurantCode}`,
        payload,
        config
    );
};

export const cloneMenuCall = async ({
    requestPayload: { target_restaurant_code, ...payload },
    url,
    token,
    isAuth0,
}: {
    url: string;
    token: StringOrNull;
    requestPayload: ICloneMenuRequestPayload;
    isAuth0: boolean;
}) => {
    const config = {
        headers: {
            Authorization: token || getAuthToken(),
        },
    };
    setAuthSourceHeader(config, isAuth0);
    return webserviceAxiosInstance.post(
        `${url}/menu/restaurants/${target_restaurant_code}/clone`,
        {
            ...payload,
            request_id: createUuid(),
        },
        config
    );
};
