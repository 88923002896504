import {
    Button,
    Chip,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Autocomplete, TextField, TextFieldProps } from "mui-rff";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import {
    CreateRestaurantGroupInput,
    UpdateRestaurantGroupInput,
} from "../../generated-interfaces/graphql";
import {
    createRestaurantGroup,
    deleteRestaurantGroup,
    updateRestaurantGroup,
} from "../../reducers/userManagementReducer";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    restaurantGroupsSelector,
    restaurantsSelector,
} from "../../selectors/userManagement";
import { Restaurant } from "../../types/restaurant";
import { TIME_OUT } from "../../utils/constants";
import { CallbackFunction } from "../../utils/types";

// TODO: Need to customize Text Field
const basicTextFieldProp: TextFieldProps = {
    name: "name",
    fullWidth: true,
    required: true,
    margin: "normal",
};

const useStyles = makeStyles((theme: Theme) => ({
    saveBtn: {
        float: "right",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
    removeBtn: {
        float: "right",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    autocomplete: {
        "& .MuiAutocomplete-clearIndicator": {
            display: "none",
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "auto",
            bottom: "5px",
        },
    },
}));

function RestaurantGroupDetail(props: Props) {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const classes = useStyles();
    const alert = useAlert();

    const [isSaved, setIsSaved] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [restaurantsForGroup, setRestaurantsForGroup] = useState<
        Restaurant[]
    >([]);
    const restaurantsGroupsMap = useSelector(restaurantGroupsSelector);
    const restaurantsMap = useSelector(restaurantsSelector);
    const restaurants = Object.values(restaurantsMap);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const initialValues = restaurantsGroupsMap[id];

    useEffect(() => {
        if (id !== "new" && initialValues) {
            const firstRestaurants = restaurants.filter((restaurant) => {
                return (
                    initialValues.restaurants.findIndex(
                        (res) =>
                            res.restaurantCode === restaurant.restaurantCode
                    ) > -1
                );
            });
            setRestaurantsForGroup([...firstRestaurants]);
            setIsSaved(false);
        }
    }, [initialValues]);

    const handleRemoveRestaurantGroup = () => {
        setShowDeleteConfirmModal(true);
    };

    const handleGoBack = (isFormDirty: boolean) => {
        if (isFormDirty) setShowBackConfirmModal(true);
        else
            history.push(
                `/${restaurantCode}/user-management/restaurant-groups`
            );
    };

    const onSubmit = (data: any) => {
        if (id === "new") {
            props.createRestaurantGroup({
                groupName: data.groupName?.trim(),
                restaurantCodes: restaurantsForGroup.map(
                    (rg) => rg.restaurantCode
                ),
                successCallback: () => {
                    alert.success("Restaurant Group Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: () => {
                    alert.error("Error Saving Restaurant Group", {
                        timeout: TIME_OUT,
                    });
                },
            });
        } else {
            props.updateRestaurantGroup({
                id: parseInt(id),
                groupName: data.groupName?.trim(),
                restaurantCodes: restaurantsForGroup.map(
                    (rg) => rg.restaurantCode
                ),
                successCallback: () => {
                    alert.success("Restaurant Group Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: () => {
                    alert.error("Error Saving Restaurant Group", {
                        timeout: TIME_OUT,
                    });
                },
            });
        }
        setIsSaved(true);
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, values, valid, dirty }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        {showDeleteConfirmModal && (
                            <ConfirmDialog
                                content="Are you sure to delete restaurant group?"
                                open={showDeleteConfirmModal}
                                onSuccess={() => {
                                    props.deleteRestaurantGroup({
                                        id: parseInt(id),
                                    });
                                    history.push(
                                        `/${restaurantCode}/user-management/restaurant-groups`
                                    );
                                }}
                                onCancel={() =>
                                    setShowDeleteConfirmModal(false)
                                }
                            />
                        )}
                        {showBackConfirmModal && (
                            <ConfirmDialog
                                title="Leave without saving?"
                                content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                                open={showBackConfirmModal}
                                onSuccess={() => {
                                    history.push(
                                        `/${restaurantCode}/user-management/restaurant-groups`
                                    );
                                }}
                                onCancel={() => setShowBackConfirmModal(false)}
                            />
                        )}
                        <IconButton onClick={() => handleGoBack(dirty)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <EntitySaveButton
                            disabled={
                                !valid ||
                                restaurantsForGroup.length === 0 ||
                                !dirty ||
                                isSaved
                            }
                            className={classes.saveBtn}
                        />
                        {id !== "new" && (
                            <Button
                                style={{ float: "right" }}
                                color="primary"
                                variant="outlined"
                                onClick={handleRemoveRestaurantGroup}
                                className={classes.removeBtn}
                                disableElevation
                            >
                                Delete Restaurant Group
                            </Button>
                        )}
                        <TextField
                            {...basicTextFieldProp}
                            name="groupName"
                            placeholder="Restaurant Group Name"
                            label="Restaurant Group Name"
                        />
                        <Typography
                            style={{ marginTop: "44px", marginBottom: "12px" }}
                        >
                            Restaurant in this group
                        </Typography>
                        <Grid container style={{ maxWidth: "600px" }}>
                            <Autocomplete
                                multiple
                                autoSelect
                                autoHighlight
                                fullWidth
                                id="restaurants"
                                name="restaurants"
                                label="Add Restaurant"
                                options={restaurants}
                                value={restaurantsForGroup}
                                onChange={(e, value) =>
                                    setRestaurantsForGroup(
                                        value as Restaurant[]
                                    )
                                }
                                getOptionLabel={(option) =>
                                    option.restaurantName
                                }
                                getOptionValue={(option) => option}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip
                                            variant="default"
                                            label={option.restaurantName}
                                            {...getTagProps({ index })}
                                        />
                                    ));
                                }}
                                className={classes.autocomplete}
                            />
                        </Grid>
                    </form>
                )}
            />
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createRestaurantGroup: (
            data: CreateRestaurantGroupInput & CallbackFunction
        ) => dispatch(createRestaurantGroup(data)),
        updateRestaurantGroup: (
            data: UpdateRestaurantGroupInput & CallbackFunction
        ) => dispatch(updateRestaurantGroup(data)),
        deleteRestaurantGroup: (data: { id: number }) =>
            dispatch(deleteRestaurantGroup(data)),
    };
};

const connected = connect(null, mapDispatchToProps);
type Props = ConnectedProps<typeof connected>;

export default connected(RestaurantGroupDetail);
