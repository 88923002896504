import { MenuItem } from "../../generated-interfaces/graphql";
import {
    IItemAvailabilityText,
    IUnavailableItem,
    MenuStages,
} from "../../types/menuVersion";
import { DATE_FORMATS } from "../../utils/constants";
import { formatDate, getStartOfTomorrow } from "../../utils/helper-functions";

export const getItemAvailabilityText = (props: {
    item: MenuItem;
    stage: string;
    unavailableItems: Record<string, IUnavailableItem>;
}) => {
    const { unavailableUntil, available } = getUnAvailableUntilAndAvailable(
        props
    );
    if (unavailableUntil) {
        return `Available from ${formatDate(
            unavailableUntil,
            DATE_FORMATS.date
        )}`;
    } else if (available) {
        return "Available";
    }
    return "Sold Out";
};

export const getUnAvailableUntilAndAvailable = ({
    item,
    stage,
    unavailableItems,
}: IItemAvailabilityText) => {
    let { unavailableUntil, name = "", available } = item || {};
    if (stage.toUpperCase() === MenuStages.LIVE && unavailableItems[name]) {
        unavailableUntil = unavailableItems[name].unavailableUntil;
        available = false;
    }
    return { unavailableUntil, available };
};

export const getUnavailableTodayLabel = (props: IItemAvailabilityText) => {
    let { unavailableUntil } = getUnAvailableUntilAndAvailable(props);
    return formatDate(
        unavailableUntil || getStartOfTomorrow(),
        DATE_FORMATS.date
    );
};

export const getItemAvailabilityValue = (
    props: IItemAvailabilityText & { isNewItem?: boolean }
) => {
    const { unavailableUntil, available } = getUnAvailableUntilAndAvailable(
        props
    );
    if (unavailableUntil) {
        return "unavailable_today";
    } else if (available || props.isNewItem) {
        return "available";
    }
    return "unavailable_indefinitely";
};

export function isPropertySetToTrue(value: string | boolean | null) {
    return value === "true" || value === true;
}
