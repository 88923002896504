import { useState } from "react";

export default function useCommitDialog() {
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen((s) => !s);
    const [isDialogDisabled, setIsDialogDisabled] = useState(false);

    return { open, toggle, isDialogDisabled, setIsDialogDisabled };
}
