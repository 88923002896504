import { GraphQLClient } from "graphql-request/dist";
import {
    getSdk as createSdk,
    SdkFunctionWrapper,
} from "../generated-interfaces/graphql";
import { readEnvVariable } from "./helper-functions";
import logger from "./logger";
import { StringOrNull } from "./types";
const AUTHORIZATION_HEADER = "Authorization";
const AUTH_SOURCE = "Auth-source";
let client: GraphQLClient | null;

interface ApiRequestResult<T> {
    data: T & { responseStatusCode: number };
    headers: Headers;
    status: number;
}

const apiCallWrapper: SdkFunctionWrapper = async <T>(
    action: () => Promise<T>
): Promise<T> => {
    const startTime = new Date().getTime();
    const result: ApiRequestResult<T> = (await action()) as any;
    const duration = new Date().getTime() - startTime;
    result.data["responseStatusCode"] = result.status;
    logger.debug("API request duration (ms)", { duration });
    return result.data;
};

function findGqlOperationName(body: BodyInit | null | undefined) {
    if (typeof body !== "string") return "Unknown";

    // Regular expression to match the operation name
    const regex = /"query"\s*:\s*"(query|mutation)\s+(\w+)/;

    // Match the regular expression against the GraphQL query
    const match = body.match(regex);

    // If match found, return the operation name
    if (match && match.length >= 3) {
        return match[2];
    }

    return "Unknown";
}

// Wrapper function around fetch to log response size
async function fetchWithLogging(
    url: string,
    options?: RequestInit
): Promise<Response> {
    try {
        const gqlOperationName = findGqlOperationName(options?.body);
        const response = await fetch(url, options);
        const contentLength = response.headers.get("content-length");

        if (contentLength !== null) {
            logger.info(`Response size: ${contentLength} bytes`, {
                contentLength,
                gqlOperationName,
            });
            return response;
        }

        const reader = response.clone().body?.getReader();

        if (!reader) {
            logger.error(
                `Response size: Unknown (Unable to get response size)`,
                { gqlOperationName }
            );
            return response;
        }

        let totalSize = 0;

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            if (value) totalSize += value.byteLength;
        }

        logger.info(
            `Response size: ${totalSize} bytes (Transfer-Encoding: chunked)`,
            {
                contentLength: totalSize,
                gqlOperationName,
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const getSdk = (token: StringOrNull, isAuth0 = false) => {
    if (!client) {
        logger.debug("Created new GraphQL Client");
        client = new GraphQLClient(`${readEnvVariable("PRP_API")}/graphql`, {
            fetch: fetchWithLogging,
        });
    }
    if (token) {
        client.setHeader(AUTHORIZATION_HEADER, token);
        if (isAuth0) {
            client.setHeader(AUTH_SOURCE, "Auth0");
        }
        logger.debug("Set authorization header in GraphQL Client");
    }
    return createSdk(client, apiCallWrapper);
};
