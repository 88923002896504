import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    USER_MANAGEMENT_ACTIONS,
    getUserManagement,
    createRestaurant,
    updateRestaurant,
    createRestaurantGroup,
    updateRestaurantGroup,
    updateUser,
    deleteRestaurantGroup,
    createUser,
} from "../reducers/userManagementReducer";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";
import {
    UpdateUserAdminInput,
    CreateRestaurantGroupInput,
    UpdateRestaurantGroupInput,
    CreateUserAdminInput,
} from "../generated-interfaces/graphql";
import { USER_MANAGEMENT_ROUTES } from "../pages/user-management/route";
import { MENU_ACTIONS } from "../reducers/menuReducer";
import {
    RestaurantDetails,
    CallbackFunction,
    DeleteItemType,
} from "../utils/types";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { PerfTimer } from "../utils/timer";
import { getAuthInfo } from "./utilsSaga";

function* userManagementSaga() {
    const timer = new PerfTimer();
    logger.debug("Attempting to get user management information");
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    try {
        const {
            restaurantGroups,
            restaurants,
            users,
            restaurantGroupsWithUsers,
            roles,
        } = yield sdk.userManagementQuery();
        yield put(
            USER_MANAGEMENT_ACTIONS.loadUserManagementSuccess({
                restaurantGroups,
                restaurants,
                users,
                restaurantGroupsWithUsers,
                roles,
            })
        );
        logger.info("Successfully got user management items", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Get user management information Failed", {
            error,
            duration: timer.stop(),
        });
    }
}

function* createUserSaga(
    action: PayloadAction<CreateUserAdminInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create user");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const data = yield sdk.createUserAdmin({
            ...payload,
        });
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        yield put(getUserManagement());
        logger.info("Successfully create user", {
            duration: timer.stop(),
        });
        const userId = data.createUserAdmin.id;
        yield put(
            push(
                USER_MANAGEMENT_ROUTES(restaurantCode)["newUser"].path.replace(
                    ":id",
                    userId
                )
            )
        );
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create User Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateUserSaga(
    action: PayloadAction<UpdateUserAdminInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update user");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        yield sdk.updateUserAdmin({
            ...payload,
        });
        yield put(getUserManagement());
        logger.info("Successfully update user", {
            duration: timer.stop(),
        });
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update User Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createRestaurantSaga(
    action: PayloadAction<RestaurantDetails & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create restaurant");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    const restaurantCodeFromStore = yield select(
        selectedRestaurantCodeSelector
    );
    try {
        const data = yield sdk.createRestaurant({
            restaurantCode: payload.restaurantCode,
            restaurantName: payload.restaurantName,
            accountType: payload.accountType,
        });
        if (action.payload.restaurantContactInfo) {
            yield sdk.updateRestaurantContactInfo({
                ...payload.restaurantContactInfo,
                restaurantCode: payload.restaurantCode,
            });
        }
        if (
            payload.restaurantGroupIds &&
            payload.restaurantGroupIds.length > 0
        ) {
            yield sdk.attachRestaurantToRestaurantGroup({
                restaurantCode: payload.restaurantCode,
                restaurantGroupIds: payload.restaurantGroupIds,
            });
        }
        yield put(getUserManagement());
        logger.info("Successfully create restaurant", {
            duration: timer.stop(),
        });
        const restaurantId = data.createRestaurant.id;
        yield put(
            push(
                USER_MANAGEMENT_ROUTES(restaurantCodeFromStore)[
                    "newRestaurant"
                ].path.replace(":id", restaurantId)
            )
        );
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Restaurant Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateRestaurantSaga(
    action: PayloadAction<RestaurantDetails & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update restaurant");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        yield sdk.updateRestaurant({
            restaurantCode: payload.restaurantCode,
            restaurantName: payload.restaurantName,
        });
        if (action.payload.restaurantContactInfo) {
            yield sdk.updateRestaurantContactInfo({
                ...payload.restaurantContactInfo,
                restaurantCode: payload.restaurantCode,
            });
        }

        yield sdk.attachRestaurantToRestaurantGroup({
            restaurantCode: payload.restaurantCode,
            restaurantGroupIds: payload.restaurantGroupIds || [],
        });

        yield put(getUserManagement());
        if (successCallback) successCallback();
        logger.info("Successfully updated restaurant", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Update Restaurant Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createRestaurantGroupSaga(
    action: PayloadAction<CreateRestaurantGroupInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to create restaurant group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    const restaurantCode = yield select(selectedRestaurantCodeSelector);
    try {
        const data = yield sdk.createRestaurantGroup({
            ...payload,
        });
        yield put(getUserManagement());
        logger.info("Successfully create restaurant group", {
            duration: timer.stop(),
        });
        const restaurantId = data.createRestaurantGroup.id;
        yield put(
            push(
                USER_MANAGEMENT_ROUTES(restaurantCode)[
                    "newRestaurantGroup"
                ].path.replace(":id", restaurantId)
            )
        );
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Restaurant Group Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateRestaurantGroupSaga(
    action: PayloadAction<UpdateRestaurantGroupInput & CallbackFunction>
) {
    const timer = new PerfTimer();
    logger.debug("Attempting to update restaurant group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        yield sdk.updateRestaurantGroup({
            ...payload,
        });
        yield put(getUserManagement());
        if (successCallback) successCallback();
        logger.info("Successfully updated restaurant group", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Update Restaurant Group Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* deleteRestaurantGroupSaga(action: PayloadAction<DeleteItemType>) {
    const timer = new PerfTimer();
    logger.debug("Attempting to delete restaurant group");
    const { token, isAuth0 } = yield getAuthInfo();
    const sdk = getSdk(token, isAuth0);
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        yield sdk.deleteRestaurantGroup({ id: payload.id });
        yield put(getUserManagement());
        if (successCallback) successCallback();
        logger.info("Successfully deleted the restaurant group", {
            duration: timer.stop(),
        });
    } catch (error) {
        logger.error("Deleting Restaurant Group Failed", {
            error,
            duration: timer.stop(),
        });
        if (errorCallback) errorCallback();
    }
}

export default function* userSaga() {
    yield takeEvery(getUserManagement.toString(), userManagementSaga);
    yield takeEvery(createRestaurant.toString(), createRestaurantSaga);
    yield takeEvery(createUser.toString(), createUserSaga);
    yield takeEvery(updateUser.toString(), updateUserSaga);
    yield takeEvery(updateRestaurant.toString(), updateRestaurantSaga);
    yield takeEvery(
        createRestaurantGroup.toString(),
        createRestaurantGroupSaga
    );
    yield takeEvery(
        updateRestaurantGroup.toString(),
        updateRestaurantGroupSaga
    );
    yield takeEvery(
        deleteRestaurantGroup.toString(),
        deleteRestaurantGroupSaga
    );
}
