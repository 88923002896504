import { useEffect, useState } from "react";
import { InputProperties } from "../types/menu";

export default function usePosProperties<
    T extends { posPropertyMap: Record<string, string> }
>(entity: T | undefined) {
    const posPropertyMap = entity?.posPropertyMap;
    const [posProperties, setPosProperties] = useState<InputProperties[]>([]);

    useEffect(() => {
        const posPropsMap: InputProperties[] = [];

        for (const [key, value] of Object.entries(posPropertyMap ?? {})) {
            posPropsMap.push({
                key,
                value,
            });
        }

        setPosProperties(posPropsMap);

        return () => {
            setPosProperties([]);
        };
    }, [posPropertyMap]);

    return {
        posProperties,
        setPosProperties,
    };
}
