import { makeStyles } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    MenuItem,
    ModalityType,
    OverrideKey,
} from "../../generated-interfaces/graphql";
import { menuItemsSelector } from "../../utils/menu";
import MultiSelectAutocomplete from "../common/MultiSelectAutocomplete";
import DragItemList, { DragItemType, OverrideInput } from "./DragItemList";

type MenuItemProps = {
    label: string;
    listItemType: DragItemType;
    secondaryId?: string;
    itemList: MenuItem[];
    setItemList: (itemList: MenuItem[]) => void;
    setOverride: (
        objPrimaryKey: string,
        modalityType: ModalityType,
        override: OverrideInput
    ) => void;
    defaultSelectedItemIds?: number[];
    setDefaultSelectedItemIds?: (dds: number[]) => void;
};

const useStyles = makeStyles(({ spacing }) => ({
    drag_container_body: {
        marginBottom: spacing(7.5),
    },
}));

const DragMenuItemContainer = (props: MenuItemProps) => {
    const menuItemMap = useSelector(menuItemsSelector);
    const { drag_container_body } = useStyles();
    const {
        itemList,
        setItemList,
        defaultSelectedItemIds,
        setDefaultSelectedItemIds,
    } = props;
    const menuItems = Object.values(menuItemMap);

    const allowedItemsFilter = (menuItem: MenuItem) => {
        if (
            props.listItemType === "menuItemForCategory" &&
            menuItem.isModifierOnly
        ) {
            return false;
        }
        return true;
    };
    const allowedMenuItems = menuItems.filter(allowedItemsFilter);

    const getItemsTitle = useCallback(
        ({ name, id } = {}) => `${id} - ${name}`,
        []
    );

    const handleRemoveFromList = (id: string) => {
        const newItemList = [...itemList];
        const index = newItemList.findIndex((item) => item?.id === id);
        newItemList.splice(index, 1);
        setItemList([...newItemList]);

        // resetting override values
        props.setOverride(id, ModalityType.Dinein, {
            objectPrimaryKey: id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Dinein,
        });
        props.setOverride(id, ModalityType.Togo, {
            objectPrimaryKey: id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Togo,
        });
        props.setOverride(id, ModalityType.Delivery, {
            objectPrimaryKey: id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Delivery,
        });
    };

    return (
        <div className={drag_container_body}>
            <MultiSelectAutocomplete
                getItemsTitle={getItemsTitle}
                options={allowedMenuItems}
                selectedItems={itemList}
                setSelectedItems={setItemList}
                inputLabel="Add item to modifier group"
                testId="add-item-to-modifier-group"
            />
            <DragItemList
                listItemType={props.listItemType}
                secondaryId={props.secondaryId}
                setOverride={props.setOverride}
                itemList={itemList}
                setItemList={setItemList}
                removeItem={handleRemoveFromList}
                defaultSelectedItemIds={defaultSelectedItemIds}
                updateDefaultSelectedItemId={(ids: number[]) =>
                    setDefaultSelectedItemIds?.(ids)
                }
            />
        </div>
    );
};

export default memo(DragMenuItemContainer);
