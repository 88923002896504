import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { auth0HandleAuthAction } from "../../../reducers/userReducer";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../reducers/rootReducer";
import { FORM_NAMES } from "../../../utils/forms";

const Profile = ({ formErrors }: any) => {
    const dispatch = useDispatch();
    useEffect(() => {
        function getAuthDetails() {
            if (
                window.location.search.includes("code=") &&
                window.location.search.includes("state=")
            ) {
                dispatch(auth0HandleAuthAction());
            }
        }
        getAuthDetails();
    }, [dispatch]);

    if (formErrors && formErrors.errorMessage) {
        return <Redirect to="/login"></Redirect>;
    }
    const style = {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Prompt",
    };
    return (
        <div style={style}>
            <h1>Logging you in ...</h1>
        </div>
    );
};
const mapStateToProps = (state: RootState) => {
    return {
        formErrors: state.error.formErrors[FORM_NAMES.loginForm],
    };
};
const connected = connect(mapStateToProps);

export default connected(Profile);
