import { Link } from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import React, { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../components/menuVersion/CustomChip";
import { fetchMenuVersionRequestStatus } from "../../reducers/menuReducer";
import { selectMenuVersionRequestStatus } from "../../selectors/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { IMenuVersionRequestStatus } from "../../types/menuVersion";
import { RequestStatus } from "../../types/menu";
import { BannerColorMapping, DATE_FORMATS } from "../../utils/constants";
import { CustomizedMaterialTable } from "../../utils/data-tables";
import { formatDate } from "../../utils/helper-functions";

const MenuRequestStatus = () => {
    const requestStatus = useSelector(selectMenuVersionRequestStatus);
    const dispatch = useDispatch();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    useEffect(() => {
        dispatch(fetchMenuVersionRequestStatus({ fromRoot: false }));
    }, [dispatch, restaurantCode]);

    // Shallow copy objects b/c they are immutable
    const data = useMemo(() => requestStatus.map((o) => ({ ...o })), [
        requestStatus,
    ]);

    const menuItemsTableConfig: MaterialTableProps<IMenuVersionRequestStatus> = {
        columns: [
            {
                title: "Serial number",
                field: "id",
            },
            {
                title: "Request id",
                field: "requestId",
            },
            {
                title: "Request type",
                field: "requestType",
            },
            {
                title: "Updated on",
                field: "updatedAt",
                render: ({ updatedAt }) =>
                    formatDate(updatedAt, DATE_FORMATS.dateAndTime),
            },
            {
                title: "Status",
                field: "status",
                render: ({ status }) => (
                    <CustomChip
                        label={status}
                        customColor={BannerColorMapping[status.toUpperCase()]}
                    />
                ),
            },
            {
                title: "Version number",
                field: "commitId",
                render: ({ commitId, resultsUrl }) =>
                    resultsUrl ? (
                        <Link href={resultsUrl} target="_blank">
                            View results
                        </Link>
                    ) : (
                        commitId || "-"
                    ),
            },
            {
                title: "Onboarding report",
                field: "humanReadableResultsUrl",
                render: ({ humanReadableResultsUrl, status }) =>
                    humanReadableResultsUrl &&
                    status === RequestStatus.success ? (
                        <Link href={humanReadableResultsUrl} target="_blank">
                            Download
                        </Link>
                    ) : (
                        "-"
                    ),
            },
        ],
        data,
    };

    return (
        <div>
            <CustomizedMaterialTable {...menuItemsTableConfig} />
        </div>
    );
};

export default memo(MenuRequestStatus);
