import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducers/rootReducer";
import { rootSaga } from "./sagas/rootSaga";
import { googleAnalyticsMiddleware } from "./utils/analytics";
import { createPreserveQueryHistory } from "./utils/router";
import { config } from "./config";
import { NodeEnv } from "./constants/enums";
import logger from "./utils/logger";

export const history = createPreserveQueryHistory(createBrowserHistory, [
    "isStaffTablet",
])();
const sagaMiddleware = createSagaMiddleware();
let middleware: any = [];
if (config.NODE_ENV === NodeEnv.development) {
    middleware.push(
        ...getDefaultMiddleware({ thunk: false, serializableCheck: false })
    );
}
middleware.push(
    routerMiddleware(history),
    googleAnalyticsMiddleware,
    sagaMiddleware
);

export const getStore = (preloadedState: any) => {
    return configureStore({
        reducer: createRootReducer(history),
        middleware,
        preloadedState,
    });
};

const store = getStore({});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
    if (logger.defaultMeta) {
        logger.defaultMeta.restaurantCode = store.getState().restaurant.selectedRestaurantCode;
    }
});

if (config.NODE_ENV === NodeEnv.development && module.hot) {
    module.hot.accept("./reducers/rootReducer", () => {
        const newRootReducer = require("./reducers/rootReducer").default;
        store.replaceReducer(newRootReducer);
    });
}

export type AppDispatch = typeof store.dispatch;

export default store;
