import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { MaterialTableProps } from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    restaurantTreeSelector,
    TreeRestaurant,
} from "../../selectors/userManagement";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../utils/data-tables";
import { renderTableCollectionText } from "../../utils/menu";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export default function Restaurants() {
    const classes = useStyles();
    const history = useHistory();
    const restaurants = useSelector(restaurantTreeSelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const restaurantTableConfig: MaterialTableProps<TreeRestaurant> = {
        columns: [
            { title: "Restaurant Name", field: "restaurantName" },
            {
                title: "City/State",
                render: (data) => {
                    return `${data.restaurantContactInfo?.city || ""} ${
                        data.restaurantContactInfo?.state || ""
                    }`;
                },
            },
            {
                title: "Restaurant Group",
                render: (data) => {
                    return renderTableCollectionText(
                        data.restaurantGroupsMap,
                        3,
                        "groupName"
                    );
                },
            },
        ],
        data: Object.values(restaurants).map((o) => ({ ...o })),
        actions: [
            {
                icon: EditIcon,
                tooltip: "Edit Restaurant",
                onClick: (event, rowData) => {
                    history.push(
                        `/${restaurantCode}/user-management/restaurants/${
                            (rowData as TreeRestaurant).id
                        }`
                    );
                },
            },
        ],
        options: {
            actionsColumnIndex: -1,
        },
    };

    return (
        <div>
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        Restaurants
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        component={Link}
                        to={`/${restaurantCode}/user-management/restaurants/new`}
                        className={classes.newBtn}
                        disableElevation
                    >
                        New restaurant
                    </Button>
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...restaurantTableConfig} />
        </div>
    );
}
