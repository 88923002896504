import React, { HTMLProps } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tagWrapper: {
        display: "flex",
        "& > div": {
            padding: theme.spacing(0.25, 1),
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export const Tag = (props: HTMLProps<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div className={classes.tagWrapper}>
            <div {...props} />
        </div>
    );
};
