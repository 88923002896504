import { Badge, IconButton, makeStyles } from "@material-ui/core";
import { AccountCircleRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveUserSessionAction } from "../reducers/userReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { activeUserSessionSelector } from "../selectors/user";
import { UserSession } from "../types/menu";
import ActiveSessionConfirmDialog from "./ActiveSessionConfirmDialog";
import { RootState } from "../reducers/rootReducer";
const useStyles = makeStyles((theme) => ({
    badge: {
        top: "50%",
        right: -3,
        // The border color match the background color.
        border: `2px solid ${
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[900]
        }`,
    },
}));

export default function ActiveUserBadge() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const userSessions = useSelector(activeUserSessionSelector) || [];
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const openPopUp = (data: UserSession[]) => {
        if (data.length) {
            setShowDialog((preState) => !preState);
        }
    };

    const showAllActiveUsers = () => {
        if (restaurantCode) {
            dispatch(
                retrieveUserSessionAction({
                    restaurantCode,
                    successCallback: openPopUp,
                })
            );
        }
    };

    return (
        <>
            {
                <ActiveSessionConfirmDialog
                    userSessions={userSessions}
                    open={showDialog}
                    onClose={() => setShowDialog(false)}
                />
            }

            <IconButton
                title="Active Users"
                aria-label="Active Users"
                onClick={showAllActiveUsers}
            >
                <Badge
                    badgeContent={userSessions?.length}
                    color="primary"
                    classes={{ badge: classes.badge }}
                >
                    <AccountCircleRounded />
                </Badge>
            </IconButton>
        </>
    );
}
