import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { FORCE_RELOAD_CHECK_TIME, IDLE_TIME } from "../constants/enums";
import { RootState } from "../reducers/rootReducer";
import { updateHeartBeatAction } from "../reducers/userReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { useLogout } from "./";
const IDEAL_TIME_OUT_MESSAGE = "Session Timeout, Please re login";
const useApp = () => {
    const { logout } = useLogout();

    const { isLoggedIn } = useSelector((state: RootState) => state.user);
    const activeRestaurantCode = useSelector(selectedRestaurantCodeSelector);
    const [
        timeOutInterval,
        setTimeOutInterval,
    ] = useState<NodeJS.Timeout | null>();

    const dispatch = useDispatch();

    const onIdle = () => {
        logout(IDEAL_TIME_OUT_MESSAGE);
    };
    const { start, pause } = useIdleTimer({
        timeout: IDLE_TIME,
        onIdle,
        startManually: true,
    });

    const checkActiveRestaurantSession = async () => {
        try {
            if (activeRestaurantCode) {
                dispatch(updateHeartBeatAction(activeRestaurantCode));
            }
        } catch (error) {
            console.error("Failed to get restaurant session details", error);
        }
    };

    //Poll if there is any other active restaurant session
    useEffect(() => {
        if (isLoggedIn) start();
        else pause();

        if (isLoggedIn && activeRestaurantCode) {
            if (timeOutInterval) clearInterval(timeOutInterval);
            setTimeOutInterval(
                setInterval(() => {
                    checkActiveRestaurantSession();
                }, FORCE_RELOAD_CHECK_TIME)
            );
        } else {
            // @ts-ignore: As timer is not initialized
            if (timeOutInterval) clearInterval(timeOutInterval);
        }
    }, [isLoggedIn, activeRestaurantCode]);
};

export default useApp;
