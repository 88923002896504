import React from "react";
import { AppRoute } from "../../routes";
import Restaurants from "./Restaurants";
import RestaurantGropus from "./RestaurantGroups";
import Users from "./Users";
import UserDetail from "./UserDetail";
import RestaurantDetail from "./RestaurantDetail";
import RestaurantGroupDetail from "./RestaurantGroupDetail";

export const USER_MANAGEMENT_ROUTES: (
    restaurantId: string
) => { [name: string]: AppRoute } = (restaurantId: string) => {
    const PARENT_ROUTE = `/${restaurantId}/user-management`;
    return {
        restaurants: {
            title: "Restaurants",
            path: PARENT_ROUTE + "/restaurants",
            originalPath: PARENT_ROUTE + "/restaurants",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <Restaurants />,
        },
        newRestaurant: {
            title: "Restaurants",
            path: PARENT_ROUTE + "/restaurants/:id",
            originalPath: PARENT_ROUTE + "/restaurants",
            component: <RestaurantDetail />,
            protected: true,
        },
        restaurantGroups: {
            title: "Restaurant Groups",
            path: PARENT_ROUTE + "/restaurant-groups",
            originalPath: PARENT_ROUTE + "/restaurant-groups",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <RestaurantGropus />,
        },
        newRestaurantGroup: {
            title: "Restaurant Groups",
            path: PARENT_ROUTE + "/restaurant-groups/:id",
            originalPath: PARENT_ROUTE + "/restaurant-groups",
            protected: true,
            component: <RestaurantGroupDetail />,
        },
        users: {
            title: "Users",
            path: PARENT_ROUTE + "/users",
            originalPath: PARENT_ROUTE + "/users",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <Users />,
        },
        newUser: {
            title: "Users",
            path: PARENT_ROUTE + "/users/:id",
            originalPath: PARENT_ROUTE + "/users",
            protected: true,
            component: <UserDetail />,
        },
    };
};
