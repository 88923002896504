import { Currency, Maybe } from "../generated-interfaces/graphql";
import { StringOrNull } from "../utils/types";

export interface RestaurantInfo {
    sessionId: string;
    restaurantName: string;
    restaurantCode: string;
    tableNumber: string;
    branding: {
        primaryColor: string;
        secondaryColor: string;
        logoUrl: string;
        backgroundImageUrl: string;
        timezone: string;
        skip_advanced_payments: boolean;
    };
    hoursOfOperation: { [day: string]: [number, number][] };
    isAvailableForOrdering: boolean;
    requestId: string;
    accountType: string;
    isTabEnabled: boolean;
    isVaultBraintreeEnabled: boolean;
    tabSize: string;
    currency: string;
    payOnly: boolean;
    isTipEnabled: boolean;
    tips: string;
    isTaxEnabled: boolean;
    isMemoEnabled: boolean;
    isSurchargeEnabled: boolean;
    surchargeType: string;
    surcharge: string;
    isSurchargeTaxable: boolean;
    surchargeDisplayText: string;
    defaultTaxRate: string;
    isNonTabOrdersDisabled: boolean;
    isRopEnabled: boolean;
    isDineInEnabled: boolean;
    isDeliveryEnabled: boolean;
    isToGoEnabled: boolean;
    isPosEnabled: boolean;
    isBrowseOnlyEnabled: boolean;
}

export interface ITTSHour {
    startTime: string;
    endTime: string;
}

export interface ITTSHourOfOperation {
    day: number;
    hours: ITTSHour[];
}

export interface ITTSHoursOfOperation {
    day: number;
    startTime: string;
    endTime: string;
}

export type TTSHoursOfOperationInput = {
    timezone: string;
    ttsHoursOfOperations: ITTSHourOfOperation[];
};

export interface ITTSOperationHour {
    restaurantCode: string;
    timezone: string;
    ttsHoursOfOperations: ITTSHourOfOperation[];
}

export interface ITTSOperationHourCommon {
    timezone: string;
    tts_hours_of_operations: ITTSHourOfOperation[];
}

export interface ITTSOperationHourResponse extends ITTSOperationHourCommon {
    restaurant_code: string;
}

export interface ITREnablementCreatePayload {
    restaurant_code: string;
    active: number;
}

export interface ITREnablementUpdatePayload {
    active: number;
}

export interface IAIEnablementUpdatePayload {
    aiActive: boolean;
}

export interface IAIRequest {
    environment: string;
}

export interface ITTSSchedulerCreatePayload {
    restaurant_code: string;
    tts_status: string;
    scheduler_enabled: boolean;
}

export interface ITTSchedulerUpdatePayload {
    scheduler_enabled: boolean;
}

export enum RestaurantAccountType {
    Live = "LIVE",
    Demo = "DEMO",
}

export type RestaurantSettings = {
    id: string;
    disablePortalMenu: boolean;
    skipAdvancedPayments: boolean;
    currency: Currency;
    isAvailableForOrdering: boolean;
    isTabEnabled: boolean;
    tabSize: number;
    isPayOnlyEnabled: boolean;
    isTaxEnabled: boolean;
    dineInModalityTaxRate: number;
    deliveryModalityTaxRate: number;
    toGoModalityTaxRate: number;
    toGoOrderConfirmationMessage: StringOrNull;
    isTipEnabled: boolean;
    defaultTipPercentages: Array<number>;
    isAlertingEnabled: boolean;
    toGoPickupMinutes: number | null;
    toGoDefaultSMSText: StringOrNull;
    autoGratuityInPercentage: number | null;
    autoGratuityDisclaimerText: StringOrNull;
    smsVerificationText: StringOrNull;
    isUnsupervisedAI: boolean;
};

export type RestaurantBranding = {
    id: string;
    primaryColor: string;
    secondaryColor: string;
    logoUrl: StringOrNull;
    backgroundImageUrl: StringOrNull;
    timezone: string;
};

export type RestaurantContactInfo = {
    id: string;
    address: StringOrNull;
    city: StringOrNull;
    zipCode: StringOrNull;
    state: StringOrNull;
    country: StringOrNull;
    phoneNumber: StringOrNull;
    email: StringOrNull;
    website: StringOrNull;
};

export type Availability = {
    day: number;
    hours: Array<Array<number>> | null;
    alwaysEnabled: boolean;
};

export type HoursOfOperation = {
    id: string;
    timezone: string;
    availability: Maybe<Array<Availability>>;
    currentUTCTime: string;
};

export type Restaurant = {
    id: string;
    restaurantName: string;
    restaurantCode: string;
    accountType: RestaurantAccountType;
    restaurantSettings: RestaurantSettings;
    restaurantBranding: RestaurantBranding;
    restaurantContactInfo: RestaurantContactInfo;
    hoursOfOperation: HoursOfOperation | null;
    primaryRestaurantCode: StringOrNull;
};

export type UserRestaurantsRole = {
    role: RolePermissions;
    restaurants: Array<Restaurant>;
};

export enum RolePermissions {
    PrestoAdmin = "PRESTO_ADMIN",
    PrestoCustomerSupport = "PRESTO_CUSTOMER_SUPPORT",
    RestaurantManager = "RESTAURANT_MANAGER",
    RestaurantStaff = "RESTAURANT_STAFF",
    Limited = "LIMITED",
    Hitl = "HITL",
}

// This is make sure we accomodate graphQL roles.
export enum RolePermissionsGraphQlMapping {
    PrestoAdmin = "PrestoAdmin",
    PrestoCustomerSupport = "PrestoCustomerSupport",
    RestaurantManager = "RestaurantManager",
    RestaurantStaff = "RestaurantStaff",
    Limited = "LIMITED",
    Hitl = "HITL",
}
