import { IconButton, Tooltip } from "@material-ui/core";
import { Edit as EditIcon, Info as InfoIcon } from "@material-ui/icons";
import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useReadOnly } from "../hooks";

const ItemNavigation = ({ url, title }: { url: string; title: string }) => {
    const { isReadOnly } = useReadOnly();
    const updatedTitle = `${isReadOnly ? "View" : "Edit"} ${title}`;
    const { search } = useLocation();
    return (
        <div>
            <Link
                to={{ pathname: url, search }}
                data-testid="item-navigation-link"
            >
                <Tooltip title={updatedTitle} aria-label={updatedTitle}>
                    <IconButton data-testid="item-navigation-button">
                        {isReadOnly ? <InfoIcon /> : <EditIcon />}
                    </IconButton>
                </Tooltip>
            </Link>
        </div>
    );
};

export default memo(ItemNavigation);
