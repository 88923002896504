import getSymbolFromCurrency from "currency-symbol-map";

export const formatCurrencyAndAmount = (currency: string, amount: number) => {
    return `${formatCurrency(currency)}${amount.toFixed(2)}`;
};

export const formatCurrency = (currency: string) => {
    return getSymbolFromCurrency(currency) || `${currency} `;
};

export const formatCurrencyAndAmountVerbose = (
    currency: string,
    amount: number
) => {
    return `${formatCurrency(currency)}${amount.toFixed(2)}`;
};
