import {
    Button,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { matchPath, useHistory } from "react-router-dom";
import { KeyboardKeys } from "../constants/enums";
import { useLoadMenu } from "../hooks";
import useDisplayMenuVersion from "../hooks/useDisplayMenuVersion.hook";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import { MENU_ACTIONS, resetMenuVersionData } from "../reducers/menuReducer";
import { RESTAURANT_ACTIONS } from "../reducers/restaurantReducer";
import {
    restaurantInfoSelector,
    restaurantsByUserRoleSelector,
    selectedStageSelector,
} from "../selectors/restaurant";
import { menuStagesMapping } from "../types/menuVersion";
import { Restaurant } from "../types/restaurant";
import { BannerColorMapping, stagesList } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
    button: {
        color: "#E0E0E0",
        borderColor: "#203F53",
        margin: theme.spacing(1, 2),
    },
    stageButton: ({ selectedStage }: { selectedStage: string }) => {
        const color = BannerColorMapping[selectedStage];
        const styles = {
            backgroundColor: `${color}`,
            color: theme.palette.getContrastText(color),
            margin: theme.spacing(1, 2),
        };

        return {
            ...styles,
            "&:hover": styles,
        };
    },
    dropdownIcon: {
        color: "#e0e0e0",
    },
    menu: {
        "& .MuiMenu-paper": {
            width: "255px",
            maxHeight: "230px",
            top: "142px !important",
            overflow: "auto",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    menuItem: {
        overflow: "revert",
        whiteSpace: "break-spaces",
    },
    input: {
        color: "#616161",
    },
    name: {
        margin: "0 auto",
        color: theme.palette.common.white,
    },
}));

export interface IRestaurantPickerProps {
    isMouseHovered?: boolean;
    pathName: string;
    restaurantId: string;
}

const RestaurantPicker = (props: IRestaurantPickerProps) => {
    const { pathName, restaurantId } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    // To align max-with with mui theme md =1279.95
    const isStaffTablet = useMediaQuery({ query: "(max-width: 1279.95px)" });
    const restaurantsByUserRole = useSelector(restaurantsByUserRoleSelector);
    const { restaurantCode, restaurantName } =
        useSelector(restaurantInfoSelector) || {};
    const selectedStage = useSelector(selectedStageSelector);
    const classes = useStyles({ selectedStage });
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [
        anchorStageEl,
        setAnchorStageEl,
    ] = useState<HTMLButtonElement | null>(null);
    const [restaurantsMap, setRestaurantsMap] = useState<
        Record<string, Restaurant>
    >({});
    const [search, setSearch] = useState<string>("");
    const [isDetailPage, setIsDetailPage] = useState<boolean>(false);
    const menuEditorRoutes = MENU_EDITOR_ROUTES(restaurantCode || "");
    const filterRef = React.useRef<HTMLInputElement>();
    const { loadMenu } = useLoadMenu();
    const { displayMenuVersion } = useDisplayMenuVersion();

    useEffect(() => {
        setRestaurantsMap(
            restaurantsByUserRole?.reduce((a, c) => {
                c.restaurants.forEach((r) => {
                    if (!(r.restaurantCode in a)) {
                        a[r.restaurantCode] = r;
                    }
                });
                return a;
            }, {} as Record<string, Restaurant>) || {}
        );
        setIsDetailPage(
            !!Object.values(menuEditorRoutes).find((route) => {
                if (!route.rootTab) {
                    return matchPath(pathName, {
                        path: route.path,
                        exact: true,
                    });
                }
                return false;
            })
        );
    }, [restaurantsByUserRole, pathName]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStageClose = () => {
        setAnchorStageEl(null);
    };

    const handleRestaurantChange = (updatedrestaurantCode: string) => {
        if (isDetailPage) {
            history.push(
                MENU_EDITOR_ROUTES(updatedrestaurantCode)["overview"].path
            );
        } else {
            const pathList = pathName.split("/");
            pathList[1] = updatedrestaurantCode || "";
            history.push(pathList.join("/"));
        }
    };

    const restaurantsEntries = Object.entries(restaurantsMap);
    const showSearchInput = restaurantsEntries.length > 5;
    const showDropdown = restaurantsEntries.length > 1;
    const filteredRestaurantsEntries = useMemo(
        () =>
            showSearchInput && search
                ? restaurantsEntries.filter((item) => {
                      const { restaurantCode, restaurantName } = item[1] || {};
                      return [restaurantName, restaurantCode]
                          .join("(")
                          .toLocaleLowerCase()
                          .includes(search.toLocaleLowerCase());
                  })
                : restaurantsEntries,
        [restaurantsEntries, showSearchInput, search]
    );

    useEffect(() => {
        if (restaurantCode !== restaurantId) {
            dispatch(
                MENU_ACTIONS.getMenuSuccess({
                    menuItems: [],
                    modifierGroups: [],
                    categories: [],
                    timePeriods: [],
                    menuOverrides: [],
                })
            );
            dispatch({
                type: RESTAURANT_ACTIONS.selectRestaurant.toString(),
                payload: restaurantId,
                params: { data: "info" },
            });
        }
    }, [dispatch, restaurantId, restaurantCode]);

    if (!showDropdown && (!isStaffTablet || props.isMouseHovered)) {
        return (
            <Typography className={classes.name} variant="subtitle1">
                {restaurantName}
            </Typography>
        );
    }

    const handleTextFieldKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (
            ![
                KeyboardKeys.ARROW_DOWN,
                KeyboardKeys.ARROW_UP,
                KeyboardKeys.HOME,
                KeyboardKeys.END,
            ].includes(e.key as KeyboardKeys)
        ) {
            e.stopPropagation();
        }
    };

    const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
        if (
            [KeyboardKeys.TAB, KeyboardKeys.ARROW_RIGHT].includes(
                e.key as KeyboardKeys
            )
        ) {
            e.stopPropagation();
            e.preventDefault();
            if (filterRef?.current) {
                filterRef.current.focus();
            }
        }
    };

    return (
        <>
            {!isStaffTablet || props.isMouseHovered ? (
                <>
                    <Button
                        disableElevation
                        aria-controls="restaurant-picker"
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        className={classes.button}
                        variant="outlined"
                    >
                        {restaurantName}
                        <ArrowDropDownIcon />
                    </Button>
                    {displayMenuVersion && (
                        <Button
                            disableElevation
                            aria-controls="stage-picker"
                            aria-haspopup="true"
                            onClick={(event) => {
                                setAnchorStageEl(event.currentTarget);
                            }}
                            className={classes.stageButton}
                            variant="outlined"
                        >
                            {menuStagesMapping[selectedStage]}
                            <ArrowDropDownIcon />
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <IconButton
                        className={classes.dropdownIcon}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                    {displayMenuVersion && (
                        <IconButton
                            className={classes.dropdownIcon}
                            onClick={(event) => {
                                setAnchorStageEl(event.currentTarget);
                            }}
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    )}
                </>
            )}
            <Menu
                id="restaurant-picker"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
            >
                {showSearchInput && (
                    <MenuItem onKeyDown={moveFocusToInput}>
                        <TextField
                            className={classes.input}
                            value={search}
                            onKeyDown={handleTextFieldKeyDown}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                setSearch(event.currentTarget.value);
                            }}
                            InputProps={{
                                startAdornment: <SearchIcon />,
                                disableUnderline: true,
                            }}
                        />
                    </MenuItem>
                )}
                {filteredRestaurantsEntries.map(
                    ([
                        restaurantCodeFromList,
                        { restaurantName: rn, restaurantCode: rc },
                    ]) => (
                        <MenuItem
                            key={restaurantCodeFromList}
                            className={classes.menuItem}
                            onClick={() => {
                                if (restaurantCode !== restaurantCodeFromList)
                                    handleRestaurantChange(
                                        restaurantCodeFromList
                                    );

                                handleClose();
                            }}
                        >
                            <Tooltip title={rc} arrow>
                                <Typography>{`${rn} (${rc})`}</Typography>
                            </Tooltip>
                        </MenuItem>
                    )
                )}
            </Menu>
            {displayMenuVersion && (
                <Menu
                    id="stage-picker"
                    anchorEl={anchorStageEl}
                    keepMounted
                    open={Boolean(anchorStageEl)}
                    onClose={handleStageClose}
                    className={classes.menu}
                >
                    {stagesList.map(({ label, value }) => (
                        <MenuItem
                            key={value}
                            onClick={() => {
                                dispatch({
                                    type: RESTAURANT_ACTIONS.selectStage.toString(),
                                    payload: value,
                                });

                                loadMenu({
                                    stage: value,
                                    successCallback: () => {
                                        dispatch(resetMenuVersionData());
                                    },
                                });
                                handleStageClose();
                            }}
                        >
                            <Tooltip title={label} arrow>
                                <Typography>{label}</Typography>
                            </Tooltip>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export default memo(RestaurantPicker);
