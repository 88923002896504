import {
    IconButton,
    Menu,
    MenuItem as MenuDropdownItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { memo, MouseEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    MenuItem,
    UpdateMenuItemAvailabilityInput,
} from "../../generated-interfaces/graphql";
import {
    createUnavialableItems,
    deleteUnavialableItems,
    updateMenuItemAvailability,
    updateUnavialableItems,
} from "../../reducers/menuReducer";
import { selectUnavailableItems } from "../../selectors/menu";
import { selectedStageSelector } from "../../selectors/restaurant";
import { MenuStages } from "../../types/menuVersion";
import { getStartOfTomorrow } from "../../utils/helper-functions";

interface ItemAvailabilityActionsProps {
    row: MenuItem;
}

function ItemAvailabilityActions({ row }: ItemAvailabilityActionsProps) {
    const dispatch = useDispatch();
    const unavailableItems = useSelector(selectUnavailableItems);
    const stage = useSelector(selectedStageSelector);
    const isProd = stage.toUpperCase() === MenuStages.LIVE;
    const [anchorRow, setAnchorRow] = useState<null | {
        el: HTMLElement;
        row: MenuItem;
    }>(null);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>, row: MenuItem) => {
            setAnchorRow({ el: event.currentTarget, row });
        },
        []
    );

    const handleClose = useCallback(() => {
        setAnchorRow(null);
    }, []);

    const dropdownButton = (
        title: string,
        action: "unavailable_indefinitely" | "available" | "available_tomorrow"
    ) => {
        const onOptionClick = () => {
            let { available, unavailableUntil, id, name } = row;

            switch (action) {
                case "available":
                    available = true;
                    unavailableUntil = null;
                    break;

                case "available_tomorrow":
                    available = false;
                    unavailableUntil = getStartOfTomorrow();
                    break;

                case "unavailable_indefinitely":
                    available = false;
                    unavailableUntil = null;
                    break;
            }

            if (isProd) {
                const unavailableItem = unavailableItems[name];
                if (available) {
                    const {
                        unavailableItemsId,
                        itemUniqueIdentifier,
                    } = unavailableItem;
                    unavailableItem &&
                        dispatch(
                            deleteUnavialableItems({
                                unavailableItemsId,
                                itemUniqueIdentifier,
                            })
                        );
                } else {
                    const createPayload = {
                        itemUniqueIdentifier: name,
                        unavailableUntil,
                    };
                    unavailableItem
                        ? dispatch(
                              updateUnavialableItems({
                                  ...createPayload,
                                  unavailableItemsId:
                                      unavailableItem.unavailableItemsId,
                              })
                          )
                        : dispatch(createUnavialableItems(createPayload));
                }
            } else {
                const data: UpdateMenuItemAvailabilityInput = {
                    restaurantCode: "",
                    id: parseInt(id),
                    availableLimitedQuantity: null,
                    available,
                    unavailableUntil,
                    rebuildCache: true,
                };
                dispatch(updateMenuItemAvailability(data));
            }

            handleClose();
        };
        return (
            <MenuDropdownItem onClick={onOptionClick} key={title}>
                {title}
            </MenuDropdownItem>
        );
    };

    const showUnavailableBtns = isProd
        ? !unavailableItems[row.name] && row.available
        : row.available;

    const menuButtons = showUnavailableBtns
        ? [
              dropdownButton("Mark Unavailable (Today)", "available_tomorrow"),
              dropdownButton(
                  "Mark Unavailable (Indefinitely)",
                  "unavailable_indefinitely"
              ),
          ]
        : [dropdownButton("Mark Available", "available")];

    return (
        <>
            <IconButton
                onClick={(event) => handleClick(event, row)}
                data-testid={`item-availability-row-action-${row?.id}`}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorRow?.el}
                open={Boolean(anchorRow?.row)}
                onClose={handleClose}
            >
                {menuButtons}
            </Menu>
        </>
    );
}

export default memo(ItemAvailabilityActions);
