import DateFnsUtils from "@date-io/date-fns";
import {
    Divider,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";
import React, { useCallback } from "react";
import { ITTSHour, ITTSHourOfOperation } from "../../types/restaurant";
import { AVAILABILITY_STATE, DAYS } from "../../utils/constants";
import { stringWithSpecialCharToNumeric } from "../../utils/helper-functions";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    hoverBtn: {
        backgroundColor: "rgba(0,0,0,0.3)",
    },
    container: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2, 4),
        marginLeft: "-36px",
        marginRight: "-30px",
        [theme.breakpoints.down("sm")]: {
            padding: "20px 24px",
            marginLeft: "-24px",
            marginRight: "-24px",
        },
    },
    timeField: {
        "& input": {
            padding: theme.spacing(1.15),
            borderColor: "#E0E1E1",
            borderRadius: theme.shape.borderRadius,
        },
        margin: "15px 0",
    },
    row: {
        width: "150px",
        display: "flex",
        flexDirection: "column",
        margin: "5px 0",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    closeIcon: {
        width: 16,
        height: 16,
        color: "rgba(0,0,0,0.8)",
        cursor: "pointer",
    },
    fullRow: {
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            "& .MuiGrid-item": {
                margin: "10px 0",
            },
        },
    },
    availabilityHeader: {
        color: "#2196f3",
        width: "100%",
    },
    marginElm: { margin: "15px 0" },
    addBtn: {
        curson: "pointer",
        textAlign: "center",
    },
}));

interface IInputChangeProps {
    day: number;
    hour: ITTSHour;
    index: number;
    time: string;
}

export interface ITTSTimePeriodProps {
    timeAvailabilities: ITTSHourOfOperation[];
    updateTimePeriod: (day: number, hour: ITTSHour, index: number) => void;
    deleteTimePeriod: (day: number, num: number) => void;
    addTimePeriod: (day: number) => void;
}

export default function TTSTimePeriod(props: ITTSTimePeriodProps) {
    const classes = useStyles();
    const {
        timeAvailabilities,
        updateTimePeriod,
        deleteTimePeriod,
        addTimePeriod,
    } = props;

    const handleChange = (args: IInputChangeProps, isStart: boolean) => {
        const eventType = isStart ? onStartTimeChange : onEndTimeChange;
        eventType?.(args);
    };

    const onStartTimeChange = useCallback(
        ({ day, hour, index, time: startTime }: IInputChangeProps) => {
            if (startTime) {
                const endTime = hour?.endTime || "0";
                updateTimePeriod(
                    day,
                    {
                        startTime,
                        endTime,
                    },
                    index
                );
            }
        },
        [updateTimePeriod]
    );

    const onEndTimeChange = useCallback(
        ({ day, hour, index, time: endTime }: IInputChangeProps) => {
            if (endTime) {
                const startTime = hour?.startTime || "0";
                if (
                    stringWithSpecialCharToNumeric(endTime, ":") >=
                    stringWithSpecialCharToNumeric(startTime, ":")
                ) {
                    updateTimePeriod(
                        day,
                        {
                            startTime,
                            endTime,
                        },
                        index
                    );
                }
            }
        },
        [updateTimePeriod]
    );

    const handleDeleteTimePeriod = useCallback(
        (day, index) => () => deleteTimePeriod(day, index),
        [deleteTimePeriod]
    );

    const handleAddTimePeriod = useCallback(
        (day: number) => () => addTimePeriod(day),
        [addTimePeriod]
    );

    return (
        <div className={classes.container}>
            <Grid container justify="space-between" className={classes.fullRow}>
                {timeAvailabilities.map(({ hours, day }) => (
                    <Grid
                        item
                        key={`${day}-row`}
                        data-testid={`day-parent-${day}`}
                    >
                        <div>{DAYS[day]}</div>
                        <Typography className={classes.availabilityHeader}>
                            {AVAILABILITY_STATE.exact}
                        </Typography>
                        <div>
                            <Divider className={classes.marginElm} />
                            {hours &&
                                hours.map((hour: ITTSHour, index: number) => (
                                    <div
                                        key={`time-${day}-${index}`}
                                        className={classes.row}
                                    >
                                        <Grid
                                            container
                                            justify="space-between"
                                            alignItems="center"
                                            style={{ fontSize: 14 }}
                                        >
                                            <b>{`Time Period ${index + 1}`}</b>
                                            <ReadOnlyWrapper
                                                element={IconButton}
                                                data-testid="delete-time-period"
                                                className={classes.closeIcon}
                                                onClick={handleDeleteTimePeriod(
                                                    day,
                                                    index
                                                )}
                                            >
                                                <CloseIcon />
                                            </ReadOnlyWrapper>
                                        </Grid>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <TextField
                                                label="Start Time"
                                                type="time"
                                                variant="outlined"
                                                className={classes.timeField}
                                                value={hour?.startTime.padStart(
                                                    5,
                                                    "0"
                                                )}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onPaste={(e) => {
                                                    const clipboardData =
                                                        e?.clipboardData?.getData(
                                                            "Text"
                                                        ) || "0";
                                                    const time = moment(
                                                        clipboardData
                                                    ).format("HH:mm");
                                                    handleChange(
                                                        {
                                                            day,
                                                            hour,
                                                            index,
                                                            time,
                                                        },
                                                        true
                                                    );
                                                }}
                                                onChange={(e) =>
                                                    handleChange(
                                                        {
                                                            day,
                                                            hour,
                                                            index,
                                                            time:
                                                                e.target.value,
                                                        },
                                                        true
                                                    )
                                                }
                                                inputProps={{
                                                    "data-testid":
                                                        "change-start-time-period",
                                                }}
                                            />
                                            <TextField
                                                label="Stop Time"
                                                type="time"
                                                variant="outlined"
                                                className={classes.timeField}
                                                value={hour?.endTime.padStart(
                                                    5,
                                                    "0"
                                                )}
                                                onPaste={(e) => {
                                                    const clipboardData =
                                                        e?.clipboardData?.getData(
                                                            "Text"
                                                        ) || "0";
                                                    const time = moment(
                                                        clipboardData
                                                    ).format("HH:mm");
                                                    handleChange(
                                                        {
                                                            day,
                                                            hour,
                                                            index,
                                                            time,
                                                        },
                                                        false
                                                    );
                                                }}
                                                onChange={(e) =>
                                                    handleChange(
                                                        {
                                                            day,
                                                            hour,
                                                            index,
                                                            time:
                                                                e.target.value,
                                                        },
                                                        false
                                                    )
                                                }
                                                inputProps={{
                                                    "data-testid":
                                                        "change-stop-time-period",
                                                }}
                                            />
                                            <Divider
                                                className={classes.marginElm}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                ))}
                            <div className={classes.addBtn}>
                                <ReadOnlyWrapper
                                    element={IconButton}
                                    data-testid="add-time-period"
                                    onClick={handleAddTimePeriod(day)}
                                >
                                    <AddCircleOutlineIcon />
                                </ReadOnlyWrapper>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
