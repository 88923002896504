import {
    Checkbox,
    makeStyles,
    TextField as MUITextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete, {
    AutocompleteRenderInputParams,
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { useCallback } from "react";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

type MultiSelectAutocompleteProps<T = {}> = {
    options: T[];
    selectedItems: T[];
    setSelectedItems: (selectedItems: T[]) => void;
    getItemsTitle: (item: T) => string;
    inputLabel: string;
    testId: string;
};

const useStyles = makeStyles(({ spacing }) => ({
    itemSelectionBody: {
        display: "flex",
        marginBottom: spacing(2.5),
        alignItems: "center",
    },
    itemSelection: {
        marginRight: spacing(2.5),
        width: "100%",
    },
    checkbox: { marginRight: spacing(1) },
}));

function MultiSelectAutocomplete<T>(props: MultiSelectAutocompleteProps<T>) {
    const {
        options,
        selectedItems,
        setSelectedItems,
        getItemsTitle,
        inputLabel,
        testId,
    } = props;
    const { itemSelectionBody, itemSelection, checkbox } = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const filterOptions = createFilterOptions({
        stringify: getItemsTitle,
    });

    const handleChange = useCallback(
        (_, selectedItems) => {
            setSelectedItems(selectedItems);
        },
        [setSelectedItems]
    );

    const handleRenderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <MUITextField {...params} placeholder="Search" />
        ),
        []
    );

    const handleRenderOption = useCallback(
        (option, { selected }) => (
            <div>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={checkbox}
                    checked={selected}
                    color="primary"
                />
                {getItemsTitle(option)}
            </div>
        ),
        [checkedIcon, icon, getItemsTitle, checkbox]
    );
    return (
        <div className={itemSelectionBody}>
            <ReadOnlyWrapper
                element={Autocomplete}
                autoHighlight
                className={itemSelection}
                label={inputLabel}
                multiple
                renderOption={handleRenderOption}
                getOptionLabel={getItemsTitle}
                renderInput={handleRenderInput}
                data-testid={testId}
                disableCloseOnSelect
                options={options}
                onChange={handleChange}
                filterOptions={filterOptions}
                value={selectedItems}
            />
        </div>
    );
}

export default MultiSelectAutocomplete;
