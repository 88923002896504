import { ThemeProvider } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import React, { memo, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, RouteProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { CircularLoading } from "../components/CircularLoading";
import { AppWrapper } from "../components/layout/Wrappers/AppWrapper";
import { useApp } from "../hooks";
import { RootState } from "../reducers/rootReducer";
import { loadUserState } from "../reducers/userReducer";
import { AppRoute, findRouteWithAccess, ROUTES } from "../routes";
import {
    selectedRestaurantAccessSelector,
    selectedRestaurantCodeSelector,
} from "../selectors/restaurant";
import { history } from "../store";
import { RolePermissions } from "../types/restaurant";
import { UI_THEME } from "../utils/branding";
import logger, { updateLogger } from "../utils/logger";
import { isEntitySavingSelector } from "../utils/menu";
import { hasRoleAccess } from "../utils/restaurants";
import { StringOrNull } from "../utils/types";

const App = () => {
    const dispatch = useDispatch();
    useApp();
    const isLoggedIn = useSelector((state: RootState) => {
        return state.user.isLoggedIn;
    });
    const userRolesHaveLoaded = useSelector(
        (state: RootState) =>
            !state.user.isLoggedIn || state.restaurant.userRolesHaveLoaded
    );
    const didAttemptPreviousStateLoad = useSelector(
        (state: RootState) => state.user.didAttemptPreviousStateLoad
    );

    const selectedRestaurantAccess = useSelector(
        selectedRestaurantAccessSelector
    );

    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const isSavingEntity = useSelector(isEntitySavingSelector);

    dayjs.extend(advancedFormat);
    dayjs.extend(utc);

    useEffect(() => {
        dispatch(loadUserState());
        updateLogger();
    }, [dispatch]);
    const generateRoute = (routeParams: AppRoute) => {
        // Don't force a redirect until we have tried to load the user state
        if (!didAttemptPreviousStateLoad || !userRolesHaveLoaded) {
            return null;
        }
        const WrappedComponent = routeParams.disableAppWrapper ? (
            routeParams.component
        ) : (
            <AppWrapper>{routeParams.component}</AppWrapper>
        );
        const privateRouteParams = {
            isLoggedIn,
            protectedRoute: routeParams.protected,
            path: routeParams.path,
            accessLevelNeeded: routeParams.accessLevelNeeded,
            selectedRestaurantAccess,
            restaurantCode,
        };
        return (
            <PrivateRoute key={routeParams.path} {...privateRouteParams}>
                {WrappedComponent}
            </PrivateRoute>
        );
    };

    return (
        <>
            <ThemeProvider theme={UI_THEME}>
                <ConnectedRouter history={history}>
                    {isSavingEntity && <CircularLoading />}
                    <Switch>
                        {didAttemptPreviousStateLoad && (
                            <Route
                                exact={true}
                                path={
                                    isLoggedIn && !!restaurantCode
                                        ? ["/", "/auth0-login"]
                                        : "/"
                                }
                            >
                                <Redirect
                                    to={`/${restaurantCode}/menu-editor`}
                                />
                            </Route>
                        )}

                        {Object.values(ROUTES).map((route) =>
                            generateRoute(route)
                        )}
                    </Switch>
                </ConnectedRouter>
            </ThemeProvider>
        </>
    );
};

interface PrivateRouteProps extends RouteProps {
    protectedRoute: boolean;
    accessLevelNeeded?: RolePermissions;
    selectedRestaurantAccess: RolePermissions | null;
    isLoggedIn: boolean;
    children: ReactNode;
    restaurantCode: StringOrNull;
}

function PrivateRoute({
    protectedRoute,
    accessLevelNeeded,
    selectedRestaurantAccess,
    isLoggedIn,
    path,
    children,
    restaurantCode,
    ...rest
}: PrivateRouteProps) {
    const routeRender = ({ location }: RouteComponentProps<any>) => {
        const redirectParams = {
            pathname: isLoggedIn && !!restaurantCode ? "/" : "/auth0-login",
            state: { from: location },
        };
        const redirect = <Redirect to={redirectParams} />;
        if (protectedRoute && !isLoggedIn) {
            return redirect;
        }
        if (
            !hasRoleAccess(accessLevelNeeded || null, selectedRestaurantAccess)
        ) {
            logger.debug(
                "Attempting to load page user does not have access to",
                { path, selectedRestaurantAccess }
            );
            if (selectedRestaurantAccess) {
                const nextBestRoute = findRouteWithAccess(
                    selectedRestaurantAccess
                );
                const pathname =
                    nextBestRoute?.path.replace(
                        ":restaurantId",
                        restaurantCode || ""
                    ) || "";

                if (nextBestRoute) {
                    return <Redirect to={{ pathname }} />;
                }
                logger.warn("Could not find new route to redirect user to");
            }
            return redirect; // TODO we are sending them to login, but should we?
        }
        return children;
    };
    return <Route {...rest} render={routeRender} />;
}

export default memo(App);
