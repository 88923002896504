import {
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
} from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrayMove from "array-move";
import React, { SyntheticEvent, useState } from "react";
import { useSelector } from "react-redux";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { componentTypeConfig } from "../../constants/menu";
import ItemNavigation from "../ItemNavigation";
import { useCustomHistory, useReadOnly } from "../../hooks";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    ModifierGroupWithMenuItems,
    renderTableCollectionText,
} from "../../utils/menu";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginLeft: "-36px",
        marginRight: "-40px",
        padding: "10px 0",
        backgroundColor: "rgba(0,0,0,0.08)",
        [theme.breakpoints.down("sm")]: {
            marginRight: "-8px",
            marginLeft: "-8px",
            paddingRight: "8px",
            paddingLeft: "8px",
        },
    },
    dragContainer: {
        backgroundColor: "white",
        marginLeft: "40px",
        width: "80%",
        borderRadius: "6px",
        boxShadow: "1px 3px 2px rgba(0,0,0,0.2)",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
        },
    },
    line: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        padding: "10px 0",
        borderBottom: "1px solid #eeeeee",
    },
    price: {
        backgroundColor: "rgba(0,0,0,0.09)",
        width: "60px",
        borderRadius: "2px",
        textAlign: "right",
        padding: theme.spacing(1, 0),
        paddingRight: "5px",
    },
}));

interface SortableProps {
    value: ModifierGroupWithMenuItems;
    removeItem: (id: string) => void;
    removeId: string;
    setRemoveId: (id: string) => void;
    index: number;
}

interface Props {
    itemList: ModifierGroupWithMenuItems[];
    setItemList: (itemList: ModifierGroupWithMenuItems[]) => void;
    removeItem: (id: string) => void;
}

const DragHandle = SortableHandle(() => (
    <span style={{ margin: "0 20px" }} data-testid="drag-handle">
        <DehazeIcon />
    </span>
));
const SortableItem = SortableElement((data: SortableProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { pushToHistory } = useCustomHistory();
    const { isReadOnly } = useReadOnly();

    const handleActions = (evt: SyntheticEvent<HTMLElement>) => {
        setAnchorEl(evt?.currentTarget);
        data.setRemoveId(data.value.id);
    };

    const handleEdit = () => {
        pushToHistory(
            `/${restaurantCode}/menu-editor/modifier-groups/${data.removeId}`
        );
    };

    const handleClose = () => setAnchorEl(null);

    const handleRemove = () => {
        data.removeItem(data.removeId);
        setAnchorEl(null);
    };
    return (
        <div className={classes.line}>
            <DragHandle />
            <div style={{ flex: 1 }}>{data.value.name}</div>
            <div style={{ marginRight: "20px" }}>
                {renderTableCollectionText(data.value.childMenuItems)}
            </div>
            {isReadOnly ? (
                <ItemNavigation
                    url={`/${restaurantCode}/menu-editor/modifier-groups/${data.value.id}`}
                    title={componentTypeConfig["modifier"].title}
                />
            ) : (
                <>
                    <IconButton
                        style={{ width: "40px", padding: 0 }}
                        onClick={handleActions}
                        data-testid="modifier-group-menu-actions"
                    >
                        <MoreVertIcon style={{ width: "40px" }} />
                    </IconButton>
                    <Menu
                        data-testid="menu-item"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                    >
                        <ReadOnlyWrapper
                            element={MenuItem}
                            onClick={handleEdit}
                        >
                            Edit
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper
                            element={MenuItem}
                            onClick={handleRemove}
                        >
                            Remove
                        </ReadOnlyWrapper>
                    </Menu>
                </>
            )}
        </div>
    );
});
const Container = SortableContainer((data: { children: React.ReactNode }) => {
    const classes = useStyles();
    return <div className={classes.dragContainer}>{data.children}</div>;
});

export default function DragModifierGroup(props: Props) {
    const classes = useStyles();
    const [removeId, setRemoveId] = React.useState("");

    const { itemList, setItemList } = props;
    const onSortEnd = (data: { oldIndex: number; newIndex: number }) => {
        setItemList(ArrayMove(itemList, data.oldIndex, data.newIndex));
    };

    return (
        <div className={classes.container}>
            <Container onSortEnd={onSortEnd} useDragHandle>
                {itemList.length > 0 &&
                    itemList.map((value, index) => (
                        <ReadOnlyWrapper
                            element={SortableItem}
                            key={`item-${value.id}`}
                            index={index}
                            value={value}
                            removeId={removeId}
                            setRemoveId={setRemoveId}
                            removeItem={props.removeItem}
                        />
                    ))}
            </Container>
        </div>
    );
}
