import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    Category,
    MenuItem,
    MenuOverride,
    ModifierGroup,
    TimePeriod,
    UpdateCategoriesSortOrderInput,
    UpdateMenuItemAvailabilityInput,
} from "../generated-interfaces/graphql";
import {
    CategoryType,
    IDuplicatePayload,
    MenuItemType,
    ModifierGroupType,
    TimePeriodInputType,
} from "../types/menu";
import { ICloneMenuActionPayload } from "../types/menu-ingestion";
import {
    IFetchMenuVersionActionType,
    IMenuHistory,
    IMenuVersionActivity,
    IMenuVersionRequestStatus,
    IPromoteMenuVersionActionType,
    IRestoreDBActionType,
    IUnavailableItem,
} from "../types/menuVersion";
import { MenuItemWithCategories, toRecord } from "../utils/menu";
import {
    CallbackFunction,
    CreateMenuVersion,
    DeleteItemType,
    GetIngestedDataType,
    IAuditLogs,
    IAuditLogsInput,
    ICopyMenuVersionToRestaurant,
    IVoicePropsFilters,
} from "../utils/types";
import { USER_ACTIONS } from "./userReducer";

export interface MenuState {
    didInitialLoad: boolean;
    isSavingEntity: boolean;
    isUploadingImage: boolean;
    menuItems: Record<string, MenuItem>;
    modifierGroups: Record<string, ModifierGroup>;
    categories: Record<string, Category>;
    timePeriods: Record<string, TimePeriod>;
    menuOverrides: Record<string, MenuOverride>;
    voicePropsFilters: IVoicePropsFilters;
    menuVersionRequestStatus: IMenuVersionRequestStatus[];
    restorationInProgress: boolean;
    stagesOverview: IMenuHistory[];
    versionList: IMenuHistory[];
    menuVersionActivity: IMenuVersionActivity[];
    latestVersionInfo: IMenuHistory;
    auditLogs: IAuditLogs[];
    auditLogsTotalCount: number;
    unavailableItems: Record<string, IUnavailableItem>;
    itemsToCopy: Record<string, MenuItemWithCategories>;
    copyInProgress: boolean;
}

export const initialMenuState: MenuState = {
    didInitialLoad: false,
    isSavingEntity: false,
    isUploadingImage: false,
    menuItems: {},
    modifierGroups: {},
    categories: {},
    timePeriods: {},
    menuOverrides: {},
    voicePropsFilters: { upsellItems: false, upsellPrompts: false },
    menuVersionRequestStatus: [],
    restorationInProgress: false,
    stagesOverview: [],
    versionList: [],
    menuVersionActivity: [],
    latestVersionInfo: {} as IMenuHistory,
    auditLogs: [],
    auditLogsTotalCount: 0,
    unavailableItems: {},
    itemsToCopy: {},
    copyInProgress: false,
};
export const getMenu = createAction<CallbackFunction | undefined>("GET_MENU");
export const createMenuItem = createAction<
    Omit<MenuItemType, "restaurantCode" | "id">
>("CREATE_MENU_ITEM");
export const updateMenuItem = createAction<
    Omit<MenuItemType, "restaurantCode">
>("UPDATE_MENU_ITEM");
export const createModifierGroup = createAction<ModifierGroupType>(
    "CREATE_MODIFIER_GROUP"
);
export const updateModifierGroup = createAction<ModifierGroupType>(
    "UPDATE_MODIFIER_GROUP"
);
export const createCategory = createAction<CategoryType>("CREATE_CATEGORY");
export const updateCategory = createAction<CategoryType>("UPDATE_CATEGORY");
export const duplicateCategory = createAction<IDuplicatePayload>(
    "DUPLICATE_CATEGORY"
);
export const deleteMenuItem = createAction<DeleteItemType>("DELETE_MENU_ITEM");
export const duplicateMenuItem = createAction<IDuplicatePayload>(
    "DUPLICATE_MENU_ITEM"
);
export const deleteCategory = createAction<DeleteItemType>("DELETE_CATEGORY");
export const deleteModifierGroup = createAction<DeleteItemType>(
    "DELETE_MODIFIER_GROUP"
);
export const duplicateModifierGroup = createAction<IDuplicatePayload>(
    "DUPLICATE_MODIFIER_GROUP"
);
export const createTimePeriod = createAction<Omit<TimePeriodInputType, "id">>(
    "CREATE_TIME_PERIOD"
);
export const updateTimePeriod = createAction<TimePeriodInputType>(
    "UPDATE_TIME_PERIOD"
);
export const deleteTimePeriod = createAction<DeleteItemType>(
    "DELETE_TIME_PERIOD"
);
export const updateCategoriesSortOrder = createAction<
    UpdateCategoriesSortOrderInput & CallbackFunction
>("UPDATE_CATEGORIES_SORT_ORDER");
export const updateMenuItemAvailability = createAction<UpdateMenuItemAvailabilityInput>(
    "UPDATE_ITEM_AVAILABILITY"
);
/** @deprecated will be removed in future */
export const getVoiceProperties = createAction<
    Partial<GetIngestedDataType> | undefined
>("GET_VOICE_PROPERTIES");
export const createMenuVersion = createAction<CreateMenuVersion>(
    "CREATE_MENU_VERSION"
);
export const fetchMenuCommitStage = createAction("FETCH_MENU_COMMIT_STAGE");
export const fetchActiveMenuCommitStage = createAction(
    "FETCH_ACTIVE_MENU_COMMIT_STAGE"
);
export const reloadCache = createAction<CallbackFunction>("RELOAD_CACHE");
export const promoteMenuVersion = createAction<IPromoteMenuVersionActionType>(
    "PROMOTE_MENU_VERSION"
);
/** fromRoot boolean determines whether the action is triggered from root component
 * If true, voiceProperties are fetched from the graphQL server  */
export const fetchMenuVersionRequestStatus = createAction<{
    fromRoot: boolean;
}>("FETCH_MENU_VERSION_REQUEST_STATUS");
export const fetchCommittedMenuVersion = createAction<IFetchMenuVersionActionType>(
    "FETCH_COMMITTED_MENU_VERSION"
);
export const fetchMenuVersionActivity = createAction(
    "FETCH_MENU_VERSION_ACTIVITY"
);
export const restoreDB = createAction<IRestoreDBActionType>("RESTORE_DB");

export const restoreDBStatus = createAction<Partial<CallbackFunction>>(
    "RESTORE_DB_STATUS"
);
export const fetchLatestVersionOnDB = createAction<Partial<CallbackFunction>>(
    "FETCH_LATEST_VERSION_ON_DB"
);
export const resetMenuVersionData = createAction<CallbackFunction | undefined>(
    "RESET_MENU_VERSION_DATA"
);
export const copyMenuVersionToRestaurant = createAction<ICopyMenuVersionToRestaurant>(
    "COPY_MENU_VERSION_TO_RESTAURANT"
);
export const fetchAuditLogs = createAction<IAuditLogsInput>("FETCH_AUDIT_LOGS");
export const fetchUnavailableItems = createAction("FETCH_UNAVIALABLE_ITEMS");
export const createUnavialableItems = createAction<
    Pick<IUnavailableItem, "itemUniqueIdentifier" | "unavailableUntil">
>("CREATE_UNAVAILABLE_ITEMS");
export const updateUnavialableItems = createAction<
    Pick<
        IUnavailableItem,
        "itemUniqueIdentifier" | "unavailableUntil" | "unavailableItemsId"
    >
>("UPDATE_UNAVAILABLE_ITEMS");
export const deleteUnavialableItems = createAction<
    Pick<IUnavailableItem, "unavailableItemsId" | "itemUniqueIdentifier">
>("DELETE_UNAVAILABLE_ITEMS");

export const cloneMenu = createAction<ICloneMenuActionPayload>("CLONE_MENU");

export const menuState = createSlice({
    name: "menu",
    initialState: initialMenuState,
    reducers: {
        setIsLoadingEntity: (state, action: PayloadAction<boolean>) => {
            state.isSavingEntity = action.payload;
        },
        getMenuSuccess: (
            state,
            action: PayloadAction<{
                menuItems: MenuItem[];
                menuOverrides: MenuOverride[];
                modifierGroups: ModifierGroup[];
                categories: Category[];
                timePeriods: TimePeriod[];
            }>
        ) => {
            const {
                menuItems,
                menuOverrides,
                modifierGroups,
                categories,
                timePeriods,
            } = action.payload;
            state.didInitialLoad = true;
            state.menuItems = toRecord(menuItems);
            state.menuOverrides = toRecord(menuOverrides);
            state.modifierGroups = toRecord(modifierGroups);
            state.categories = toRecord(categories);
            state.timePeriods = toRecord(timePeriods);
        },
        loadMenuComitStage: (
            state,
            action: PayloadAction<{
                versionList: IMenuHistory[];
            }>
        ) => {
            state.versionList = action.payload.versionList;
        },
        loadActiveMenuComitStage: (
            state,
            action: PayloadAction<{
                stagesOverview: IMenuHistory[];
            }>
        ) => {
            state.stagesOverview = action.payload.stagesOverview;
        },
        loadMenuVersionRequestStatus: (
            state,
            action: PayloadAction<{
                menuVersionRequestStatus: IMenuVersionRequestStatus[];
            }>
        ) => {
            state.menuVersionRequestStatus =
                action.payload.menuVersionRequestStatus;
        },
        updateDidInitialLoad: (state, action: PayloadAction<boolean>) => {
            state.didInitialLoad = action.payload;
        },
        updateRestorationInProgress: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.restorationInProgress = action.payload;
        },
        loadMenuVersionActivity: (
            state,
            action: PayloadAction<{
                menuVersionActivity: IMenuVersionActivity[];
            }>
        ) => {
            state.menuVersionActivity = action.payload.menuVersionActivity;
        },
        updateLatestVersionInfo: (
            state,
            action: PayloadAction<{
                latestVersionInfo: IMenuHistory;
            }>
        ) => {
            state.latestVersionInfo = action.payload.latestVersionInfo;
        },
        updateStateProperty: (
            state,
            { payload }: PayloadAction<Partial<MenuState>>
        ) => {
            return { ...state, ...payload };
        },
        updateItemsToCopy: (
            state,
            { payload }: PayloadAction<Record<string, MenuItemWithCategories>>
        ) => {
            state.itemsToCopy = payload;
        },
        setVoicePropsFilters: (
            state,
            action: PayloadAction<IVoicePropsFilters>
        ) => {
            state.voicePropsFilters = action.payload;
        },
    },
    extraReducers: {
        [USER_ACTIONS?.logout.toString()]: () => {
            return initialMenuState;
        },
    },
});

export const MENU_ACTIONS = menuState.actions;

export default menuState.reducer;
