import { Button, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { MenuType } from "../../constants/menu";
import AddNewItem from "./items/AddNewItem";

const useStyles = makeStyles((theme: Theme) => ({
    saveBtn: {
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
    actionBtns: {
        "& button:not(:first-child)": {
            marginLeft: "20px",
        },
        "& a:not(:first-child)": {
            marginLeft: "20px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            "& button:not(:first-child)": {
                marginLeft: "0px",
                marginTop: "20px",
            },
            "& a:not(:first-child)": {
                marginLeft: "0px",
                marginTop: "20px",
            },
            "& button": {
                width: "100%",
            },

            "& a": {
                width: "100%",
            },
        },
    },
}));

interface IProps {
    handleGoBack: () => void;
    handleRemove: () => void;
    handleDuplicate?: () => void;
    disableSave: boolean;
    id: string;
    menuType: keyof typeof MenuType;
}

export default function ActionItems({
    handleGoBack,
    handleRemove,
    handleDuplicate,
    disableSave,
    id,
    menuType,
}: IProps) {
    const classes = useStyles();
    return (
        <Grid container justify="space-between" id="action-items">
            <Grid item>
                <IconButton onClick={handleGoBack}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>
            <Grid item className={classes.actionBtns}>
                {id !== "new" && (
                    <>
                        <ReadOnlyWrapper
                            element={Button}
                            disableElevation
                            color="primary"
                            variant="outlined"
                            onClick={handleRemove}
                        >
                            Remove
                        </ReadOnlyWrapper>
                        {menuType !== MenuType.mealPeriod && (
                            <ReadOnlyWrapper
                                element={Button}
                                disableElevation
                                color="secondary"
                                variant="contained"
                                onClick={handleDuplicate}
                            >
                                Duplicate
                            </ReadOnlyWrapper>
                        )}
                        <AddNewItem type={menuType} />
                    </>
                )}
                <ReadOnlyWrapper
                    element={EntitySaveButton}
                    disabled={disableSave}
                    className={classes.saveBtn}
                />
            </Grid>
        </Grid>
    );
}
