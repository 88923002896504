import React from "react";
import { AppRoute } from "../../routes";
import MenuExport from "../menuVersion/MenuExport";
import { Categories } from "./categories/Categories";
import NewCategories from "./categories/NewCategory";
import Items from "./items/Items";
import NewMenuItem from "./items/MenuItemDetail";
import { ModifierGroups } from "./modifier-groups/ModifierGroups";
import NewModifierGroup from "./modifier-groups/NewModifierGroup";
import Overview from "./Overview";
import MealPeriods from "./time-period/MealPeriods";
import NewTimePeriod from "./time-period/NewTimePeriod";

export const MENU_EDITOR_ROUTES: (
    restaurantId: string
) => { [name: string]: AppRoute } = (restaurantId: string) => {
    const PARENT_ROUTE = `/${restaurantId}/menu-editor`;
    return {
        exportItems: {
            title: "Export Items",
            path: PARENT_ROUTE + "/items/export",
            originalPath: PARENT_ROUTE + "/items/export",
            protected: true,
            // To be updated with the correct component
            component: <MenuExport />,
        },
        overview: {
            title: "Overview",
            path: PARENT_ROUTE + "/overview",
            originalPath: PARENT_ROUTE + "/overview",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <Overview />,
        },
        newCategory: {
            title: "Categories",
            path: PARENT_ROUTE + "/categories/:id",
            originalPath: PARENT_ROUTE + "/categories",
            protected: true,
            component: <NewCategories />,
        },
        categories: {
            title: "Categories",
            path: PARENT_ROUTE + "/categories",
            originalPath: PARENT_ROUTE + "/categories",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <Categories />,
        },
        newMenuItem: {
            title: "Items",
            path: PARENT_ROUTE + "/items/:id",
            originalPath: PARENT_ROUTE + "/items",
            protected: true,
            component: <NewMenuItem />,
        },
        items: {
            title: "Items",
            path: PARENT_ROUTE + "/items",
            originalPath: PARENT_ROUTE + "/items",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <Items />,
        },
        newModifierGroup: {
            title: "Modifier Groups",
            originalPath: PARENT_ROUTE + "/modifier-groups",
            path: PARENT_ROUTE + "/modifier-groups/:id",
            protected: true,
            component: <NewModifierGroup />,
        },
        modifierGroups: {
            title: "Modifier Groups",
            originalPath: PARENT_ROUTE + "/modifier-groups",
            path: PARENT_ROUTE + "/modifier-groups",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <ModifierGroups />,
        },
        newMealPeriod: {
            title: "Meal Periods",
            path: PARENT_ROUTE + "/meal-periods/:id",
            originalPath: PARENT_ROUTE + "/meal-periods",
            protected: true,
            component: <NewTimePeriod />,
        },
        mealPeriods: {
            title: "Meal Periods",
            path: PARENT_ROUTE + "/meal-periods",
            originalPath: PARENT_ROUTE + "/meal-periods",
            protected: true,
            visibleTab: true,
            rootTab: true,
            component: <MealPeriods />,
        },
        newMenuItemModify: {
            title: "Items",
            path: PARENT_ROUTE + "/items/:id/modify",
            originalPath: PARENT_ROUTE + "/items",
            protected: true,
            visibleTab: false,
            component: <NewMenuItem />,
        },
        duplicateCategory: {
            title: "Categories",
            path: PARENT_ROUTE + "/categories/:id/duplicate",
            originalPath: PARENT_ROUTE + "/categories",
            protected: true,
            component: <NewCategories />,
        },
        duplicateModifierGroup: {
            title: "Modifier Groups",
            originalPath: PARENT_ROUTE + "/modifier-groups",
            path: PARENT_ROUTE + "/modifier-groups/:id/duplicate",
            protected: true,
            component: <NewModifierGroup />,
        },
    };
};
