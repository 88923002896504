import { makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(8, 4, 8, 4),
        backgroundColor: "#fff",
        height: "100%",
    },
    heading: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const Home: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography
                className={classes.heading}
                variant="h4"
                color="primary"
            >
                No Restaurant Group assigned to this user. Please connect with
                PRP Admin to get Restaurant Group assigned.
            </Typography>
        </div>
    );
};

export default Home;
