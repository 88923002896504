import Axios from "axios";
import { AUTH_TOKEN_RESPONSE_HEADER } from "../utils/constants";
import { readEnvVariable } from "../utils/helper-functions";
import {
    getAuthToken,
    saveAuthToken,
    saveRestaurantAccessToken,
} from "../utils/local-storage";

export const menuAuthApi = Axios.create({
    baseURL: readEnvVariable("MENU_AUTH_API"),
    headers: {
        "Content-Type": "application/json",
    },
});

menuAuthApi.interceptors.response.use(
    (response) => {
        /* Storing Auth Token in Local Storage */
        if (response?.config?.url === "/login") {
            saveAuthToken(response?.headers?.[AUTH_TOKEN_RESPONSE_HEADER]);
        }
        /* Storing Restaurant Access Token in Local Storage */
        if (response?.config?.url === "/login/status") {
            const { restaurant_code } = response?.config?.params;
            const restaurant_token =
                response?.headers?.[AUTH_TOKEN_RESPONSE_HEADER];
            saveRestaurantAccessToken(restaurant_token, restaurant_code);
            // As `/login/status` Request Header accept both access_token & restaurant_token.
            // So replacing access_token with updated restaurant_token to avoid token expiry.
            saveAuthToken(restaurant_token);
        }
        return response?.data || {};
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const loginUser = async ({
    email,
    password,
    url,
}: {
    email: string;
    password: string;
    url: string;
}) => {
    return await menuAuthApi.post("/login", {
        email,
        password,
    });
};

export const fetchUserLoginStatus = async ({
    url,
    restaurantCode: restaurant_code,
}: {
    url: string;
    restaurantCode?: string;
}) => {
    return await menuAuthApi.get("/login/status", {
        params: {
            ...(restaurant_code ? { restaurant_code } : {}),
        },
        headers: {
            Authorization: getAuthToken(),
        },
    });
};

export const MfLoginUser = async ({
    otp,
    token,
    source,
    url,
}: {
    otp: string;
    token: string;
    source: string;
    url: string;
}) => {
    return await menuAuthApi.post("/login/google-auth/mfa", {
        otp,
        token,
        source,
    });
};

export const verifyGoogleAuthToken = async ({
    token,
    source,
    url,
}: {
    token: string;
    source: string;
    url: string;
}) => {
    return await menuAuthApi.post("/login/google-auth/verify", {
        token,
        source,
    });
};
