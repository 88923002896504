import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import { MaterialTableProps } from "material-table";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { MenuType } from "../../../constants/menu";
import { useCustomHistory, useReadOnly } from "../../../hooks";
import { selectedRestaurantCodeSelector } from "../../../selectors/restaurant";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../../utils/data-tables";
import {
    categoriesSelector,
    FormattedTimePeriod,
    renderTableCollectionText,
    timePeriodTableSelector,
} from "../../../utils/menu";
import AddNewItem from "../items/AddNewItem";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export default function MealPeriods() {
    const classes = useStyles();
    const formattedTimePeriods = useSelector(timePeriodTableSelector);
    const categoriesMap = useSelector(categoriesSelector);
    const categories = Object.values(categoriesMap);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { isReadOnly } = useReadOnly();
    const { pushToHistory } = useCustomHistory();

    const IconComp = useCallback(
        () => (isReadOnly ? <InfoIcon /> : <EditIcon />),
        [isReadOnly]
    );

    const timePeriodsTableConfig: MaterialTableProps<any> = {
        columns: [
            { title: "Name", field: "description" },
            {
                title: "Available Days",
                render: (data) => {
                    return data.availableDays.join(" - ");
                },
            },
            {
                title: "Categories",
                field: "categories",
                render: (data) => {
                    const categoriesForTimeperiod = categories.filter(
                        (c) =>
                            c.timePeriods.findIndex(
                                (t) => t === parseFloat(data.id)
                            ) > -1
                    );
                    return renderTableCollectionText(
                        categoriesForTimeperiod,
                        3,
                        "name"
                    );
                },
            },
        ],
        data: Object.values(formattedTimePeriods).map((timePeriod) => ({
            ...timePeriod,
        })),
        actions: [
            {
                icon: IconComp,
                tooltip: `${isReadOnly ? "View" : "Edit"} Meal Period`,
                onClick: (event, rowData) => {
                    pushToHistory(
                        `/${restaurantCode}/menu-editor/meal-periods/${
                            (rowData as FormattedTimePeriod).id
                        }`
                    );
                },
            },
        ],
        options: {
            actionsColumnIndex: 3,
        },
    };
    return (
        <div id="meal-periods">
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        Meal Periods
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <AddNewItem type={MenuType.mealPeriod} />
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...timePeriodsTableConfig} />
        </div>
    );
}
