import React from "react";
import { useReadOnly } from "../hooks";

export default function ReadOnlyWrapper({ element: Element, ...props }: any) {
    const { isReadOnly } = useReadOnly();
    const additionalProps: Record<any, any> = {};
    if (isReadOnly) {
        additionalProps.disabled = isReadOnly;
    }
    return <Element {...props} {...additionalProps} />;
}
