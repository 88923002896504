import {
    Button,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import ReadOnlyWrapper from "../ReadOnlyWrapper";
import { InputProperties } from "../../types/menu";

interface Props {
    inputProperties: InputProperties[];
    handleInputChange: (
        e: ChangeEvent<HTMLInputElement>,
        key: "key" | "value",
        index: number
    ) => void;
    handleRemoveClick: (index: number) => void;
    handleAddClick: () => void;
    isInputValue?: boolean;
    heading: string;
    hideKeyField?: boolean;
}

interface RenderProps {
    inputProps: InputProperties[];
    setInputProps: (inputProps: InputProperties[]) => void;
    heading: string;
    isInputValue?: boolean;
    hideKeyField?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontSize: 14,
        marginBottom: "0.5rem",
        color: "#9e9e9e",
    },
    marginBottom: { marginBottom: "3rem" },
    input: {
        width: "350px",
        marginRight: "1.0rem",
    },
}));

const InputPropertiesListItems = (props: Props) => {
    const {
        handleAddClick,
        handleInputChange,
        handleRemoveClick,
        inputProperties,
        isInputValue,
        heading,
        hideKeyField,
    } = props;
    const classes = useStyles();

    const updatedInputProperties =
        inputProperties.length >= 1
            ? inputProperties
            : [{ key: "", value: "" }];

    return (
        <>
            <Typography className={classes.heading}>{heading}</Typography>
            <Grid className={classes.marginBottom} container direction="column">
                {updatedInputProperties.map(
                    (
                        { key: inputPropsKey, value: inputPropsValue },
                        inputPropertiesIndex
                    ) => {
                        const showRemoveButton =
                            inputPropertiesIndex !== 0 ||
                            ((inputPropsKey || hideKeyField) &&
                                inputPropsValue);
                        return (
                            <Grid
                                className={classes.marginBottom}
                                item
                                key={inputPropertiesIndex}
                            >
                                {!hideKeyField && (
                                    <ReadOnlyWrapper
                                        element={TextField}
                                        className={classes.input}
                                        variant="filled"
                                        label="Key"
                                        placeholder="Enter Key"
                                        inputProps={{
                                            "data-testid":
                                                "input-props-key-field",
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        fullWidth={false}
                                        value={inputPropsKey}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            handleInputChange(
                                                e,
                                                "key",
                                                inputPropertiesIndex
                                            )
                                        }
                                    />
                                )}
                                <ReadOnlyWrapper
                                    element={TextField}
                                    className={classes.input}
                                    variant="filled"
                                    label="Value"
                                    rows={isInputValue ? 1 : 4}
                                    multiline={!isInputValue}
                                    placeholder="Enter Value"
                                    inputProps={{
                                        "data-testid":
                                            "input-props-value-field",
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    value={inputPropsValue}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        handleInputChange(
                                            e,
                                            "value",
                                            inputPropertiesIndex
                                        )
                                    }
                                />
                                {showRemoveButton ? (
                                    <ReadOnlyWrapper
                                        element={Button}
                                        style={{
                                            marginRight: "1.0rem",
                                        }}
                                        disableElevation
                                        color="primary"
                                        variant="outlined"
                                        onClick={(
                                            e: React.SyntheticEvent<HTMLButtonElement>
                                        ) =>
                                            handleRemoveClick(
                                                inputPropertiesIndex
                                            )
                                        }
                                    >
                                        Remove
                                    </ReadOnlyWrapper>
                                ) : null}
                                {updatedInputProperties.length - 1 ===
                                    inputPropertiesIndex && (
                                    <ReadOnlyWrapper
                                        element={Button}
                                        disableElevation
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleAddClick}
                                    >
                                        Add
                                    </ReadOnlyWrapper>
                                )}
                            </Grid>
                        );
                    }
                )}
            </Grid>
        </>
    );
};

export const NewInputProperties = ({
    inputProps,
    setInputProps,
    isInputValue,
    heading,
    hideKeyField,
}: RenderProps) => {
    // handle input change
    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        key: "key" | "value",
        index: number
    ) => {
        const { value } = e.target;
        const list: InputProperties[] = [...inputProps];
        if (!list[index]) {
            const otherKey = key === "key" ? "value" : "key";
            list.push(({ [otherKey]: "" } as unknown) as InputProperties);
        }
        list[index][key] = value;
        setInputProps(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index: number) => {
        const list: InputProperties[] = [...inputProps];
        list.splice(index, 1);
        setInputProps(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputProps([...inputProps, { key: "", value: "" }]);
    };

    return (
        <InputPropertiesListItems
            heading={heading}
            inputProperties={inputProps}
            handleAddClick={handleAddClick}
            handleInputChange={handleInputChange}
            handleRemoveClick={handleRemoveClick}
            isInputValue={isInputValue}
            hideKeyField={hideKeyField}
        />
    );
};
