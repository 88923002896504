import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ChangePaswordInput,
    CompletePasswordResetMutationVariables,
    LoginQueryQueryVariables,
    Query,
    UserProfile,
} from "../generated-interfaces/graphql";
import { IActiveUserReqType, UserSession } from "../types/menu";
import {
    saveAuthToken,
    saveGoogleId,
    saveSessionID,
    saveUserState,
    resetRestaurantAccessToken,
} from "../utils/local-storage";
import { IRequestType, StringOrNull } from "../utils/types";

export interface UserState {
    userProfile: UserProfile | null;
    isLoggedIn: boolean;
    token: StringOrNull;
    didAttemptPreviousStateLoad: boolean;
    isResetCodeSent: boolean;
    resetPasswordSuccessCode: string;
    tabActive: boolean;
    userSessions: UserSession[] | null;
    qrCodeURL: string | null;
    isAuth0: boolean;
}

export const initialUserState: UserState = {
    userProfile: null,
    isLoggedIn: false,
    token: null,
    didAttemptPreviousStateLoad: false,
    isResetCodeSent: false,
    resetPasswordSuccessCode: "",
    tabActive: true,
    userSessions: null,
    qrCodeURL: null,
    isAuth0: false,
};

export const auth0LoginRequestAction = createAction("AUTH0_LOGIN_REQUEST");
export const auth0HandleAuthAction = createAction("AUTH0_HANDLE_AUTH");
export const auth0LogOutRequestAction = createAction("AUTH0_LOGOUT_REQUEST");

export const loginAction = createAction<LoginQueryQueryVariables>("LOGIN");
export const checkAuthStatusAction = createAction<{ restaurantCode: string }>(
    "CHECK_AUTH_STATUS"
);
export const startPasswordreset = createAction<{ email: string }>(
    "START_PASSWORD_RESET"
);
export const changePassword = createAction<ChangePaswordInput>(
    "CHANGE_PASSWORD"
);
export const completePasswordReset = createAction<CompletePasswordResetMutationVariables>(
    "COMPLETE_PASSWORD_RESET"
);
export const loadUserState = createAction("LOAD_USER_STATE");
export const createUserSessionAction = createAction("CREATE_USER_SESSION");
export const updateUserSessionAction = createAction<string>(
    "UPDATE_USER_SESSION"
);
export const retrieveUserSessionAction = createAction<IActiveUserReqType>(
    "GET_USER_SESSION"
);
export const deleteUserSessionAction = createAction<string>(
    "DELETE_USER_SESSION"
);
export const updateHeartBeatAction = createAction<string>(
    "UPDATE_HEARTBEAT_SESSION"
);
export const verifyGAuthTokenAction = createAction<IRequestType>(
    "VERIFY_GOOGLE_AUTH"
);
export const mfLoginAction = createAction<IRequestType>("MF_LOGIN");
export const resetMFLoginAction = createAction<IRequestType>("RESET_MF_LOGIN");

export const userState = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        auth0LogInSuccess: (state, action) => {
            state.userProfile = action.payload.user;
            state.isLoggedIn = true;
            state.isAuth0 = true;
            state.token = action.payload.token;
            saveUserState(state);
        },

        auth0LogInFailure: (state, action) => {
            state.userProfile = null;
            state.isLoggedIn = false;
            saveUserState(state);
        },
        auth0LogOut: (state) => {
            state.userProfile = null;
            state.isLoggedIn = false;
            state.isAuth0 = false;
            state.token = null;
            state.userSessions = null;
            saveUserState(state);
        },

        loginSuccess: (state, action: PayloadAction<Query["login"]>) => {
            state.userProfile = action.payload;
            state.isLoggedIn = true;
            saveUserState(state);
        },
        logout: (state) => {
            state.userProfile = null;
            state.isLoggedIn = false;
            state.isAuth0 = false;
            state.token = null;
            state.userSessions = null;
            saveSessionID(null);
            state.qrCodeURL = null;
            saveAuthToken(null);
            saveGoogleId(null);
            saveUserState(state);
            resetRestaurantAccessToken();
        },
        authCheckSuccess: (
            state,
            action: PayloadAction<Query["authStatus"]>
        ) => {
            if (state.userProfile) {
                const { authorizationToken } = action.payload;
                state.token = authorizationToken;
                state.userProfile.authorizationToken = authorizationToken;
                saveUserState(state);
            }
        },
        loadUserStateSuccess: (state) => {
            state.didAttemptPreviousStateLoad = true;
        },
        passwordResetCodeSuccess: (state) => {
            state.isResetCodeSent = true;
        },
        resetCompleteSuccess: (state) => {
            state.resetPasswordSuccessCode = "SUCCESS";
        },
        resetCompleteFail: (state) => {
            state.resetPasswordSuccessCode = "FAIL";
        },
        setTabActive: (state, action: PayloadAction<boolean>) => {
            state.tabActive = action.payload;
        },
        setSelectedRestaurantUserSession: (
            state,
            action: PayloadAction<UserSession[]>
        ) => {
            state.userSessions = action.payload;
        },
        setMFLoginState: (
            state,
            action: PayloadAction<Query["verifyGAuthToken"]>
        ) => {
            const { __typename, qrCodeURL, ...userProfile } = action.payload;
            if (qrCodeURL) {
                state.qrCodeURL = qrCodeURL;
            }
            if (userProfile) {
                state.userProfile = userProfile;
            }
        },
        resetMFLoginState: (state) => {
            state.qrCodeURL = "";
            state.userProfile = null;
        },
    },
});

export const USER_ACTIONS = userState.actions;
