import {
    Button,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, useState } from "react";
import { KeyboardKeys } from "../constants/enums";
import { Restaurant } from "../types/restaurant";

const useStyles = makeStyles((theme) => ({
    dropdownIcon: {
        color: "#e0e0e0",
    },
    menu: {
        "& .MuiMenu-paper": {
            width: "255px",
            maxHeight: "230px",
            overflow: "auto",
        },
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
    },
    menuItem: {
        overflow: "revert",
        whiteSpace: "break-spaces",
    },
    input: {
        color: "#616161",
    },
    name: {
        margin: "0 auto",
        color: theme.palette.common.white,
    },
    boxStyle: {
        position: "absolute",
        top: 28,
        right: 0,
        left: 0,
        zIndex: 1,
        border: "1px solid",
        p: 1,
        bgcolor: "background.paper",
    },
}));

interface Props {
    restaurantsEntries: [string, Restaurant][];
    inputValue: string;
    handleSelect: (val: any) => void;
}

export function RestaurantAutoComplete(props: Props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { restaurantsEntries, inputValue, handleSelect } = props;
    const [search, setSearch] = useState("");
    const filterRef = React.useRef<HTMLInputElement>();

    const filteredRestaurants = search
        ? restaurantsEntries.filter((item) => {
              const { restaurantCode, restaurantName } = item[1];
              return [restaurantName, restaurantCode]
                  .join("(")
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase());
          })
        : restaurantsEntries;

    const handleTextFieldKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (
            ![
                KeyboardKeys.ARROW_DOWN,
                KeyboardKeys.ARROW_UP,
                KeyboardKeys.HOME,
                KeyboardKeys.END,
            ].includes(e.key as KeyboardKeys)
        ) {
            e.stopPropagation();
        }
    };

    const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
        if (
            [KeyboardKeys.TAB, KeyboardKeys.ARROW_RIGHT].includes(
                e.key as KeyboardKeys
            )
        ) {
            e.stopPropagation();
            e.preventDefault();
            if (filterRef?.current) {
                filterRef.current.focus();
            }
        }
    };

    return (
        <>
            <Button disableElevation onClick={handleClick} variant="outlined">
                {inputValue}
            </Button>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem onKeyDown={moveFocusToInput}>
                    <TextField
                        value={search}
                        onKeyDown={handleTextFieldKeyDown}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSearch(event.currentTarget.value);
                        }}
                        InputProps={{
                            startAdornment: <SearchIcon />,
                            disableUnderline: true,
                        }}
                    />
                </MenuItem>
                <MenuItem
                    value=""
                    onClick={() => {
                        handleSelect("");
                        handleClose();
                    }}
                >
                    None
                </MenuItem>
                {filteredRestaurants.map(
                    ([restaurantCode, { restaurantName, id }]) => {
                        return (
                            <MenuItem
                                key={id}
                                className={classes.menuItem}
                                value={restaurantCode}
                                onClick={() => {
                                    handleSelect(restaurantCode);
                                    handleClose();
                                }}
                            >
                                {`${restaurantName} (${restaurantCode})`}
                            </MenuItem>
                        );
                    }
                )}
            </Menu>
        </>
    );
}
