import {
    Grid,
    makeStyles,
    Paper,
    Tab,
    Tabs,
    Theme,
    Typography,
} from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import QueryString from "query-string";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MirrorRestaurantLabel from "../../components/common/MirrorRestaurantLabel";
import { MenuItem } from "../../generated-interfaces/graphql";
import { useLoadMenu, useRecursiveTimeout } from "../../hooks";
import { MENU_ACTIONS } from "../../reducers/menuReducer";
import {
    selectDidInitialMenuLoad,
    selectUnavailableItems,
} from "../../selectors/menu";
import {
    selectedRestaurantAccessSelector,
    selectedStageSelector,
} from "../../selectors/restaurant";
import { tabActiveSelector } from "../../selectors/user";
import { MenuStages } from "../../types/menuVersion";
import { DATE_FORMATS } from "../../utils/constants";
import { CustomizedMaterialTable } from "../../utils/data-tables";
import { formatDate } from "../../utils/helper-functions";
import { menuItemsSelector } from "../../utils/menu";
import { canAccessItemAvailability } from "../../utils/restaurants";
import ItemAvailabilityActions from "./ItemAvailabilityActions";

const useStyles = makeStyles((theme: Theme) => ({
    childComponent: {
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.background.paper,
        marginTop: "2px",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    tabsList: {
        backgroundColor: theme.palette.background.default,
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
    },
}));

type AvailabilityTabSelection = "Available" | "Unavailable";

function ItemAvailability() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const didInitialLoad = useSelector(selectDidInitialMenuLoad);
    const unavailableItems = useSelector(selectUnavailableItems);
    const stage = useSelector(selectedStageSelector);
    const selectedRestaurantAccessLevel = useSelector(
        selectedRestaurantAccessSelector
    );
    const history = useHistory();
    const queryParams = QueryString.parse(history.location.search);
    const isStaffTablet = queryParams["isStaffTablet"] === "true";
    const tabActive = useSelector(tabActiveSelector);
    const menuItems = useSelector(menuItemsSelector);
    const [currentTab, setCurrentTab] = useState<AvailabilityTabSelection>(
        "Available"
    );
    const [filteredItems, setFilteredItems] = useState<MenuItem[]>([]);
    const { loadMenu } = useLoadMenu();
    const isProd = stage.toUpperCase() === MenuStages.LIVE;

    useEffect(() => {
        if (!selectedRestaurantAccessLevel) return;
        if (
            !canAccessItemAvailability(
                selectedRestaurantAccessLevel,
                stage as MenuStages
            )
        ) {
            // access not allowed for this page, redirect to default landing page
            history.replace("/");
        }
    }, [history, selectedRestaurantAccessLevel, stage]);

    useEffect(() => {
        if (!didInitialLoad) {
            loadMenu({ reload: true });
        }
    }, [didInitialLoad, dispatch, loadMenu]);

    useEffect(() => {
        return () => {
            dispatch(MENU_ACTIONS.updateDidInitialLoad(false));
        };
    }, [dispatch]);

    useEffect(() => {
        // Shallow copy objects b/c they are immutable
        setFilteredItems(
            Object.values(menuItems)
                .filter(({ available, name }) => {
                    if (isProd) {
                        if (available) {
                            if (currentTab === "Available") {
                                return unavailableItems[name] ? false : true;
                            } else {
                                return unavailableItems[name] ? true : false;
                            }
                        }
                        return false;
                    } else {
                        return currentTab === "Available"
                            ? available
                            : !available;
                    }
                })
                .map((o) => ({ ...o }))
        );
    }, [menuItems, currentTab, unavailableItems, isProd]);

    const onTabChange = useCallback(
        (event: ChangeEvent<{}>, value: AvailabilityTabSelection) => {
            setCurrentTab(value);
        },
        []
    );

    const fetchMenuItems = useCallback((): Promise<any> => {
        if (!isStaffTablet && !tabActive) return Promise.resolve(true);

        return Promise.resolve(loadMenu({}));
    }, [isStaffTablet, loadMenu, tabActive]);

    useRecursiveTimeout(fetchMenuItems, 30 * 1000, "fetchMenuItems");

    const tabText =
        currentTab === "Available"
            ? "Available Items"
            : "Unavailable Items (86)";

    const menuItemsTableConfig: MaterialTableProps<MenuItem> = {
        columns: [
            { title: "Display Name", field: "name" },
            {
                title: "Status",
                field: "available",
                render: (row) => {
                    let { available, unavailableUntil, name } = row;
                    if (isProd) {
                        if (unavailableItems[name]) {
                            unavailableUntil =
                                unavailableItems[name].unavailableUntil || null;
                            available = false;
                        } else {
                            available = true;
                            unavailableUntil = "";
                        }
                    }
                    if (available) {
                        return "Available";
                    } else if (unavailableUntil !== null) {
                        return (
                            <>
                                Not available untill{" "}
                                <b>
                                    {formatDate(
                                        unavailableUntil,
                                        DATE_FORMATS.date
                                    )}
                                </b>
                            </>
                        );
                    }
                    return "Not available";
                },
            },
            {
                title: "Edit Item",
                field: "available",
                render: (data) => <ItemAvailabilityActions row={data} />,
                align: "right",
            },
        ],
        data: filteredItems,
    };

    return (
        <>
            <Paper elevation={0}>
                <Tabs
                    value={currentTab}
                    onChange={onTabChange}
                    aria-label="Item Availability Nav"
                    className={classes.tabsList}
                    indicatorColor="secondary"
                >
                    <Tab value="Available" label="Available Items" />
                    <Tab value="Unavailable" label="Unavailable Items (86)" />
                </Tabs>
            </Paper>
            <MirrorRestaurantLabel />
            <div className={classes.childComponent}>
                <Grid item>
                    <Typography variant="h6" component="h4" color="primary">
                        {tabText}
                    </Typography>
                </Grid>
                <CustomizedMaterialTable {...menuItemsTableConfig} />
            </div>
        </>
    );
}

export default ItemAvailability;
