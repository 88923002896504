import {
    Button,
    Grid,
    makeStyles,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { Autocomplete, Select, TextField } from "mui-rff";
import React, { useCallback, useMemo, useState } from "react";
import { FormRenderProps, useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { CircularLoading } from "../../components/CircularLoading";
import FileUpload from "../../components/FileUpload";
import { UploadRawMenuFileMutation } from "../../generated-interfaces/graphql";
import { restaurantsSelector } from "../../selectors/restaurant";
import { getTokenSelector } from "../../selectors/user";
import { getSdk } from "../../utils/network";
import { StringOrNull } from "../../utils/types";
import VoiceConfigEnabler from "../RestaurantSetting/VoiceConfigEnabler";
import { ApiStack, PosType } from "./constants";
import { IMenuIngestionFormData } from "./types";

const posTypeNameMap: { [key in PosType]: string } = {
    [PosType.aloha]: "Aloha",
    [PosType.xpient]: "Xpient",
    [PosType.qu]: "QU",
    [PosType.brink]: "Brink",
};

const apiStackNameMap: { [key in ApiStack]: string } = {
    [ApiStack.api15]: "API 15",
    [ApiStack.apiApiInt]: "API INT",
};

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: spacing(4),
        padding: spacing(6, 4, 4),
        height: "100vh",
        overflow: "auto",
        position: "relative",
    },
    content: {
        fontSize: spacing(1.75),
        color: "#535663",
    },
}));

const useRawMenuUpload = (token: StringOrNull) => {
    const [isUploading, setIsUploading] = useState(false);

    const uploadFile = async (file: File, restaurantCode: string) => {
        try {
            setIsUploading(true);

            const result = await getSdk(token).uploadRawMenuFile({
                file,
                restaurantCode,
            });
            return (
                ((result as unknown) as UploadRawMenuFileMutation)
                    .rawMenuFileUpload || ""
            );
        } catch (error) {
            return "";
        } finally {
            setIsUploading(false);
        }
    };

    return { isUploading, uploadFile };
};

export type MenuIngestionFormBodyProps = FormRenderProps<
    IMenuIngestionFormData,
    IMenuIngestionFormData
>;

const MenuIngestionFormBody = ({
    handleSubmit,
    values,
    initialValues,
}: MenuIngestionFormBodyProps) => {
    const classes = useStyles();

    const form = useForm<IMenuIngestionFormData>();
    const { invalid, submitting } = useFormState();
    const restaurants = useSelector(restaurantsSelector);
    const token = useSelector(getTokenSelector);
    const { isUploading, uploadFile } = useRawMenuUpload(token);

    const handleFileUpload = async (file: File) => {
        const url = await uploadFile(
            file,
            values?.sourceRestaurant?.restaurantCode || ""
        );
        form.mutators.setValue("targetRawFileUrl", url);
    };

    const handleToggleSwitch = (
        event: React.ChangeEvent<HTMLInputElement>,
        name: string
    ) => {
        form.mutators.setValue(name, event.target.checked);
    };

    const instructions = useMemo(
        () => (
            <>
                <Typography className={classes.content}>
                    Menu File must be a ZIP file
                </Typography>
                <Typography className={classes.content} gutterBottom>
                    Maximum size 5MB.
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>or S3 URL to the file:</Typography>
                    </Grid>
                    <Grid item>
                        <TextField name="targetRawFileUrl" />
                    </Grid>
                </Grid>
            </>
        ),
        [classes.content]
    );

    const getOption = useCallback(
        ({ restaurantName, restaurantCode }) =>
            restaurantCode ? `${restaurantName} (${restaurantCode})` : "",
        []
    );

    const clearTargetRawFileUrl = () => {
        form.mutators.setValue(
            "targetRawFileUrl",
            initialValues.targetRawFileUrl
        );
    };
    return (
        <Grid
            data-testid="menu-ingestion-form"
            component="form"
            className={classes.root}
            onSubmit={handleSubmit}
            noValidate
        >
            {submitting && <CircularLoading />}
            <Grid container justify="space-between" alignItems="center">
                <Typography variant="h6">Menu Ingestion</Typography>
                <Button
                    disabled={invalid}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    Run Ingestion
                </Button>
            </Grid>
            <Grid item>
                <Autocomplete
                    multiple={false}
                    label="Search location to set as Reference Menu for this Franchise"
                    name="sourceRestaurant"
                    options={restaurants}
                    getOptionLabel={getOption}
                />
            </Grid>
            <Grid item>
                <Select
                    name="targetPosType"
                    label="Select Restaurant POS"
                    onClick={() => {
                        form.mutators.setValue(
                            "locationToken",
                            initialValues.locationToken
                        );
                        form.mutators.setValue(
                            "apiStack",
                            initialValues.apiStack
                        );
                        form.mutators.setValue(
                            "targetRawFileUrl",
                            initialValues.targetRawFileUrl
                        );
                    }}
                >
                    {Object.values(PosType).map((posType, index) => (
                        <MenuItem value={posType} key={index}>
                            {posTypeNameMap[posType]}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <VoiceConfigEnabler
                    label="Run Validation"
                    checkedValue={values?.runValidation}
                    testId="runValidation"
                    onChange={(e) => handleToggleSwitch(e, "runValidation")}
                />
            </Grid>
            {values?.targetPosType === PosType.brink ? (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            name="locationToken"
                            label="Location Token"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Select name="apiStack" label="Api Stack">
                            {Object.values(ApiStack).map((api) => (
                                <MenuItem value={api}>
                                    {apiStackNameMap[api]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            ) : (
                <Grid item>
                    <FileUpload
                        accept=".zip"
                        buttonLabel="Upload Menu File"
                        header="Menu File Upload"
                        instructions={instructions}
                        isUploading={isUploading}
                        placeholder="Drag and drop Menu File here or"
                        title="Add Menu File for comparison with the Reference Menu"
                        uploadFile={handleFileUpload}
                        onFileDelete={clearTargetRawFileUrl}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default MenuIngestionFormBody;
