import { createSelector } from "@reduxjs/toolkit";
import { Restaurant } from "../generated-interfaces/graphql";
import { RootState } from "../reducers/rootReducer";
import logger from "../utils/logger";

export const restaurantSelect = (state: RootState) => state.restaurant;

export const restaurantInfoSelector = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.selectedRestaurant
);

export const restaurantAccessLevelsSelector = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.restaurantAccessLevels
);

export const restaurantsByUserRoleSelector = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.restaurantsByUserRole
);

export const restaurantListSelector = createSelector(
    restaurantsByUserRoleSelector,
    (roles) => {
        const restaurantsMap =
            roles?.reduce((restaurantList, userRole) => {
                userRole.restaurants.forEach((restaurant) => {
                    const { restaurantCode } = restaurant;
                    if (!(restaurantCode in restaurantList)) {
                        restaurantList[restaurantCode] = {
                            ...restaurant,
                        };
                    }
                });
                return restaurantList;
            }, {} as Record<string, Restaurant>) || {};
        return restaurantsMap;
    }
);

export const restaurantsSelector = createSelector(
    restaurantListSelector,
    (restaurantsMap) => {
        return Object.values(restaurantsMap);
    }
);

export const selectedRestaurantCodeSelector = createSelector(
    restaurantSelect,
    (restaurant) => {
        if (!restaurant.selectedRestaurantCode) {
            logger.error("No Selected Restaurant/Restaurant Info Found", {
                restaurantState: restaurant,
            });
            return null;
        }
        return restaurant.selectedRestaurantCode;
    }
);

export const selectedRestaurantAccessSelector = createSelector(
    selectedRestaurantCodeSelector,
    restaurantAccessLevelsSelector,
    (selectedRestaurantCode, accessLevels) => {
        if (selectedRestaurantCode) {
            return accessLevels[selectedRestaurantCode]?.role || null;
        }
        return null;
    }
);

export const currencySelector = (state: RootState) => "USD";

export const selectedPrimaryRestaurantCodeSelector = createSelector(
    restaurantSelect,
    (restaurant) => {
        if (!restaurant.selectedRestaurantCode) {
            logger.error("No Selected Restaurant/Restaurant Info Found", {
                restaurantState: restaurant,
            });
            return null;
        }
        if (restaurant.primaryRestaurantCode) {
            return restaurant.primaryRestaurantCode;
        }
        return restaurant.selectedRestaurantCode;
    }
);

export const selectedStageSelector = createSelector(
    restaurantSelect,
    (restaurant) => restaurant?.selectedStage || ""
);

export const uniqueRestaurantByUserRoleSelector = createSelector(
    restaurantListSelector,
    selectedRestaurantCodeSelector,
    (restaurantList, selectedRestaurantCode) => {
        if (selectedRestaurantCode) {
            delete restaurantList[selectedRestaurantCode];
        }
        return Object.values(restaurantList);
    }
);

export const ttsHoursOfOperationSelector = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.ttsOperationHours
);

export const selectIsTREnabled = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.isTREnabled
);

export const selectIsTTSSchedulerEnabled = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.isTTSSchedulerEnabled
);

export const selectIsAIEnabled = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.isAIEnabled
);

export const selectIsUnsupervisedAI = createSelector(
    restaurantSelect,
    (restaurant) =>
        restaurant.selectedRestaurant?.restaurantSettings.isUnsupervisedAI ||
        false
);

export const selectIsRestaurantStatusReceived = createSelector(
    restaurantSelect,
    (restaurant) => restaurant.isRestaurantStatusReceived
);
