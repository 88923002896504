import {
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Theme,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import TTSTimePeriod from "../../components/menu/TTSTimePeriod";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import {
    createTTSHoursOfOperation,
    getTTSHoursOfOperation,
    updateTTSHoursOfOperation,
} from "../../reducers/restaurantReducer";
import {
    restaurantInfoSelector,
    selectedRestaurantCodeSelector,
    ttsHoursOfOperationSelector,
} from "../../selectors/restaurant";
import { ITTSHourOfOperation } from "../../types/restaurant";
import { DAYS, OFFSET_TIMEZONE, TIME_OUT } from "../../utils/constants";
import { stringWithSpecialCharToNumeric } from "../../utils/helper-functions";

const useStyles = makeStyles((theme: Theme) => ({
    timePeriodContainer: {
        padding: theme.spacing(3, 4),
    },
    saveBtn: {
        float: "right",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
    timezoneContainer: {
        marginTop: "24px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "48px",
        },
        "& .MuiInputBase-input": {
            paddingLeft: theme.spacing(1),
        },
    },
    menuPaper: {
        maxHeight: "400px",
    },
}));

function TTSOperationHours() {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const alert = useAlert();

    const restaurantInfo = useSelector(restaurantInfoSelector);
    const ttsOperationHours = useSelector(ttsHoursOfOperationSelector);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const [timeAvailabilities, setTimeAvailabilities] = useState<
        ITTSHourOfOperation[]
    >([]);
    const [timeZone, setTimezone] = useState("");

    useEffect(() => {
        restaurantCode && dispatch(getTTSHoursOfOperation({}));
    }, [dispatch, restaurantCode]);

    useEffect(() => {
        let updatedTtsHoursOfOperation = [
            ...ttsOperationHours?.ttsHoursOfOperations,
        ];
        updatedTtsHoursOfOperation = DAYS.map((_, day) => {
            const item = updatedTtsHoursOfOperation.find(
                (item) => item?.day === day
            );
            if (!item) {
                return {
                    day,
                    hours: [],
                };
            }
            const hours = item?.hours
                ? [...item?.hours?.map((item) => ({ ...item }))]
                : [];
            return {
                ...item,
                hours,
            };
        });
        setTimeAvailabilities(updatedTtsHoursOfOperation);
        setTimezone(ttsOperationHours?.timezone || "US/Pacific");
    }, [ttsOperationHours]);

    /* istanbul ignore next */
    const handleUpdateTimePeriod = useCallback(
        (day: number, hour: any, index: number) => {
            const updatedTimeAvailabilities = [...timeAvailabilities];
            let selectedTimePeriod = { ...updatedTimeAvailabilities[day] };
            let hours = selectedTimePeriod.hours
                ? [...selectedTimePeriod.hours]
                : [];
            if (hours.length > 0) hours.splice(index, 1, hour);
            selectedTimePeriod = { ...selectedTimePeriod, hours };

            updatedTimeAvailabilities.splice(day, 1, selectedTimePeriod);
            setTimeAvailabilities([...updatedTimeAvailabilities]);
            setIsFormDirty(true);
        },
        [timeAvailabilities]
    );

    /* istanbul ignore next */
    const handleDeleteTimePeriod = useCallback(
        (day: number, index: number) => {
            const updatedTimeAvailabilities = [...timeAvailabilities];
            const selectedTimePeriod = updatedTimeAvailabilities[day];
            if (selectedTimePeriod.hours)
                selectedTimePeriod.hours.splice(index, 1);
            updatedTimeAvailabilities.splice(day, 1, selectedTimePeriod);
            setTimeAvailabilities([...updatedTimeAvailabilities]);
            setIsFormDirty(true);
        },
        [timeAvailabilities]
    );

    const handleSubmit = useCallback(
        (e: React.MouseEvent<any>) => {
            e.preventDefault();
            e.stopPropagation();
            const operation = ttsOperationHours?.ttsHoursOfOperations?.length
                ? updateTTSHoursOfOperation
                : createTTSHoursOfOperation;
            dispatch(
                operation({
                    timezone: timeZone,
                    ttsHoursOfOperations: timeAvailabilities,
                    successCallback: /* istanbul ignore next */ () => {
                        alert.success("Operation Hours Saved", {
                            timeout: TIME_OUT,
                        });
                    },

                    errorCallback: /* istanbul ignore next */ () => {
                        alert.error("Error Saving Operation Hours", {
                            timeout: TIME_OUT,
                        });
                    },
                })
            );
            setIsFormDirty(false);
        },
        [alert, dispatch, timeZone, timeAvailabilities, ttsOperationHours]
    );

    const isValidHours = useCallback(() => {
        const unAvailableTimeperiodIndex = timeAvailabilities.findIndex(
            (available) => {
                if (available && available?.hours?.length) {
                    const unAvailableIndex = available.hours.findIndex(
                        (hour) => {
                            return !(
                                hour &&
                                hour?.startTime &&
                                hour.endTime &&
                                stringWithSpecialCharToNumeric(
                                    hour.endTime,
                                    ":"
                                ) >
                                    stringWithSpecialCharToNumeric(
                                        hour.startTime,
                                        ":"
                                    )
                            );
                        }
                    );
                    return unAvailableIndex > -1;
                }
                return false;
            }
        );
        return unAvailableTimeperiodIndex === -1;
    }, [timeAvailabilities]);

    /* istanbul ignore next */
    const addTimePeriod = useCallback(
        (day: number) => {
            const newTimeAvailabilities = [...timeAvailabilities];
            let selectedTimePeriod = { ...newTimeAvailabilities[day] };
            let hours = selectedTimePeriod.hours
                ? [
                      ...selectedTimePeriod.hours,
                      { startTime: "09:00", endTime: "17:00" },
                  ]
                : [];
            selectedTimePeriod = { ...selectedTimePeriod, hours };
            newTimeAvailabilities.splice(day, 1, selectedTimePeriod);
            setTimeAvailabilities([...newTimeAvailabilities]);
            setIsFormDirty(true);
        },
        [timeAvailabilities]
    );

    /* istanbul ignore next */
    const handleGoBack = useCallback(() => {
        if (isFormDirty) {
            setShowBackConfirmModal(true);
        } else {
            history.push(
                `/${restaurantInfo?.restaurantCode}/restaurant-settings`
            );
        }
    }, [history, isFormDirty, restaurantInfo]);

    /* istanbul ignore next */
    const onConfirmModalSuccess = useCallback(() => {
        history.push(`/${restaurantInfo?.restaurantCode}/restaurant-settings`);
    }, [restaurantInfo, history]);

    /* istanbul ignore next */
    const onConfirmModalCancel = useCallback(
        () => setShowBackConfirmModal(false),
        []
    );

    /* istanbul ignore next */
    const haadleTimeZoneChange = useCallback((evt) => {
        setTimezone(evt.target.value as string);
        setIsFormDirty(true);
    }, []);

    return (
        <div className={classes.timePeriodContainer}>
            <form
                onSubmit={handleSubmit}
                data-testid="tts-operation-hours-form"
            >
                {
                    /* istanbul ignore next */
                    showBackConfirmModal && (
                        <ConfirmDialog
                            title="Leave without saving?"
                            content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                            open={showBackConfirmModal}
                            onSuccess={onConfirmModalSuccess}
                            onCancel={onConfirmModalCancel}
                        />
                    )
                }
                <IconButton onClick={handleGoBack}>
                    <ArrowBackIcon />
                </IconButton>
                <ReadOnlyWrapper
                    element={EntitySaveButton}
                    disabled={!isValidHours()}
                    className={classes.saveBtn}
                />
                <Typography variant="h6" color="primary" gutterBottom>
                    TTS Hours of Operation
                </Typography>
                <Typography style={{ marginBottom: "38px" }}>
                    Set the TTS hours of operations for your restaurant.
                </Typography>
                <TTSTimePeriod
                    timeAvailabilities={timeAvailabilities}
                    updateTimePeriod={handleUpdateTimePeriod}
                    deleteTimePeriod={handleDeleteTimePeriod}
                    addTimePeriod={addTimePeriod}
                />
                <div className={classes.timezoneContainer}>
                    <Typography variant="h6" color="primary">
                        Time Zone
                    </Typography>
                    <Typography>Set the time zone of your location</Typography>
                    <Select
                        data-testid="time-zone-selector"
                        name="timeZone"
                        value={timeZone}
                        onChange={haadleTimeZoneChange}
                        style={{ marginTop: "24px" }}
                        disableUnderline
                        inputProps={{
                            "data-testid": "time-zone-input",
                        }}
                    >
                        {OFFSET_TIMEZONE.map(({ value, label }) => (
                            <MenuItem value={value} key={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </form>
        </div>
    );
}

export default memo(TTSOperationHours);
