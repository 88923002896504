import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getErrorMessages } from "../utils/errors";
import { FORM_NAMES } from "../utils/forms";
import { ErrorResponse } from "../utils/types";

interface FormFieldError {
    formField: string | string[];
    errorMessage: string;
}

interface FormError {
    errorMessage: string | string[] | null;
    formFieldErrors: Record<string, FormFieldError>;
}

export interface ErrorState {
    hasTopLevelError: boolean;
    topLevelError: string | string[] | null;
    /*
    This is the format for form errors
    addItemForm: {
        errorMessage: null,
        displayName: {
            formField: 'displayName',
            errorMessage: 'Display Name must be at least 4 characters'
        }
    }
     */
    formErrors: Record<string, FormError>;
}

const initialState: ErrorState = {
    hasTopLevelError: false,
    topLevelError: null,
    formErrors: {},
};

export const errorState = createSlice({
    name: "error",
    initialState: initialState,
    reducers: {
        clearErrors: (state) => {
            state.hasTopLevelError = false;
            state.topLevelError = null;
            state.formErrors = {};
        },
        loginFailure: (state, action: PayloadAction<ErrorResponse>) => {
            state.formErrors[FORM_NAMES.loginForm] = {
                errorMessage: getErrorMessages(action.payload),
                formFieldErrors: {},
            };
        },
    },
});

export const ERROR_ACTIONS = errorState.actions;
