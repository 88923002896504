import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    createFilterOptions,
} from "@material-ui/lab";
import QueryString from "query-string";
import React, { memo, useCallback, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Restaurant } from "../../generated-interfaces/graphql";
import { copyMenuVersionToRestaurant } from "../../reducers/menuReducer";
import { selectItemsToCopy } from "../../selectors/menu";
import { uniqueRestaurantByUserRoleSelector } from "../../selectors/restaurant";
import { MAX_SELECTIONS, TIME_OUT } from "../../utils/constants";
import { DATA_TABLE_DEFAULT_STYLING } from "../../utils/data-tables";
import { ICopyMenuVersionToRestaurant } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) => {
    const { spacing, palette } = theme;
    return {
        ...DATA_TABLE_DEFAULT_STYLING(theme),
        container: {
            width: "100%",
            padding: spacing(3, 4),
            backgroundColor: palette.background.paper,
            "& > *": {
                marginBottom: spacing(2.5),
            },
        },
        restaurantList: {
            listStyle: "number",
            "& > li": {
                padding: spacing(0.5),
            },
        },
        cancelBtn: {
            textAlign: "right",
            marginRight: spacing(2.5),
        },
        exportBtn: {
            textAlign: "right",
        },
        copyMenuSelector: {
            width: spacing(62.5),
        },
        checkbox: { marginRight: spacing(1) },
        allowDuplicateCheckbox: {
            margin: `0 ${spacing(1)}px ${spacing(1)}px 0`,
            paddingLeft: 0,
        },
        backBtn: { paddingLeft: 0 },
    };
});

const MenuExport = () => {
    const classes = useStyles();
    const { location, goBack } = useHistory();
    const dispatch = useDispatch();
    const alert = useAlert();
    const restaurantsMap = useSelector(uniqueRestaurantByUserRoleSelector);
    const itemsToCopy = useSelector(selectItemsToCopy);
    const [selectedOptions, setSelectedOptions] = useState<Restaurant[]>(
        [] as Restaurant[]
    );
    const [openDialog, setOpenDialog] = useState(false);
    const [allowDuplicates, setAllowDuplicates] = useState<boolean>(false);
    const queryParams = QueryString.parse(location.search);
    const { version: menuVersion = "", menuTitle } = queryParams;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const isMenuVersionExport = !!(menuVersion || menuTitle);
    const menuVersionText = `Menu Version ${menuVersion} (${menuTitle})`;
    const title = isMenuVersionExport
        ? `Export ${menuVersionText} to`
        : "Export selected items to restaurants";
    const dialogTitle = `Export ${
        isMenuVersionExport ? menuVersionText : "selected items"
    } to ${selectedOptions.length} restaurants:`;
    const getRestaurantTitle = useCallback(
        ({ restaurantName, restaurantCode }: Restaurant) =>
            `${restaurantName} (${restaurantCode})`,
        []
    );

    const filterOptions = createFilterOptions({
        stringify: getRestaurantTitle,
    });

    const handleChange = useCallback((_: any, selectedItems: Restaurant[]) => {
        setSelectedOptions(selectedItems);
    }, []);

    const handleGoBack = useCallback(() => {
        goBack();
    }, [goBack]);

    const toggleDialog = useCallback(() => {
        setOpenDialog(!openDialog);
    }, [openDialog]);

    const handleExport = useCallback(() => {
        setOpenDialog(true);
    }, []);

    const handleFinalExport = useCallback(() => {
        toggleDialog();
        const itemsToCopyArr = Object.values(itemsToCopy);
        const payload: ICopyMenuVersionToRestaurant = {
            commitId: menuVersion?.toString() || "",
            applicableRestaurants: selectedOptions.map(
                ({ restaurantCode }) => restaurantCode
            ),
            allowDuplicates,
            successCallback: /* istanbul ignore next */ () => {
                alert.success("Menu is exported successfully", {
                    timeout: TIME_OUT,
                });
                goBack();
            },
            errorCallback: /* istanbul ignore next */ () => {
                alert.error("Menu export failed", {
                    timeout: TIME_OUT,
                });
            },
        };
        if (itemsToCopyArr?.length) {
            payload.itemIds = itemsToCopyArr.map(({ id }) => Number(id));
        }
        dispatch(copyMenuVersionToRestaurant(payload));
        setSelectedOptions([]);
    }, [
        toggleDialog,
        menuVersion,
        selectedOptions,
        dispatch,
        alert,
        goBack,
        itemsToCopy,
        allowDuplicates,
    ]);

    const handleRenderOption = useCallback(
        (option: Restaurant, { selected }: any) => (
            <div>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                />
                {getRestaurantTitle(option)}
            </div>
        ),
        [checkedIcon, classes, icon, getRestaurantTitle]
    );

    const handleGetOptionLabel = useCallback(
        (option) =>
            selectedOptions?.length === MAX_SELECTIONS &&
            !selectedOptions?.includes(option),
        [selectedOptions]
    );

    const handleRenderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                label={`Search location to export to (Max ${MAX_SELECTIONS} selections)`}
                placeholder="Search"
            />
        ),
        []
    );

    const handleAllowedDuplicatesChange = useCallback((_, isChecked) => {
        setAllowDuplicates(isChecked);
    }, []);

    const getAutoCompleteComp = (
        <Autocomplete
            renderOption={handleRenderOption}
            value={selectedOptions}
            getOptionDisabled={handleGetOptionLabel}
            getOptionLabel={getRestaurantTitle}
            className={classes.copyMenuSelector}
            renderInput={handleRenderInput}
            data-testid="restaurants-to-export"
            disableCloseOnSelect
            options={restaurantsMap}
            multiple
            onChange={handleChange}
            filterOptions={filterOptions}
        />
    );

    return (
        <Box className={classes.container}>
            <Grid container justify="space-between" id="action-items">
                <Grid item>
                    <IconButton
                        onClick={handleGoBack}
                        data-testid="go-back-btn"
                        className={classes.backBtn}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.cancelBtn}
                        variant="outlined"
                        size="medium"
                        onClick={handleGoBack}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.exportBtn}
                        color="secondary"
                        variant="contained"
                        size="medium"
                        disabled={!selectedOptions.length}
                        onClick={handleExport}
                        data-testid="intiate-export"
                    >
                        Export
                    </Button>
                </Grid>
            </Grid>
            <Typography
                className={classes.header}
                component="h4"
                variant="h6"
                color="primary"
            >
                {title}
            </Typography>
            {isMenuVersionExport ? (
                getAutoCompleteComp
            ) : (
                <>
                    <Grid container direction="row" style={{ minHeight: 400 }}>
                        <Grid item sm={4}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                className={classes.allowDuplicateCheckbox}
                                checked={allowDuplicates}
                                onChange={handleAllowedDuplicatesChange}
                                color="primary"
                                data-testid="allowed-duplicates-checkbox"
                            />
                            <Typography variant="h6" component="span">
                                Allow Duplicates
                            </Typography>
                            <Typography variant="h6" component="h4">
                                Selected Items:
                            </Typography>
                            <ul
                                style={{
                                    listStyle: "number",
                                    fontSize: "1.25rem",
                                }}
                            >
                                {Object.values(itemsToCopy).map(({ name }) => (
                                    <li key={name}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                        >
                                            {name}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                        <Divider
                            orientation="vertical"
                            flexItem
                            style={{ margin: "0 20px", width: 4 }}
                            variant="middle"
                        />
                        <Grid item sm={7}>
                            <Typography variant="h6" component="h4">
                                Select Restaurants:
                            </Typography>
                            {getAutoCompleteComp}
                        </Grid>
                    </Grid>
                </>
            )}
            {isMenuVersionExport && (
                <b>
                    <u>Note</u>: This will create a new unverified Menu Version
                    for the selected Restaurants
                </b>
            )}
            <Dialog
                open={openDialog}
                onClose={toggleDialog}
                data-testid="export-dialog"
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <ul className={classes.restaurantList}>
                        {selectedOptions.map(
                            ({ restaurantName, restaurantCode }) => (
                                <li key={restaurantCode}>
                                    {`${restaurantName} (${restaurantCode})`}
                                </li>
                            )
                        )}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleFinalExport}
                        data-testid="export-btn"
                    >
                        Export
                    </Button>
                    <Button
                        onClick={toggleDialog}
                        variant="outlined"
                        data-testid="cancel-btn"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default memo(MenuExport);
