import DateFnsUtils from "@date-io/date-fns";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
    TextField,
    TextFieldProps,
    Theme,
    Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../../components/ConfirmDialog";
import TimePeriodComponent from "../../../components/menu/TimePeriod";
import ReadOnlyWrapper from "../../../components/ReadOnlyWrapper";
import { MenuType } from "../../../constants/menu";
import {
    AvailabilityInput,
    TimePeriod,
} from "../../../generated-interfaces/graphql";
import { useCustomHistory } from "../../../hooks";
import {
    createTimePeriod,
    deleteTimePeriod,
    updateTimePeriod,
} from "../../../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../../../selectors/restaurant";
import { TimePeriodInputType } from "../../../types/menu";
import { AVAILABILITY_STATE, TIME_OUT } from "../../../utils/constants";
import { timePeriodsSelector } from "../../../utils/menu";
import { DeleteItemType } from "../../../utils/types";
import ActionItems from "../ActionItems";

const DatePickerTextField = (props: TextFieldProps) => (
    <TextField {...props} variant="outlined" />
);

const useStyles = makeStyles((theme: Theme) => ({
    dateSpecificMealPeriodCheckbox: {
        marginTop: theme.spacing(1),
    },
    timePeriodContainer: {
        [theme.breakpoints.down("xs")]: {
            padding: "0 8px",
        },
    },
    saveBtn: {
        float: "right",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
    removeBtn: {
        float: "right",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
}));

function NewTimePeriod(props: Props) {
    const { id } = useParams<{ id: string }>();
    const classes = useStyles();
    const alert = useAlert();

    const timePeriodMap = useSelector(timePeriodsSelector);
    const [timePeriod, setTimePeriod] = useState<TimePeriod>({
        id: "",
        description: "",
        timePeriodCategoryMappings: [],
        availability: [],
        startDate: null,
        endDate: null,
    });
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [timeAvailabilities, setTimeAvailabilities] = useState<
        AvailabilityInput[]
    >([]);

    const [startDateError, setStartDateError] = useState(false);
    const [isDateSpecificMealPeriod, setIsDateSpecificMealPeriod] = useState(
        false
    );

    const initialValue = timePeriodMap[id];
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { pushToHistory } = useCustomHistory();

    useEffect(() => {
        if (id === "new") {
            const newTimePeriods: AvailabilityInput[] = [];
            for (let i = 0; i < 7; i++) {
                newTimePeriods.push({
                    day: i,
                    hours: [],
                    alwaysEnabled: true,
                });
            }
            setTimeAvailabilities(newTimePeriods);
            resetStateToDefault();
        }
    }, [id]);

    useEffect(() => {
        if (initialValue) {
            let availability = initialValue.availability;

            setIsDateSpecificMealPeriod(Boolean(initialValue.startDate));

            setTimePeriod({
                id: initialValue.id,
                startDate: initialValue.startDate
                    ? new Date(initialValue.startDate)
                    : null,
                endDate: initialValue.endDate
                    ? new Date(initialValue.endDate)
                    : null,
                description: initialValue.description,
                timePeriodCategoryMappings:
                    initialValue.timePeriodCategoryMappings,
                availability,
            });
            const newTimePeriods: AvailabilityInput[] = [];
            for (let i = 0; i < 7; i++) {
                const aIndex = availability.findIndex(
                    (timePeriod) => timePeriod.day === i
                );
                if (aIndex > -1) {
                    const timeAvailability = availability[aIndex];
                    newTimePeriods.push({
                        day: timeAvailability.day,
                        alwaysEnabled: timeAvailability.alwaysEnabled,
                        hours: timeAvailability.hours || [],
                    });
                } else {
                    newTimePeriods.push({
                        day: i,
                        hours: [],
                        alwaysEnabled: false,
                    });
                }
            }
            setTimeAvailabilities([...newTimePeriods]);
        }
    }, [initialValue]);

    const toggleIsDateSpecificMealPeriod = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.checked) {
                setTimePeriod((timePeriod) => ({
                    ...timePeriod,
                    startDate: null,
                    endDate: null,
                }));
            }
            setIsDateSpecificMealPeriod(e.target.checked);
        },
        []
    );

    const setStartDate = (date: Date | null) => {
        setStartDateError(false);
        setTimePeriod((timePeriod) => ({
            ...timePeriod,
            startDate: date,
        }));
    };

    const setEndDate = (date: Date | null) => {
        setTimePeriod((timePeriod) => ({
            ...timePeriod,
            endDate: date,
        }));
    };

    const handleUpdateTimePeriod = (
        day: number,
        hour: [number, number],
        index: number
    ) => {
        let selectedTimePeriod = { ...timeAvailabilities[day] };
        let hours = selectedTimePeriod.hours
            ? [...selectedTimePeriod.hours]
            : [];
        if (hours.length > 0) hours.splice(index, 1, hour);
        selectedTimePeriod = { ...selectedTimePeriod, hours };

        timeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...timeAvailabilities]);
        setIsFormDirty(true);
    };

    const handleDeleteTimePeriod = (day: number, index: number) => {
        const selectedTimePeriod = timeAvailabilities[day];
        if (selectedTimePeriod.hours) {
            const hours = [...selectedTimePeriod.hours];
            hours.splice(index, 1);
            selectedTimePeriod.hours = hours;
        }
        timeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...timeAvailabilities]);
        setIsFormDirty(true);
    };

    const resetStateToDefault = () => {
        setShowConfirmModal(false);
        setShowBackConfirmModal(false);
        setTimePeriod({
            id: "",
            description: "",
            timePeriodCategoryMappings: [],
            availability: [],
            startDate: null,
            endDate: null,
        });
        setShowBackConfirmModal(false);
        setShowConfirmModal(false);
        setIsFormDirty(false);
    };

    const handleSubmit = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isDateSpecificMealPeriod && !timePeriod.startDate) {
            // start date is required when "Date Specific Meal Period" checkbox is checked
            setStartDateError(true);
            return;
        }

        if (timePeriod.id) {
            props.updateTimePeriod({
                ...timePeriod,
                rebuildCache: true,
                id: parseInt(timePeriod.id),
                availability: timeAvailabilities,
                restaurantCode: "",
                successCallback: () => {
                    alert.success("Meal Period Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: (err: string) => {
                    alert.error("Error Saving Meal Period", {
                        timeout: TIME_OUT,
                    });
                },
            });
        } else {
            props.createTimePeriod({
                startDate: timePeriod.startDate,
                endDate: timePeriod.endDate,
                description: timePeriod.description?.trim() || "",
                availability: timeAvailabilities,
                restaurantCode: "",
                rebuildCache: true,
                successCallback: () => {
                    alert.success("Meal Period Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: (err: string) => {
                    alert.error("Error Saving Meal Period", {
                        timeout: TIME_OUT,
                    });
                },
            });
        }
        setIsFormDirty(false);
    };

    const isValidHours = () => {
        const unAvailableTimeperiodIndex = timeAvailabilities.findIndex(
            (available) => {
                if (available && available.hours) {
                    const unAvailableIndex = available.hours.findIndex(
                        (hour) => {
                            return !(
                                hour &&
                                hour.length === 2 &&
                                hour[0] !== undefined &&
                                hour[1] !== undefined &&
                                hour[1] > hour[0]
                            );
                        }
                    );
                    return unAvailableIndex > -1;
                }
                return false;
            }
        );
        return unAvailableTimeperiodIndex === -1;
    };

    const addTimePeriod = (day: number) => {
        const newTimeAvailabilities = [...timeAvailabilities];
        let selectedTimePeriod = { ...newTimeAvailabilities[day] };
        let hours = selectedTimePeriod.hours
            ? [...selectedTimePeriod.hours, [900, 1700]]
            : [];
        selectedTimePeriod = { ...selectedTimePeriod, hours };
        newTimeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...newTimeAvailabilities]);
        setIsFormDirty(true);
    };

    const handleGoBack = () => {
        if (isFormDirty) {
            setShowBackConfirmModal(true);
        } else {
            pushToHistory(`/${restaurantCode}/menu-editor/meal-periods`);
        }
    };

    const handleRemoveTimePeriod = () => {
        setShowConfirmModal(true);
    };

    const updateTimePeriodAvailability = (day: number, value: string) => {
        let selectedTimePeriod = { ...timeAvailabilities[day] };
        if (value === AVAILABILITY_STATE.available) {
            selectedTimePeriod.alwaysEnabled = true;
            selectedTimePeriod.hours = [];
        }
        if (value === AVAILABILITY_STATE.unavailable) {
            selectedTimePeriod.alwaysEnabled = false;
            selectedTimePeriod.hours = [];
        }
        if (value === AVAILABILITY_STATE.exact) {
            selectedTimePeriod.alwaysEnabled = false;
            selectedTimePeriod.hours = [[900, 1700]];
        }
        timeAvailabilities.splice(day, 1, selectedTimePeriod);
        setTimeAvailabilities([...timeAvailabilities]);
    };

    return (
        <div className={classes.timePeriodContainer}>
            <form onSubmit={handleSubmit}>
                {showConfirmModal && (
                    <ConfirmDialog
                        content="Are you sure to delete this Time Period?"
                        open={showConfirmModal}
                        onSuccess={() => {
                            props.deleteTimePeriod({
                                id: parseFloat(id),
                                successCallback: () => {
                                    alert.success("Meal Period Deleted", {
                                        timeout: TIME_OUT,
                                    });
                                    pushToHistory(
                                        `/${restaurantCode}/menu-editor/meal-periods`
                                    );
                                },
                                errorCallback: () => {
                                    alert.error("Error Deleting Meal Period", {
                                        timeout: TIME_OUT,
                                    });
                                },
                            });
                            pushToHistory(
                                `/${restaurantCode}/menu-editor/meal-periods`
                            );
                        }}
                        onCancel={() => setShowConfirmModal(false)}
                    />
                )}
                {showBackConfirmModal && (
                    <ConfirmDialog
                        title="Leave without saving?"
                        content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                        open={showBackConfirmModal}
                        onSuccess={() => {
                            pushToHistory(
                                `/${restaurantCode}/menu-editor/meal-periods`
                            );
                        }}
                        onCancel={() => setShowBackConfirmModal(false)}
                    />
                )}
                <ActionItems
                    handleRemove={handleRemoveTimePeriod}
                    handleGoBack={handleGoBack}
                    disableSave={!isValidHours()}
                    id={id}
                    menuType={MenuType.mealPeriod}
                />
                <ReadOnlyWrapper
                    element={TextField}
                    label="Meal Period Name"
                    value={timePeriod.description}
                    fullWidth
                    required
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setTimePeriod({
                            ...timePeriod,
                            description: e.target.value as string,
                        })
                    }
                    margin="normal"
                    style={{ marginBottom: "40px" }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ReadOnlyWrapper
                                element={FormControlLabel}
                                className={
                                    classes.dateSpecificMealPeriodCheckbox
                                }
                                control={
                                    <Checkbox
                                        checked={isDateSpecificMealPeriod}
                                        color="primary"
                                        onChange={
                                            toggleIsDateSpecificMealPeriod
                                        }
                                    />
                                }
                                label="Date Specific Meal Period"
                            />
                        </Grid>
                        <Grid item>
                            <ReadOnlyWrapper
                                element={DatePicker}
                                disabled={!isDateSpecificMealPeriod}
                                required={isDateSpecificMealPeriod}
                                error={startDateError}
                                helperText={
                                    startDateError
                                        ? "This field is required"
                                        : null
                                }
                                margin="none"
                                label="Select start date"
                                format="dd/MM/yyyy"
                                value={timePeriod.startDate}
                                onChange={setStartDate}
                                TextFieldComponent={DatePickerTextField}
                            />
                        </Grid>
                        <Grid item>
                            <ReadOnlyWrapper
                                element={DatePicker}
                                clearable
                                disabled={!isDateSpecificMealPeriod}
                                margin="none"
                                minDate={timePeriod.startDate}
                                label="Select end date"
                                format="dd/MM/yyyy"
                                value={timePeriod.endDate}
                                onChange={setEndDate}
                                TextFieldComponent={DatePickerTextField}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
                <Typography variant="h6" color="primary" gutterBottom>
                    Periods
                </Typography>
                <Typography style={{ marginBottom: "38px" }}>
                    Create availability time windows for assigning Meal Periods
                    to Categories
                </Typography>
                <TimePeriodComponent
                    timeAvailabilities={timeAvailabilities}
                    changeAvailability={updateTimePeriodAvailability}
                    updateTimePeriod={handleUpdateTimePeriod}
                    deleteTimePeriod={handleDeleteTimePeriod}
                    addTimePeriod={addTimePeriod}
                />
            </form>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createTimePeriod: (data: Omit<TimePeriodInputType, "id">) =>
            dispatch(createTimePeriod(data)),
        updateTimePeriod: (data: TimePeriodInputType) =>
            dispatch(updateTimePeriod(data)),
        deleteTimePeriod: (data: DeleteItemType) =>
            dispatch(deleteTimePeriod(data)),
    };
};

type Props = ConnectedProps<typeof connected>;

const connected = connect(null, mapDispatchToProps);

export default connected(NewTimePeriod);
