import { TextFieldProps } from "@material-ui/core";
import React, { forwardRef } from "react";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

// Wrap MyComponent to forward the ref as expected by Tooltip
const WrappedToolTipRefComponent = forwardRef(
    function WrappedToolTipRefComponent(
        {
            element,
            ...props
        }: {
            // Created This type from NumericInput
            element: React.ReactNode;
            handleUpdate: (newValue: string) => void;
        } & TextFieldProps & {
                value: string;
                prefixText?: string;
                suffixText?: string;
            },
        ref
    ) {
        return <ReadOnlyWrapper element={element} {...props} innerRef={ref} />;
    }
);

export default WrappedToolTipRefComponent;
