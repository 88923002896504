import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Typography, makeStyles } from "@material-ui/core";

import { selectedRestaurantAccessSelector } from "../selectors/restaurant";
import { RolePermissions } from "../types/restaurant";
import { history } from "../store";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: theme.spacing(2),
    },
}));

const LimitedHome = () => {
    const classes = useStyles();

    const selectedRestaurantAccess = useSelector(
        selectedRestaurantAccessSelector
    );

    useEffect(() => {
        if (!selectedRestaurantAccess) return;
        if (selectedRestaurantAccess !== RolePermissions.Limited) {
            history.push("/");
        }
    }, [selectedRestaurantAccess]);

    return (
        <div className={classes.root}>
            <Typography variant="body1">
                Your current user permissions do not allow you to access other
                features.
            </Typography>
        </div>
    );
};

export default LimitedHome;
