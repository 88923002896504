interface PerfTimerOptions {
    /**
     * if true, then the timer will start immediately
     * @default true
     */
    autoStart?: boolean;
}

const startedAt = Symbol("startedAt");

/**
 * Timer for measuring performance with high precision.
 */
export class PerfTimer {
    [startedAt] = 0;

    constructor(options?: PerfTimerOptions) {
        const { autoStart = true } = options || {};
        if (autoStart) this.start();
    }

    /** High precision time in milliseconds, it can be in fractions */
    get startedAt() {
        return this[startedAt];
    }

    /**
     * Starts the timer, does nothing if already started
     */
    start() {
        if (!this[startedAt]) this[startedAt] = performance.now();
        return this[startedAt];
    }

    /**
     * Stops the timer and returns the time elapsed until now in milliseconds,
     * It is a rounded off value, so it will be an integer.
     *
     * Once stopped, it can be reused to start the timer again using start().
     *
     * Returns -1, if called before starting the timer.
     */
    stop() {
        if (!this[startedAt]) return -1;
        const stoppedAt = Math.round(performance.now() - this[startedAt]);
        this[startedAt] = 0;
        return stoppedAt;
    }
}
