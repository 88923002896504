import { useSelector } from "react-redux";
import {
    restaurantInfoSelector,
    selectedRestaurantAccessSelector,
} from "../selectors/restaurant";
import { RolePermissions } from "../types/restaurant";
import { readEnvVariable } from "../utils/helper-functions";
import { hasRoleAccess } from "../utils/restaurants";

export default function useDisplayMenuVersion() {
    const { restaurantCode, primaryRestaurantCode } =
        useSelector(restaurantInfoSelector) || {};
    const selectedRestaurantAccessLevel = useSelector(
        selectedRestaurantAccessSelector
    );
    const hasMenuVersionAccess = hasRoleAccess(
        RolePermissions.RestaurantManager,
        selectedRestaurantAccessLevel
    );
    return {
        displayMenuVersion:
            readEnvVariable("FEATURE_FLAG_MENU_VERSION").toUpperCase() ===
                "TRUE" &&
            hasMenuVersionAccess &&
            !!restaurantCode &&
            !primaryRestaurantCode,
        defaultMenuVersionSetting:
            !hasMenuVersionAccess || !!primaryRestaurantCode,
    };
}
