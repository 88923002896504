import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useReadOnly } from "../../hooks";
import { selectedPrimaryRestaurantCodeSelector } from "../../selectors/restaurant";

const useStyles = makeStyles(({ spacing }: Theme) => ({
    mirrorRestuarant: {
        color: "red",
        fontWeight: 700,
        fontSize: spacing(3),
        padding: spacing(1.25),
        textAlign: "right",
    },
}));

const MirrorRestaurantLabel = () => {
    const classes = useStyles();

    const { isMirrorRestro } = useReadOnly();
    const primaryRestaurantCode = useSelector(
        selectedPrimaryRestaurantCodeSelector
    );
    return isMirrorRestro ? (
        <div className={classes.mirrorRestuarant}>
            {`Mirrored Restaurant: ${primaryRestaurantCode || ""}`}
        </div>
    ) : null;
};

export default MirrorRestaurantLabel;
