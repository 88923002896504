import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import auth0 from "../pages/Auth/Auth0/auth0";
import { getAuthInfo } from "./utilsSaga";

import {
    fetchUserLoginStatus,
    loginUser,
    MfLoginUser,
    verifyGoogleAuthToken,
} from "../api/login";
import { fetchRestaurantListCall } from "../api/restaurant";
import {
    ChangePasswordMutation,
    ChangePaswordInput,
    CompletePasswordResetMutationVariables,
    LoginInput,
    MfUserProfile,
    StartPasswordResetMutation,
    UserProfile,
} from "../generated-interfaces/graphql";
import { ERROR_ACTIONS } from "../reducers/errorReducer";
import { MENU_ACTIONS } from "../reducers/menuReducer";
import {
    getAIEnablement,
    getRestaurantRolesAction,
    getTREnablement,
    getTTSScheduler,
    RESTAURANT_ACTIONS,
} from "../reducers/restaurantReducer";
import { RootState } from "../reducers/rootReducer";
import {
    changePassword,
    checkAuthStatusAction,
    completePasswordReset,
    createUserSessionAction,
    deleteUserSessionAction,
    loadUserState,
    loginAction,
    mfLoginAction,
    retrieveUserSessionAction,
    startPasswordreset,
    updateHeartBeatAction,
    updateUserSessionAction,
    auth0LogOutRequestAction,
    auth0HandleAuthAction,
    auth0LoginRequestAction,
    USER_ACTIONS,
    verifyGAuthTokenAction,
} from "../reducers/userReducer";
import {
    selectForceLogoutApi,
    selectMenuAuthApi,
} from "../redux/features/config/config.selector";
import {
    restaurantAccessLevelsSelector,
    selectedRestaurantCodeSelector,
} from "../selectors/restaurant";
import { currentUserSelector, getTokenSelector } from "../selectors/user";
import { Android } from "../types/android";
import { IActiveUserReqType } from "../types/menu";
import { RolePermissions, UserRestaurantsRole } from "../types/restaurant";
import { SOURCE_MODULE } from "../utils/constants";
import {
    getAuthToken,
    getGoogleId,
    getSessionID,
    saveGoogleId,
    saveSessionID,
} from "../utils/local-storage";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";
import { hasRoleAccess, RestaurantAccess } from "../utils/restaurants";
import { IRequestType, StringOrNull } from "../utils/types";
import {
    createUserSession,
    deleteUserSession,
    getUserSessions,
    updateUserSession,
} from "../utils/webserviceAPI";
import { jwtDecode } from "jwt-decode";

const isTokenExpired = (token: any) => {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
};

function* loadSavedUserState() {
    logger.debug("Attempting to load previous user state");
    let user: any;
    try {
        const claims = yield call([auth0, auth0.getIdTokenClaims]);
        if (claims) {
            if (isTokenExpired(claims.__raw)) {
                logger.error(
                    "Invalid saved auth token",
                    new Error("Token expired.Please log in again")
                );
            } else {
                user = yield call([auth0, auth0.getUser]);
                yield put(USER_ACTIONS.auth0LogInSuccess({ user }));
            }
        } else {
            const authToken = getAuthToken();
            if (authToken) {
                const url: string = yield select(selectMenuAuthApi);
                const restaurantCode: string = yield select(
                    selectedRestaurantCodeSelector
                );
                user = yield call(fetchUserLoginStatus, {
                    url,
                    restaurantCode,
                });
                yield put(USER_ACTIONS.loginSuccess(user));
            }
        }
        yield getRestaurantAccess();
    } catch (error) {
        logger.error("Invalid saved auth token", error);
    }
    yield put(USER_ACTIONS.loadUserStateSuccess());
}

function* getRestaurantAccess() {
    logger.debug("Attempting to get restaurantsByUserRole");
    const isLoggedIn: boolean = yield select((state: RootState) => {
        return state.user.isLoggedIn;
    });
    if (!isLoggedIn) {
        logger.info("Not Logged In For getRestaurantAccess");
        return;
    }
    try {
        const { token, isAuth0 } = yield call(getAuthInfo);
        const userRoles: UserRestaurantsRole[] = yield call(
            fetchRestaurantListCall,
            isAuth0,
            token,
            { data: "roles" }
        );
        yield put(RESTAURANT_ACTIONS.userRolesLoadSuccess(userRoles));
        yield put(MENU_ACTIONS.updateDidInitialLoad(false));
    } catch (error) {
        logger.error("Failed Getting Restaurant Access", error);
        yield put(RESTAURANT_ACTIONS.userRolesLoadFailure());
    }
}

function* login(action: PayloadAction<LoginInput>) {
    logger.debug("Attempting to log the user in");
    const { email, password } = action.payload;
    const url: string = yield select(selectMenuAuthApi);
    try {
        const user: UserProfile = yield call(loginUser, {
            email,
            password,
            url,
        });
        yield put(USER_ACTIONS.loginSuccess(user));
        yield put(createUserSessionAction());
        logger.debug("Attempting to get restaurantsByUserRole after login");
        yield getRestaurantAccess();
        yield put(ERROR_ACTIONS.clearErrors());
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        logger.debug("Successfully logged in user");
        if (restaurantCode) yield put(push(`/${restaurantCode}/menu-editor`));
        else yield put(push(`/home`));
    } catch (errorResponse) {
        logger.error("Login Failed", errorResponse);
        yield put(
            ERROR_ACTIONS.loginFailure(
                errorResponse.response?.errors || [{ message: "login Failed" }]
            )
        );
        yield put(RESTAURANT_ACTIONS.userRolesLoadFailure());
    }
}

function* checkAuthStatus(action: PayloadAction<{ restaurantCode: string }>) {
    logger.debug("Attempting to check auth status");
    yield put(RESTAURANT_ACTIONS.updateIsRestaurantStatusRecieved(false));
    try {
        const userState: any = yield select((state: RootState) => state.user);
        if (!userState.isLoggedIn) {
            logger.info("Not Logged In For checkAuthStatus");
            return;
        }
        const { restaurantCode } = action.payload;
        if (!userState.isAuth0) {
            const url: string = yield select(selectMenuAuthApi);
            const user: UserProfile = yield call(fetchUserLoginStatus, {
                url,
                restaurantCode,
            });
            yield put(USER_ACTIONS.authCheckSuccess(user));
        }
        const accessLevels: RestaurantAccess = yield select(
            restaurantAccessLevelsSelector
        );
        yield put(RESTAURANT_ACTIONS.updateIsRestaurantStatusRecieved(true));
        if (
            hasRoleAccess(
                RolePermissions.RestaurantManager,
                accessLevels[restaurantCode]?.role || null
            )
        ) {
            yield put(getTREnablement(restaurantCode));
            yield put(getTTSScheduler(restaurantCode));
            yield put(getAIEnablement(restaurantCode));
        }
    } catch (error) {
        logger.error("Auth Status Check Failed", error);
        // Don't redirect if it's a network error. GraphQL will always return 200
        if (error && error.response && error.response.status === 200) {
            // @ts-ignore
            const AndroidFns: Android = window.Android;
            if (AndroidFns && AndroidFns.sessionTokenInvalid) {
                // Android will take care of logging out
                AndroidFns.sessionTokenInvalid();
            } else {
                yield put(USER_ACTIONS.logout());
                yield put(push("/login"));
            }
        }
    }
}

function* startPasswordResetSaga(action: PayloadAction<{ email: string }>) {
    logger.debug("Attempting to reset password");
    const token: StringOrNull = yield select(getTokenSelector);
    const sdk = getSdk(token);
    try {
        const startingResetPassword: StartPasswordResetMutation = yield sdk.startPasswordReset(
            action.payload
        );
        if (startingResetPassword.startPasswordReset)
            yield put(USER_ACTIONS.passwordResetCodeSuccess());
    } catch (error) {
        logger.error("Start Password Reset Failed", error);
    }
}

function* changePassowrdSaga(action: PayloadAction<ChangePaswordInput>) {
    logger.debug("Attempting to change password");
    const token: StringOrNull = yield select(getTokenSelector);
    const sdk = getSdk(token);
    try {
        const changingPassword: ChangePasswordMutation = yield sdk.changePassword(
            action.payload
        );
        console.log("changePassowrdSaga", changingPassword);
    } catch (error) {
        logger.error("Change Password Failed", error);
    }
}

function* completePasswordResetSaga(
    action: PayloadAction<CompletePasswordResetMutationVariables>
) {
    logger.debug("Attempting to complete password reset");
    const token: StringOrNull = yield select(getTokenSelector);
    const sdk = getSdk(token);
    try {
        yield sdk.completePasswordReset(action.payload);
        yield put(USER_ACTIONS.resetCompleteSuccess());
    } catch (error) {
        logger.error("Complete Password Reset Failed", error);
        yield put(USER_ACTIONS.resetCompleteFail());
    }
}

function* createUserSessionSaga() {
    const authToken = getAuthToken();
    if (authToken) {
        const userProfile: UserProfile = yield select(currentUserSelector);
        const url: string = yield select(selectForceLogoutApi);
        const sessionId = uuidv4();
        const { email, firstName, lastName, username } = userProfile;
        const request = {
            user_session_id: sessionId,
            source_module: SOURCE_MODULE,
            email_id: email,
            first_name: firstName,
            last_name: lastName,
            user_name: username,
            unique_user_id: username,
            active: 1,
        };

        yield call(createUserSession, url, request);
        saveSessionID(sessionId);
        logger.info("Complete user session");
    }
}

function* updateUserSessionSaga(action: PayloadAction<string>) {
    try {
        const sessionid = getSessionID();
        if (sessionid) {
            const userProfile: UserProfile = yield select(currentUserSelector);
            const url: string = yield select(selectForceLogoutApi);
            const token: StringOrNull = yield select(getTokenSelector);
            const restaurantCode = action.payload;
            const { email, firstName, lastName, username } = userProfile;
            const request = {
                user_session_id: sessionid,
                source_module: SOURCE_MODULE,
                email_id: email,
                first_name: firstName,
                last_name: lastName,
                user_name: username,
                unique_user_id: username,
                restaurant_code: restaurantCode,
                active: 1,
            };

            yield call(updateUserSession, url, sessionid, request, token);
            logger.info("Complete user session Update");
            yield put(retrieveUserSessionAction({ restaurantCode }));
        }
    } catch (error) {
        logger.error("User session update error", error);
    }
}

function* retrieveUserSessionSaga(action: PayloadAction<IActiveUserReqType>) {
    try {
        const sessionid = getSessionID();
        if (sessionid) {
            const { restaurantCode, successCallback } = action.payload;
            const url: string = yield select(selectForceLogoutApi);
            const token: StringOrNull = yield select(getTokenSelector);
            const { data } = yield call(
                getUserSessions,
                url,
                restaurantCode,
                token
            );
            yield put(USER_ACTIONS.setSelectedRestaurantUserSession(data));
            logger.info(`Complete user session retrieve`, data);
            successCallback?.(data);
        }
    } catch (error) {
        logger.error("User session retrieve error", error);
    }
}

function* deleteUserSessionSaga(action: PayloadAction<string>) {
    try {
        const url: string = yield select(selectForceLogoutApi);
        const token: StringOrNull = yield select(getTokenSelector);
        yield call(deleteUserSession, url, action.payload, token);
    } catch (error) {
        logger.error("Delete user session", error);
    }
}

function* updateHeartBeatSessionSaga(action: PayloadAction<string>) {
    try {
        const sessionid = getSessionID();
        if (sessionid) {
            const url: string = yield select(selectForceLogoutApi);
            const userProfile: UserProfile = yield select(currentUserSelector);
            const token: StringOrNull = yield select(getTokenSelector);
            const request = {
                user_session_id: sessionid,
                source_module: SOURCE_MODULE,
                email_id: userProfile.email,
                first_name: userProfile.firstName,
                last_name: userProfile.lastName,
                user_name: userProfile.username,
                unique_user_id: userProfile.username,
                restaurant_code: action.payload,
                active: 1,
            };

            yield call(updateUserSession, url, sessionid, request, token);
            logger.info("Complete heart beat session Update");
        }
    } catch (error) {
        logger.error("User heart beat session update error", error);
    }
}

function* verifyGAuthTokenSaga(action: PayloadAction<IRequestType>) {
    const { payload, successCallback, errorCallback } = action.payload;
    try {
        logger.debug("Attempting to verify user");
        const url: string = yield select(selectMenuAuthApi);
        const verifyGAuthToken: MfUserProfile = yield call(
            verifyGoogleAuthToken,
            {
                source: SOURCE_MODULE,
                token: payload,
                url,
            }
        );
        saveGoogleId(payload);
        yield put(ERROR_ACTIONS.clearErrors());
        yield put(USER_ACTIONS.setMFLoginState(verifyGAuthToken));
        successCallback?.();
        return verifyGAuthToken;
    } catch (error) {
        logger.error("error in verifying g auth token | Login Failed", error);
        errorCallback?.();
        yield put(
            ERROR_ACTIONS.loginFailure(
                error?.response?.errors || [
                    {
                        message:
                            "error in verifying g auth token | Login Failed",
                    },
                ]
            )
        );
    }
}

function* mfLoginSaga(action: PayloadAction<IRequestType>) {
    const { payload, successCallback, errorCallback } = action.payload;
    try {
        logger.debug("Attempting to verify user");
        const token = getGoogleId();
        if (token) {
            const url: string = yield select(selectMenuAuthApi);
            const user: UserProfile = yield call(MfLoginUser, {
                source: SOURCE_MODULE,
                otp: payload,
                token,
                url,
            });
            yield put(USER_ACTIONS.loginSuccess(user));
            yield put(createUserSessionAction());
            logger.debug("Attempting to get restaurantsByUserRole after login");
            yield getRestaurantAccess();
            yield put(ERROR_ACTIONS.clearErrors());
            const restaurantCode: string = yield select(
                selectedRestaurantCodeSelector
            );
            logger.debug("Successfully logged in user");
            if (restaurantCode)
                yield put(push(`/${restaurantCode}/menu-editor`));
            else yield put(push(`/home`));
        }
        successCallback?.();
    } catch (error) {
        logger.error("error in verifying g auth token", error);
        logger.error("Login Failed", error);

        let navigateToLogin = false;

        if (error.response?.errors?.length) {
            const { extensions } = error.response.errors[0];
            if (extensions?.exception?.extraFields?.length) {
                extensions.exception.extraFields.find(
                    (o: { name: string; value: string }) => {
                        if (o.name === "navigateToLogin") {
                            if (o.value === "YES") {
                                navigateToLogin = true;
                            }
                            return true;
                        }
                    }
                );
            }
        }

        if (navigateToLogin) {
            errorCallback?.(true);
            yield put(ERROR_ACTIONS.loginFailure(error.response?.errors));
        } else {
            errorCallback?.();
            yield put(
                ERROR_ACTIONS.loginFailure(
                    error.response?.errors || [
                        {
                            message:
                                "error in verifying g auth token | Login failed",
                        },
                    ]
                )
            );
        }
    }
}

export default function* userSaga() {
    yield takeEvery(loginAction.toString(), login);
    yield takeEvery(checkAuthStatusAction.toString(), checkAuthStatus);
    yield takeEvery(getRestaurantRolesAction.toString(), getRestaurantAccess);
    yield takeEvery(loadUserState.toString(), loadSavedUserState);
    yield takeEvery(startPasswordreset.toString(), startPasswordResetSaga);
    yield takeEvery(changePassword.toString(), changePassowrdSaga);
    yield takeEvery(
        completePasswordReset.toString(),
        completePasswordResetSaga
    );
    yield takeEvery(createUserSessionAction.toString(), createUserSessionSaga);
    yield takeEvery(updateUserSessionAction.toString(), updateUserSessionSaga);
    yield takeEvery(
        retrieveUserSessionAction.toString(),
        retrieveUserSessionSaga
    );

    yield takeEvery(deleteUserSessionAction.toString(), deleteUserSessionSaga);
    yield takeEvery(
        updateHeartBeatAction.toString(),
        updateHeartBeatSessionSaga
    );

    yield takeEvery(verifyGAuthTokenAction.toString(), verifyGAuthTokenSaga);
    yield takeEvery(mfLoginAction.toString(), mfLoginSaga);
    yield takeEvery(auth0LoginRequestAction.toString(), auth0LoginSaga);
    yield takeEvery(auth0HandleAuthAction.toString(), auth0HandleAuthSaga);
    yield takeEvery(auth0LogOutRequestAction.toString(), auth0LogOut);
}

function* auth0LoginSaga() {
    try {
        yield call([auth0, auth0.loginWithRedirect]);
    } catch (error) {
        yield put(ERROR_ACTIONS.loginFailure(error));
    }
}

function* auth0HandleAuthSaga() {
    try {
        yield call([auth0, auth0.handleRedirectCallback]);
        const user: any = yield call([auth0, auth0.getUser]);
        const idToken: any = yield call([auth0, auth0.getIdTokenClaims]);
        yield put(
            USER_ACTIONS.auth0LogInSuccess({ user, token: idToken.__raw })
        );
        logger.debug("Attempting to get restaurantsByUserRole after login");
        yield getRestaurantAccess();
        yield put(ERROR_ACTIONS.clearErrors());
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        logger.info("Successfully logged in user");
        if (restaurantCode) {
            yield put(push(`/${restaurantCode}/menu-editor`));
        } else {
            yield put(push(`/home`));
        }
    } catch (error) {
        error.message += " Please try login again";
        yield put(ERROR_ACTIONS.loginFailure([error]));
    }
}

function* auth0LogOut() {
    try {
        const isAuthenticated: any = yield call([auth0, auth0.isAuthenticated]);
        if (isAuthenticated) {
            yield put(USER_ACTIONS.auth0LogOut());
            yield auth0.logout({
                logoutParams: { returnTo: `${window.location.origin}/login` },
            });
        }
    } catch (error) {
        logger.error("Log out error", error);
    }
}
