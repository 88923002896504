import { useEffect, useState } from "react";
import { SYNONYM_KEY } from "../constants";
import { isPropertySetToTrue } from "../pages/menu-editor/utils";
import { InputProperties } from "../types/menu";
import { IS_UPSELL } from "../utils/constants";
import logger from "../utils/logger";

export type useVoicePropertiesType = {
    isUpselling: boolean;
    voiceProperties: InputProperties[];
    setVoiceProperties: (T: InputProperties[]) => void;
    synonymProperties: InputProperties[];
    setSynonymProperties: (T: InputProperties[]) => void;
    getVoicePropertiesWithSynonyms: () => InputProperties[];
};

function parseValue(value: string) {
    try {
        return JSON.parse(value);
    } catch {}
    return value;
}

export default function useVoiceProperties<
    T extends { voiceProperties: Record<string, string> }
>(entity: T | undefined): useVoicePropertiesType {
    const [voiceProperties, setVoiceProperties] = useState<InputProperties[]>(
        []
    );
    const [synonymProperties, setSynonymProperties] = useState<
        InputProperties[]
    >([]);

    const voicePropertyMap = entity?.voiceProperties;

    const isUpselling = isPropertySetToTrue(
        voicePropertyMap?.[IS_UPSELL] ?? null
    );

    useEffect(() => {
        const voiceProps: InputProperties[] = [];
        const synonymProps: InputProperties[] = [];

        for (const [key, value] of Object.entries(voicePropertyMap ?? {})) {
            if (key !== SYNONYM_KEY) {
                voiceProps.push({ key, value });
                continue;
            }

            const parsedValue = parseValue(value);

            if (Array.isArray(parsedValue)) {
                parsedValue.forEach((value) => {
                    synonymProps.push({ key: SYNONYM_KEY, value });
                });
            } else {
                synonymProps.push({ key, value });
                logger.warn(
                    `Synonym property is expected to be an Array but got: ${value}`
                );
            }
        }

        setVoiceProperties(voiceProps);
        setSynonymProperties(synonymProps);

        return () => {
            setVoiceProperties([]);
            setSynonymProperties([]);
        };
    }, [voicePropertyMap]);

    const getVoicePropertiesWithSynonyms = () => {
        const synonymSet = new Set<string>();

        // Loop through synonyms array and populate it as a voice property
        for (const { value } of synonymProperties) {
            if (value) synonymSet.add(value);
        }

        if (synonymSet.size > 0) {
            return [
                ...voiceProperties,
                {
                    key: SYNONYM_KEY,
                    value: JSON.stringify(Array.from(synonymSet)),
                },
            ];
        }

        return voiceProperties;
    };

    return {
        isUpselling,
        voiceProperties,
        setVoiceProperties,
        synonymProperties,
        setSynonymProperties,
        getVoicePropertiesWithSynonyms,
    };
}
