import { MaterialTableProps } from "material-table";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../components/menuVersion/CustomChip";
import { fetchMenuCommitStage } from "../../reducers/menuReducer";
import { selectVersionList } from "../../selectors/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    IMenuHistory,
    MenuStages,
    menuStagesStatusMapping,
} from "../../types/menuVersion";
import { BannerColorMapping, DATE_FORMATS } from "../../utils/constants";
import { CustomizedMaterialTable } from "../../utils/data-tables";
import { formatDate } from "../../utils/helper-functions";
import MenuHistoryItemRowActions from "./MenuHistoryItemRowActions";

const MenuVersionList = () => {
    const versionList = useSelector(selectVersionList);
    const dispatch = useDispatch();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    useEffect(() => {
        if (restaurantCode) {
            dispatch(fetchMenuCommitStage());
        }
    }, [dispatch, restaurantCode]);

    const menuHistoryActiveTableConfig: MaterialTableProps<IMenuHistory> = {
        columns: [
            {
                title: "Menu version",
                field: "commitId",
            },
            {
                title: "Status",
                field: "stage",
                render: ({ stage, isActive }) => {
                    const { LIVE, PRELIVE, PLAYGROUND, ISACTIVE } = MenuStages;
                    const labelKey =
                        isActive && stage === LIVE
                            ? ISACTIVE
                            : stage === PLAYGROUND
                            ? PLAYGROUND
                            : PRELIVE;
                    return (
                        <CustomChip
                            label={menuStagesStatusMapping[labelKey]}
                            customColor={BannerColorMapping[labelKey]}
                        />
                    );
                },
            },
            {
                title: "Menu title",
                field: "comment",
            },
            {
                title: "Created on",
                field: "createdAt",
                render: ({ createdAt }) =>
                    formatDate(createdAt, DATE_FORMATS.dateAndTime),
            },
            {
                title: "Created by",
                field: "creatorName",
            },
            {
                title: "Actions",
                render: (data) => {
                    return <MenuHistoryItemRowActions data={data} />;
                },
                align: "right",
            },
        ],
        data: versionList.map((o) => ({ ...o })), // Shallow copy objects b/c they are immutable
    };

    return (
        <div>
            <CustomizedMaterialTable {...menuHistoryActiveTableConfig} />
        </div>
    );
};
export default memo(MenuVersionList);
