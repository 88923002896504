import { makeStyles } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import { ModifierGroupWithMenuItems } from "../../utils/menu";
import MultiSelectAutocomplete from "../common/MultiSelectAutocomplete";
import DragModifierGroup from "./DragModifierGroup";

const useStyles = makeStyles(({ spacing }) => ({
    drag_container_body: {
        marginBottom: spacing(7.5),
    },
}));

type ModifierGroupProps = {
    modifyGroups: ModifierGroupWithMenuItems[];
    modifierGroupList: ModifierGroupWithMenuItems[];
    setModifierGroupList: (
        modifierGroupList: ModifierGroupWithMenuItems[]
    ) => void;
    handleRemoveFromModifierGroupList: (id: string) => void;
};

const DragModifierGroupContainer = (props: ModifierGroupProps) => {
    const { drag_container_body } = useStyles();

    const getItemsTitle = useCallback(({ id, name }) => `${id} - ${name}`, []);

    const {
        modifierGroupList,
        setModifierGroupList,
        handleRemoveFromModifierGroupList,
        modifyGroups,
    } = props;
    return (
        <div className={drag_container_body}>
            <MultiSelectAutocomplete
                getItemsTitle={getItemsTitle}
                options={modifyGroups}
                selectedItems={modifierGroupList}
                setSelectedItems={setModifierGroupList}
                inputLabel="Add item to modifier group"
                testId="add-item-to-modifier-group"
            />
            <DragModifierGroup
                itemList={modifierGroupList}
                setItemList={setModifierGroupList}
                removeItem={handleRemoveFromModifierGroupList}
            />
        </div>
    );
};

export default memo(DragModifierGroupContainer);
