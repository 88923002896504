import { Button, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReadOnlyWrapper from "../../../components/ReadOnlyWrapper";
import { componentTypeConfig, MenuType } from "../../../constants/menu";
import { selectedRestaurantCodeSelector } from "../../../selectors/restaurant";
import { DATA_TABLE_DEFAULT_STYLING } from "../../../utils/data-tables";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
    bulkEditBtn: {
        marginRight: theme.spacing(1.25),
    },
}));

export default function AddNewItem({
    type,
    showBulkEdit,
    onBulkEdit,
    hasSelectedItems,
    onExportItems,
}: {
    type: keyof typeof MenuType;
    showBulkEdit?: boolean;
    onBulkEdit?: (isBulkEditSelected: boolean) => void;
    hasSelectedItems?: boolean;
    onExportItems?: () => void;
}) {
    const classes = useStyles();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { title, url } = componentTypeConfig[type];
    const { search } = useLocation();
    const [isBulkEditSelected, setIsBulkEditSelected] = useState<boolean>(
        false
    );

    const handleBulkEdit = useCallback(() => {
        setIsBulkEditSelected(!isBulkEditSelected);
        onBulkEdit?.(!isBulkEditSelected);
    }, [onBulkEdit, isBulkEditSelected]);

    return (
        <>
            {showBulkEdit && (
                <ReadOnlyWrapper
                    element={Button}
                    variant={isBulkEditSelected ? "outlined" : "contained"}
                    color={isBulkEditSelected ? "default" : "secondary"}
                    disableElevation
                    className={classes.bulkEditBtn}
                    data-testid="bulk-edit-btn"
                    onClick={handleBulkEdit}
                >
                    {isBulkEditSelected ? "Cancel" : "Bulk Export"}
                </ReadOnlyWrapper>
            )}

            {isBulkEditSelected ? (
                <ReadOnlyWrapper
                    element={Button}
                    variant="contained"
                    color="secondary"
                    className={classes.newBtn}
                    disableElevation
                    disabled={!hasSelectedItems}
                    data-testid="export-items-btn"
                    onClick={onExportItems}
                >
                    {`Export ${title}s`}
                </ReadOnlyWrapper>
            ) : (
                <ReadOnlyWrapper
                    element={Button}
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    component={Link}
                    to={{
                        pathname: `/${restaurantCode}/menu-editor/${url}/new`,
                        search,
                    }}
                    className={classes.newBtn}
                    disableElevation
                    data-testid={`new-btn-${type}`}
                >
                    {`New ${title}`}
                </ReadOnlyWrapper>
            )}
        </>
    );
}
