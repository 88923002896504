import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import { TextField } from "mui-rff";
import React from "react";
import { useAlert } from "react-alert";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { createMenuVersion } from "../../reducers/menuReducer";
import { CreateMenuVersionResponseType } from "../../types/menuVersion";
import { nomenclatureTextList, TIME_OUT } from "../../utils/constants";

export interface ICommitDialogProps {
    open: boolean;
    setIsDialogDisabled: (o: boolean) => void;
    toggle: () => void;
    onCommitSuccess?: () => void;
}

const useStyles = makeStyles(({ spacing }) => ({
    nomenclatureBody: {
        margin: `${spacing(0.75)}px 0`,
    },
    nomenclatureList: {
        paddingLeft: spacing(2),
        margin: 0,
    },
}));

export default function CommitDialog({
    open,
    setIsDialogDisabled,
    toggle,
    onCommitSuccess,
}: ICommitDialogProps) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const validate = async ({ comment }: { comment: string }) =>
        !comment && { comment: "Menu title is required" };
    const classes = useStyles();

    const saveMenuVersion = ({ comment }: { comment: string }) => {
        setIsDialogDisabled(true);
        dispatch(
            createMenuVersion({
                comment,
                successCallback: (response: CreateMenuVersionResponseType) => {
                    alert.success(
                        <div>
                            New version of Menu will be created soon for
                            request_id: {response?.data?.request_id}
                        </div>,
                        {
                            timeout: TIME_OUT,
                        }
                    );
                    toggle();
                    onCommitSuccess?.();
                    setIsDialogDisabled(false);
                },
                errorCallback: (error: any) => {
                    alert.error(
                        <div>
                            <span>Error creating menu version</span>
                            <div>{error?.message}</div>
                        </div>,
                        {
                            timeout: TIME_OUT,
                        }
                    );
                    setIsDialogDisabled(false);
                },
            })
        );
    };

    return (
        <>
            <Dialog open={open} onClose={toggle}>
                <DialogTitle>
                    Save ongoing draft Menu to Menu Version
                </DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={saveMenuVersion}
                        validate={validate}
                        id="saveMenuVersion"
                        render={({ handleSubmit }) => (
                            <form
                                onSubmit={handleSubmit}
                                name="saveMenuVersion"
                                id="saveMenuVersion"
                                data-testid="saveMenuVersion"
                            >
                                <TextField
                                    autoComplete="off"
                                    label="Menu Title"
                                    name="comment"
                                    type="text"
                                    inputProps={{
                                        "data-testid": "save-version-input",
                                        maxLength: 100,
                                    }}
                                    helperText="Please add Menu Title"
                                />
                                <div className={classes.nomenclatureBody}>
                                    <b>Menu Title Format:</b>
                                    <ol className={classes.nomenclatureList}>
                                        {nomenclatureTextList.map((item) => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ol>
                                    <span>
                                        *DCT-### refers to the DCT Ticket Number
                                    </span>
                                </div>
                            </form>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        form="saveMenuVersion"
                        color="primary"
                    >
                        Save
                    </Button>
                    <Button onClick={toggle} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
