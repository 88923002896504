import { UserState } from "../reducers/userReducer";
import logger from "./logger";
import { nullOrUndefined } from "./types";

export const USER_STATE_KEY = "user-info";
export const USER_STATE_VERSION_KEY = "user-info-version";

export const AUTH_TOKEN_KEY = "auth-token";
export const RESTAURANT_TOKEN_KEY = "restaurant-tokens";

export const AUTO_PRINTED_ORDER_IDS = "auto-printed-order-ids";
const GOOGLE_TOKEN = "google-token";

const SESSION_ID = "session-id";

export const getAutoPrintedOrderIds = () => {
    return localStorage.getItem(AUTO_PRINTED_ORDER_IDS);
};

export const setAutoPrintedOrderIds = (orderIds: string) => {
    return localStorage.setItem(AUTO_PRINTED_ORDER_IDS, orderIds);
};

export const saveAuthToken = (authToken: string | nullOrUndefined) => {
    if (authToken) {
        logger.debug(`Saving ${AUTH_TOKEN_KEY} to local storage`);
        localStorage.setItem(AUTH_TOKEN_KEY, authToken);
    } else {
        logger.debug(`Removing ${AUTH_TOKEN_KEY} from local storage`);
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }
};

export const getAuthToken = (): string | undefined => {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
    if (authToken && authToken !== "") {
        logger.debug(`Retrieving ${AUTH_TOKEN_KEY} from local storage`);
        return authToken;
    }
    logger.debug(`No ${AUTH_TOKEN_KEY} found in local storage`);
    return undefined;
};

export const saveRestaurantAccessToken = (
    restaurantToken: string | nullOrUndefined,
    restaurantCode: string = ""
) => {
    if (restaurantToken && restaurantCode) {
        logger.debug(
            `Saving ${RESTAURANT_TOKEN_KEY} for '${restaurantCode}' to local storage`
        );
        const tokens = localStorage.getItem(RESTAURANT_TOKEN_KEY);
        let tokensObj;
        if (tokens && tokens !== "") {
            tokensObj = {
                ...JSON.parse(tokens),
                [restaurantCode]: restaurantToken,
            };
        } else {
            tokensObj = { [restaurantCode]: restaurantToken };
        }
        localStorage.setItem(RESTAURANT_TOKEN_KEY, JSON.stringify(tokensObj));
    }
};

export const getRestaurantAccessToken = (
    restaurantCode: string
): string | undefined => {
    const tokensStr = localStorage.getItem(RESTAURANT_TOKEN_KEY);
    const tokensObj = JSON.parse(tokensStr || "{}");
    if (tokensObj[restaurantCode] !== undefined) {
        logger.debug(
            `Retrieving ${RESTAURANT_TOKEN_KEY} for '${restaurantCode}' from local storage`
        );
        return tokensObj[restaurantCode];
    }
    logger.debug(
        `No ${RESTAURANT_TOKEN_KEY} for '${restaurantCode}' found in local storage`
    );
    return undefined;
};

export const resetRestaurantAccessToken = () => {
    logger.debug(`Removing ${RESTAURANT_TOKEN_KEY} from local storage`);
    localStorage.removeItem(RESTAURANT_TOKEN_KEY);
};

// Change this versionNumber whenever the format for user state changes so we know to delete the old data
export const userStateVersion = "1.0.1";
export const getUserState = (): UserState | undefined => {
    // If called on server, localStorage doesn't exist
    if (typeof window === "undefined") {
        logger.debug('Not loading user state b/c "window" is not defined');
        return undefined;
    }
    const serializedState = localStorage.getItem(USER_STATE_KEY);
    if (serializedState === null) {
        logger.debug("No previous user state found");
        return undefined;
    }
    const foundUserStateVersion = localStorage.getItem(USER_STATE_VERSION_KEY);
    if (!foundUserStateVersion || foundUserStateVersion !== userStateVersion) {
        logger.debug("User state version is out of date. Disregarding");
        localStorage.removeItem(USER_STATE_KEY);
        return undefined;
    }
    const userState = JSON.parse(serializedState);
    if (userState) {
        logger.debug("Restoring previous user state");
        return userState;
    }
    logger.debug("User state is falsey");
    return undefined;
};

export const saveUserState = (userState: UserState) => {
    logger.debug("Saving user state to local storage");
    const serializedState = JSON.stringify(userState);
    localStorage.setItem(USER_STATE_KEY, serializedState);
    localStorage.setItem(USER_STATE_VERSION_KEY, userStateVersion);
};

export const saveSessionID = (sessionid: string | nullOrUndefined) => {
    if (sessionid) {
        logger.debug("Saving session id local storage");
        localStorage.setItem(SESSION_ID, sessionid);
    } else {
        logger.debug("Removing session id from local storage");
        localStorage.removeItem(SESSION_ID);
    }
};

export const getSessionID = (): string | null => {
    return localStorage.getItem(SESSION_ID);
};
export const saveGoogleId = (id: string | nullOrUndefined) => {
    if (id) {
        logger.debug("Saving Google id local storage");
        localStorage.setItem(GOOGLE_TOKEN, id);
    } else {
        logger.debug("Removing Google id from local storage");
        localStorage.removeItem(GOOGLE_TOKEN);
    }
};

export const getGoogleId = (): string | null =>
    localStorage.getItem(GOOGLE_TOKEN);
