import {
    Category,
    MenuItem,
    MenuItemSetting,
    MenuOverride,
    ModifierGroup,
    PosProperties,
    TimePeriod,
    UpdateMenuItemInput,
    UpdateTimePeriodInput,
} from "../generated-interfaces/graphql";
import { CallbackFunction, GetIngestedDataType } from "../utils/types";

type WithVoiceProperties<T extends object> = T & {
    voiceProperties?: InputProperties[];
};

export type CategoryType = Category & CallbackFunction;
export type MenuItemType = WithVoiceProperties<
    UpdateMenuItemInput & CallbackFunction
>;
export type ModifierGroupType = Omit<ModifierGroup, "posProperties"> & {
    posProperties: ModifierGroup["posPropertyMap"];
} & CallbackFunction;

export type TimePeriodInputType = UpdateTimePeriodInput &
    CallbackFunction & { id?: number };

export type BasicAPIResponseType = { status: string; message: string };

export type UserSession = {
    user_session_id: string;
    source_module: string;
    email_id: string;
    first_name: string;
    last_name: string;
    user_name: string;
    unique_user_id: string;
    force_reload?: number;
    restaurant_code?: string;
    active: number;
};

export enum RequestStatus {
    success = "SUCCESS",
    pending = "PENDING",
    failed = "FAILED",
    inProgress = "IN_PROGRESS",
}

export enum RequestType {
    commit = "Save Version",
    promote = "Promote",
    commitActive = "Commit Active",
    restore = "Restore",
    copy = "Copy",
    clone = "Clone",
}

export enum ComponentType {
    modifier = "Modifier Group",
    menuItem = "MenuItem",
    category = "Category",
}

export enum MenuItemSettingKey {
    IsDineInEnabled = "IS_DINE_IN_ENABLED",
    IsToGoEnabled = "IS_TO_GO_ENABLED",
    IsDeliveryEnabled = "IS_DELIVERY_ENABLED",
    PosId = "POS_ID",
    ScreenNumber = "SCREEN_NUMBER",
    CellNumber = "CELL_NUMBER",
    IsRefillEnabled = "IS_REFILL_ENABLED",
    IsAlcoholicItem = "IS_ALCOHOLIC_ITEM",
}

export interface IDuplicatePayload extends CallbackFunction {
    id: number;
}
export type IActiveUserReqType = CallbackFunction & {
    restaurantCode: string;
};

export interface IItem {
    id: string;
    name: string;
    type: string;
}
export interface InputProperties {
    key: string;
    value: string | null;
}

export interface LegacyMenuSchema {
    menuItems: MenuItem[];
    modifierGroups: ModifierGroup[];
    categories: Category[];
    menuOverrides: MenuOverride[];
    menuItemSettings: MenuItemSetting[];
    timePeriods: TimePeriod[];
    posProperties: PosProperties[];
    voiceProperties: GetIngestedDataType[];
}

export interface MenuSchemaV1 {
    menuItems: MenuItem[];
    modifierGroups: ModifierGroup[];
    categories: Category[];
    menuOverrides: MenuOverride[];
    timePeriods: TimePeriod[];
}
