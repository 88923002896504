import {
    CircularProgress,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { memo, ReactNode, useCallback } from "react";
import Dropzone, {
    IFileWithMeta,
    ILayoutProps,
    IPreviewProps,
    Preview,
    StatusValue,
} from "react-dropzone-uploader";
import { StringOrNull } from "../../utils/types";
import ReadOnlyWrapper from "../ReadOnlyWrapper";
import FileUploadLayout from "./Layout";

const useFileUploadStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
    body: { marginBottom: spacing(2.5) },
    header: {
        fontSize: spacing(1.75),
        fontWeight: "bold",
        paddingBottom: spacing(1.25),
    },
    uploadContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        width: spacing(50),
        height: spacing(37.5),
        padding: spacing(3),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        [breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    chooseFile: {
        padding: spacing(1, 2),
        minWidth: spacing(17.5),
    },
    previewContainer: {
        position: "relative",
    },
    loaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        inset: 0,
        zIndex: 1,
        background: "#ccc",
        opacity: 0.5,
    },
}));

interface IFileUploadProps {
    accept: string;
    /** @default 'Upload file' */
    buttonLabel?: ReactNode;
    deleteOriginalFile?: () => void;
    fileUrl?: StringOrNull;
    header: string;
    instructions?: ReactNode;
    isUploading: boolean;
    /** @default 'Drag and drop file here or' */
    placeholder?: string;
    title: string;
    uploadFile: (file: File) => void;
    onFileDelete?: Function;
}

function FileUpload({
    accept,
    buttonLabel = "Upload file",
    deleteOriginalFile,
    fileUrl,
    header,
    instructions,
    isUploading,
    placeholder = "Drag and drop file here or",
    title,
    uploadFile,
    onFileDelete,
}: IFileUploadProps) {
    const classes = useFileUploadStyles();

    const handleSubmit = useCallback(
        (files: IFileWithMeta[]) => {
            if (files.length && files[0].file) {
                uploadFile(files[0].file);
            }
        },
        [uploadFile]
    );

    const handleChangeStatus = useCallback(
        (_: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => {
            if (status === "done" && allFiles.length && allFiles[0].file) {
                uploadFile(allFiles[0].file);
            }
        },
        [uploadFile]
    );

    const generateLayoutWithProps = useCallback(
        (layoutProps: ILayoutProps) => (
            <FileUploadLayout
                {...layoutProps}
                isUploading={isUploading}
                deleteOriginalFile={deleteOriginalFile}
                fileUrl={fileUrl}
                instructions={instructions}
                title={title}
                onDeleteComplete={onFileDelete}
            />
        ),
        [deleteOriginalFile, fileUrl, instructions, isUploading, title]
    );

    const generatePreviewComponent = useCallback(
        (props: IPreviewProps) => (
            <div
                className={clsx(
                    classes.uploadContainer,
                    classes.previewContainer
                )}
            >
                {isUploading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress />
                    </div>
                )}
                <Preview {...props} />
            </div>
        ),
        [classes, isUploading]
    );

    return (
        <div className={classes.body}>
            <Typography className={classes.header} gutterBottom>
                {header}
            </Typography>
            <ReadOnlyWrapper
                element={Dropzone}
                accept={accept}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent={
                    <div
                        key="file-dropzone-preview"
                        className={classes.uploadContainer}
                    >
                        {!fileUrl && (
                            <>
                                <p>{placeholder}</p>
                                <div
                                    className={clsx(
                                        classes.chooseFile,
                                        "chooseFile",
                                        "MuiButton-root",
                                        "MuiButton-containedSecondary"
                                    )}
                                >
                                    {buttonLabel}
                                </div>
                            </>
                        )}
                    </div>
                }
                PreviewComponent={generatePreviewComponent}
                LayoutComponent={generateLayoutWithProps}
                onSubmit={handleSubmit}
                canRemove={true}
                onChangeStatus={handleChangeStatus}
            />
        </div>
    );
}

export default memo(FileUpload);
