import { Button, PropTypes } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { isEntitySavingSelector } from "../../utils/menu";

interface Props {
    type?: "submit" | "reset" | "button";
    color?: PropTypes.Color;
    onClick?: () => void;
    disabled: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export function EntitySaveButton(props: Props) {
    const isEntitySaving = useSelector(isEntitySavingSelector);
    let buttonText = "Save";
    if (isEntitySaving) {
        buttonText = "Saving";
    }
    return (
        <Button
            disableElevation
            type={props.type || "submit"}
            color={props.color || "secondary"}
            variant="contained"
            disabled={props.disabled || isEntitySaving}
            className={props.className}
            style={props.style}
        >
            {buttonText}
        </Button>
    );
}
