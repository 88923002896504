import { useEffect, useRef } from "react";
import logger from "../utils/logger";

export default function useRecursiveTimeout<T>(
    callback: () => Promise<T> | (() => void),
    delay: number | null,
    key: string
) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the timeout loop.
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const tick = () => {
            const ret = savedCallback.current();

            if (ret instanceof Promise) {
                ret.finally(() => {
                    if (delay !== null) {
                        timeoutId = setTimeout(tick, delay);
                    }
                });
            } else {
                if (delay !== null) {
                    timeoutId = setTimeout(tick, delay);
                }
            }
            logger.debug(`Timer Reset (${key})`, {
                timeoutId,
                duration: `${delay ? delay / 1000 : 0} sec`,
            });
        };
        if (delay !== null) {
            timeoutId = setTimeout(tick, delay);
            return () => timeoutId && clearTimeout(timeoutId);
        }
    }, [delay]);
}
