import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FC, memo } from "react";
import ToggleSwitch from "./ToggleSwitch";

const useStyles = makeStyles((theme: Theme) => ({
    sectionClass: {
        paddingTop: theme.spacing(1),
    },
    toggleClass: {
        width: theme.spacing(16),
    },
    subheadingClass: {
        color: "black",
    },
}));

interface IVoiceConfigEnablerProps {
    label: string;
    checkedValue: boolean;
    testId: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const VoiceConfigEnabler: FC<IVoiceConfigEnablerProps> = ({
    label,
    checkedValue,
    testId,
    onChange,
}) => {
    const { sectionClass, subheadingClass, toggleClass } = useStyles();

    return (
        <Grid
            container
            item
            xs={12}
            alignItems="center"
            className={sectionClass}
        >
            <Grid item xs={4}>
                <Typography className={subheadingClass}>{label}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography component="div" className={toggleClass}>
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>Off</Grid>
                        <Grid item>
                            <ToggleSwitch
                                checked={checkedValue}
                                onChange={onChange}
                                data-testid={testId}
                            />
                        </Grid>
                        <Grid item>On</Grid>
                    </Grid>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default memo(VoiceConfigEnabler);
