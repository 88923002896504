import {
    Chip,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Autocomplete, TextField, TextFieldProps } from "mui-rff";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import {
    RestaurantAccountType,
    RestaurantGroup,
} from "../../generated-interfaces/graphql";
import {
    createRestaurant,
    updateRestaurant,
} from "../../reducers/userManagementReducer";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    restaurantGroupsSelector,
    restaurantTreeSelector,
} from "../../selectors/userManagement";
import { TIME_OUT } from "../../utils/constants";
import { CallbackFunction, RestaurantDetails } from "../../utils/types";

interface IParams {
    id: string;
}

const basicTextFieldProp: TextFieldProps = {
    name: "name",
    fullWidth: true,
    required: true,
    margin: "normal",
};

const useStyles = makeStyles((theme: Theme) => ({
    saveBtn: {
        float: "right",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
}));

function RestaurantDetail(props: Props) {
    const { id } = useParams<IParams>();
    const history = useHistory();
    const classes = useStyles();
    const alert = useAlert();

    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [restaurantGroupSelections, setRestaurantGroupSelections] = useState<
        RestaurantGroup[]
    >([]);
    const restaurantsMap = useSelector(restaurantTreeSelector);
    const restaurantGroupsMap = useSelector(restaurantGroupsSelector);
    const restaurantGroups = Object.values(restaurantGroupsMap);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const initialValues = restaurantsMap[id]; // Temp Value
    useEffect(() => {
        if (id !== "new" && initialValues) {
            const groups = Object.values(initialValues.restaurantGroupsMap);
            setRestaurantGroupSelections([...groups]);
        }
    }, [initialValues]);

    const handleGoBack = (isFormDirty: boolean) => {
        if (isFormDirty) setShowBackConfirmModal(true);
        else history.push(`/${restaurantCode}/user-management/restaurants`);
    };

    const onSubmit = (data: any) => {
        const restaurantGroupIds = restaurantGroupSelections.map((restaurant) =>
            parseInt(restaurant.id)
        );
        const payload = ({
            restaurantName: data.restaurantName?.trim(),
            restaurantCode: data.restaurantCode,
            accountType: RestaurantAccountType.Live,
            restaurantContactInfo: data.restaurantContactInfo,
            restaurantGroupIds,
            successCallback: () => {
                alert.success("Restaurant Saved", {
                    timeout: TIME_OUT,
                });
            },
            errorCallback: () => {
                alert.error("Error Saving Restaurant", {
                    timeout: TIME_OUT,
                });
            },
        } as unknown) as RestaurantDetails;
        if (id === "new") {
            props.createRestaurant(payload);
        } else {
            props.updateRestaurant(payload);
        }
    };

    async function validate(values: any) {
        if (values) {
            if (values.restaurantName?.trim() === "") {
                return { restaurantName: "Restaurant Name is required" };
            }
            if (values.restaurantCode?.trim() === "") {
                return { restaurantCode: "Restaurant Code is required" };
            }
            if (!values.restaurantContactInfo?.address) {
                return { required: "Address is required" };
            }
            if (!values.restaurantContactInfo?.city) {
                return { required: "City is required" };
            }
            if (!values.restaurantContactInfo?.zipCode) {
                return { required: "Zip Code is required" };
            }
            if (!values.restaurantContactInfo?.state) {
                return { required: "State is required" };
            }
            if (!values.restaurantContactInfo?.country) {
                return { required: "Country is required" };
            }
        }
    }

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, values, valid, dirty }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        {showBackConfirmModal && (
                            <ConfirmDialog
                                title="Leave without saving?"
                                content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                                open={showBackConfirmModal}
                                onSuccess={() => {
                                    history.push(
                                        `/${restaurantCode}/user-management/restaurants`
                                    );
                                }}
                                onCancel={() => setShowBackConfirmModal(false)}
                            />
                        )}
                        <IconButton onClick={() => handleGoBack(dirty)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <EntitySaveButton
                            disabled={!valid}
                            className={classes.saveBtn}
                        />
                        <TextField
                            {...basicTextFieldProp}
                            name="restaurantName"
                            placeholder="Restaurant Name"
                            label="Restaurant Name"
                            required
                        />
                        <TextField
                            {...basicTextFieldProp}
                            name="restaurantCode"
                            placeholder="Restaurant Code"
                            label="Restaurant Code"
                            disabled={id !== "new"}
                            required
                        />
                        <Typography
                            variant="h6"
                            color="primary"
                            style={{ marginTop: "22px", marginBottom: "12px" }}
                        >
                            Restaurant Information
                        </Typography>
                        <Typography>Restaurant Location</Typography>
                        <Grid container style={{ maxWidth: "600px" }}>
                            <Grid item xs={12}>
                                <TextField
                                    {...basicTextFieldProp}
                                    name="restaurantContactInfo.address"
                                    placeholder="Number & State"
                                    label="Number & State"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <TextField
                                    {...basicTextFieldProp}
                                    name="restaurantContactInfo.city"
                                    placeholder="City"
                                    label="City"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <TextField
                                    {...basicTextFieldProp}
                                    name="restaurantContactInfo.zipCode"
                                    placeholder="ZIP Code"
                                    label="Zip Code"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <TextField
                                    name="restaurantContactInfo.state"
                                    placeholder="State"
                                    label="State"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <TextField
                                    name="restaurantContactInfo.country"
                                    placeholder="Country"
                                    label="Country"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={{ maxWidth: "800px" }}>
                            <Autocomplete
                                multiple
                                autoSelect
                                autoHighlight
                                fullWidth
                                id="restaurantGroups"
                                name="restaurantGroups"
                                label="Add Restaurant Group"
                                options={restaurantGroups}
                                value={restaurantGroupSelections}
                                onChange={(event, value) =>
                                    setRestaurantGroupSelections(
                                        value as RestaurantGroup[]
                                    )
                                }
                                getOptionLabel={(option) => option.groupName}
                                getOptionValue={(option) => option}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip
                                            variant="default"
                                            label={option.groupName}
                                            {...getTagProps({ index })}
                                        />
                                    ));
                                }}
                            />
                        </Grid>
                    </form>
                )}
            />
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createRestaurant: (data: RestaurantDetails & CallbackFunction) =>
            dispatch(createRestaurant(data)),
        updateRestaurant: (data: RestaurantDetails & CallbackFunction) =>
            dispatch(updateRestaurant(data)),
    };
};

const connected = connect(null, mapDispatchToProps);
type Props = ConnectedProps<typeof connected>;

export default connected(RestaurantDetail);
