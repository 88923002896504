import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HoursOfOperationInput } from "../generated-interfaces/graphql";
import { MenuStages } from "../types/menuVersion";
import {
    IAIEnablementUpdatePayload,
    ITREnablementUpdatePayload,
    ITTSchedulerUpdatePayload,
    ITTSOperationHour,
    Restaurant,
    RestaurantBranding,
    RestaurantContactInfo,
    RestaurantSettings,
    TTSHoursOfOperationInput,
    UserRestaurantsRole,
} from "../types/restaurant";
import {
    getHighestAccessRestaurant,
    getRestaurantAccessLevels,
    RestaurantAccess,
} from "../utils/restaurants";
import {
    CallbackFunction,
    RestaurantUpdateDetails,
    StringOrNull,
} from "../utils/types";
import { USER_ACTIONS } from "./userReducer";

const getDefaultSelectedStage = () => {
    let defaultSelectedStage = localStorage.getItem(
        "restaurant-selected-stage"
    );
    if (!defaultSelectedStage) {
        defaultSelectedStage = MenuStages.PLAYGROUND;
        localStorage.setItem("restaurant-selected-stage", defaultSelectedStage);
    }
    return defaultSelectedStage;
};

export interface RestaurantState {
    selectedRestaurant: Restaurant | null;
    selectedRestaurantCode: StringOrNull;
    restaurantsByUserRole: UserRestaurantsRole[];
    restaurantAccessLevels: RestaurantAccess;
    userRolesHaveLoaded: boolean;
    primaryRestaurantCode: StringOrNull;
    selectedStage: string;
    ttsOperationHours: ITTSOperationHour;
    isTREnabled: number | null;
    isTTSSchedulerEnabled: boolean | null;
    isAIEnabled: boolean;
    isRestaurantStatusReceived: boolean;
}

export const initialRestaurantState: RestaurantState = {
    selectedRestaurant: null,
    selectedRestaurantCode: null,
    restaurantsByUserRole: [],
    restaurantAccessLevels: {},
    userRolesHaveLoaded: false,
    primaryRestaurantCode: null,
    selectedStage: getDefaultSelectedStage(),
    ttsOperationHours: {
        restaurantCode: "",
        timezone: "",
        ttsHoursOfOperations: [],
    },
    isTREnabled: null,
    isTTSSchedulerEnabled: null,
    isAIEnabled: false,
    isRestaurantStatusReceived: false,
};

export const getRestaurantRolesAction = createAction("GET_RESTAURANT_ROLES");
export const updateRestaurantContactInfo = createAction<
    RestaurantContactInfo & CallbackFunction
>("UPDATE_RESTAURANT_INFO");
export const updateRestaurantSettings = createAction<
    RestaurantSettings & CallbackFunction
>("UPDATE_RESTAURANT_SETTINGS");
export const updateRestaurantBranding = createAction<
    RestaurantBranding & CallbackFunction
>("UPDATE_RESTAURANT_BRANDING");
export const updateHoursOfOperation = createAction<
    HoursOfOperationInput & CallbackFunction
>("UPDATE_HOURS_OF_OPERATION");
export const createHoursOfOperation = createAction<
    HoursOfOperationInput & CallbackFunction
>("CREATE_HOURS_OF_OPERATION");
export const updatePrimaryRestaurant = createAction<
    RestaurantUpdateDetails & CallbackFunction
>("UPDATE_PRIMARY_RESTAURANT");
export const updateStage = createAction<
    { stage: MenuStages } & CallbackFunction
>("UPDATE_STAGE");
export const getTTSHoursOfOperation = createAction<CallbackFunction>(
    "FETCH_TTS_HOURS_OF_OPERATION"
);
export const createTTSHoursOfOperation = createAction<
    TTSHoursOfOperationInput & CallbackFunction
>("CREATE_TTS_HOURS_OF_OPERATION");
export const updateTTSHoursOfOperation = createAction<
    TTSHoursOfOperationInput & CallbackFunction
>("UPDATE_TTS_HOURS_OF_OPERATION");

export const getTREnablement = createAction<string>("FETCH_TR_ENABLEMENT");
export const createTREnablement = createAction<
    ITREnablementUpdatePayload & CallbackFunction
>("CREATE_TR_ENABLEMENT");
export const updateTREnablement = createAction<
    ITREnablementUpdatePayload & CallbackFunction
>("UPDATE_TR_ENABLEMENT");

export const getTTSScheduler = createAction<string>("FETCH_TTS_SCHEDULER");
export const createTTSScheduler = createAction<
    ITTSchedulerUpdatePayload & CallbackFunction
>("CREATE_TTS_SCHEDULER");
export const updateTTSScheduler = createAction<
    ITTSchedulerUpdatePayload & CallbackFunction
>("UPDATE_TTS_SCHEDULER");

export const getAIEnablement = createAction<string>("GET_AI_ENABLEMENT");
export const updateAIEnablement = createAction<
    IAIEnablementUpdatePayload & CallbackFunction
>("UPDATE_AI_ENABLEMENT");

export const restaurantState = createSlice({
    name: "restaurant",
    initialState: initialRestaurantState,
    reducers: {
        selectPrimaryRestaurant: (
            state,
            action: PayloadAction<{
                restaurantCode: string;
                primaryRestaurantCode?: string;
            }>
        ) => {
            state.selectedRestaurantCode = action.payload.restaurantCode;
            if (action.payload.primaryRestaurantCode) {
                if (state.selectedRestaurant) {
                    state.selectedRestaurant.primaryRestaurantCode =
                        action.payload.primaryRestaurantCode;
                }
                state.primaryRestaurantCode =
                    action.payload.primaryRestaurantCode;
            } else {
                state.primaryRestaurantCode = null;
                if (state.selectedRestaurant)
                    state.selectedRestaurant.primaryRestaurantCode = null;
            }
        },
        selectRestaurant: (state, action: PayloadAction<string>) => {
            state.selectedRestaurantCode = action.payload;
        },
        selectStage: (state, action: PayloadAction<string>) => {
            localStorage.setItem("restaurant-selected-stage", action.payload);
            state.selectedStage = action.payload;
        },
        restaurantInfoLoadSuccess: (
            state,
            action: PayloadAction<Restaurant>
        ) => {
            state.selectedRestaurant = action.payload;
            state.selectedRestaurantCode = action.payload.restaurantCode;
            state.primaryRestaurantCode = action.payload.primaryRestaurantCode;
        },
        userRolesLoadSuccess: (
            state,
            action: PayloadAction<UserRestaurantsRole[]>
        ) => {
            state.restaurantsByUserRole = action.payload;
            state.restaurantAccessLevels = getRestaurantAccessLevels(
                action.payload
            );
            state.userRolesHaveLoaded = true;
            const preSelectedRestaurant = getHighestAccessRestaurant(
                action.payload
            );
            if (preSelectedRestaurant) {
                state.selectedRestaurantCode =
                    preSelectedRestaurant.restaurantCode;
                state.primaryRestaurantCode =
                    preSelectedRestaurant.primaryRestaurantCode;
            }
        },
        userRolesLoadFailure: (state) => {
            state.userRolesHaveLoaded = true;
        },
        ttsHoursOfOperationSuccess: (
            state,
            action: PayloadAction<ITTSOperationHour>
        ) => {
            state.ttsOperationHours = action.payload;
        },
        updateTREnablement: (state, action: PayloadAction<number | null>) => {
            state.isTREnabled = action.payload;
        },
        updateTTSScheduler: (state, action: PayloadAction<boolean | null>) => {
            state.isTTSSchedulerEnabled = action.payload;
        },
        updateAIEnablement: (state, action: PayloadAction<boolean>) => {
            state.isAIEnabled = action.payload;
        },
        updateIsUnsupervisedAI: (
            state,
            action: PayloadAction<{ isEnabled: boolean }>
        ) => {
            if (state.selectedRestaurant) {
                state.selectedRestaurant.restaurantSettings.isUnsupervisedAI =
                    action.payload.isEnabled;
            }
        },
        updateIsRestaurantStatusRecieved: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.isRestaurantStatusReceived = action.payload;
        },
    },
    extraReducers: {
        [USER_ACTIONS?.logout.toString()]: () => {
            return initialRestaurantState;
        },
        [USER_ACTIONS?.loginSuccess.toString()]: (state) => {
            state.userRolesHaveLoaded = false;
        },
    },
});

export const RESTAURANT_ACTIONS = restaurantState.actions;
