import { all, fork } from "redux-saga/effects";
import categorySaga from "./categorySaga";
import menuSaga from "./menuSaga";
import menuVersionSaga from "./menuVersionSaga";
import modifierGroupSaga from "./modifierGroupSaga";
import restaurantSaga from "./restaurantSaga";
import userManagementSaga from "./userManagementSaga";
import userSaga from "./userSaga";

export function* rootSaga() {
    yield all([
        fork(userSaga),
        fork(menuSaga),
        fork(menuVersionSaga),
        fork(restaurantSaga),
        fork(categorySaga),
        fork(modifierGroupSaga),
        fork(userManagementSaga),
    ]);
}
