import { TextField, TextFieldProps } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { INTEGER_SIGNS } from "../../utils/constants";
import ReadOnlyWrapper from "../ReadOnlyWrapper";

type Props = TextFieldProps & {
    value: string;
    handleUpdate: (newValue: string) => void;
    prefixText?: string;
    suffixText?: string;
};

export default function NumericInput(props: Props) {
    const {
        value,
        prefixText,
        suffixText,
        handleUpdate,
        className = "",
        ...otherProps
    } = props;
    const [internalValue, setInternalValue] = useState<string>();

    useEffect(() => {
        setInternalValue(value);
    }, [value]);
    let valueStr = prefixText ? `${prefixText}${internalValue}` : internalValue;
    if (suffixText) valueStr += suffixText;
    return (
        <ReadOnlyWrapper
            element={TextField}
            className={`custom-input-gray custom-input-gray-numeric ${className}`}
            value={valueStr}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                let newValue = e.target.value?.trim();
                newValue = suffixText
                    ? newValue.replace(suffixText, "")
                    : newValue;
                newValue = prefixText
                    ? newValue.replace(prefixText, "")
                    : newValue;
                const dotLength = newValue.split("").filter((ch) => ch === ".")
                    .length;

                if (newValue === ".") {
                    // Change "." to "0."
                    setInternalValue("0.");
                } else if (
                    // Change "01" to "1" and do not change "0."
                    newValue.length > 1 &&
                    newValue[0] === "0" &&
                    dotLength === 0
                ) {
                    setInternalValue(newValue.substring(1));
                } else if (
                    (!isNaN(newValue as any) && dotLength <= 1) ||
                    INTEGER_SIGNS.includes(newValue)
                ) {
                    setInternalValue(newValue);
                }
            }}
            // Will Update Value when focus is changed.
            // This will help us to do various kinds of validation on onChange function.
            onBlurCapture={() => {
                if (!internalValue || INTEGER_SIGNS.includes(internalValue)) {
                    setInternalValue("0");
                    handleUpdate("0.00");
                } else {
                    if (Number.isInteger(parseFloat(internalValue))) {
                        handleUpdate(parseFloat(internalValue) + ".00");
                    } else if (
                        !Number.isNaN(internalValue) &&
                        internalValue.split("").filter((ch) => ch === ".")
                            .length <= 1
                    ) {
                        handleUpdate(
                            parseFloat(internalValue).toFixed(2).toString()
                        );
                    }
                }
            }}
            {...otherProps}
        />
    );
}
